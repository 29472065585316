import { useState } from 'react';

// 3p
import { SubmitHandler, useForm } from 'react-hook-form';

// app
import Loader from 'components/common/Loader';

import { useAuth } from 'hooks/useAuth';

import { ILoginForm } from 'interfaces';
import HttpStatusCode from 'utils/HttpStatusCode';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import styles from 'styles';
import { Link } from 'react-router-dom';
import { AlertError, AlertInfo } from 'components/common/alert';
import axios from 'axios';
import { ModalLayout } from 'components/common';

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: (e: unknown) => void;
}

const LoginModal = (props: LoginModalProps) => {
  const { onClose, onSuccess, onError, open } = props;
  const [error, setError] = useState<string>();

  const { signin, isLoading } = useAuth();
  const { register, handleSubmit } = useForm<ILoginForm>();

  const onSubmit: SubmitHandler<ILoginForm> = async ({ email, password }) => {
    try {
      await signin(email, password);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
          const message = error.response.data as string;
          setError(message);
        }
      } else {
        setError("Errore generico, contattare l'assistenza.");
      }

      if (onError) onError(error);
    }
  };

  const renderLoginModalForm = () => (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="text-left overflow-hidden transition-all"
    >
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Accesso
          </Dialog.Title>

          <div className="bg-white sm:rounded-lg sm:px-10 mt-8">
            <AlertInfo message="Devi prima effettuare l'accesso" />
            <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              {error && (
                <div className="sm:col-span-3 mb-3">
                  <AlertError message={error} />
                </div>
              )}

              <div className="sm:col-span-3">
                <label htmlFor="email" className={styles.input.label}>
                  Indirizzo email
                </label>
                <div className={styles.input.containerRelative}>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    {...register('email', { required: true })}
                    className={styles.input.base}
                  />
                </div>
              </div>

              <div className="mt-4 sm:col-span-3">
                <label htmlFor="password" className={styles.input.label}>
                  Password
                </label>
                <div className={styles.input.containerRelative}>
                  <input
                    id="password"
                    type="password"
                    autoComplete="current-password"
                    {...register('password', { required: true })}
                    className={styles.input.base}
                  />
                </div>
              </div>
            </div>

            <div className="flex mt-6 mb-6 items-center justify-between">
              <div className="text-sm">
                <Link
                  to="/recovery"
                  className="font-medium text-pink-600 hover:text-pink-500"
                >
                  Password dimenticata ?
                </Link>
              </div>
            </div>

            <div>
              <button type="submit" className={styles.button.full}>
                Accedi
              </button>
            </div>
          </div>

          <div className="bg-white py-8 px-4 mt-4 shadow sm:rounded-lg sm:px-10 ">
            <p className="text-center text-base text-gray-700">
              Non hai un account?{' '}
              <Link
                className="text-base text-blue-500 hover:text-pink-500"
                to="/signup/customer"
              >
                Registrati
              </Link>
            </p>
          </div>
        </div>
      </div>
    </form>
  );

  const renderLoader = () => (
    <div className="align-middle w-full">
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          Caricamento
        </Dialog.Title>
        <div className="mt-5">
          <Loader />
        </div>
      </div>
    </div>
  );

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoading}>
      {renderLoginModalForm()}
    </ModalLayout>
  );
};

export default LoginModal;
