import { Fragment, useRef } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { IImageGalleryItem, IMedia2 } from 'interfaces';

import { MediaModalMenu } from './MediaModalMenu';
import { MediaModalVideo } from './MediaModalVideo';
import { MediaModalPhoto } from './MediaModalPhoto';

import ImageGallery from 'react-image-gallery';

interface MediaModalProps {
  open: boolean;
  data?: IMedia2;
  mediaArray?: IImageGalleryItem[];
  isProfilePhotos?: boolean;
  onClose: () => void;
  onDelete?: (media: IMedia2) => void;
  onSetPreview?: (media: IMedia2) => void;
}

export function MediaModal(props: MediaModalProps): JSX.Element {
  const { open, onClose, onDelete, onSetPreview, data, mediaArray, isProfilePhotos } =
    props;

  const refImg = useRef<any>(null);

  const newResArray = mediaArray?.map((item, idx) => ({
    ...item,
    isVideo: item.bunnyId ? true : false,
    index: idx,
  }));

  const renderItem = (item: any) => {
    const { isVideo, index } = item;

    if (!isVideo) return <MediaModalPhoto photo={item} />;

    if (isVideo && refImg && refImg.current && index === refImg.current.getCurrentIndex())
      return <MediaModalVideo video={item} />;

    return <Fragment />;
  };

  if (!data) {
    return <Fragment />;
  }

  const { id, photo, video, cover } = data;

  const startIndex = mediaArray?.findIndex((el) => el.id === id);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-screen justify-center p-4 items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black p-2 text-center shadow-xl transition-all w-full md:w-3/4">
                <div className="z-10 absolute top-0 right-0 pt-4 pr-4 sm:block">
                  {!isProfilePhotos ? (
                    <MediaModalMenu
                      media={data}
                      onDelete={onDelete}
                      onSetPreview={onSetPreview}
                    />
                  ) : null}
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div style={{ width: '95%' }} className="my-0 mx-auto">
                  {mediaArray ? (
                    <ImageGallery
                      ref={refImg}
                      items={newResArray || []}
                      showPlayButton={false}
                      showThumbnails={false}
                      showFullscreenButton={false}
                      startIndex={startIndex ? startIndex : 0}
                      renderItem={renderItem}
                      infinite={false}
                      lazyLoad={true}
                    />
                  ) : (
                    <>
                      {(photo || cover) && <MediaModalPhoto photo={data} />}
                      {video && <MediaModalVideo video={video} />}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
