// std
import { useCallback, useMemo, useState } from 'react';

// 3p
import { BriefcaseIcon, LocationMarkerIcon, TagIcon } from '@heroicons/react/outline';

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { AxiosError } from 'axios';

import ImageGallery from 'react-image-gallery';

// app
import { generateOriginalURL, generatePreviewURL } from 'utils';
import {
  IFile,
  ILoginForm,
  IModal,
  IPayInTipForm,
  IPhotoProfileUser,
  IUser,
} from 'interfaces';

import {
  Loader,
  AlertError,
  BadgeOnline,
  BadgeVerification,
  ProfileAlbumGalleryPreview,
  TipBuyModal,
} from 'components/common';

import { PaymentWarningAlert } from 'components/web/PaymentWarningAlert';

import { useAuth, useChat, useToast } from 'hooks';
import { useGetUserProfile } from 'api/user';

import { ProfileTabGetAllProduct } from './ProfileTabGetAllProduct';
import LoginModal from '../auth/LoginModal';
import { ProfileChatButton } from 'components/web/ProfileChatButton';
import { ROLES } from 'config';

interface ITipButtonProps {
  isMyProfile: boolean;
  onTipClick: () => void;
  user?: IUser;
}

const TipButton = (props: ITipButtonProps) => {
  const { isMyProfile, onTipClick, user } = props;

  if (isMyProfile)
    return (
      <Link
        to="/app/settings/profile"
        className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
        type="button"
      >
        Modifica profilo
      </Link>
    );

  if (!user || user.role.code === ROLES.CUSTOMER)
    return (
      <button
        onClick={onTipClick}
        className="bg-pink-600 hover:bg-pink-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
        type="button"
      >
        Mandami una TIP
      </button>
    );

  return null;
};

function CreatorWebProfile(): JSX.Element {
  const [tipBuyModal, setTipBuyModal] = useState<IModal<IPayInTipForm>>({
    open: false,
  });

  const { username } = useParams<{ username: string }>() as { username: string };

  const { toastError } = useToast();

  const { user } = useAuth();

  const { createChatThread } = useChat();

  const { getUserProfileQuery } = useGetUserProfile(username);
  const { isError, error, isLoading, data } = getUserProfileQuery;

  const isMyProfile = useMemo(() => {
    if (!user) {
      return false;
    }

    return user.username === username;
  }, [user, username]);

  const tipBuyOpen = () => {
    setTipBuyModal({ open: true });
  };

  const tipBuyClose = () => {
    setTipBuyModal({ open: false });
  };

  const tipError = (error: AxiosError<string, any>) => {
    toastError({
      title: 'Impossibile inviare tip',
      message: error.response?.data,
    });
  };

  /** CHAT START */
  const navigate = useNavigate();

  const [loginModal, setloginModal] = useState<IModal<ILoginForm>>({
    open: false,
  });

  const loginModalOpen = () => {
    setloginModal({ open: true });
  };

  const loginModalClose = () => {
    setloginModal({ open: false });
  };

  const onClickChat = useCallback(async () => {
    if (!data) {
      return;
    }

    if (!user) {
      loginModalOpen();
      return;
    }

    const { username } = data;

    try {
      const { uuid } = await createChatThread(username);
      navigate(`/app/chat/${uuid}`);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user, createChatThread]);

  const profilePhotosMapper = useMemo(() => {
    if (!data) return [];

    const { image, profilePhotos } = data;

    const photos: any[] = [];

    if (!image) {
      return photos;
    }

    const { id, url } = image;

    photos.unshift({
      id: id,
      url: generatePreviewURL(url),
      thumbnail: generatePreviewURL(url),
      original: generateOriginalURL(url),
      isVideo: false,
    });

    if (!profilePhotos) {
      return photos;
    }

    for (const photo of profilePhotos) {
      const {
        fileStorage: { url },
        id,
      } = photo;

      photos.push({
        id: id,
        url: generatePreviewURL(url),
        thumbnail: generatePreviewURL(url),
        original: generateOriginalURL(url),
        isVideo: false,
      });
    }

    return photos;
  }, [data]);

  /** CHAT END */
  const renderError = () => <AlertError error={error} />;

  if (isError) {
    return renderError();
  }

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return renderError();
  }

  const { profile, image, profilePhotos, lastAccess } = data;

  return (
    <>
      <LoginModal {...loginModal} onClose={loginModalClose} onSuccess={loginModalClose} />

      <TipBuyModal
        {...tipBuyModal}
        username={username}
        setOpen={setTipBuyModal}
        onClose={tipBuyClose}
        onSuccess={tipBuyClose}
        onError={tipError}
      />

      <PaymentWarningAlert />

      <div className="mt-12">
        {/* COVER IMAGE */}

        <div className="inline-flex rounded-md border border-transparent px-2.5 py-2 bg-pink-600 text-base font-medium text-white shadow hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-5">
          <Link to="/">⬅️ Torna alla lista delle Ragazze/i</Link>
        </div>

        <section className="mt-6">
          <div className="flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded shadow sm:shadow-md sm:rounded-md">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-4/12 px-4 lg:order-2 flex justify-center">
                  <div className="flex rounded-md bg-white w-full">
                    <ImageGallery
                      items={profilePhotosMapper}
                      showThumbnails={false}
                      showFullscreenButton={true}
                      showBullets={true}
                      infinite={false}
                      showPlayButton={false}
                      showNav={false}
                      useBrowserFullscreen={false}
                      additionalClass="image-gallery-aspect-1"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                  <div className="py-6 px-3 mt-4 sm:mt-0 text-center">
                    <TipButton
                      user={user}
                      isMyProfile={isMyProfile}
                      onTipClick={tipBuyOpen}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4 lg:order-1 lg:self-center">
                  {/*<>
                    <p className="lg:text-sm text-sm leading-normal border-gray-200 text-center font-bold uppercase ml-1">
                      &#11015; Seguimi sui canali social &#11015;
                    </p>
                    <div className="flex justify-center py-4 lg:pt-4">
                      <div className="space-x-4 p-3 text-center">
                        <button
                          onClick={onClickChat}
                          className="bg-pink-600 hover:bg-pink-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                          type="button"
                        >
                          Inviami un messaggio
                        </button>
                      </div>
                       <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-600">
                          22
                        </span>
                        <span className="text-sm text-slate-400">Friends</span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-600">
                          10
                        </span>
                        <span className="text-sm text-slate-400">Photos</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-600">
                          89
                        </span>
                        <span className="text-sm text-slate-400">Comments</span>
                      </div> 
                    </div>
                  </>*/}
                </div>
              </div>
              <div className="text-center lg:mt-5 space-y-5 mb-5">
                <div className="flex justify-center items-center space-x-1 -space-y-1">
                  <BadgeOnline isOnline={lastAccess?.isOnline} />
                  <h3 className="truncate text-4xl font-semibold leading-normal text-grey-800">
                    {username}
                  </h3>
                  <BadgeVerification />
                </div>
                <div className="flex justify-center items-center space-x-1 -space-y-1">
                  <button
                    onClick={onClickChat}
                    className="bg-fuchsia-600 hover:bg-fuchsia-700 uppercase text-white font-bold hover:shadow-md shadow text-xl px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    💬 Contattami
                  </button>
                </div>

                {profile.country && (
                  <div className="flex items-center justify-center">
                    <span className="text-pink-800">
                      <LocationMarkerIcon className="h-5 w-5" />
                    </span>
                    <span className="text-sm leading-normal text-grey-400 font-bold uppercase ml-1">
                      {profile.country.name}
                    </span>
                  </div>
                )}
                {profile.categories.length > 0 && (
                  <div className="flex flex-wrap items-center justify-center">
                    <span className="text-pink-800">
                      <TagIcon className="h-5 w-5" />
                    </span>
                    {profile.categories.map((c) => (
                      <div key={c.id} className="mt-1">
                        <span
                          key={c.id}
                          className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800 ml-1"
                        >
                          {c.label}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {profile.tags.length > 0 && (
                  <div className="flex flex-wrap items-center justify-center">
                    <span className="text-pink-800">
                      <BriefcaseIcon className="h-5 w-5" />
                    </span>
                    {profile.tags.map((c) => (
                      <div key={c.id} className="mt-1">
                        <span
                          key={c.id}
                          className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800 ml-1"
                        >
                          {c.label}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="py-5 border-t border-gray-200 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <p className="mb-4 text-lg font-semibold leading-relaxed text-slate-700">
                      {profile.biography}
                    </p>
                    {/* <a href="#pablo" className="font-normal text-pink-500">
                        Show more
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/**sezione preview album */}
        <div className="relative flex flex-col min-w-0 break-words border border-pink-400 bg-white w-full mb-6 rounded shadow-lg shadow-pink-800 sm:shadow-md sm:rounded-md">
          <div className="px-6">
            <div className="py-5 border-b border-pink-300 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4">
                  <p className="max-w-lg mx-auto text-center font-semibold text-xl sm:text-2xl text-pink-500 sm:max-w-3xl">
                    Album foto e video
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ProfileAlbumGalleryPreview username={username} />
          <div className="px-6">
            <div className="py-5 border-t border-pink-300 text-center">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12 px-4">
                  <Link
                    to={`albums`}
                    type="button"
                    className="inline-flex rounded-md border border-transparent px-2.5 py-1 bg-pink-600 text-base font-medium text-white shadow hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
                  >
                    Vai a tutti gli album
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/**sezione prodotti creator */}
        <ProfileTabGetAllProduct username={username} />
      </div>

      {!user || user.role.code === ROLES.CUSTOMER ? (
        <ProfileChatButton
          onClick={onClickChat}
          image={image}
          isOnline={lastAccess ? lastAccess.isOnline : false}
        />
      ) : null}
    </>
  );
}

/*
COVER IMAGE

<section className="relative block h-96">
          <div className="absolute top-0 w-full h-full bg-center bg-cover rounded-t-lg">
            <div
              className="absolute top-0 w-full h-full bg-center bg-cover rounded-t-lg"
              style={{
                backgroundImage:
                  "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=2710&amp;q=80')",
              }}
            />
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black rounded-t-lg"
            />
          </div>
           <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-80"
            style={{ transform: 'translateZ(0px)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-pink-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div> 
        </section> */

export default CreatorWebProfile;
