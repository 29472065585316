// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN, ENV_COMMUNICATION } from 'config/Enviroment';

import APIClient from '../ApiClient';

export const saveReadReceipt = async (threadUUID: string, messageUUID: string) => {
  const { data } = await APIClient.post<void>(
    ENV_COMMUNICATION.CREATE_MESSAGE_CHECKMARK(threadUUID),
    { messageUUID }
  );

  return data;
};

export function useSaveReadReceipt(threadId: string) {
  const queryClient = useQueryClient();

  const saveReadReceiptMutation = useMutation<void, AxiosError<string, string>, string>(
    (messageId: string) => saveReadReceipt(threadId, messageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_COMMUNICATION.GET_ALL_THREAD);
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_THREAD);
      },
    }
  );

  return {
    saveReadReceiptMutation,
  };
}
