import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function usePrevious(value: string) {
  const ref = useRef<string>('');

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    const isNestedRoute =
      pathname.length >= prevPathname.length
        ? pathname.includes(prevPathname)
        : prevPathname.includes(pathname);

    if (!isNestedRoute) {
      window.scrollTo(0, 0);
    }
  }, [prevPathname, pathname]);

  return null;
}
