import { FormEvent, useEffect, useState } from 'react';

//3p
import { Dialog } from '@headlessui/react';
import { UploadIcon, XIcon } from '@heroicons/react/outline';

// app
import { IMedia } from 'interfaces';
import { fromByteToMb, getFileType } from 'utils';

import { AlertError } from 'components/common/alert';
import Loader from 'components/common/Loader';

import { MediaPreview } from '../album/MediaPreview';
import APIClient from 'api/ApiClient';
import axios from 'axios';
import { useSaveProfilePhoto } from 'api/user/useSaveProfilePhoto';
import { useAuth } from 'hooks';
import { compressImage } from 'utils/image';
import { ModalLayout } from 'components/common';

interface ProfilePhotoUploadModalProps {
  open: boolean;
  data?: IMedia;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: (error: Error, variables: File[], context: unknown) => void;
}

const ACCEPTED_EXT = ['image/jpeg', 'image/jpg', 'image/png'];

const NUMERO_MASSIMO_FOTO_PROFILO = 10;

//Si potrebbe centralizzare la modale utilizzando MediaUploadModal come fatto per la cover, quindi passando
//una prop isProfilePhoto (come fatto per isCover) ma ho preferito tenere i due flussi separati per una
//questione funzionale: una si riferisce agli album mentre questa al profilo
export function ProfilePhotoUploadModal(
  props: ProfilePhotoUploadModalProps
): JSX.Element {
  const { open, onSuccess, onClose, onError } = props;

  const { user } = useAuth();

  const { saveProfilePhotosMutation } = useSaveProfilePhoto();
  const { isLoading, isError, error, mutate, reset } = saveProfilePhotosMutation;

  const [mediaList, setMediaList] = useState<File[]>([]);
  const [isLoadingDO, setIsLoadingDO] = useState<boolean>(false);

  const handleRemoveMedia = (file: File) => {
    setMediaList((prevState) => {
      const newState = [...prevState];
      const index = prevState.indexOf(file);
      newState.splice(index, 1);
      return newState;
    });
  };

  const clearOnClose = () => {
    if (isLoading) return;

    onClose();

    setMediaList([]);
    reset();
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isLoading) return;

    if (!mediaList || mediaList.length === 0) return;

    if (mediaList.length >= NUMERO_MASSIMO_FOTO_PROFILO) {
      alert(
        "E' possibile caricare fino a un massimo di " +
          NUMERO_MASSIMO_FOTO_PROFILO +
          ' foto profilo'
      );
      return;
    }

    setIsLoadingDO(true);

    let fileSizeSum = 0;
    for (const media of mediaList) {
      const fileSizeMb = fromByteToMb(media.size);
      if (fileSizeMb > 5) {
        alert(
          'Il file: ' +
            media.name +
            ' è ' +
            fileSizeMb.toFixed(2) +
            'Mb. Il limite massimo è di 5Mb. Provare a comprimere o a cambiare file.'
        );
        setIsLoadingDO(false);
        clearOnClose();
        return;
      }
      fileSizeSum += fileSizeMb;

      if (!ACCEPTED_EXT.includes(media.type)) {
        alert(
          "L'estensione del file " +
            media.name +
            " non è supportata. E' possibile caricare solo immagini. Riprovare"
        );
        setIsLoadingDO(false);
        return;
      }
    }

    //Limite somma file caricati in foto profilo
    if (fileSizeSum > 300) {
      alert(
        "E' possibile caricare contemporaneamente fino a 300Mb. Hai caricato " +
          fileSizeSum +
          'Mb. Riprovare.'
      );
      setIsLoadingDO(false);
      return;
    }

    let mediaArray = [];
    for (const media of mediaList) {
      const data = await getSignedUrl(media.type);
      await uploadFile(data.presignedUrl, media);
      const { fileName } = data;
      const type = getFileType(media);
      mediaArray.push({
        file: type === 'image' ? media : undefined,
        fileName: fileName,
        fileType: type,
        mimeType: media.type,
      });
    }
    setIsLoadingDO(false);

    mutate(
      { mediaList: mediaArray },
      {
        onSuccess: onSuccess,
        onError: onError,
      }
    );
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) return;

    setMediaList((mediaList) => {
      const newMediaList = Array.from(files);
      return [...mediaList, ...newMediaList];
    });
  };

  const getSignedUrl = async (fileType: string) => {
    if (!user) return <AlertError message="Utente non loggato" />;

    const body = {
      fileType: fileType,
      folder: user.username + '-profilePhotos',
    };

    const response = await APIClient.post<any>('/common/getPresignedUrl', body, {
      headers: {
        'content-type': 'application/json',
      },
    });

    return response.data;
  };

  const uploadFile = async (signedUrl: string, file: File) => {
    try {
      const type = getFileType(file);
      let fileBlobCompressed;
      if (type === 'image') {
        fileBlobCompressed = await compressImage(file, 0.8);
      }
      const res = await axios.put<any>(
        signedUrl,
        fileBlobCompressed ? fileBlobCompressed : file,
        {
          headers: {
            'Content-Type': file.type,
            //'x-amz-acl': 'public-read',
          },
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const renderMediaPreview = (file: File) => {
    return (
      <MediaPreview
        key={file.name}
        file={file}
        onRemove={handleRemoveMedia}
        //isUploaded={file.isUploaded}
      />
    );
  };

  // Set Default data or clean
  useEffect(() => {
    setMediaList([]);
  }, [open]);

  const renderLoader = () => (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          Caricamento
        </Dialog.Title>
        <div className="mt-2">
          <Loader />
        </div>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          ERRORE
        </Dialog.Title>
        <div className="mt-2">
          <AlertError message="Errore generico" />
        </div>
      </div>
    </div>
  );

  const renderData = () => {
    return (
      <form onSubmit={onSubmit}>
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            onClick={clearOnClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
            <Dialog.Title
              as="h3"
              className="text-2xl leading-6 font-medium text-gray-700"
            >
              Carica foto profilo
            </Dialog.Title>

            {isError ? <div>An error occurred: {error?.message}</div> : null}

            <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              <div className="sm:col-span-3">
                <div className="grid grid-cols-1 space-y-2">
                  <h3 className="block text-md font-medium text-gray-700 tracking-wide">
                    Seleziona file
                  </h3>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="images"
                      className="overflow-auto hover:overflow-scroll rounded-lg border-4 border-dashed w-full h-80 sm:h-60 sm:p-10 p-5 group text-center"
                    >
                      {/* <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10"> */}
                      {/* <img
                                  className="has-mask h-36 object-center"
                                  src="https://img.freepik.com/free-vector/image-upload-concept-landing-page_52683-27130.jpg?size=338&ext=jpg"
                                  alt="freepik image"
                                /> */}
                      {(mediaList.length && (
                        <div className="grid grid-cols-3 gap-y-3 gap-x-3 sm:grid-cols-3 ">
                          {mediaList.map(renderMediaPreview)}
                        </div>
                      )) || (
                        <div className="h-full w-full text-center flex flex-col items-center justify-center">
                          <UploadIcon className="h-20 w-20 object-center text-pink-200" />
                          {/* </div> */}
                          <p className="pointer-none text-gray-500 ">
                            {/* <span className="text-sm">Trascina</span> qui i tuoi
                                  media
                                  <br /> oppure clicca qui per selezionarli dal tuo */}
                            Clicca qui per selezionare le foto dal tuo dispositivo
                          </p>
                        </div>
                      )}
                      <input
                        type="file"
                        id="images"
                        multiple
                        accept="image/png, image/jpeg, image/png"
                        className="hidden"
                        onChange={handleOnChangeInput}
                      />
                    </label>
                  </div>
                </div>
                <p className="text-sm text-gray-300 mt-3">
                  <span>
                    Formati supportati: jpeg, jpg, png - Dimensione massima: 300Mb
                  </span>
                </p>
                {/* {errors.files && (
                        <p className="text-sm text-red mt-3">{errors.files[0].message}</p>
                      )} */}
              </div>
              {/* <div>{files.length > 0 && <img src={files[0]} alt="nada" />}</div> */}
            </div>
          </div>
        </div>
        <div className="mt-8 sm:mt-8 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Salva
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={clearOnClose}
          >
            Annulla
          </button>
        </div>
      </form>
    );
  };

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingDO || isLoading}>
      {isError ? renderError() : renderData()}
    </ModalLayout>
  );
}
