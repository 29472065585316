import { IKeyValue, ITransferStatus } from 'interfaces';
import { classNames } from 'utils';

interface ITransferStatusProps {
  status: ITransferStatus;
}

const statusStyles: IKeyValue = {
  SUCCESS: 'bg-green-100 text-green-800',
  PENDING: 'bg-yellow-100 text-yellow-800',
  FAIL: 'bg-gray-100 text-gray-800',
  REJECT: 'bg-gray-100 text-gray-800',
};

const statusLabel: IKeyValue = {
  SUCCESS: 'Completato',
  PENDING: 'In corso',
  FAIL: 'Fallito',
  REJECT: 'Non approvato',
};

export const TransferStatus = (props: ITransferStatusProps) => {
  const {
    status: { code },
  } = props;

  return (
    <span
      className={classNames(
        statusStyles[code],
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium capitalize'
      )}
    >
      {statusLabel[code]}
    </span>
  );
};
