import { IS_ENABLE_SIGNUP_CREATOR } from 'config/Config';
import { useAuth } from 'hooks';
import { Link } from 'react-router-dom';

function HeroSection(): JSX.Element {
  const { user } = useAuth();

  return (
    <div className="relative rounded sm:shadow-md sm:rounded-md sm:overflow-hidden">
      <div className="rounded sm:shadow-md sm:rounded-md absolute inset-0">
        <img
          className="rounded sm:shadow-md sm:rounded-md h-full w-full object-cover"
          src="/assets/Hero.jpg"
          alt="Peops"
        />
        <div className="rounded sm:shadow-md sm:rounded-md absolute inset-0 bg-pink-700 mix-blend-multiply" />
      </div>
      <div className="rounded sm:shadow-md sm:rounded-md relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-4xl lg:text-5xl">
          <span className="block text-white">VieniOra.com</span>
          <span className="block text-pink-200">
            La miglior piattaforma di adult creators
          </span>
        </h1>
        <p className="mt-6 max-w-lg mx-auto text-center text-xl text-pink-200 sm:max-w-3xl">
          Trova il creator adatto a te!
        </p>
        {!user && (
          <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <>
                <Link
                  to="/signup/customer"
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-pink-700 bg-white hover:bg-pink-50 sm:px-8"
                >
                  Registrati come cliente
                </Link>

                <Link
                  to={IS_ENABLE_SIGNUP_CREATOR === true ? '/signup/creator' : '/support'}
                  className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-500/60 hover:bg-pink-500/70 sm:px-8"
                >
                  Registrati come creator
                </Link>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroSection;
