export const SupportButton = (props: any) => {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      className="flex justify-center items-center fixed z-10 shadow-md cursor-pointer bg-pink-600 hover:bg-pink-700 text-white font-bold hover:shadow-md shadow text-lg px-4 py-2 rounded-2xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
      style={{ padding: 12, right: 20, bottom: 20 }}
    >
      <span>Contatta il supporto</span>
    </div>
  );
};
