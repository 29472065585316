import { useState } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';

// app
import { IAlbum, IModal } from 'interfaces';
import { DEFAULT_LIMIT } from 'config/Enviroment';
import { useToast } from 'hooks';

import { AlbumFormModal } from 'components/creator/album';
import { AlbumGetAllBar, AlbumGrid } from 'components/common/album';
import { useGetAllAlbum } from 'api/album';

export function CreatorAlbumGetAll() {
  const [mediaFormModal, setMediaFormModal] = useState<IModal<IAlbum>>({
    open: false,
  });

  const navigate = useNavigate();

  const { getAllAlbumQuery } = useGetAllAlbum(
    {},
    { limit: DEFAULT_LIMIT, order: 'DESC' }
  );

  const handleAlbumClick = (album: IAlbum, title: string) => {
    navigate(`/app/albums/${album.id}`);
  };

  const { toastError, toastSuccess } = useToast();

  /** ON SUCCESS */
  const onSuccessCreate = (album: IAlbum) => {
    albumFormClose();

    toastSuccess({
      title: 'Ottimo!',
      message: `Album ${album.title} creato.`,
    });
  };

  const onErrorCreate = () => {
    toastError({
      title: 'Attenzione',
      message: 'Errore generico',
    });
  };

  /** MEDIA MODAL FORM */
  const albumFormOpen = (album?: IAlbum) => {
    setMediaFormModal({ open: true, data: album });
  };

  const albumFormClose = () => {
    setMediaFormModal({ open: false });
  };

  return (
    <>
      <AlbumFormModal
        {...mediaFormModal}
        onClose={albumFormClose}
        onSuccess={onSuccessCreate}
        onError={onErrorCreate}
      />
      <AlbumGetAllBar onCreateNew={albumFormOpen} />
      {/* Media Content */}
      <div className="px-1 py-5 sm:p-6">
        <AlbumGrid
          query={getAllAlbumQuery}
          onAlbumClick={handleAlbumClick}
          emptyMessage="Non hai creato nessun album"
          hasDelete={true}
        />
      </div>
    </>
  );
}
