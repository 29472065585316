import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

// 3p
import { XIcon } from '@heroicons/react/outline';

// App
import { IChatMedia, IChatThread } from 'interfaces';
import { CHAT_IMAGE_EXT, VIDEO_EXT } from 'config';

import { ChatAttachmentMedia } from './ChatAttachmentMedia';
import { ChatAttachmentAudio } from './ChatAttachmentAudio';
import { uploadFile } from 'api/chat';
import { generateVideoThumbnail } from 'utils';

interface IChatAreaFooterProps {
  thread: IChatThread;
  onSend: (content: string | null, media?: IChatMedia) => Promise<void>;
}

export const ChatAreaFooter = (props: IChatAreaFooterProps) => {
  const { onSend } = props;

  const [media, setMedia] = useState<IChatMedia>();
  const [progress, setProgress] = useState<number>();

  const [showAttachmentAudio, setShowAttachmentAudio] = useState(false);

  const textareaRef = useRef(null);

  const handleOnSuccessAttachmentMedia = useCallback(
    async (fileName: string, file: File) => {
      let preview: string | undefined;

      const { type } = file;

      // Controllo se è un video o una foto
      const isFileVideo = VIDEO_EXT.includes(type);

      // se è un video creo la preview
      if (isFileVideo) {
        // Video preview (è stato rimossa perchè nel body era troppo grande)
        preview = await generateVideoThumbnail(file);
        setMedia({ file, preview, type: 'video' });
        return;
      }

      const isFileImage = CHAT_IMAGE_EXT.includes(type);

      // Image preview
      if (isFileImage) {
        preview = URL.createObjectURL(file);
        setMedia({ file, preview, type: 'image' });
        return;
      }
    },
    [setMedia]
  );

  const handleRemoveAttachment = useCallback(() => {
    setMedia(undefined);
  }, []);

  const [content, setContent] = useState('');

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const adjustTextareaHeight = () => {
      const textarea: any = textareaRef.current;
      if (textarea) {
        // Resetto l'altezza
        textarea.style.height = 'auto';
        const newHeight = textarea.scrollHeight + 'px';
        textarea.style.height =
          Math.min(
            parseFloat(newHeight),
            3 * parseFloat(getComputedStyle(textarea).lineHeight)
          ) + 'px';

        // const currentRows = Math.floor(
        //   textarea.scrollHeight / parseInt(getComputedStyle(textarea).lineHeight)
        // );
        // setRows(currentRows);

        // Verifica se la scrollbar è necessaria e imposta l'overflowY di conseguenza
        textarea.style.overflowY =
          textarea.scrollHeight - 3 > textarea.clientHeight ? 'auto' : 'hidden';
      }
    };

    adjustTextareaHeight();
    setContent(e.target.value);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  // TODO: Gestione errori
  const handleSend = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      setShowAttachmentAudio(false);

      let mediaToSend = media;

      // media is an image or audio
      if (mediaToSend) {
        const { file, type } = mediaToSend;

        // Effettua l'upload dell'audio o dell' immagine
        // Se è un video, prima salvo il messaggio e poi fa l'upload
        if (type === 'image') {
          const { fileName } = await uploadFile(file, setProgress);
          const newFile = new File([file], fileName, { type: file.type });
          mediaToSend = { ...mediaToSend, file: newFile };
        }
      }

      await onSend(content, mediaToSend);
    } catch (e) {
    } finally {
      setIsLoading(false);

      setContent('');
      setMedia(undefined);

      setProgress(undefined);
    }
  }, [isLoading, media, onSend, content]);

  const handleOnSuccessAttachmentAudio = useCallback(
    async (fileName1: string, file: File) => {
      setShowAttachmentAudio(false);

      const { fileName } = await uploadFile(file, setProgress);
      const newFile = new File([file], fileName, { type: file.type });
      await onSend(null, { file: newFile, type: 'audio' });
      setProgress(undefined);
    },
    [onSend]
  );

  const handleOnCancelAttachmentAudio = useCallback(() => {
    setShowAttachmentAudio(false);
  }, []);

  const mediaPreview = useMemo(() => {
    if (!media) return;

    const { preview } = media;

    if (!preview) return;

    return (
      <div
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '5px 20px',
          display: !progress ? 'flex' : 'none',
        }}
        className="bg-gray-200"
      >
        <div>
          <img
            src={media.preview}
            alt="media Preview"
            style={{ height: 100, width: 100, objectFit: 'cover' }}
          />
        </div>

        <div>
          <button onClick={handleRemoveAttachment}>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  }, [handleRemoveAttachment, media, progress]);

  return (
    <div className="bg-white w-full">
      {progress && (
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 20px',
          }}
          className="bg-gray-200 flex"
        >
          <div className="h-5 w-full bg-pink-200">
            <div
              className="h-5 bg-pink-600 transition-all"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      )}

      {mediaPreview}

      {!progress && showAttachmentAudio && (
        <ChatAttachmentAudio
          onCancel={handleOnCancelAttachmentAudio}
          onSuccess={handleOnSuccessAttachmentAudio}
        />
      )}

      {!progress && !showAttachmentAudio && (
        <div className="flex flex-row items-center p-4">
          <ChatAttachmentMedia onSuccess={handleOnSuccessAttachmentMedia} />

          <div className="relative flex-grow">
            <label>
              <textarea
                ref={textareaRef}
                autoComplete="none"
                className=" resize-none rounded-md py-2 pl-3 pr-10 w-full border border-gray-200 bg-gray-200 focus:bg-white focus:outline-none text-gray-600 focus:shadow-md transition duration-300 ease-in"
                rows={1}
                style={{
                  minHeight: 'fit-content',
                  overflowY: 'hidden',
                }}
                name="message"
                placeholder="Scrivi un messaggio"
                maxLength={1000}
                onChange={handleChange}
                value={content}
              />
            </label>
          </div>
          {media || content.length ? (
            <button
              type="submit"
              className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700"
              onClick={handleSend}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          ) : (
            <button
              type="submit"
              className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700"
              onClick={() => setShowAttachmentAudio(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                />
              </svg>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
