import { useGetAllUser } from 'api/admin/user/useGetAllUser';
import UserTable from 'components/admin/user/UserTable';
import { AlertError } from 'components/common/alert';
import { BasicEmptyState } from 'components/common/empty';
import Loader from 'components/common/Loader';
import { DEFAULT_LIMIT_USER } from 'config/Enviroment';
import { ROLES } from 'config/Roles';
import { ICursorObj, ISearchFormFilter } from 'interfaces';
import { useState } from 'react';

export const CreatorGetAll = () => {
  const [cursor, setCursor] = useState<ICursorObj>({});
  const [filter, setFilter] = useState<ISearchFormFilter>();

  const { getAllUserQuery } = useGetAllUser(
    { role: ROLES.CREATOR, ...filter },
    { limit: DEFAULT_LIMIT_USER, order: 'DESC', ...cursor }
  );
  const { data, isError, isLoading, error } = getAllUserQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderEmpty = () => (
    <>
      <BasicEmptyState title="Registro creators" descr="Non sono presenti creators" />
      <div className="mt-6 flex flex-row justify-center">
        <div className="basis-1/4">
          <button
            type="button"
            className="w-full bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            onClick={() => setFilter({ filter: '' })}
          >
            Indietro
          </button>
        </div>
      </div>
    </>
  );

  const renderData = () => {
    if (!data || !data.data.length) return renderEmpty();
    return (
      <>
        <UserTable
          setCursor={setCursor}
          setFilter={setFilter}
          query={getAllUserQuery}
          tableTitle="Creators"
        />
      </>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
  //return renderData();
};
