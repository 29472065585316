import { useState } from 'react';

// app
import { IModal, ITransactionPayout } from 'interfaces';

import { useGetAllPayout } from 'api/admin/transfer';

import { PayoutModalStatus, SummaryPayout } from 'components/admin/payout';
import { PayoutTable } from 'components/admin/payout/PayoutTable';

import { DEFAULT_LIMIT } from 'config/Enviroment';
import { useToast } from 'hooks';

export const PayoutGetAll = () => {
  const { toastSuccess } = useToast();

  const [payoutFormModal, setPayoutFormModal] = useState<
    IModal<ITransactionPayout | undefined>
  >({
    open: false,
  });

  const onPayoutFormModalClose = () => {
    setPayoutFormModal({ open: false });
  };

  const onPayoutFormModalSuccess = () => {
    setPayoutFormModal({ open: false });

    toastSuccess({
      title: 'Operazione eseguita',
      message: 'Richiesta inviata correttamente',
    });
  };

  const [cursor, setCursor] = useState<any>({});

  const { getAllPayoutQuery } = useGetAllPayout({
    limit: DEFAULT_LIMIT,
    order: 'DESC',
    ...cursor,
  });

  const onChangeStatus = (item: ITransactionPayout) => {
    setPayoutFormModal({ open: true, data: item });
  };

  return (
    <>
      <PayoutModalStatus
        {...payoutFormModal}
        onClose={onPayoutFormModalClose}
        onSuccess={onPayoutFormModalSuccess}
        // onError={onPayoutFormModalError}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <SummaryPayout />
      </div>
      <div className="mt-8 px-4 sm:px-6 lg:px-8">
        <PayoutTable
          setCursor={setCursor}
          query={getAllPayoutQuery}
          onChangeStatus={onChangeStatus}
        />
      </div>
    </>
  );
};
