// 3p
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';
import { Link } from 'react-router-dom';

// app
import { ROLES } from 'config/Roles';

import {
  ICursorObj,
  IResponseWithCursor,
  IRole,
  ISearchFormFilter,
  IUserTableRow,
} from 'interfaces';
import { formatMoney } from 'utils';

import { UserImage } from 'components/common/image';
import styles from 'styles';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BadgeCheckIcon } from '@heroicons/react/solid';
import { BadgeTypeEnum } from 'config';

interface IUserTableRowProps {
  user: IUserTableRow;
}

const UserTableRow = (props: IUserTableRowProps) => {
  const { user } = props;

  const checkStatus = (
    role: IRole,
    blockAt: string,
    deletedAt: string,
    verificationAt: string,
    approvedAt: string | undefined
  ) => {
    if (blockAt)
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Bloccato
        </span>
      );
    if (deletedAt)
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Eliminato
        </span>
      );
    if (!verificationAt)
      return (
        <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
          Non verificato
        </span>
      );
    if (role.code === ROLES.CREATOR && !approvedAt)
      return (
        <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
          Non approvato
        </span>
      );
    return (
      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
        Attivo
      </span>
    );
  };

  const image = user?.image ? (
    <div className="shrink-0 h-12 w-12">
      <UserImage
        className="h-12 w-12 rounded-full"
        alt={user.username}
        image={user.image}
        params={{ class: 'small' }}
      />
    </div>
  ) : (
    <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
      <span className="text-md font-medium leading-none text-white">
        {user ? user.username.slice(0, 2).toUpperCase() : 'UK'}
      </span>
    </span>
  );

  const userBalance =
    user.role.code === ROLES.CUSTOMER
      ? user.wallet.availableDeposit
      : user.wallet.availableNow;

  return (
    <tr key={user.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">{image}</div>
          <div className="ml-4">
            <div className="flex">
              <Link
                to={
                  user.role.code === ROLES.CREATOR
                    ? `/${user.username}`
                    : `/admin/users/customers/${user.id}`
                }
              >
                <div className="font-medium text-gray-900">{user.username}</div>
              </Link>
              {user.badge &&
                user.badge.code &&
                user.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
                  <BadgeCheckIcon
                    className="h-4 w-4 ml-1"
                    aria-hidden="true"
                    color="#3897f0"
                  />
                )}
            </div>

            <div className="text-gray-500">{user.email}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-900">{formatMoney(userBalance)}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {checkStatus(
          user.role,
          user.blockAt,
          user.deleteAt,
          user.verificationAt,
          user.profile?.approveAt
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.role.code}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.profile ? (user.profile.isVisible ? 'Si' : 'No') : ' '}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link to={user.id.toString()} className="text-pink-600 hover:text-pink-900">
          Dettagli
        </Link>
      </td>
    </tr>
  );
};

interface IUserTableProps {
  query: UseQueryResult<IResponseWithCursor<IUserTableRow>, AxiosError<string, any>>;
  setCursor: React.Dispatch<React.SetStateAction<ICursorObj>>;
  setFilter?: React.Dispatch<React.SetStateAction<ISearchFormFilter | undefined>>;
  tableTitle?: string;
}

function UserTable(props: IUserTableProps): JSX.Element {
  const { query, setCursor, setFilter, tableTitle } = props;
  const { data } = query;

  const prevPage = () => {
    if (data?.cursor.beforeCursor) setCursor({ beforeCursor: data?.cursor.beforeCursor });
  };

  const nextPage = () => {
    if (data?.cursor.afterCursor) setCursor({ afterCursor: data?.cursor.afterCursor });
  };

  const { handleSubmit, register } = useForm<ISearchFormFilter>();

  const onSubmit: SubmitHandler<ISearchFormFilter> = async (formData) => {
    // si potrebbe mettere un trim nell'if per non fare una richiesta inutile se inserice solo uno o più spazi
    // nell'if mettere && formdata.filter.trim().length > 0. Solo che poi dovrebbe essere inserito un tasto di RESET per azzerare i filtri
    if (setFilter) setFilter(formData);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">{tableTitle}</h1>
          <p className="mt-2 text-sm text-gray-700">
            Registro dei {tableTitle?.toLowerCase()} presenti all'interno del sistema
          </p>
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Aggiungi utente
          </button>
        </div> */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 grid grid-cols-3 gap-y-6 gap-x-4 sm:grid-cols-9">
          <div className="col-span-2 sm:col-span-3">
            <div className="relative rounded-md shadow-sm">
              <input
                id="filter"
                type="text"
                {...register('filter', { required: false })}
                className={styles.input.base}
              />
            </div>
          </div>
          <div className="col-span-1 sm:col-span-1">
            <button
              type="submit"
              className="w-full bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              Cerca
            </button>
          </div>
        </div>
      </form>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Nome
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Saldo disponibile
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Stato
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ruolo
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Visibile
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Dettagli</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data &&
                    data.data.map((user) => <UserTableRow key={user.id} user={user} />)}
                </tbody>
              </table>
              {/* Pagination */}
              <nav
                className="bg-white px-4 py-3 flex items-center border-t border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                {/*TODO: mettere pagina 1 di x*/}
                {/* <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Mostro <span className="font-medium">1</span> a{' '}
            <span className="font-medium">10</span> di{' '}
            <span className="font-medium">{data && data.data.length}</span> risultati
          </p>
        </div> */}
                <div className="flex-1 flex sm:justify-end">
                  {data?.cursor.beforeCursor !== null && (
                    <button
                      onClick={prevPage}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Precedenti
                    </button>
                  )}
                  {data?.cursor.afterCursor !== null && (
                    <button
                      onClick={nextPage}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Successivi
                    </button>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserTable;
