import { Link } from 'react-router-dom';

// app
import { IS_ENABLE_SIGNUP_CREATOR } from 'config/Config';

export default function CreatorSignUpSection() {
  return (
    <div className="bg-pink-700 rounded sm:shadow-md sm:rounded-md">
      <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          <span className="block">Vuoi diventare un creator ?</span>
          {/* <span className="block">Start using our app today.</span> */}
        </h2>
        <p className="mt-4 text-lg leading-6 text-pink-200">
          Iscriviti gratuitamente alla nostra pittaforma e inizia a guadagnare.
        </p>
        <Link
          to={IS_ENABLE_SIGNUP_CREATOR === true ? '/signup/creator' : '/support'}
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-pink-600 hover:bg-pink-50 sm:w-auto"
        >
          Inizia ora!
        </Link>
      </div>
    </div>
  );
}

// function CreatorSubscription(): JSX.Element {
//   return (
//     <div className="relative mt-6 sm:mt-8 sm:py-16">
//       <div aria-hidden="true" className="hidden sm:block">
//         <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-2xl" />
//         <svg
//           className="absolute top-8 left-1/2 -ml-3"
//           width={404}
//           height={392}
//           fill="none"
//           viewBox="0 0 404 392"
//         >
//           <defs>
//             <pattern
//               id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
//               x={0}
//               y={0}
//               width={20}
//               height={20}
//               patternUnits="userSpaceOnUse"
//             >
//               <rect
//                 x={0}
//                 y={0}
//                 width={4}
//                 height={4}
//                 className="text-gray-200"
//                 fill="currentColor"
//               />
//             </pattern>
//           </defs>
//           <rect
//             width={404}
//             height={392}
//             fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
//           />
//         </svg>
//       </div>
//       <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl sm:px-6">
//         <div className="relative rounded sm:rounded-md px-6 py-10 bg-pink-500 overflow-hidden sm:shadow-md sm:px-12 sm:py-20">
//           <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
//             <svg
//               className="absolute inset-0 h-full w-full"
//               preserveAspectRatio="xMidYMid slice"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 1463 360"
//             >
//               <path
//                 className="text-pink-250 text-opacity-40"
//                 fill="rgb(249, 168, 212)"
//                 d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
//               />
//               <path
//                 className="text-pink-250 text-opacity-40"
//                 fill="rgb(249, 168, 212)"
//                 d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
//               />
//             </svg>
//           </div>
//           <div className="relative">
//             <div className="sm:text-center">
//               <h2 className="text-2xl font-extrabold text-white tracking-tight sm:text-3xl">
//                 Vuoi diventare un creator ?
//               </h2>
//             </div>

//             <div className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
//               <Link
//                 to={IS_ENABLE_SIGNUP_CREATOR === true ? '/signup/creator' : '/support'}
//                 className="block m-auto rounded-md border border-transparent px-5 py-3 bg-pink-600 text-base font-medium text-white shadow hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
//               >
//                 Registrati
//               </Link>
//             </div>

//             {/* <form action="#" className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
//               <div className="min-w-0 flex-1">
//                 <label htmlFor="cta-email" className="sr-only">
//                   Email address
//                 </label>
//                 <input
//                   id="cta-email"
//                   type="email"
//                   className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
//                   placeholder="Inserisci la tua e-mail"
//                 />
//               </div>
//               <div className="mt-4 sm:mt-0 sm:ml-3">
//                 <button
//                   type="submit"
//                   className="block w-full rounded-md border border-transparent px-5 py-3 bg-pink-600 text-base font-medium text-white shadow hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
//                 >
//                   Iscriviti
//                 </button>
//               </div>
//             </form> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreatorSubscription;
