// 3p
import { CashIcon, SwitchVerticalIcon } from '@heroicons/react/outline';

// app
import { TransferTypeEnum } from 'config';
import { ITransferType } from 'interfaces';

interface ITransferTypeProps {
  type: ITransferType;
}

export function TransferType(props: ITransferTypeProps) {
  const {
    type: { code },
  } = props;

  let label, Icon;

  switch (TransferTypeEnum[code]) {
    case TransferTypeEnum.PURCHASE: // Acquisto tramite wallet
    case TransferTypeEnum.PAYIN: // Pagamento dall'esterno
      label = 'Acquisto';
      Icon = CashIcon;
      break;
    case TransferTypeEnum.PAYOUT:
      label = 'Prelievo';
      Icon = SwitchVerticalIcon;
      break;
    case TransferTypeEnum.TIP:
      label = 'Mancia';
      Icon = CashIcon;
      break;
    case TransferTypeEnum.TOPUP:
      label = 'Ricarica';
      Icon = SwitchVerticalIcon;
      break;
    case TransferTypeEnum.REDEEM:
      label = 'Coupon';
      Icon = SwitchVerticalIcon;
      break;
    case TransferTypeEnum.REFUND:
      label = 'Rimborso';
      Icon = SwitchVerticalIcon;
      break;
    default:
      label = 'Pagamento';
      Icon = CashIcon;
  }

  return (
    <>
      <Icon
        className="shrink-0 mr-1 h-5 w-5 text-gray-600 group-hover:text-gray-900"
        aria-hidden="true"
      />
      <span className="text-gray-600 font-medium group-hover:text-gray-900">{label}</span>
    </>
  );
}
