import { useEffect, useState } from 'react';
import { generateVideoThumbnail, getFileType } from 'utils';

interface IMediaPreview {
  file: File;
  onRemove?: (file: File) => void;
}

export const MediaPreview = (props: IMediaPreview) => {
  const { file, onRemove } = props;

  const [data, setData] = useState<string>('');

  const loadMediaPreview = async (file: File) => {
    const type = getFileType(file);

    let previewImg = '';

    if (type === 'image') {
      const blob = URL.createObjectURL(file);
      previewImg = blob;
    } else {
      previewImg = await generateVideoThumbnail(file);
    }

    setData(previewImg);
  };

  useEffect(() => {
    loadMediaPreview(file);
  }, [file]);

  return (
    <div key={file.name} className="inline-block relative w-20">
      <img src={data} alt={file.name} className="rounded-sm" />
      <span className="absolute top-0 right-0 block h-4 w-4">
        <button
          type="button"
          className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
          onClick={(e) => {
            e.preventDefault();
            if (onRemove) onRemove(file);
          }}
        >
          <svg
            className="pointer-events-none fill-current w-4 h-4 ml-auto"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              className="pointer-events-none"
              d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
            />
          </svg>
        </button>
      </span>
    </div>
  );
};
