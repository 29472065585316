// std

// 3p
import { Switch } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';

// app
import { classNames } from 'utils';
import { Link } from 'react-router-dom';
import { useAuth, useToast } from 'hooks';
import APIClient from 'api/ApiClient';
import { ENV_COMMON } from 'config/Enviroment';
import { ROLES } from 'config/Roles';

interface ISupportForm {
  fullname: string;
  subject: string;
  email: string;
  message: string;
  hasAcceptedPolicies: boolean;
}

export default function Support() {
  const { user } = useAuth();
  const { toastSuccess, toastError } = useToast();

  const { control, reset, register, handleSubmit } = useForm<ISupportForm>({
    defaultValues: {
      hasAcceptedPolicies: false,
    },
  });

  const onSubmit = async (data: ISupportForm) => {
    if (!data.hasAcceptedPolicies) {
      toastError({
        title: 'Attenzione!',
        message: 'Accetta le policy',
      });
      return;
    }

    try {
      await APIClient.post(ENV_COMMON.SEND_SUPPORT_EMAIL, data);

      toastSuccess({
        title: 'Richiesta inviata',
        message: 'La tua richiesta di supporto è stata inviata correttamente',
      });

      reset();
    } catch (error) {
      console.error(error);

      toastError({
        title: 'Errore',
        message: 'Impossibile inviare il messaggio',
      });
    }
  };

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-pink-600 sm:text-4xl">
            Invia un messaggio
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Hai bisogno di supporto? Puoi contattarci su Whatsapp o Telegram per avere una
            risposta rapida
          </p>
        </div>
        <div className="flex mt-6 justify-around">
          <a href="whatsapp://send?phone=+393273441737">
            <div className="h-12 px-5 inline-flex items-center justify-around border-none shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <svg
                style={{ fill: 'white' }}
                className=" h-6 w-6 mr-2"
                viewBox="0 0 32 32"
              >
                <path
                  d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                  fillRule="evenodd"
                ></path>
              </svg>
              Whatsapp
            </div>
          </a>
          <a href="https://t.me/vieniorasupport">
            <div className="h-12 px-5 inline-flex items-center justify-around border-none shadow-sm text-base font-medium text-white bg-sky-500 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
              <svg
                style={{ fill: 'white' }}
                className="h-6 w-6 mr-2"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Telegram</title>
                <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z" />
              </svg>
              Telegram
            </div>
          </a>
        </div>
        <div className="text-center mt-12">
          <p className="mt-4 text-lg leading-6 text-gray-500">
            In alternativa, compila il modulo sottostante. Verrai ricontattato al più
            presto
          </p>
        </div>
        <div className="mt-6">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="fullname"
                className="block text-sm font-medium text-gray-700"
              >
                Nome o username
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="fullname"
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md"
                  {...register('fullname', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md"
                  {...register('email', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700"
              >
                Oggetto
              </label>
              <div className="mt-1">
                <input
                  id="subject"
                  type="text"
                  autoComplete="subject"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md"
                  {...register('subject', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Messaggio
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border border-gray-300 rounded-md"
                  {...register('message')}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="shrink-0">
                  <Controller
                    name="hasAcceptedPolicies"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch
                        {...field}
                        checked={value}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600'
                        )}
                      >
                        <span className="sr-only">Accetta le hasAcceptedPolicies</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    Selezionando, accetterai la{' '}
                    <span className="text-blue-600 hover:text-blue-500">
                      <Link to="/legal/privacy" className="font-medium underline">
                        Privacy Policy
                      </Link>{' '}
                    </span>
                    e la{' '}
                    <span className="text-blue-600 hover:text-blue-500">
                      <Link to="/legal/cookie" className="font-medium underline">
                        Cookie Policy
                      </Link>
                    </span>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
              >
                Invia
              </button>
            </div>
          </form>
        </div>
        {user?.role.code === ROLES.CREATOR && (
          <div className="mt-10 text-center">
            <span className="items-baseline text-lg leading-6 text-gray-500">
              Sei un creator? Contattaci su Whatsapp per supporto live
              <div className="grid grid-cols-6">
                <div className="col-start-2 col-end-6 sm:col-start-3 sm:col-end-5">
                  <a
                    href="whatsapp://send?phone=+393273441737"
                    className="w-full mt-6 inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Contatta
                    <svg
                      style={{ fill: 'white' }}
                      className="rounded-full h-6 w-6 ml-2 self-center"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
