import axios from 'axios';

// AUTH
import { LOCAL_STORAGE_KEY, ENV_BASE_URL } from 'config';

const APIClient = axios.create({
  baseURL: ENV_BASE_URL,
});

APIClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);

    if (!token) {
      return config;
    }

    config.headers = {
      ...config.headers,
      Authorization: 'Bearer ' + token,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

APIClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // remove token if exsist
      localStorage.removeItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);
    }
    throw error;
  }
);

export default APIClient;
