import { Route, Routes } from 'react-router-dom';
import { CreatorGetAll } from './CreatorGetAll';
import { CustomerGetAll } from './CustomerGetAll';
import { UserGetOne } from './UserGetOne';

export const AdminUserRouter = () => {
  return (
    <Routes>
      <Route path={`creators`}>
        <Route index element={<CreatorGetAll />} />
        <Route path=":userId" element={<UserGetOne />} />
      </Route>

      <Route path={`customers`}>
        <Route index element={<CustomerGetAll />} />
        <Route path=":userId" element={<UserGetOne />} />
      </Route>
    </Routes>
  );
};
