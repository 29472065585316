// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IResponseWithCursor } from 'interfaces';

import APIClient from '../../ApiClient';

const getAllCoupon = async (params: any) => {
  const { queryKey } = params;
  const [key, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<any>>(key, {
    params: {
      ...pagingQuery,
    },
  });
  return data;
};

export function useGetAllCoupon(pagingQuery: any) {
  const getAllCouponQuery = useQuery<IResponseWithCursor<any>, AxiosError<string, any>>(
    [ENV_ADMIN.GET_ALL_COUPON, pagingQuery],
    getAllCoupon,
    {
      keepPreviousData: true,
    }
  );

  return {
    getAllCouponQuery,
  };
}
