// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_PRODUCT, ENV_USER } from 'config/Enviroment';
import { IProduct, IProductForm } from 'interfaces';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks/useAuth';

const createProduct = async (product: IProductForm) => {
  const { data } = await APIClient.post<IProduct>(ENV_PRODUCT.CREATE, product);
  return data;
};

const updateProduct = async (productId: number, product: IProductForm) => {
  const { data } = await APIClient.put<IProduct>(
    ENV_PRODUCT.UPDATE + '/' + productId,
    product
  );
  return data;
};

export function useSaveProduct(productId?: number) {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const saveProductMutation = useMutation<IProduct, AxiosError, IProductForm>(
    (data) => {
      // if product id, update
      if (productId) {
        return updateProduct(productId, data);
      }
      // else create
      return createProduct(data);
    },
    {
      onSuccess: () => {
        if (user) queryClient.invalidateQueries(ENV_USER.GET_ALL_PRODUCT(user.username));
      },
    }
  );

  return { saveProductMutation };
}
