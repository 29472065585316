import { useMemo } from 'react';

// 3p
import { CurrencyEuroIcon } from '@heroicons/react/outline';

// app
import { formatMoney } from 'utils';

import { useGetSummary } from 'api/transfer';
import { useAuth } from 'hooks';
import { ROLES } from 'config/Roles';

interface IHeaderWalletSummaryProps {
  containerClassName: string;
  iconClassName: string;
  textClassName: string;
  onClick?: () => void;
}

export const HeaderWalletSummary = (props: IHeaderWalletSummaryProps) => {
  const { onClick, containerClassName, textClassName, iconClassName } = props;

  const { getSummaryQuery } = useGetSummary();
  const { data } = getSummaryQuery;

  const { user } = useAuth();

  const walletSummaryText = useMemo(() => {
    if (!data || !user) {
      return 0;
    }

    if (user.role.code === ROLES.CREATOR) {
      return data.availableNow;
    } else if (user.role.code === ROLES.CUSTOMER) {
      return data.availableDeposit;
    } else {
      return 0;
    }
  }, [user, data]);

  return data ? (
    <>
      <div onClick={onClick && onClick} className={containerClassName}>
        <CurrencyEuroIcon className={iconClassName} aria-hidden="true" />
        <span className={textClassName}>{formatMoney(walletSummaryText)}</span>
      </div>
    </>
  ) : null;
};
