const CREATOR = {
  DASHBOARD_VIEW: true,

  CHAT_VIEW: true,

  MEDIA_VIEW: true,
  MEDIA_UPLOAD: true,
  MEDIA_EDIT: true,
  MEDIA_DELETE: true,

  PRODUCT_VIEW: true,
  PRODUCT_CREATE: true,
  PRODUCT_UPDATE: true,
  PRODUCT_DELETE: true,

  NOTIFICATION_VIEW: true,

  SETTING_ACCOUNT: true,
  SETTING_PROFILE: true,
  SETTING_NOTIFICATION: false,

  PAYOUT_VIEW: true,

  PURCHASE_VIEW: true,
};

const ADMIN = {
  USER_GET_ALL: true,
  MEDIA_GET_ALL: true,
  PAYOUT_GET_ALL: true,
  TRANSFER_GET_ALL: true,
  COUPON_GET_ALL: true,
};

export { CREATOR, ADMIN };
