import { useSetUserBlockAt } from 'api/admin/user/useSetUserBlockAt';
import { useSetUserUnlock } from 'api/admin/user/useSetUserUnlock';
import { ROLES } from 'config/Roles';
import { useModal, useToast } from 'hooks';
import { IRole, IUserTableRow } from 'interfaces';
import { useCallback } from 'react';
import { formatMoney } from 'utils';
import { ApproveFormModal } from './ApproveFormModal';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { BadgeTypeEnum } from 'config';

interface IUserHeaderProfileProps {
  user: IUserTableRow;
}

const checkStatus = (
  role: IRole,
  blockAt: string,
  deletedAt: string,
  verificationAt: string,
  approvedAt: string | undefined
) => {
  if (blockAt)
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Bloccato
      </span>
    );
  if (deletedAt)
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Eliminato
      </span>
    );
  if (!verificationAt)
    return (
      <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
        Non verificato
      </span>
    );
  if (role.code === ROLES.CREATOR && !approvedAt)
    return (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Non approvato
      </span>
    );
  return (
    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
      Attivo
    </span>
  );
};

export const UserHeaderProfile = (props: IUserHeaderProfileProps) => {
  const { user } = props;

  const { setUserBlockAtMutation } = useSetUserBlockAt(user.id);
  const { mutate: blockUser } = setUserBlockAtMutation;

  const { setUserUnlockMutation } = useSetUserUnlock(user.id);
  const { mutate: unlockUser } = setUserUnlockMutation;

  const { toastError, toastSuccess } = useToast();

  const {
    openModal: approveFormModalOpen,
    closeModal: approveFormModalClose,
    modalData: approveFormModal,
    setData: approveFormModalSetData,
  } = useModal();

  const handleApprove = useCallback(() => {
    approveFormModalSetData({ id: user.id });
    approveFormModalOpen();
  }, [approveFormModalSetData, approveFormModalOpen, user]);

  const onSuccesApproveModal = useCallback(() => {
    approveFormModalClose();

    toastSuccess({
      title: 'Operazione completata',
      // message: 'Utente approvato correttamente',
    });
  }, [approveFormModalClose, toastSuccess]);

  const onErrorApproveModal = useCallback(() => {
    toastError({
      title: 'Errore generico',
      message: "Impossibile approvare l'utente",
    });

    approveFormModalClose();
  }, [toastError, approveFormModalClose]);

  // approveUser(user.id, {
  //   onSuccess: () =>
  //     toastSuccess({
  //       title: 'Operazione completata',
  //       message: 'Utente approvato correttamente',
  //     }),
  //   onError: () =>
  //
  // });

  return (
    <>
      <ApproveFormModal
        {...approveFormModal}
        onClose={approveFormModalClose}
        onError={onErrorApproveModal}
        onSuccess={onSuccesApproveModal}
      />
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex justify-center">
                {user?.image ? (
                  <img
                    className="mx-auto h-20 w-20 rounded-full"
                    src={user.image?.url}
                    alt=""
                  />
                ) : (
                  <span className="inline-flex items-center justify-center h-20 w-20 rounded-full bg-gray-500">
                    <span className="text-2xl font-medium leading-none text-white">
                      {user ? user.username.slice(0, 2).toUpperCase() : 'UK'}
                    </span>
                  </span>
                )}
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">
                  {checkStatus(
                    user.role,
                    user.blockAt,
                    user.deleteAt,
                    user.verificationAt,
                    user.profile?.approveAt
                  )}
                </p>
                <div className="flex justify-center">
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                    {user.username}
                  </p>
                  {user.badge &&
                    user.badge.code &&
                    user.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
                      <BadgeCheckIcon
                        className="h-4 w-4 ml-1"
                        aria-hidden="true"
                        color="#3897f0"
                      />
                    )}
                </div>

                <p className="text-sm font-medium text-gray-600">{user.role?.code}</p>
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0">
              {user.blockAt ? (
                <button
                  onClick={() => {
                    unlockUser(user.id, {
                      onSuccess: () =>
                        toastSuccess({
                          title: 'Operazione completata',
                          message: 'Utente sbloccato correttamente',
                        }),
                      onError: () =>
                        toastError({
                          title: 'Errore generico',
                          message: "Impossibile bloccare l'utente",
                        }),
                    });
                  }}
                  className="inline-block mr-5 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Sblocca
                </button>
              ) : (
                <button
                  onClick={() => {
                    blockUser(user.id, {
                      onSuccess: () =>
                        toastSuccess({
                          title: 'Operazione completata',
                          message: 'Utente bloccato correttamente',
                        }),
                      onError: () =>
                        toastError({
                          title: 'Errore generico',
                          message: "Impossibile bloccare l'utente",
                        }),
                    });
                  }}
                  className="inline-block sm:mr-5 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Blocca
                </button>
              )}
              {user.role.code === ROLES.CUSTOMER || user.profile?.approveAt ? null : (
                <button
                  onClick={handleApprove}
                  className="inline-block items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Approva / Rifiuta
                </button>
              )}
            </div>
          </div>
        </div>
        {user.role.code === ROLES.CREATOR ? (
          <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-gray-900">
                {formatMoney(user.wallet?.availableNow)}
              </span>
              <br />
              <span className="text-gray-600">Saldo disponibile</span>
            </div>
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-gray-900">
                {formatMoney(user.wallet?.availableSoon)}
              </span>
              <br />
              <span className="text-gray-600">Presto disponibile</span>
            </div>
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-gray-900">{formatMoney(user.wallet?.inTransit)}</span>
              <br />
              <span className="text-gray-600">In trasferimento</span>
            </div>
            <div className="px-6 py-5 text-sm font-medium text-center">
              <span className="text-gray-900">{user.wallet?.fee.amount}%</span>
              <br />
              <span className="text-gray-600">Fee</span>
            </div>
          </div>
        ) : user.role.code === ROLES.CUSTOMER ? (
          <div className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-1 sm:divide-y-0 sm:divide-x">
            <div className="px-6 py-5 text-sm font-medium text-left">
              <span className="text-gray-600">Saldo attuale wallet</span>
              <br />
              <span className="text-gray-900">
                {formatMoney(user.wallet?.availableDeposit)}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
