// 3p
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';

// app
import { IProduct } from 'interfaces';
import { formatMoney } from 'utils';

interface ProductCardProps {
  data: IProduct;
  onEdit?: Function;
  onBuy?: Function;
  onRemove?: Function;
}

export default function ProductCard(props: ProductCardProps) {
  const { data, onEdit, onBuy, onRemove } = props;

  const onClickEdit = () => {
    if (onEdit) onEdit(data);
  };

  const onClickRemove = () => {
    if (onRemove) onRemove(data);
  };

  const onClickBuy = () => {
    if (onBuy) onBuy(data);
  };

  const { price, title, description } = data;

  return (
    <div className="flex m-auto max-w-md bg-white rounded sm:shadow-md sm:rounded-md overflow-hidden">
      <div className="w-full p-4">
        <h1 className="text-gray-900 font-medium text-2xl">{title}</h1>
        <p className="mt-2 text-gray-600 text-sm">{description}</p>
        <div className="flex item-center mt-2">
          {/* <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
          </svg>
          <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
          </svg>
          <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
          </svg>
          <svg className="w-5 h-5 fill-current text-gray-500" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
          </svg>
          <svg className="w-5 h-5 fill-current text-gray-500" viewBox="0 0 24 24">
            <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
          </svg> */}
        </div>
        <div className="flex item-center justify-between mt-3">
          <h1 className="text-gray-700 font-medium text-xl">{formatMoney(price)}</h1>
          {/* <button className="px-3 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded">
            Add to Card
          </button> */}
          <div>
            {onBuy && (
              <button
                onClick={onClickBuy}
                type="button"
                title="Buy"
                className="inline-flex rounded-md border border-transparent px-2.5 py-1 bg-pink-600 text-base font-medium text-white shadow hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
              >
                Acquista
              </button>
            )}

            {onEdit && (
              <button
                onClick={onClickEdit}
                type="button"
                title="Modifica"
                className="mr-2 inline-flex justify-center shadow-sm px-2.5 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-300 bg-white hover:bg-gray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}

            {onRemove && (
              <button
                type="button"
                title="Elimina"
                className="inline-flex justify-center shadow-sm px-2.5 py-1 border border-red-300 text-sm leading-5 font-medium rounded-md text-red-300 bg-white hover:bg-red-50 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={onClickRemove}
              >
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <li
  //     key={id}
  //     className="bg-white shadow overflow-hidden px-4 py-4 sm:px-6 sm:rounded-md hover:shadow-md transition duration-500 "
  //   >
  //     <div className="flex items-center space-x-4">
  //       <div className="shrink-0">
  //         {/* <button
  //                   type="button"
  //                   className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
  //                 >
  //                   <span> €{data.price} </span>
  //                 </button> */}
  //         <span className="inline-flex items-center px-2.5 py-2 rounded-md text-lg font-medium bg-pink-100 text-pink-800">
  //           {formatMoney(price)}
  //         </span>
  //         {/* <span className="inline-flex items-center justify-center p-5 rounded-lg bg-pink-500">
  //                   <span className="text-xl font-medium leading-none text-white">
  //                     €{data.price}
  //                   </span>
  //                 </span> */}
  //         {/* <span className="text-xl font-regular text-gray-500"></span> */}
  //       </div>
  //       <div className="flex-1 min-w-0">
  //         <p className="text-lg font-medium text-gray-900 truncate">{title}</p>
  //         <p className="text-sm text-gray-500 line-clamp-3">{description}</p>
  //       </div>
  //       <div>
  //
  //       </div>
  //     </div>
  //   </li>
  // );
}
