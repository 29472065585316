import { Route, Routes } from 'react-router-dom';

import CreatorWebProfile from './Profile';
import { ProfileAlbumGetAll } from './ProfileAlbumGetAll';
import { ProfileAlbumGetOne } from './ProfileAlbumGetOne';

export default function ProfileWebsiteView() {
  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <Routes>
        <Route index element={<CreatorWebProfile />} />
        <Route path={`albums`}>
          <Route index element={<ProfileAlbumGetAll />} />
          <Route path={`:albumId`} element={<ProfileAlbumGetOne />} />
        </Route>
      </Routes>
    </div>
  );
}
