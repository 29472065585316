import { Fragment, useCallback, useMemo } from 'react';

// 3p
import { useParams } from 'react-router-dom';

// app
import { IChatThread } from 'interfaces';

import { UserImage } from '../image';
import { formatDateDistance } from 'utils';
import { BadgeCheckIcon } from '@heroicons/react/solid';
import { BadgeTypeEnum } from 'config';
import { useAuth } from 'hooks';

interface IChatListItemProps extends IChatThread {
  onClick: (uuid: string) => void;
}

export const ChatListItem = (props: IChatListItemProps) => {
  const { uuid, participants, message, hasNewMessage, onClick } = props;

  const { user } = useAuth();
  const { chatId } = useParams();

  const isActive = useMemo(() => uuid === chatId, [uuid, chatId]);

  // Is Online
  let isUserOnline = false;
  if (participants && participants.length === 1) {
    const { lastActivityAt } = participants[0];

    const { seconds } = formatDateDistance(lastActivityAt);
    isUserOnline = !(seconds > 300);
  }

  // Message Data
  const [messageContent, messageTimeFormatted] = useMemo(() => {
    const { sendAt, content, photo, video, audio } = message;

    const messageContent = content
      ? content
      : audio
      ? '🔊 Audio'
      : photo
      ? '📷 Photo'
      : video
      ? '📹 Video'
      : null;

    let messagesendAt = null;
    if (sendAt) {
      const { distance, seconds } = formatDateDistance(sendAt);
      messagesendAt = seconds > 180 ? distance : 'Poco fa';
    }

    return [messageContent, messagesendAt];
  }, [message]);

  // Partecipans Names
  const renderParticipants = useMemo(() => {
    const { id: userId } = user!;

    const users = participants.filter((u) => u.id !== userId);

    return users.map((u, i) => (
      <Fragment key={u.id}>
        <p className={`${hasNewMessage ? 'font-bold' : ''}`}>{u.username}</p>
        {u.badge && u.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
          <BadgeCheckIcon className="h-4 w-4 ml-0.5" aria-hidden="true" color="#3897f0" />
        )}
        {i === 0 && users.length > 1 && <span className="mr-0.5">,</span>}
      </Fragment>
    ));
  }, [user, participants, hasNewMessage]);

  // Images
  const renderImages = useMemo(() => {
    const { id: userId } = user!;

    const images = participants
      .filter((u) => u.id !== userId)
      .flatMap(({ image }) => (image ? image.url : []));

    return images.length > 1 ? (
      <>
        <img
          className="shadow-md rounded-full w-10 h-10 object-cover absolute ml-6"
          src={images[0]}
          alt="User2"
        />
        <img
          className="shadow-md rounded-full w-10 h-10 object-cover absolute mt-6"
          src={images[1]}
          alt="User2"
        />
      </>
    ) : (
      <UserImage
        alt={images[0]}
        params={{ class: 'medium', aspect_ratio: '1:1' }}
        image={{ type: 'image', url: images[0] }}
        className="w-full h-full object-cover rounded"
      />
    );
  }, [participants, user]);

  const handleOnClick = useCallback(() => {
    if (!onClick) return;
    onClick(uuid);
  }, [uuid, onClick]);

  return (
    <div
      onClick={handleOnClick}
      className={`${
        isActive ? 'bg-gray-200' : 'hover:bg-gray-100'
      } flex justify-between items-center p-3 relative cursor-pointer`}
    >
      <div className="w-16 h-16 relative flex flex-shrink-0">
        {renderImages}
        {isUserOnline && (
          <div className="absolute bg-white p-1 rounded-full bottom-0 right-0">
            <div className="bg-green-500 rounded-full w-3 h-3"></div>
          </div>
        )}
      </div>
      <div className="flex-auto min-w-0 ml-4 mr-6 block">
        <div className="flex truncate">{renderParticipants}</div>
        <div
          className={`flex items-center text-sm  ${
            hasNewMessage ? 'font-bold' : 'text-gray-600'
          }`}
        >
          <div className="min-w-0 flex-1">
            <p className="truncate">{messageContent}</p>
          </div>
          <p className="ml-2 whitespace-nowrap">
            {messageTimeFormatted ? `${messageTimeFormatted}` : ''}
          </p>
        </div>
      </div>
      {hasNewMessage ? (
        <div className="bg-pink-500 w-3 h-3 rounded-full flex flex-shrink-0"></div>
      ) : null}
      {/* {lastseen ? (
        <div className="w-4 h-4 flex flex-shrink-0 hidden md:block">
          <img className="rounded-full w-full h-full object-cover" alt={names} src={''} />
        </div>
      ) : null} */}
    </div>
  );
};
