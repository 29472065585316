// std

// 3p
import { CheckIcon } from '@heroicons/react/outline';

// app
import { IPayInResponse } from 'interfaces';
import { PaymentInTypeEnum } from 'config';

interface IPaymentSuccess {
  payment: IPayInResponse;
  onSuccess?: () => void;
}

export const ContentPaymentSuccess = (props: IPaymentSuccess) => {
  const { payment, onSuccess } = props;

  if (PaymentInTypeEnum[payment.type] === PaymentInTypeEnum.DIRECT) {
    return (
      <>
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Perfetto!</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Pagamento effettuato correttamente.</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:text-sm"
            onClick={() => {
              if (onSuccess) onSuccess();
            }}
          >
            Chiudi
          </button>
        </div>
      </>
    );
  }

  if (PaymentInTypeEnum[payment.type] === PaymentInTypeEnum.LINK) {
    return (
      <>
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Perfetto!</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Concludi il pagamento in modo sicuro.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <a
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:text-sm"
            target="_blank"
            rel="noopener noreferrer"
            href={payment.url}
            onClick={() => {
              if (onSuccess) onSuccess();
            }}
          >
            Vai al checkout
          </a>
        </div>
      </>
    );
  }

  return <div />;
};
