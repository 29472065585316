// 3p
import { Dialog } from '@headlessui/react';

// app
import { AlertError } from '../alert';

export const ContentError = () => (
  <>
    <div className="mt-3 text-center sm:mt-5">
      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
        Errore
      </Dialog.Title>
      <div className="mt-2">
        <AlertError message="Errore generico" />
      </div>
    </div>
  </>
);
