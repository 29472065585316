// border border-yellow-300
export const PaymentWarningAlert = () => (
  <div className="rounded bg-yellow-50 p-4 sm:shadow-md sm:rounded-md">
    <div className="flex">
      {/* <div className="shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div> */}
      <div>
        <h3 className="text-xl font-bold text-yellow-800">⚠️ATTENZIONE LEGGERE BENE⚠️</h3>

        <div className="mt-4 text-md text-yellow-700 font-bold leading-relaxed">
          PRIMA DI EFFETTUARE IL PAGAMENTO CONTATTA IL CREATORS E ACCERTATI CHE SIA
          DISPONIBILE AD EFFETTUARE IL SERVIZIO RICHIESTO.
        </div>
      </div>
    </div>
  </div>
);
