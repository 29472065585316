// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { IResponseWithCursor, ITransferTableRow } from 'interfaces';

import { ENV_ADMIN } from 'config/Enviroment';

import APIClient from '../../ApiClient';

const getAllTransfer = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, pagingQuery, searchParams] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<ITransferTableRow>>(key, {
    params: {
      ...searchParams,
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllTransfer(
  pagingQuery: ITransferTableRow,
  searchParams?: { code?: string }
) {
  const getAllTransferQuery = useQuery<
    IResponseWithCursor<ITransferTableRow>,
    AxiosError<string, any>
  >([ENV_ADMIN.GET_ALL_TRANSFER, pagingQuery, searchParams], getAllTransfer, {
    keepPreviousData: true,
  });

  return { getAllTransferQuery };
}
