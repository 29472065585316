// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ALBUM, ENV_USER } from 'config/Enviroment';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks';
import { IAlbum } from 'interfaces';

const deleteAlbum = async (album: IAlbum) => {
  await APIClient.delete<void>(ENV_ALBUM.DELETE(album.id));
  return album;
};

export function useDeleteAlbum() {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const deleteAlbumMutation = useMutation<IAlbum, AxiosError<string, any>, IAlbum>(
    (album) => deleteAlbum(album),
    {
      onSuccess: (album) => {
        if (user) queryClient.invalidateQueries(ENV_USER.GET_ALL_ALBUM(user.username));
        queryClient.invalidateQueries(ENV_ALBUM.GET_ALBUM(album.id));
        queryClient.invalidateQueries(ENV_ALBUM.GET_ALL);
      },
    }
  );

  return { deleteAlbumMutation };
}
