// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_USER } from 'config/Enviroment';

import APIClient from '../ApiClient';

const deleteAccount = async () => {
  await APIClient.delete(ENV_USER.DELETE_ACCOUNT);
};

export function useDeleteAccount() {
  const queryClient = useQueryClient();

  const deleteAccountMutation = useMutation<void, AxiosError<string, any>>(
    () => deleteAccount(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  return { deleteAccountMutation };
}
