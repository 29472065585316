export const BAD_WORD_LIST = [
  'admin',
  'amministratore',
  'supporto',
  'help',
  'aiuto',
  'support',
  'vieniora',
  'bastardo',
  'bastardi',
  'bastarda',
  'bastarde',
  'bernarda',
  'bischero',
  'bischera',
  'bocchino',
  'bordello',
  'cacare',
  'cacarella',
  'cagare',
  'cagata',
  'cagate',
  'caghetta',
  'cagone',
  'cazz',
  'cazzata',
  'cazzo',
  'cazzone',
  'cazzoni',
  'cazzona',
  'cess',
  'cessa',
  'cesso',
  'ciucciata',
  'cogliona',
  'coglione',
  'cristo',
  'cretina',
  'cretino',
  'culattone',
  'culattona',
  'culo',
  'culone',
  'culona',
  'culoni',
  'deficiente',
  'dio',
  'dick',
  'figa',
  'fottuta',
  'fottuto',
  'frocio',
  'frocione',
  'frocetto',
  'gesu',
  'imbecille',
  'imbecilli',
  'incazzare',
  'incazzato',
  'incazzati',
  'madonna',
  'maronna',
  'merda',
  'merdina',
  'merdona',
  'merdaccia',
  'mignotta',
  'mignottona',
  'mignottone',
  'mortacci',
  'negro',
  'negra',
  'pippa',
  'pippona',
  'pippone',
  'pippaccia',
  'pirla',
  'pompino',
  'porco',
  'puttana',
  'puttanona',
  'puttanone',
  'puttaniere',
  'puttanate',
  'rompiballe',
  'rompipalle',
  'rompicoglioni',
  'scazzi',
  'scemo',
  'scopare',
  'scopata',
  'stronzata',
  'stronzo',
  'stronzone',
  'troia',
  'troione',
  'trombata',
  'vaffanculo',
  'zoccola',
  'zoccolona',
];
