// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IAnalyticsGateway } from 'interfaces';

import APIClient from '../../ApiClient';

const getAdminAnalyticsGateway = async (startAt: string | null, endAt: string | null) => {
  const { data } = await APIClient.get<IAnalyticsGateway>(
    ENV_ADMIN.GET_ALL_ANLYTICS_SOURCE,
    {
      params: {
        startAt: startAt,
        endAt: endAt,
        groupBy: 'month',
      },
    }
  );
  return data;
};

export function useGetAdminAnalyticsGateway(
  startAt: string | null,
  endAt: string | null
) {
  const getAdminAnalyticsGatewayQuery = useQuery<
    IAnalyticsGateway,
    AxiosError<string, any>
  >(
    [ENV_ADMIN.GET_ALL_ANLYTICS_SOURCE, startAt, endAt],
    () => getAdminAnalyticsGateway(startAt, endAt),
    { enabled: startAt != null && endAt != null }
  );

  return {
    getAdminAnalyticsGatewayQuery,
  };
}
