import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import * as yup from 'yup';

import { Switch } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadIcon } from '@heroicons/react/solid';

import axios from 'axios';

import { compress, EImageType } from 'image-conversion';

// app
import { ISignupCreatorConfirmForm } from 'interfaces';
import { ENV_AUTH, CURRENT_LOGO_URI, CURRENT_SITE_NAME } from 'config';
import { calculateAge, classNames, fromByteToMb } from 'utils';
import { useGetAllCountry, useGetAllGender } from 'api/common';
import { useAuth, useToast } from 'hooks';
import APIClient from 'api/ApiClient';

import Loader from '../Loader';
import { AlertSuccess } from '../alert';

import styles from 'styles';

// interface ISignupCreatorConfirmProps {
//   error?: string;
//   onSubmit: SubmitHandler<ISignupCreatorConfirmForm>;
// }

const ENUM_JPEG = 'image/jpeg';
const ENUM_JPG = 'image/jpg';
const ENUM_PNG = 'image/png';
const ADULT_AGE = 18;

export const SignupCreatorConfirm = () => {
  let { token } = useParams<{ token: string }>() as { token: string };

  const [isLoadingReq, setIsLoadingReq] = useState<boolean>(false);
  const { isLoading } = useAuth();
  const [isSignupConfirmed, setIsSignupConfirmed] = useState<boolean>(false);
  const { toastError } = useToast();

  // const [tokenError, setTokenError] = useState<boolean>(false);
  // const [tokenErrorMessage, setTokenErrorMessage] = useState<string | undefined>(
  //   undefined
  // );

  const { getAllCountryQuery } = useGetAllCountry();
  const { data: getAllCountryResponse } = getAllCountryQuery;

  const { getAllGenderQuery } = useGetAllGender();
  const { data: getAllGenderResponse } = getAllGenderQuery;

  useEffect(() => {
    if (!getAllCountryResponse?.items.length) return;
  }, [getAllCountryResponse]);

  useEffect(() => {
    if (!getAllGenderResponse?.items.length) return;
  }, [getAllGenderResponse]);

  const SignupCreatorConfirmSchema = yup
    .object({
      fullname: yup.string().required(),
      birthday: yup
        .string()
        .required()
        .test({
          name: 'isAdult',
          exclusive: true,
          message: 'È necessario essere maggiorenni',
          test: (value) => (value ? calculateAge(value) >= ADULT_AGE : false),
        }),
      country: yup
        .string()
        .required()
        .test({
          name: 'required',
          exclusive: false,
          message: 'Campo obbligatorio',
          test: (value) => (value ? parseInt(value) !== 0 : false),
        }),
      gender: yup
        .string()
        .required()
        .test({
          name: 'required',
          exclusive: false,
          message: 'Campo obbligatorio',
          test: (value) => (value ? parseInt(value) !== 0 : false),
        }),
      creatorReadFaqConsent: yup.boolean().required().isTrue(),
    })
    .required();

  const areFilesValid = (documents: File[]): boolean => {
    if (!documents || documents.length === 0) return false;
    for (const fileBlob of documents) {
      const fileSizeMb = fromByteToMb(fileBlob.size);

      if (fileSizeMb > 5) {
        toastError({
          title: 'Attenzione',
          message:
            'Il file ' + fileBlob.name + ' supera il limite massimo di 5Mb. Riprovare',
        });
        return false;
      }

      if (![ENUM_JPEG, ENUM_JPG, ENUM_PNG].includes(fileBlob.type)) {
        toastError({
          title: 'Attenzione',
          message:
            "L'estensione del file " + fileBlob.name + ' non è supportata. Riprovare',
        });
        return false;
      }
    }
    return true;
  };

  // const compressFiles = async (documents: File[]): Promise<FormData | undefined> => {
  //   if (!documents || documents.length === 0) return undefined;
  //   const formData = new FormData();
  //   for (const fileBlob of documents) {
  //     const fileBlobCompressed = await compress(fileBlob, {
  //       quality: 0.8,
  //       type: EImageType.JPEG,
  //     });
  //     formData.append('documents', fileBlobCompressed);
  //   }
  //   return formData;
  // };

  const compressFile = async (document: File): Promise<Blob | undefined> => {
    if (!document) return undefined;

    return await compress(document, {
      quality: 0.8,
      type: EImageType.JPEG,
    });
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ISignupCreatorConfirmForm>({
    resolver: yupResolver(SignupCreatorConfirmSchema),
  });

  const watchIdentityCardFront = watch('identityCardFront');
  const identityCardFrontFileName = useMemo(
    () => (watchIdentityCardFront ? watchIdentityCardFront.name : null),
    [watchIdentityCardFront]
  );

  const watchIdentityCardBack = watch('identityCardBack');
  const identityCardBackFileName = useMemo(
    () => (watchIdentityCardBack ? watchIdentityCardBack.name : null),
    [watchIdentityCardBack]
  );

  const watchIdentityCardSelfie = watch('identityCardSelfie');
  const identityCardSelfieFileName = useMemo(
    () => (watchIdentityCardSelfie ? watchIdentityCardSelfie.name : null),
    [watchIdentityCardSelfie]
  );

  const onSubmit: SubmitHandler<ISignupCreatorConfirmForm> = async ({
    fullname,
    birthday,
    country,
    gender,
    identityCardFront,
    identityCardBack,
    identityCardSelfie,
    creatorReadFaqConsent,
  }) => {
    if (
      [identityCardFront, identityCardBack, identityCardSelfie].some(
        (element) => element === undefined
      )
    ) {
      toastError({
        title: 'Attenzione',
        message: 'Caricare tutti i documenti',
      });
      return;
    }
    if (!areFilesValid([identityCardFront, identityCardBack, identityCardSelfie])) return;

    try {
      setIsLoadingReq(true);
      const identityCardFrontCompressed = await compressFile(identityCardFront);
      const identityCardBackCompressed = await compressFile(identityCardBack);
      const identityCardSelfieCompressed = await compressFile(identityCardSelfie);

      const dataToSend = new FormData();
      dataToSend?.append('token', token);
      dataToSend?.append('fullname', fullname);
      dataToSend?.append('birthday', birthday);
      dataToSend?.append('country', country);
      dataToSend?.append('gender', gender);
      dataToSend?.append('creatorReadFaqConsent', creatorReadFaqConsent.toString());
      dataToSend?.append('identityCardFront', identityCardFrontCompressed!);
      dataToSend?.append('identityCardBack', identityCardBackCompressed!);
      dataToSend?.append('identityCardSelfie', identityCardSelfieCompressed!);

      await APIClient.post(ENV_AUTH.SIGNUP_CREATOR_CONFIRM, dataToSend);
      setIsLoadingReq(false);
      setIsSignupConfirmed(true);
    } catch (ex) {
      if (axios.isAxiosError(ex)) {
        setIsLoadingReq(false);
        // const error = ex as AxiosError<string>;
        // setTokenError(true);
        // setTokenErrorMessage(error.response?.data);
      }

      console.error(ex);
    }
  };

  // try {
  //TODO: controllo token
  // await APIClient.post(ENV_AUTH.SIGNUP_CONFIRM, dataToSend);
  // setIsSignupConfirmed(true);
  //   await signup(fullname, birthday, country, gender, documents);
  //   setIsSubmitSuccessful(true);
  // } catch (error: any) {
  // const error = ex as Error | AxiosError<string>;
  // if (axios.isAxiosError(error)) {
  //   setTokenError(true);
  //   setTokenErrorMessage(error.response?.data);
  // }
  // console.error(error);
  //   if (error.response) {
  //     //TODO: gestire errore in caso di richiesta già inviata e non ancora confermata
  //     setError(error.message || 'Errore generico');
  //   }
  // }

  if (isLoading || isLoadingReq) return <Loader />;

  if (isSignupConfirmed)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertSuccess
          message="Registrazione completata con successo. 
        Il tuo account è in fase di verifica, entro 48h riceverai una mail di conferma, dopodichè potrai cominciare a pubblicare i tuoi contenuti privati."
        />
        <Link className={styles.button.full + ' mt-5'} to="/">
          Vai alla home
        </Link>
      </div>
    );

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 ">
        <div className="flex justify-center mb-4">
          <Link to="/">
            <span className="sr-only">{CURRENT_SITE_NAME}</span>
            <img
              className="block h-8 w-auto"
              src={CURRENT_LOGO_URI}
              alt={CURRENT_SITE_NAME}
            />
          </Link>
        </div>
        <div className="divide-y">
          <div className="mb-4">
            <p className="flex justify-center prose prose-indigo prose-md text-gray-500 mx-auto">
              Complimenti, hai quasi terminato!
            </p>
            <p className="text-center prose prose-indigo prose-md text-gray-500 mx-auto">
              Completa l'ultimo passaggio per diventare un creator e cominciare a
              guadagnare con i tuoi contenuti
            </p>
          </div>

          <div>
            <form className="space-y-6 mt-8" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome e cognome (non sarà visibile agli altri utenti)
                </label>
                <div className="mt-1">
                  <input
                    id="fullname"
                    type="text"
                    {...register('fullname', { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />
                  {errors.fullname?.type === 'required' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Campo obbligatorio
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-gray-700"
                >
                  Data di nascita
                </label>
                <div className="mt-1">
                  <input
                    id="birthday"
                    type="date"
                    autoComplete="birthday"
                    {...register('birthday', { required: true })}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  />
                  {errors.birthday?.type === 'required' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Campo obbligatorio
                    </p>
                  ) : errors.birthday?.type === 'isAdult' ? (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      {errors.birthday?.message}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700"
                >
                  Genere
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <select
                    id="gender"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md"
                    defaultValue={0}
                    {...register('gender', { required: true })}
                  >
                    <option disabled value={0}>
                      Seleziona
                    </option>
                    {getAllGenderResponse?.items.map(({ id, code, description }) => (
                      <option key={id} value={code}>
                        {description}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.gender?.type === 'required' ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    Campo obbligatorio
                  </p>
                ) : null}
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nazionalità
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <select
                    id="country"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md"
                    defaultValue={0}
                    {...register('country', { required: true })}
                  >
                    <option disabled value={0}>
                      Seleziona
                    </option>
                    {getAllCountryResponse?.items.map(({ id, code, name }) => (
                      <option key={id} value={code}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.country?.type === 'required' ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    Campo obbligatorio
                  </p>
                ) : null}
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
                <div className="sm:col-span-3">
                  <div className="grid grid-cols-1 space-y-2">
                    <h3 className="block text-md font-medium text-gray-700 tracking-wide">
                      Carica il fronte della tua carta d'identità
                    </h3>
                    <div className="col-span-4 sm:col-span-2">
                      <label className="inline-flex items-center cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        <UploadIcon className="mr-2 h-5 w-5" />
                        <span>Carica fronte</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => {
                            setValue('identityCardFront', e.target.files![0]);
                          }}
                        />
                      </label>
                      <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {identityCardFrontFileName ? identityCardFrontFileName : null}
                      </p>
                    </div>
                  </div>
                  <p className="text-sm text-gray-300 mt-3">
                    <span>
                      Formati supportati: jpeg, jpg, png - Dimensione massima: 5MB
                    </span>
                  </p>
                </div>

                <div className="sm:col-span-3">
                  <div className="grid grid-cols-1 space-y-2">
                    <h3 className="block text-md font-medium text-gray-700 tracking-wide">
                      Carica il retro della tua carta d'identità
                    </h3>
                    <div className="col-span-4 sm:col-span-2">
                      <label className="inline-flex items-center cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        <UploadIcon className="mr-2 h-5 w-5" />
                        <span>Carica retro</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          name="identityCardBack"
                          onChange={(e) => {
                            setValue('identityCardBack', e.target.files![0]);
                          }}
                        />
                      </label>
                      <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {identityCardBackFileName ? identityCardBackFileName : null}
                      </p>
                    </div>
                  </div>
                  <p className="text-sm text-gray-300 mt-3">
                    <span>
                      Formati supportati: jpeg, jpg, png - Dimensione massima: 5MB
                    </span>
                  </p>
                </div>

                <div className="sm:col-span-3">
                  <div className="grid grid-cols-1 space-y-2">
                    <h3 className="block text-md font-medium text-gray-700 tracking-wide">
                      Carica un selfie con il fronte del tuo documento
                    </h3>
                    <div className="col-span-4 sm:col-span-2">
                      <label className="inline-flex items-center cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        <UploadIcon className="mr-2 h-5 w-5" />
                        <span>Carica selfie</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          name="identityCardSelfie"
                          onChange={(e) => {
                            setValue('identityCardSelfie', e.target.files![0]);
                          }}
                        />
                      </label>
                      <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {identityCardSelfieFileName ? identityCardSelfieFileName : null}
                      </p>
                    </div>
                  </div>
                  <p className="text-sm text-gray-300 mt-3">
                    <span>
                      Formati supportati: jpeg, jpg, png - Dimensione massima: 5MB
                    </span>
                  </p>
                </div>
              </div>

              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-start justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="block text-md font-medium text-gray-700"
                      passive
                    >
                      <span className="block text-sm font-medium text-gray-700">
                        Dichiaro di aver letto le{' '}
                        <span className="text-sm font-medium text-blue-600 hover:text-blue-500">
                          <Link to="/faq">Faq creators </Link>
                        </span>
                        e di aver accettato il{' '}
                        <a
                          href="/assets/Vieniora Contratto Creator.pdf"
                          className="text-blue-500"
                        >
                          CONTRATTO
                        </a>{' '}
                        creators
                      </span>
                    </Switch.Label>
                  </span>

                  <Controller
                    name="creatorReadFaqConsent"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
                {errors.creatorReadFaqConsent ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    È necessario accettare di aver letto le faq creators
                  </p>
                ) : null}
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  Completa registrazione
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
