// /* This example requires Tailwind CSS v2.0+ */
// import { InboxIcon, HeartIcon, TrashIcon, UsersIcon } from '@heroicons/react/outline';

// const features = [
//   {
//     name: 'Unlimited Inboxes',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: InboxIcon,
//   },
//   {
//     name: 'Manage Team Members',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: UsersIcon,
//   },
//   {
//     name: 'Spam Report',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: TrashIcon,
//   },
//   {
//     name: 'Compose in Markdown',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: HeartIcon,
//   },
//   {
//     name: 'Team Reporting',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: HeartIcon,
//   },
//   {
//     name: 'Saved Replies',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: HeartIcon,
//   },
//   {
//     name: 'Email Commenting',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: HeartIcon,
//   },
//   {
//     name: 'Connect with Customers',
//     description:
//       'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: HeartIcon,
//   },
// ];

// export function FeatureSection() {
//   return (
//     <div className="bg-pink-700 rounded mt-6 mb-6 sm:mt-8 sm:mb-8 sm:shadow-md sm:rounded-md">
//       <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
//         <h2 className="text-3xl font-bold tracking-tight text-white">
//           Inbox support built for efficiency
//         </h2>
//         <p className="mt-4 max-w-3xl text-lg text-pink-200">
//           Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit
//           morbi lobortis. Blandit aliquam sit nisl euismod mattis in.
//         </p>
//         <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
//           {features.map((feature) => (
//             <div key={feature.name}>
//               <div>
//                 <span className="flex h-12 w-12 items-center justify-center rounded-md bg-white bg-opacity-10">
//                   <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
//                 </span>
//               </div>
//               <div className="mt-6">
//                 <h3 className="text-lg font-medium text-white">{feature.name}</h3>
//                 <p className="mt-2 text-base text-pink-200">{feature.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

/* This example requires Tailwind CSS v2.0+ */
import {
  BadgeCheckIcon,
  CogIcon,
  CreditCardIcon,
  EyeIcon,
  LockClosedIcon,
  SupportIcon,
} from '@heroicons/react/outline';

const features = [
  {
    name: 'Anonimato grantito',
    description:
      'Per tutti gli utenti sia creators che clienti è garantito il completo anonimato.',
    icon: CogIcon,
  },
  {
    name: 'Utenti verificati',
    description:
      'Tutti i creators sono verificati e certificati. I contenuti caricati sul sito sono sottoposti a una fase di approvazione da parte del nostro team.',
    icon: BadgeCheckIcon,
  },
  {
    name: 'Protezione acquisti',
    description:
      'Per tutti i pagamenti avvenuti tramite il nostro sito è garantito il rimborso nel caso in cui il servizio non sia stato erogato.',
    icon: LockClosedIcon,
  },
  {
    name: 'Pagamenti sicuri',
    description:
      'Supportiamo numerosi metodi di pagamento attraveso i nostri partner leader nel settore.',
    icon: CreditCardIcon,
  },
  {
    name: 'Privacy',
    description:
      "Le descrizioni dei pagamenti sull'estratto conto non sono riconducibili a nessun servizio per adulti.",
    icon: EyeIcon,
  },
  {
    name: 'Supporto h24',
    description:
      'Il nostro team è sempre pronto a fornire supporto, tramite gli appositi canali whatsapp, email e live chat.',
    icon: SupportIcon,
  },
];

export function FeatureGridSection() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32 rounded mt-6 mb-6 sm:mt-8 sm:mb-8 sm:shadow-md sm:rounded-md">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-pink-600">
          Scopri la nostra piattaforma
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Perchè utilizzare vieniora ?
        </p>
        {/* <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis
          quis nunc, ullamcorper malesuada. Eleifend condimentum id viverra nulla.
        </p> */}
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-pink-500 p-3 shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6 lg:mt-8">
          <div className="grid grid-cols-2 gap-6 md:grid-cols-3">
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src="assets/Visa-Logo.svg" alt="Visa" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img
                className="max-h-12"
                src="assets/Mastercard-Logo.svg"
                alt="Mastercard"
              />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src="assets/Maestro-Logo.svg" alt="Bitcoin" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src="assets/PayPal-Logo.svg" alt="Laravel" />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img
                className="max-h-12"
                src="assets/PaysafeCard-Logo.svg"
                alt="StaticKit"
              />
            </div>
            <div className="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
              <img className="max-h-12" src="assets/Bitcoin-Logo.svg" alt="Bitcoin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
