import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// app
import { MediaGrid } from 'components/common/media';
import { useGetAlbum } from 'api/album';

import { CalendarIcon, PhotographIcon, VideoCameraIcon } from '@heroicons/react/solid';

import { IAlbum } from 'interfaces';

import { formatDistance } from 'date-fns';
import { it } from 'date-fns/locale';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { UserImage } from 'components/common/image';

interface IProps {
  album: IAlbum;
}

export default function Example(props: IProps) {
  const { album } = props;

  const { user, title, createAt, videoCount = 0, photoCount = 0 } = album;

  const { username, image } = user;

  const navigate = useNavigate();

  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      <div className="shrink-0 mr-3">
        <button
          type="button"
          className="p-2 bg-white hover:bg-gray-200 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          // onClick={() => navigate(`/${username}/albums`)}
          onClick={() => navigate(-1)}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
        </button>
      </div>
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 truncate sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        <div className="mt-1 flex flex-row sm:mt-0 sm:flex-wrap space-x-2 sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <UserImage
              alt={username}
              className="mr-1.5 h-7 w-7 flex-shrink-0 rounded-full"
              aria-hidden="true"
              image={image}
            />
            <Link to={`/${username}`}>{username}</Link>
          </div>

          {videoCount > 0 && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <VideoCameraIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {videoCount} video
            </div>
          )}
          {photoCount > 0 && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <PhotographIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {photoCount} foto
            </div>
          )}
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {createAt
              ? formatDistance(new Date(), new Date(createAt), { locale: it })
              : null}
          </div>
        </div>
      </div>
      <div className="mt-5 flex justify-center items-center lg:mt-0 lg:ml-4">
        {/* <span className="hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
            Edit
          </button>
        </span>

        <span className="ml-3 hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
            View
          </button>
        </span> */}

        {/* {price > 0 && (
          <input
            type="button"
            className="w-full sm:w-auto inline-flex items-center justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
            value={`Sblocca tutti i contenuti ${formatMoney(price)}`}
          />
        )} */}
      </div>
    </div>
  );
}

export const ProfileAlbumGetOne = () => {
  const { albumId } = useParams<{ albumId: string }>() as { albumId: string };

  const { getAlbumQuery } = useGetAlbum(albumId);
  const { data: album } = getAlbumQuery;

  // TODO Handle Errors and loading

  if (!album) {
    return <Fragment />;
  }

  const { description } = album;

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-2 py-2 sm:px-2">
        <Example album={album} />
      </div>
      <div className="px-4 py-5 sm:p-6">{description}</div>
      <div className="px-4 py-4 sm:px-6">
        <MediaGrid
          album={album}
          hasDelete={false}
          hasSetPreview={false}
          hasBadgeApprovation={false}
        />
      </div>
    </div>
  );
};
