// 3p
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';
import { PencilAltIcon, UserGroupIcon } from '@heroicons/react/outline';

// app
import { ICursorObj, IResponseWithCursor, ICoupon } from 'interfaces';
import { formatMoney } from 'utils';
import { useModal } from 'hooks';

import { CouponFormModal } from './CouponFormModal';
import { CouponCustomerModal } from './CouponCustomerModal';

interface ICouponTableRowProps {
  coupon: ICoupon;
  onCustomerButton?: (id: number) => void;
  onEditButton?: (coupon: ICoupon) => void;
}

const checkStatus = (
  isEnable: boolean,
  maxNumOfUsage: number,
  currNumOfUsage: number
) => {
  if (!isEnable)
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Disabilitato
      </span>
    );

  if (maxNumOfUsage && currNumOfUsage >= maxNumOfUsage)
    return (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Esaurito
      </span>
    );
  return (
    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
      Attivo
    </span>
  );
};

const CouponTableRow = (props: ICouponTableRowProps) => {
  const { coupon, onCustomerButton, onEditButton } = props;

  const { id, code, description, amount, maxNumOfUsage, currNumOfUsage, isEnable } =
    coupon;

  const handleCustomerButton = () => {
    if (!onCustomerButton) return;
    onCustomerButton(id);
  };

  const handleEditButton = () => {
    if (!onEditButton) return;
    onEditButton(coupon);
  };

  return (
    <tr key={id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          {/* <div className="h-10 w-10 shrink-0">{image}</div> */}
          <div className="ml-4">
            <div className="font-medium text-gray-900">{code}</div>
            <div className="text-gray-500 overflow-hidden truncate w-40">
              {description}
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <div className="text-gray-900">{formatMoney(amount)}</div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {maxNumOfUsage ? maxNumOfUsage : 'Illimitati'}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {currNumOfUsage}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {checkStatus(isEnable, maxNumOfUsage, currNumOfUsage)}
      </td>
      <td className="relative whitespace-nowrap py-4 text-center text-sm font-medium pr-6">
        <button onClick={handleEditButton} className="text-pink-600 hover:text-pink-900">
          <PencilAltIcon className="h-5 w-5" />
        </button>
      </td>
      <td className="relative whitespace-nowrap py-4 text-center text-sm font-medium pr-6">
        <button
          onClick={handleCustomerButton}
          className="text-pink-600 hover:text-pink-900"
        >
          <UserGroupIcon className="h-5 w-5" />
        </button>
      </td>
    </tr>
  );
};

interface ICouponTableProps {
  query: UseQueryResult<IResponseWithCursor<ICoupon>, AxiosError<string, any>>;
  setCursor: React.Dispatch<React.SetStateAction<ICursorObj>>;
  tableTitle?: string;
}

export function CouponTable(props: ICouponTableProps): JSX.Element {
  const { query, setCursor, tableTitle } = props;
  const { data } = query;

  const {
    openModal: couponFormModalOpen,
    closeModal: couponFormModalClose,
    modalData: couponFormModal,
    setData: couponFormModalSetData,
  } = useModal<ICoupon>();

  const {
    openModal: couponCustomerModalOpen,
    closeModal: couponCustomerModalClose,
    modalData: couponCustomerModal,
    setData: couponCustomerModalSetData,
  } = useModal();

  const onCustomerButton = (id: number) => {
    couponCustomerModalSetData({ couponId: id });
    couponCustomerModalOpen();
  };

  const onEditButton = (coupon: ICoupon) => {
    couponFormModalSetData(coupon);
    couponFormModalOpen();
  };

  const prevPage = () => {
    if (data?.cursor.beforeCursor) setCursor({ beforeCursor: data?.cursor.beforeCursor });
  };

  const nextPage = () => {
    if (data?.cursor.afterCursor) setCursor({ afterCursor: data?.cursor.afterCursor });
  };

  return (
    <>
      <CouponFormModal
        {...couponFormModal}
        onClose={couponFormModalClose}
        onSuccess={couponFormModalClose}
      />
      <CouponCustomerModal {...couponCustomerModal} onClose={couponCustomerModalClose} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{tableTitle}</h1>
            <p className="mt-2 text-sm text-gray-700">
              Registro dei {tableTitle?.toLowerCase()} presenti all'interno del sistema
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 sm:w-auto"
              onClick={couponFormModalOpen}
            >
              Crea nuovo
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Codice e descrizione
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Importo
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Limite di utilizzi
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Numero utilizzi
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Stato
                      </th>
                      <th scope="col" className="relative py-3.5 pl-2 pr-2 sm:pr-3">
                        <span className="sr-only">Modifica</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-2 pr-2 sm:pr-3">
                        <span className="sr-only">Clienti</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data &&
                      data.data.map((coupon) => (
                        <CouponTableRow
                          key={coupon.id}
                          coupon={coupon}
                          onCustomerButton={onCustomerButton}
                          onEditButton={onEditButton}
                        />
                      ))}
                  </tbody>
                </table>
                {/* Pagination */}
                <nav
                  className="bg-white px-4 py-3 flex items-center border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  {/*TODO: mettere pagina 1 di x*/}
                  {/* <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Mostro <span className="font-medium">1</span> a{' '}
            <span className="font-medium">10</span> di{' '}
            <span className="font-medium">{data && data.data.length}</span> risultati
          </p>
        </div> */}
                  <div className="flex-1 flex sm:justify-end">
                    {data?.cursor.beforeCursor !== null && (
                      <button
                        onClick={prevPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Precedenti
                      </button>
                    )}
                    {data?.cursor.afterCursor !== null && (
                      <button
                        onClick={nextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Successivi
                      </button>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
