import { useMemo, useState } from 'react';

// 3p
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, UploadIcon } from '@heroicons/react/outline';

import { IAlbum, IModal } from 'interfaces';
import { useAuth, useToast } from 'hooks';

import { AlbumFormModal } from 'components/creator/album';

interface MediaBarProps {
  onUploadCover?: () => void;
  onUploadMedia?: () => void;
  album?: IAlbum;
  isWebsite?: boolean;
}

interface ILocationState extends Location {
  state: { albumTitle: string };
}

export function MediaBar(props: MediaBarProps) {
  const { onUploadCover, onUploadMedia, album } = props;
  const navigate = useNavigate();
  const { state } = useLocation() as ILocationState;

  const albumTitle = state?.albumTitle;

  const title = album?.title || albumTitle;

  const [mediaFormModal, setMediaFormModal] = useState<IModal<IAlbum>>({
    open: false,
  });

  const { toastError, toastSuccess } = useToast();

  /** ON SUCCESS */
  const onSuccessAlbumForm = (album: IAlbum) => {
    onCloseAlbumForm();

    toastSuccess({
      title: 'Ottimo!',
      message: 'Informazioni salvate correttamente',
    });
  };

  const onErrorAlbumForm = () => {
    toastError({
      title: 'Attenzione',
      message: 'Errore generico',
    });
  };

  const albumFormOpen = () => {
    setMediaFormModal({ open: true, data: album });
  };

  const onCloseAlbumForm = () => {
    setMediaFormModal({ open: false });
  };

  const { user } = useAuth();

  const isMyAlbum = useMemo(
    () => user && album && album.user.id === user.id,
    [user, album]
  );

  return (
    <>
      <AlbumFormModal
        {...mediaFormModal}
        onClose={onCloseAlbumForm}
        onSuccess={onSuccessAlbumForm}
        onError={onErrorAlbumForm}
      />
      <div className="bg-white shadow rounded-lg">
        <div className="sm:flex sm:items-center sm:justify-between sm:space-x-5 py-4 px-4">
          <div className="flex sm:space-x-5 sm:mb-0 truncate">
            <div className="shrink-0">
              <button
                type="button"
                className="p-2 bg-white hover:bg-gray-200 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
            <div className="text-center sm:text-left pt-1 truncate">
              <h2 className="text-xl sm:text-2xl font-bold leading-7 text-gray-900 truncate">
                {title}
              </h2>
            </div>
          </div>
          {isMyAlbum && (
            <div className="flex sm:flex-row flex-col md:mt-0 md:ml-4">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={albumFormOpen}
              >
                Modifica
              </button>
              <button
                type="button"
                className="mt-3 sm:ml-3 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={onUploadMedia}
              >
                <UploadIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Aggiungi media
              </button>
              <button
                type="button"
                className="mt-3 sm:ml-3 sm:mt-0 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={onUploadCover}
              >
                <UploadIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Aggiungi copertina
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
