// std
import { useEffect, useMemo } from 'react';

// 3p
import { SubmitHandler, useForm } from 'react-hook-form';

// app
import Loader from 'components/common/Loader';

import { useCreateBankAccount, useGetAllBankAccount, useGetUserProfile } from 'api/user';

import { IBankAccount } from 'interfaces';
import { useAuth, useToast } from 'hooks';

export const BankAccountForm = () => {
  const { user } = useAuth();

  // Profile Data
  const { getUserProfileQuery } = useGetUserProfile(user!.username);
  const { isLoading: isLoadingUserProfile, data: userProfile } = getUserProfileQuery;

  /** Retrive current */
  const { getAllBankAccountQuery } = useGetAllBankAccount();
  const { data } = getAllBankAccountQuery;

  const primaryBankAccount = useMemo(() => {
    return data ? data[0] : undefined;
  }, [data]);

  // const fullname = useMemo(() => {
  //   return userProfile && userProfile.profile ? userProfile.profile.fullname : undefined;
  // }, [userProfile]);

  /** Creation */
  const { createBankAccountMutation } = useCreateBankAccount();
  const { mutateAsync, isLoading } = createBankAccountMutation;

  const { register, handleSubmit, reset } = useForm<IBankAccount>({
    defaultValues: useMemo(
      () => ({
        beneficiaryName: userProfile?.profile.fullname,
        IBAN: primaryBankAccount?.IBAN,
      }),
      [userProfile, primaryBankAccount]
    ),
  });

  useEffect(() => {
    //if (!primaryBankAccount || !userProfile) return;

    reset({
      beneficiaryName: userProfile?.profile.fullname,
      IBAN: primaryBankAccount?.IBAN,
      contractDocFile: undefined,
      identityDocFile: undefined,
    });
  }, [primaryBankAccount, userProfile, reset]);

  const { toastSuccess } = useToast();

  const onSubmit: SubmitHandler<IBankAccount> = async (data) => {
    // const { contractDocFile, identityDocFile } = data;

    // Controllo se i file sono stati selezionati
    // if (!contractDocFile) {
    //   toastError({
    //     title: 'Attenzione',
    //     message: 'Contratto non selezionato',
    //   });

    //   return;
    // }

    // if (!identityDocFile) {
    //   toastError({
    //     title: 'Attenzione',
    //     message: "Documento d'identità non selezionato",
    //   });

    //   return;
    // }

    // if (fromByteToMb(contractDocFile.size) > 3) {
    //   toastError({
    //     title: 'Attenzione',
    //     message: 'La dimensione del contratto è superiore a 3Mb',
    //   });

    //   return;
    // }

    // if (fromByteToMb(identityDocFile.size) > 3) {
    //   toastError({
    //     title: 'Attenzione',
    //     message: 'La dimensione del documento è superiore a 3Mb',
    //   });

    //   return;
    // }

    try {
      await mutateAsync(data);

      toastSuccess({
        title: 'Ottimo!',
        message: 'Informazioni salvate correttamente',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const renderLoading = () => (
    <div className="mb-4">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <Loader />
        </div>
      </div>
    </div>
  );

  const renderData = () => (
    <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Informazioni bancarie
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Inserisci le informazioni del conto bancario su cui verranno effettuati i
              versamenti
            </p>
          </div>

          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-2">
              <label
                htmlFor="beneficiaryName"
                className="block text-sm font-medium text-gray-700"
              >
                Nome beneficiario
              </label>
              <input
                type="text"
                disabled
                {...register('beneficiaryName', { required: true })}
                className="bg-gray-200 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              <label htmlFor="IBAN" className="block text-sm font-medium text-gray-700">
                IBAN
              </label>
              <input
                type="text"
                id="IBAN"
                {...register('IBAN', { required: true })}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
              />
            </div>

            <div className="col-span-4 sm:col-span-2">
              {/* <label className="inline-flex items-center cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <UploadIcon className="mr-2 h-5 w-5" />
                <span>Carica contratto</span>
                <input
                  type="file"
                  className="hidden"
                  name="contractDocFile"
                  onChange={(e) => {
                    setValue('contractDocFile', e.target.files![0]);
                  }}
                />
              </label>
              <p className="mt-2 text-sm text-gray-500" id="email-description">
                {!contractDocFileName && primaryBankAccount?.contractDoc
                  ? 'Contratto caricato'
                  : contractDocFileName}
              </p> */}
            </div>

            <div className="col-span-4 sm:col-span-2">
              {/* <label className="inline-flex items-center cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                <UploadIcon className="mr-2 h-5 w-5" />
                <span>Documento beneficiario</span>
                <input
                  type="file"
                  className="hidden"
                  name="identityDocFile"
                  onChange={(e) => {
                    setValue('identityDocFile', e.target.files![0]);
                  }}
                />
              </label>
              <p className="mt-2 text-sm text-gray-500" id="email-description">
                {!identityDocFileName && primaryBankAccount?.identityDoc
                  ? 'Documento caricato'
                  : identityDocFileName}
              </p> */}
            </div>

            {/* <div className="col-span-4">
              <p className="mt-3 text-xs text-gray-500">
                Formati supportati per i documenti jpeg e pdf con dimensione massima 3Mb
                per file.
              </p>
            </div> */}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
          >
            Salva
          </button>
        </div>
      </div>
    </form>
  );

  return isLoading || isLoadingUserProfile ? renderLoading() : renderData();
};
