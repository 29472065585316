import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

// app
import Loader from 'components/common/Loader';
import { useAuth } from 'hooks/useAuth';

// TODO
export const PrivateRoute: FC<{}> = () => {
  const { isLoading, user } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return (
      <div className="flex flex-1 justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }

  if (user) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace state={{ from: location }} />;
};
