import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';

const creatorsFaqs = [
  {
    question: 'Come posso guadagnare con VieniOra.com?',
    answer: `Il nostro sito è aperto a tutti i creatori di contenuti per adulti e non solo.<br />Potrai creare una tua pagina personale completamente personalizzata con:
      <ul>
        <li>• Un listino dei servizi che offri;</li>
        <li>• Una sezione album dove potrai caricare foto e video che i clienti potranno visionare gratuitamente o sbloccare A PAGAMENTO;</li>
        <li>• Foto profilo;</li> 
        <li>• Biografia (dove puoi raccontare qualcosa di te, le tue specialità…);</li>
      </ul>
    <br />
    Una volta concordato il servizio con il cliente potete farlo andare sulla vostra pagina personale per effettuare il pagamento. <br />
    Nel caso non abbiate creato un servizio apposito il cliente comunque potrà inviarvi un importo personalizzato tramite il bottone “mandami una tip” sul vostro profilo. (Il bottone “mandami una tip” è visibile solo dal lato clienti).<br />
    Oppure più semplicemente nell’icona delle banconote in alto a destra nella chat.`,
  },
  {
    question: 'Come funziona la sezione Album?',
    answer: `Potrai creare i tuoi album come meglio credi, potendo inserire una copertina e un anteprima dell’ album.
      <ul>
        <li>• La copertina e la FOTO che apparirà nella sezione album nel tuo profilo.</li>
        <li>• L’anteprima è la FOTO O VIDEO che i clienti potranno guardare gratuitamente per capire cosa c’è all’interno dell’album, ad esempio se all’interno dell album c’è un video di 15 minuti potete caricare come anteprima un piccolo “trailer”.</li>
      </ul>
      Ricordiamo che è possibile aggiungere una descrizione dell’album così il cliente sa cosa c’è dentro prima di acquistare.
      Ricordiamo che ogni foto/video deve essere prima approvato dal nostro reparto apposito prima di essere visibile negli album, quindi per selezionare l’anteprima bisogna aspettare che il contenuto sia approvato.
      Ricordiamo che potete condividere sui vostri social il link della vostra sezione album così che i vostri fan possano sbloccare video a pagamento senza bisogno di contattarvi.`,
  },
  {
    question: 'Perché VieniOra.com è differente dagli altri siti?',
    answer:
      'Grazie alla nostra piattaforma chiunque sarà in grado di guadagnare GRAZIE AI CLIENTI DEL SITO. Una volta registrata/o apparirai nella home principale e i clienti potranno scriverti tramite la nostra chat per contattarti. A differenza di molti siti noi ti offriamo la visibilità e sopratutto abbiamo una chat che permette di effettuare anche le videochiamate e invio di foto/video/audio quindi potrai tranquillamente effettuare tutti i servizi sulla nostra piattaforma e garantiamo il completo anonimato.',
  },
  {
    question: 'È garantito il mio anonimato?',
    answer:
      'Assolutamente sì, il cliente pagherà direttamente VieniOra.com senza aver accesso ai tuoi dati personali. È richiesto un fronte e retro del documento di identità da te in possesso ed un selfie con il documento stesso ben leggibile, tutto questo per garantire la legalità per quanto riguarda la verifica della maggiore età del creator.',
  },
  {
    question:
      "Sull'estratto conto della carta del cliente si capirà che servizio ha acquistato?",
    answer: `
      Assolutamente no. Ovviamente la dicitura della descrizione dipenderà soprattutto dal metodo di pagamento che il cliente sceglierà ma ci assicuriamo che ogni transazione non contenga in nessun modo una descrizione riconducibile ad un servizio per adulti.`,
  },
  {
    question: 'Come e quando verrò pagata?',
    answer: `
      Per quanto riguarda la modalità di pagamento, all’inizio della registrazione ti verrà chiesto il tuo IBAN sul quale ricevere il bonifico. (Il conto deve essere OBBLIGATORIAMENTE intestato al nome del creator).
      Ogni volta che si riceve un pagamento potrai visualizzare l’importo ricevuto nel tuo portafoglio virtuale nella sezione “Bilancio”. Appena ricevuto un pagamento verrà visualizzato nella sezione “Presto Disponibili”, dopo 15 giorni dal pagamento ricevuto verranno spostati nella sezione “Ora Disponibili” questo sta a significare che possono essere prelevati (Minimo prelievo 50€).
      Una volta richiesto il prelievo dal nostro sito il bonifico verrà effettuato entro 3 giorni lavorativi (di solito riusciamo a farlo molto prima).`,
  },
  {
    question: 'Quanto costa usufruire della vostra piattaforma?',
    answer: `
      La nostra piattaforma non richiede nessun pagamento anticipato, tratteniamo una percentuale del 30%.
      Incassando su conto corrente bancario in Europa non avrai nessun costo aggiuntivo se l'importo prelevato è di almeno 250€, altrimenti ti verranno addebitati 3€ per le spese di bonifico.
      Incassando su conto corrente bancario in area NON EUROPEA non avrai nessun costo aggiuntivo se l'importo prelevato è di almeno 750€, altrimenti ti verranno addebitati 20€ per le spese.`,
  },
  {
    question: 'Come Funziona la chat?',
    answer: `All’interno della chat i creators hanno la possibilità di chattare con i clienti per accordare un servizio.
       Troverete tutte le chat nella sezione messaggi.
       I Creator hanno anche la possibilità di videochiamare i clienti nel caso il servizio richiesto sia una videochiamata.
       Nel caso di malfunzionamento per la videochiamata basta precisare al cliente che è meglio usare chrome, stessa cosa anche per i creators.
       INVIO VIDEO : È importante rimanere nella chat durante l’invio di un video e aspettare il completo caricamento altrimenti potrebbero esserci problemi di invio.
       IMPORTANTE ( IMPORTANTE in grassetto)
       Ogni volta che si riceve un messaggio riceverete una mail che vi avviserà, quindi per rispondere prontamente ad un cliente basterà attivare le notifiche delle mail sul vostro cellulare/computer, in caso di problemi controllare la casella Spam.`,
  },
  {
    question: "Come avere la sicurezza dell'avvenuto pagamento?",
    answer:
      'Riceverai una email oppure potrai controllare all’interno della tua sezione “bilancio” nell’area personale dove troverai tutte le transazioni ricevute.',
  },
  {
    question: 'È possibile effettuare rimborsi ai clienti?',
    answer: `
    Sì, nella lista delle transazioni avrai la possibilità tramite il bottone "Rimborsa". Una volta richiesto il rimborso le verrà fornito un codice dalla nostra chat di supporto, il codice andrà dato al cliente che potrà riscattare l’importo sul suo wallet.
    In altri casi o di rimborsi direttamente sul metodo di pagamento del cliente contattare il supporto del sito tramite la chat o qualsiasi altro metodo che trovate nella sezione “Supporto”.`,
  },
];

function CreatorsFaqs() {
  return (
    <div className="max-w-3xl mx-auto">
      <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
        Diventa un Creator - Termini e condizioni
      </h2>
      <h4 className="text-center text-base text-gray-500 mt-10">
        Si prega di leggere attentamente le seguenti informazioni e LEGGERE IL{' '}
        <a
          href="/assets/Vieniora Contratto Creator.pdf"
          className="text-blue-500 underline font-bold"
        >
          CONTRATTO
        </a>{' '}
        prima di diventare un Creator. Per qualsiasi altra informazione potete scriverci
        nella sezione “supporto” oppure tramite la chat se siete un cliente/creator
        registrati.
      </h4>
      <dl className="mt-6 space-y-6 divide-y divide-gray-200">
        {creatorsFaqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-medium text-gray-900">{faq.question}</span>
                    <span className="ml-6 h-7 flex items-center">
                      <ChevronDownIcon
                        className={classNames(
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform'
                        )}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel className="mt-2 pr-12">
                  <div
                    className="text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
      <h3 className="text-center text-xl sm:text-2xl text-gray-500 mt-10">
        Comportamenti strettamente vietati per evitare di essere bannati!
      </h3>
      <h4 className="text-center text-base text-gray-500 mt-4">
        È ASSOLUTAMENTE VIETATO :
      </h4>
      <ul className="text-base text-gray-500 text-justify">
        <li className="font-bold  text-gray-500">
          • Utilizzare un nome utente sulla piattaforma VieniOra.com che sia direttamente
          o indirettamente collegato ad altri canali social come Telegram, Instagram, ecc.
        </li>
        <li className="mt-2">
          • Richiedere o fornire contatti esterni (come WhatsApp, Telegram, Skype, ecc.)
          al di fuori della piattaforma per garantire l'erogazione legale del servizio e
          proteggere tutti gli utenti
        </li>
        <li className="mt-2">
          • Accettare o proporre pagamenti esterni - tutti i pagamenti devono essere
          elaborati attraverso la nostra piattaforma per garantire la sicurezza e la
          legalità degli utenti e dei servizi
        </li>
        <li className="mt-2">• Caricare foto/video rubati o senza consenso</li>
        <li className="mt-2">• Proporre incontri di persona</li>
      </ul>

      <p className="text-base text-gray-500 mt-4 text-justify">
        Vogliamo sottolineare che qualsiasi comportamento inappropriato o scortese nei
        confronti di un utente o di un creator sulla piattaforma verrà preso molto sul
        serio per garantire a tutti gli utenti un'esperienza piacevole.
      </p>

      <p className="text-base text-gray-500 font-bold text-justify">
        Qualsiasi comportamento che violi i termini e le condizioni comporterà un ban
        immediato dalla piattaforma. I ban degli account comporteranno la perdita di
        eventuali crediti rimanenti e l'utente bannato non potrà più registrarsi sulla
        nostra piattaforma.
      </p>
    </div>
  );
}

export default function FaqCreator() {
  return (
    <>
      <CreatorsFaqs />
    </>
  );
}
