// std
import { useCallback, useEffect, useMemo, useState } from 'react';

// app
import { DEFAULT_LIMIT_TRANSFER } from 'config';
import { ITransfer } from 'interfaces';

import { useModal, useToast } from 'hooks';

import { useGetAllTransfer } from 'api/transfer';

import {
  SummaryCard,
  PayoutTable,
  BankAccountForm,
  RefundModal,
} from 'components/creator';

import { Loader, BasicEmptyState, TransferList, AlertError } from 'components/common';

export default function CreatorPayoutView() {
  const [page, setPage] = useState(0);

  // Transaction Data
  const { getAllTransferQuery, setCurrSkip } = useGetAllTransfer(
    page,
    DEFAULT_LIMIT_TRANSFER
  );
  const { isError, error, isLoading, data } = getAllTransferQuery;

  const items = useMemo(() => (data ? data.items : []), [data]);

  const {
    openModal: refundModalOpen,
    closeModal: refundModalClose,
    modalData: refoundModal,
    setData: refundModalSetData,
  } = useModal<ITransfer>();

  const { toastSuccess } = useToast();

  useEffect(() => {
    setCurrSkip(page);
  }, [page, setCurrSkip]);

  const handleRefundModalOpen = useCallback(
    (transfer: ITransfer) => {
      refundModalSetData(transfer);
      refundModalOpen();
    },
    [refundModalOpen, refundModalSetData]
  );

  const handleRefundModalSuccess = useCallback(() => {
    refundModalClose();

    toastSuccess({
      title: 'Operazione completata',
      message: "Riceverai un email con l'esito del rimborso",
    });
  }, [refundModalClose, toastSuccess]);

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => (
    <>
      {items.length ? (
        <>
          <RefundModal
            {...refoundModal}
            onClose={refundModalClose}
            onSuccess={handleRefundModalSuccess}
          />

          {/* Activity list (smallest breakpoint only) */}
          <TransferList
            data={items}
            page={page}
            setPage={setPage}
            onRequestRefund={handleRefundModalOpen}
          />

          {/* Activity table (small breakpoint and up) */}
          {/* <div className="hidden sm:block">
            <TransferTable data={items} page={page} setPage={setPage} />
          </div> */}
        </>
      ) : (
        <BasicEmptyState title="Non sono presenti movimenti" />
      )}
    </>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <BankAccountForm />

      <div className="mb-4">
        <SummaryCard />
      </div>

      {/** Prelievi */}
      <PayoutTable />

      {/** Trasferimenti */}
      {isLoading ? renderLoading() : isError ? renderError() : renderData()}
    </div>
  );
}
