export * from './IMedia';
export * from './IMenu';
export * from './IProduct';
export * from './ITransfer';
export * from './IUser';
export * from './IPayout';
export * from './IAuth';
export * from './ISignupForm';
export * from './ISearchFormFilter';
export * from './ISignupCreatorConfirmForm';
export * from './ITab';
export * from './ITag';
export * from './IPaymentIn';
export * from './ICategory';
export * from './IAlbum';
export * from './IUser';
export * from './ICoupon';
export * from './IAnalytics';
export * from './ICommunication';
export * from './IChat';

export interface IKeyValue {
  [key: string]: string;
}

export enum IToastStatus {
  SUCCESS,
  ERROR,
  WARNING,
}

export interface IToast {
  title: string;
  message?: string;
  type?: IToastStatus;
  icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  time?: number;
  visible?: boolean;
  removeFn?: any;
  duration?: number;
}

export interface IModal<T> {
  open: boolean;
  isCover?: boolean;
  data?: T;
}

export interface IRestClientGetResponse<T> {
  items: T[];
  hasMore: boolean;
}

export interface IRestClientError {
  code: string;
  message: string;
}

export interface IResponseWithCursor<T> {
  data: T[];
  cursor: {
    beforeCursor: string | null;
    afterCursor: string | null;
  };
}

export interface ICountry {
  id: number;
  code: string;
  name: string;
  phone?: number;
  symbol?: string;
  currency?: string;
}

export interface ITransfertSummary {
  availableDeposit: number;
  availableNow: number;
  availableSoon: number;
  inTransit: number;
  currency: string;
}

export interface IPlatform {
  id: number;
  code: string;
  description: string;
}

export interface IBankAccount {
  beneficiaryName: string;
  IBAN: string;
  contractDoc: any;
  identityDoc: any;
  contractDocFile?: File;
  identityDocFile?: File;
}

export interface IPayoutRequest {
  amount: number;
}

export interface IPaymentSource {
  code: string;
  description: string;
  priority: number;
  logoUrl: string;
}
export interface ICursorObj {
  beforeCursor?: string;
  afterCursor?: string;
}
