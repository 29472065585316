import { useMemo } from 'react';

import { PlayIcon } from '@heroicons/react/solid';

// app
import { FileStatusEnum } from 'config/Enum';

import { IMedia2 } from 'interfaces';
import { useToast } from 'hooks';

import Loader from '../Loader';

interface IMediaCardProps {
  data: IMedia2;
  onClick?: (media: IMedia2) => void;
  // onDelete?: (media: IMedia2) => void;
  hasBadgeApprovation?: boolean;
}

export function MediaCard(props: IMediaCardProps): JSX.Element {
  const { data, onClick, hasBadgeApprovation } = props;
  const { url, photo, cover, video, status } = data;

  const isAvailable = useMemo(
    () => status && status.code === FileStatusEnum.AVAILABLE,
    [status]
  );

  const { toastSuccess } = useToast();

  const handleOnClick = () => {
    if (video && !isAvailable) {
      toastSuccess({
        title: 'Elaborazione in corso',
        message: 'Video in fase di elaborazione. Attenti che sia pronto',
      });

      return;
    }

    if (onClick) {
      onClick(data);
    }
  };

  return (
    <div
      className={`bg-white relative shadow rounded sm:shadow-md sm:rounded-md transition duration-500 dark:bg-grey-800 ${
        !isAvailable ? 'opacity-50' : ''
      }`}
    >
      {isAvailable ? (
        <div
          className="rounded-t-lg rounded-b-lg w-full cursor-pointer h-40 bg-cover bg-center"
          onClick={handleOnClick}
          style={{
            backgroundImage: `url(${
              photo || cover
                ? url
                : video
                ? video.thumbnailURL
                : '/assets/placeholder-image.png'
            })`,
          }}
        >
          {video && (
            <div className="flex justify-center items-center h-full text-white text-sm ">
              <PlayIcon className="h-16 opacity-80" />
            </div>
          )}
        </div>
      ) : (
        <div className="h-40 w-full">
          <Loader />
        </div>
      )}

      {/* {onDelete && isAvailable && (
        <span className="absolute top-1 right-3 block h-4 w-4">
          <button
            type="button"
            className="delete ml-auto focus:outline-none hover:bg-gray-400 bg-gray-300 p-1 rounded-md"
            onClick={(e) => {
              e.preventDefault();
              if (onDelete) onDelete(data);
            }}
          >
            <svg
              className="pointer-events-none fill-current w-4 h-4 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                className="pointer-events-none"
                d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
              />
            </svg>
          </button>
        </span>
      )} */}

      {hasBadgeApprovation && (data.photo?.isPreview || data.video?.isPreview) ? (
        <div className="absolute top-2 left-2 py-0.5 px-2 bg-yellow-100 rounded-lg">
          <span className="text-sm font-semibold text-yellow-800">Anteprima</span>
        </div>
      ) : null}

      {hasBadgeApprovation &&
      (data.photo?.isApprove ||
        data.video?.isApprove ||
        data.cover?.isApprove) ? null : hasBadgeApprovation && // </div> //   <span className="text-sm font-semibold text-green-800">Approvato</span> // <div className="absolute top-2 left-2 py-0.5 px-2 bg-green-100 rounded-lg">
        (data.photo?.isApprove === false ||
          data.video?.isApprove === false ||
          data.cover?.isApprove === false) ? (
        <div className="absolute top-2 left-2 py-0.5 px-2 bg-red-100 rounded-lg">
          <span className="text-sm font-semibold text-red-800">Non approvato</span>
        </div>
      ) : (
        hasBadgeApprovation && (
          <div className="absolute top-2 left-2 py-0.5 px-2 bg-yellow-100 rounded-lg">
            <span className="text-sm font-semibold text-yellow-800">Da approvare</span>
          </div>
        )
      )}
    </div>
  );
}
