export const input = {
  descr: 'mt-1 text-sm text-gray-500',
  base: 'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 text-md',
  label: 'block text-md font-medium text-gray-700',
  disabled: 'bg-gray-100 text-gray-500',
  select:
    'shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-md border-gray-300 rounded-md',

  containerFlex: 'mt-1 flex rounded-md shadow-sm',
  containerRelative: 'mt-1 relative rounded-md shadow-sm',
};

export const button = {
  full: 'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500',
};
