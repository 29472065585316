import { useEffect, useMemo } from 'react';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Switch } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { TrasnferStatusEnum } from 'config';
import { IPayoutStatusForm, ITransactionPayout } from 'interfaces';

import { ModalLayout, TransferStatus } from 'components/common';

import { classNames, formatDate, formatMoney } from 'utils';
import { usePayoutConfirm } from 'api/admin/transfer';

interface IPayoutStatusModalProps {
  data?: ITransactionPayout;
  open: boolean;
  onSuccess?: (data?: any) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const PayoutModalStatus = (props: IPayoutStatusModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { usePayoutConfirmMutation } = usePayoutConfirm();
  const { mutateAsync, isLoading: isLoadingCreatePayout } = usePayoutConfirmMutation;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IPayoutStatusForm>({
    defaultValues: useMemo(
      () => ({
        transferId: data?.id,
      }),
      [data]
    ),
  });

  useEffect(() => {
    reset({ transferId: data?.id });
  }, [data, reset]);

  const onSubmit: SubmitHandler<IPayoutStatusForm> = async (data) => {
    const { hasConfirm, transferId } = data;

    if (!hasConfirm) {
      return;
    }

    try {
      await mutateAsync(transferId);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };

  const renderData = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Richiesta {data?.code.substring(data.code.length - 5)}
          </Dialog.Title>

          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Data richiesta</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data && formatDate(data.startAt)}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Username</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {' '}
                  {data?.sourceUser.username}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Beneficiario</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.paymentOut.bankAccount.beneficiaryName}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">IBAN</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data?.paymentOut.bankAccount.IBAN}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Cifra</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data && formatMoney(data.transaction.net)}
                </dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Stato</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {data && <TransferStatus status={data.status} />}
                </dd>
              </div>
            </dl>
          </div>

          {data?.status.code === TrasnferStatusEnum.PENDING && (
            <div className="mt-2 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              <div className="sm:col-span-3">
                <Switch.Group as="div" className="flex items-start justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label
                      as="span"
                      className="block text-md font-medium text-gray-700"
                      passive
                    >
                      <span className="block text-sm font-medium text-gray-700">
                        Conferma pagamento
                      </span>
                    </Switch.Label>
                  </span>

                  <Controller
                    name="hasConfirm"
                    control={control}
                    defaultValue={false}
                    rules={{ required: "Conferma l'invio del pagamento" }}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </Switch.Group>
                {errors.hasConfirm?.type === 'required' ? (
                  <p className="mt-2 text-red-500 text-sm">{errors.hasConfirm.message}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        {data?.status.code === TrasnferStatusEnum.PENDING && (
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Salva
          </button>
        )}
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Chiudi
        </button>
      </div>
    </form>
  );

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingCreatePayout || !data}>
      {renderData()}
    </ModalLayout>
  );
};
