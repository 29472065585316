export const Terms = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              TERMINI E CONDIZIONI
            </span>
          </h1>
          <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
            PREMESSA
          </span>
          <p className="mt-4 mb-8 text-xl text-gray-500 text-justify leading-8">
            <a href="https://www.vieniora.com">www.vieniora.com</a>è un sito che fornisce
            servizi di intrattenimento per adulti, inclusi, a titolo esemplificativo ma
            non esclusivo, Servizi interattivi online a pagamento. I Servizi sono forniti
            in modo autonomo e indipendente da parti terze (Fornitori) e hanno esclusiva
            finalità di intrattenimento. I presenti Termini e Condizioni sono legalmente
            vincolanti e gli Utenti sono tenuti all’accettazione degli stessi per poter
            accedere al sito e ai Servizi. La premessa è considerata parte integrante dei
            Termini e Condizioni.
          </p>
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          DEFINIZIONI
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          <a href="https://www.vieniora.com">www.vieniora.com</a> (nel seguito anche il
          “Sito”) è un sito, come definito in Premessa. L’Utente (nel seguito anche Tu o
          Te) è chiunque acceda al Sito e ne utilizzi i Servizi, accettando così senza
          riserve i Termini e Condizioni di utilizzo. I Servizi o Contenuti sono tutti i
          contenuti e le funzionalità offerti dal Sito agli Utenti, previa registrazione.
          I Fornitori sono parti terze e indipendenti che utilizzano il Sito per offrire i
          propri Servizi. Iscrizione o Account è il rapporto fra l’Utente e il Sito,
          previa indicazione da parte dell’Utente delle proprie generalità, volta
          all’accesso e utilizzo dei Servizi. I Dati sono le informazioni fornite dagli
          Utenti al momento dell’Iscrizione.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          DISPOSIZIONI GENERALI
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. 3.1. L’Utente accetta i Termini e Condizioni di utilizzo del Sito all’atto
          dell’accesso allo stesso. Il Sito si riserva di modificare tali Termini e
          Condizioni in ogni momento mediante pubblicazione sulla presente pagina.
          <br />
          2. L’Utente si impegna a controllare regolarmente i Termini e Condizione per
          restare aggiornato su eventuali modifiche. L’Utente ha facoltà di terminare
          l’iscrizione (vedi Cancellazione sotto) in ogni momento in caso di mancata
          accettazione dei Termini e Condizioni aggiornati. L’utilizzo del Sito a seguito
          di modifica o aggiornamento dei Termini e Condizioni comporta tacita e
          automatica accettazione delle modifiche o aggiornamenti.
          <br />
          3. www.vieniora.com è gestito da Osses Ltd, 19 Leyden Street, Londra,Regno unito
          E17LE (Numero identificativo della società: 12083699).
          <br />
          4. La violazione dei Termini e Condizioni comporta l’annullamento
          dell’Iscrizione da parte del Sito. In particolare, www.vieniora.com è contrario
          a qualsiasi atto offensivo, discriminatorio, lesivo dell’immagine, dell’onore
          e/o della reputazione di Fornitori, altri Utenti o soggetti terzi, inclusi, a
          titolo non esaustivo, bestialità, incesto, sadomasochismo estremo o
          sottomissione presentati in un contesto sessuale, o presentazioni di minori
          nell’atto di contatto fisico intimo o situazioni sessuali recitate o raffigurate
          o prostituzione (nel seguito: Atti Illeciti). Allo scopo di tutelare i
          Fornitori, gli Utenti e i terzi dagli Atti Illeciti, il Sito si riserva di
          verificare le Comunicazioni e i Contenuti. www.vieniora.com è e resterà una
          piattaforma di servizi di intrattenimento per adulti. Per garantire l’incolumità
          di tutti i Fornitori e gli Utenti, nonché il rispetto delle regole suddette di
          condanna degli Atti Illeciti, è fatto espresso divieto a Utenti e Fornitori di
          fornire o avvalersi contatti personali, profili social o piattaforme terze. Il
          Sito si riserva di annullare l’iscrizione di chiunque violi tale regola.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          UTENTE
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. Il Sito e i Servizi sono riservati ai soli Utenti che abbiano compiuto la
          maggiore età ai sensi della normativa vigente nel paese di residenza degli
          Utenti stessi. L’Utente dichiara pertanto di avere almeno diciotto anni (ovvero
          ventuno nei paesi in cui la maggiore età è fissata a ventuno anni). Il Sito non
          è responsabile di dichiarazioni mendaci da parte dell’Utente circa la propria
          età e identità, che costituisce un utilizzo illecito di www.vieniora.com.
          Tuttavia, il Sito si riserva la possibilità di interrompere l’Iscrizione di
          Utenti che non abbiano compiuto la maggiore età, ovvero concedano l’utilizzo del
          proprio Account a persone che non abbiano compiuto la maggiore età.
          <br />
          2. L’Utente dichiara di risiedere in un paese dove l’utilizzo del Sito e dei
          Servizi non è dichiarato illecito dalla legge. Il Sito potrebbe non essere
          accessibile in paesi in cui violi disposizioni di legge in ragione dei propri
          contenuti. L’Utente è personalmente ed esclusivamente responsabile dell’utilizzo
          del Sito in paesi in cui esso violi termini di legge.
          <br />
          3. L’Utente si impegna a non utilizzare in modo improprio e/o illecito il Sito e
          i Servizi. In particolare, a titolo meramente esemplificativo, l’Utente si
          impegna a non utilizzare il Sito e/o i Servizi in modo che possa essere
          offensivo, discriminatorio, lesivo dell’immagine, dell’onore e/o della
          reputazione di Fornitori, altri Utenti o soggetti terzi, inclusi, a titolo non
          esaustivo, bestialità, incesto, sadomasochismo estremo o sottomissione
          presentati in un contesto sessuale, o presentazioni di minori nell’atto di
          contatto fisico intimo o situazioni sessuali recitate o raffigurate o
          prostituzione. L’Utente è personalmente responsabile e perseguibile ai sensi di
          legge per ogni utilizzo illecito del Sito e/o dei Servizi.
          <br />
          4. I Contenuti sono prodotti ed elaborati da Fornitori terzi e indipendenti. Il
          Sito non può e non intende Controllare preventivamente le Comunicazioni fra
          Utenti e Fornitori, né i Contenuti stessi. Tuttavia, ai fini di quanto stabilito
          dal punto 4.3. il Sito si riserva la possibilità, a sua discrezione, di
          controllare parte delle Comunicazioni e/o dei Contenuti allo scopo di impedire o
          inibire un utilizzo illecito dei Servizi. Qualora www.vieniora.com ritenga che
          un Utente stia violando i presenti Termini e Condizioni potrà annullare
          l’Iscrizione dell’Utente.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          ISCRIZIONE
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. L’Iscrizione è necessaria per l’accesso al Sito e ai Servizi. Al momento
          dell’Iscrizione l’Utente è tenuto a fornire in modo completo e veritiero dati
          relativi alle proprie generalità e dati necessari per l’accesso dei Servizi a
          pagamento.
          <br />
          2. Il Sito gestisce e conserva i Dati nel pieno rispetto della normativa sulla
          Privacy, garantendo che l’uso dei Dati è limitato esclusivamente alle finalità
          strettamente necessarie all’operatività del Sito. In particolare, i Dati
          personali necessari alle iscrizioni vengono conservati in modo anonimo, nel
          rispetto della privacy e solo per quanto strettamente necessario ad ottemperare
          alle disposizioni di legge. Per maggiori dettagli, si rimanda alla pagina sulla
          privacy policy.
          <br />
          3. L’Iscrizione è gratuita e può essere terminata dall’Utente in qualsiasi
          momento.
          <br />
          4. Il Sito si riserva di terminare l’Iscrizione di Utenti che violino i presenti
          Termini e Condizioni, senza obblighi di preavviso.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          SERVIZI
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. Il Sito fornisce servizi di comunicazione (nel seguito: Comunicazioni) fra
          Utente e Fornitori mediante chat e videochat. Il servizio di chat comporta una
          notifica via e-mail per i nuovi messaggi ricevuti. Accettando i presenti Termini
          e Condizioni, l’Utente accetta di ricevere delle e-mail di notifica dei nuovi
          messaggi. Il Sito, in ottemperanza agli obblighi di legge, conserva tali
          Comunicazioni nei propri archivi digitali per un periodo di 6 (sei) mesi dalla
          data in cui tali Comunicazioni sono avvenute. Per maggiori dettagli, si rimanda
          alla pagina sulla privacy policy.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          PAGAMENTO
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. Alcuni Servizi del Sito sono a pagamento. Per potervi accedere, l’Utente
          dovrà fornire, al momento dell’Iscrizione, dati relativi ad una carta di credito
          o debito. L’uso della carta di credito o debito per i pagamenti è regolato dal
          contratto con l’ente emittente la carta di credito o debito. Il Sito non è
          responsabile di commissioni o maggiorazioni del pagamento legate al tipo di
          transazione, come regolato dal contratto con l’emittente la carta di credito o
          debito.
          <br />
          2. Il prezzo dei Servizi è stabilito dai Fornitori. Il Sito si limita a fornire
          la modalità di pagamento e garantisce la sicurezza, attendibilità e riservatezza
          assoluta del pagamento stesso. L’Utente potrà accedere all’estratto conto della
          propria carta di credito o debito e controllare le transazioni, che verranno
          descritte in modo generico e non direttamente riconducibile al Sito o al tipo di
          Servizio.
          <br />
          3. Il Sito è responsabile delle transazioni effettuale mediante la propria
          piattaforma e di eventuali rimborsi dovuti al mancato esito del pagamento. Ciò
          non include il caso in cui il pagamento non vada a buon fine per mancato
          riscontro da parte dell’emittente la carta di credito o debito o altro
          malfunzionamento che non dipenda dalla piattaforma offerta da
          <a href="https://www.vieniora.com"> www.vieniora.com</a>. In caso di fallimento
          del pagamento da parte dell’Utente, il Sito si riserva di agire nei confronti
          dell’Utente, che sarà tenuto al pagamento del capitale dovuto, oltre che le
          spese legali e i costi legati al recupero di tali somme. La cancellazione o
          termine dell’Iscrizione non estingue le eventuali obbligazioni ancora pendenti
          in capo all’Utente.
          <br />
          4. In caso di reiterato fallimento delle transazioni, il Sito si riserva la
          possibilità di terminare l’iscrizione dell’Utente e di agire per il recupero di
          eventuali crediti nei confronti dello stesso.
          <br />
          5. Il Sito ha facoltà di cambiare i metodi di pagamento e fatturazione in ogni
          momento. Il Sito ha inoltre facoltà di cambiare le tariffe legate ai Servizi,
          nei limiti della propria competenza. Ciò non include il prezzo dei Servizi
          stesso, stabilito in modo autonomo e indipendente dai Fornitori. L’Utente ha
          facoltà di terminare l’Iscrizione in ogni momento a seguito della modifica dei
          costi e delle tariffe da parte del Sito. L’Utilizzo del Sito a seguito di tali
          modifiche costituisce tacita accettazione delle modifiche e non conferisce alcun
          diritto al rimborso dei prezzi, né delle eventuali differenze di eventuali
          maggiorazioni rispetto ai prezzi precedenti.
          <br />
          6. L’Utente è personalmente ed esclusivamente responsabile di tutti i costi
          associati all’utilizzo del Sito, che accetta in modo specifico e tacito al
          momento dell’Iscrizione e dell’utilizzo dei singoli Servizi.
          <br />
          7. Per qualsiasi ulteriore domanda o informazione relativa alle transazioni, è
          possibile contattare <a href="https://www.vieniora.com">www.vieniora.com </a>
          mediante il Supporto Tecnico.
          <br />
          8. Il Sito non è responsabile delle condizioni dei Servizi e del relativo
          pagamento. Tuttavia, qualora il Servizio non sia stato svolto secondo gli
          accordi, è fatta facoltà all’Utente di contattare il Sito per poter richiedere
          un rimborso, facendo specifico riferimento alle circostanze di fatto. Il Sito si
          riserva la possibilità di verificare il mancato rispetto degli accordi da parte
          dei Fornitori e procedere ad eventuale rimborso, concesso esclusivamente nella
          forma di credito sull’Account dell’Utente.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          PROPRIETA' INTELLETTUALE
        </span>
        <div className="mt-4 mb-8 border-b text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. I Fornitori sono titolari di tutti i diritti d’autore e altri diritti di
          proprietà intellettuale relativi ai Contenuti.
          <br />
          2. È fatto divieto agli Utenti di riprodurre, pubblicare, modificare, rendere
          disponibile ovvero duplicare qualsiasi Contenuto del Sito o dei Servizi.
          <br />
          3. I diritti di sfruttamento economico, nonché di pubblicazione, modifica,
          riproduzione dei Contenuti sono oggetto di specifico accordo fra il Sito e i
          Fornitori. Qualsiasi utilizzo da parte dell’Utente in violazione dei presenti
          Termini e Condizioni sarà perseguibile ai sensi di legge.
        </div>

        {/*English */}
        <h1>
          <span className="mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            TERMS AND CONDITIONS
          </span>
        </h1>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          PREMISE
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          <a href="https://www.vieniora.com"> www.vieniora.com </a> is a site that
          provides adult entertainment services, including but not limited to
          non-exclusive, Paid Online Interactive Services. The Services are provided
          autonomously and independently from third parties (Suppliers) and have exclusive
          rights entertainment purposes. These Terms and Conditions are legally binding
          and Users are required to accept them in order to access the site and the
          Services. The premise is considered an integral part of the Terms and
          conditions.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          DEFINITIONS
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          <a href="https://www.vieniora.com">www.vieniora.com</a> (hereinafter also the
          "Site") is a site, as defined in the Introduction. The User (hereinafter also
          You or You) is anyone who accesses the Site and uses the Services, thus
          accepting without reserves the Terms and Conditions of use. The Services or
          Contents are all content and features offered by the Site to Users, upon
          registration. The Suppliers are independent third parties who use the Site to
          offer the own services. Membership or Account is the relationship between the
          User and the Site, subject to indication by the User of his/her personal
          details, time access and use of the Services. Data is information provided by
          Users at the time of Registration.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          GENERAL PROVISIONS
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1.3.1. The User accepts the Terms and Conditions of use of the Site upon of
          access to it. The Site reserves the right to modify these Terms and Conditions
          at any time by posting on this page.
          <br />
          2. The User undertakes to regularly check the Terms and Conditions for stay
          updated on any changes. The User has the right to terminate subscription (see
          Cancellation below) at any time in case of failure acceptance of the updated
          Terms and Conditions. The use of the Site following of modification or updating
          of the Terms and Conditions involves tacit e automatic acceptance of changes or
          updates.
          <br />
          3. www.vieniora.com is operated by Osses Ltd, 19 Leyden Street, London, United
          Kingdom E17LE (Company identification number: 12083699).
          <br />
          4. Violation of the Terms and Conditions will result in cancellation of
          Registration by the Site. In particular, www.vieniora.com is against it to any
          offensive, discriminatory act, harmful to the image, to the honour and/or the
          reputation of Suppliers, other Users or third parties, including, a
          non-exhaustive title, bestiality, incest, extreme sadomasochism or submission
          presented in a sexual context, or presentations of minors in the act of intimate
          physical contact or acted out or depicted sexual situations or prostitution
          (hereinafter: Illicit Acts). In order to protect the Suppliers, Users and third
          parties from Illicit Acts, the Site reserves the right to verify the
          Communications and the Contents. www.vieniora.com is and will remain one adult
          entertainment service platform. To ensure safety of all Suppliers and Users, as
          well as compliance with the aforementioned rules of condemnation of the Illicit
          Acts, it is expressly forbidden for Users and Suppliers of provide or use
          personal contacts, social profiles or third-party platforms. The The site
          reserves the right to cancel the registration of anyone who violates this rule.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          USER
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. The Site and the Services are reserved only for Users who have completed the
          age of majority in accordance with the legislation in force in the country of
          residence of the Users themselves. The User therefore declares to be at least
          eighteen years old (i.e twenty-one in countries where the age of majority is set
          at twenty-one). The site does not is responsible for false statements by the
          User about his own age and identity, which constitutes an illegal use of
          www.vieniora.com. However, the Site reserves the right to terminate the
          Membership of Users who have not reached the age of majority, or who grant the
          use of the own Account to persons who have not reached the age of majority.
          <br />
          2. The User declares to reside in a country where the use of the Site and the
          Services is not declared illegal by law. The Site may not be accessible in
          countries where you violate legal provisions due to your own rights content. The
          User is personally and exclusively responsible for its use of the Site in
          countries where it violates terms of the law.
          <br />
          3. The User undertakes not to use the Site improperly and/or illegally e
          services. In particular, by way of example only, the User yes undertakes not to
          use the Site and/or the Services in any way that may be offensive,
          discriminatory, harmful to the image, honor and/or reputation of Suppliers,
          other Users or third parties, including, but not limited to exhaustive,
          bestiality, incest, extreme sadomasochism or submission presented in a sexual
          context, or presentations of minors in the act of intimate physical contact or
          sexual situations acted out or depicted o prostitution. The User is personally
          responsible and liable pursuant to law for any illicit use of the Site and/or
          Services.
          <br />
          4. The Contents are produced and processed by independent third-party Suppliers.
          The Site cannot and does not intend to pre-screen Communications between Users
          and Suppliers, nor the Contents themselves. However, for the purposes of the
          above from point 4.3. the Site reserves the right, at its discretion, to control
          part of the Communications and/or Content for the purpose of preventing or
          inhibit unlawful use of the Services. If www.vieniora.com deems that a User who
          is violating these Terms and Conditions may cancel User Registration.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          REGISTRATION
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. Registration is required to access the Site and the Services. At the moment
          of the Registration, the User is required to provide data in a complete and
          truthful way relating to personal details and data necessary for access to the
          Services a payment.
          <br />
          2. The Site manages and stores the Data in full compliance with the legislation
          on Privacy, ensuring that the use of data is limited exclusively to the purposes
          strictly necessary for the operation of the Site. In particular, the Data
          personal data necessary for registrations are stored anonymously, in the respect
          for privacy and only as strictly necessary to comply to the provisions of the
          law. For more details, please refer to the page on privacy policy.
          <br />
          3. Membership is free and can be terminated by the User at any time moment.
          <br />
          4. The Site reserves the right to terminate the Registration of Users who
          violate the present Terms and Conditions, without notice obligations.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          SERVICES
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. The Site provides communication services (hereinafter: Communications)
          between User and Suppliers via chat and video chat. The chat service involves a
          Email notification for new messages received. By accepting these Terms and
          Conditions, the User agrees to receive email notifications of new ones messages.
          The Site, in compliance with legal obligations, keeps these Communications in
          your digital archives for a period of 6 (six) months from date on which such
          Communications occurred. For more details, please refer to the privacy policy
          page.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          PAYMENT
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. Some Site Services are subject to payment. In order to access it, the User
          must provide, at the time of registration, data relating to a credit card or
          debt. The use of credit or debit card for payments is governed by the contract
          with the credit or debit card issuer. The site is not responsible for fees or
          payment surcharges related to the type of transaction, as governed by the
          agreement with the credit card issuer or debt.
          <br />
          2. The price of the Services is established by the Suppliers. The Site is
          limited to providing the method of payment and guarantees security, reliability
          and confidentiality absolute of the payment itself. The User will be able to
          access the account statement of the own credit or debit card and check the
          transactions, which will come described in a generic way and not directly
          attributable to the Site or to the type of Service.
          <br />
          3. The Site is responsible for the transactions made through its own platform
          and any refunds due to failed payment outcome. That is it does not include the
          case that the payment fails due to non-payment acknowledgment by the issuer of
          the credit or debit card or other malfunction that does not depend on the
          platform offered by
          <a href="https://www.vieniora.com"> www.vieniora.com</a>. In case of failure
          payment by the User, the Site reserves the right to take action against it of
          the User, who will be required to pay the principal due, as well as the legal
          fees and the costs associated with the recovery of such sums. The cancellation
          or The term of Enrollment does not extinguish any outstanding obligations in
          charge of the User.
          <br />
          4. In the event of repeated failure of the transactions, the Site reserves the
          right possibility to terminate the User's registration and to act for the
          recovery of any claims against it.
          <br />
          5. The Site has the right to change payment and billing methods at any time
          moment. The Site also has the right to change the rates associated with the
          Services, within the limits of its competence. This does not include the price
          of the Services itself, established autonomously and independently by the
          Suppliers. The User has right to terminate the Membership at any time following
          the modification of the costs and tariffs by the Site. Use of the Site following
          such changes constitutes tacit acceptance of the changes and does not confer any
          right to reimbursement of the prices, nor of any differences in any increases
          compared to previous prices.
          <br />
          6. The User is personally and exclusively responsible for all costs associated
          with the use of the Site, which you specifically and tacitly accept time of
          Registration and use of the individual Services.
          <br />
          7. For any additional questions or information related to transactions, it is
          You can contact <a href="https://www.vieniora.com">www.vieniora.com </a>
          through Technical Support.
          <br />
          8. The Site is not responsible for the conditions of the Services and the
          relative payment. However, if the Service has not been performed according to
          the agreements, the User is entitled to contact the Site in order to request a
          refund, making specific reference to the factual circumstances. The site yes
          reserves the right to verify the non-compliance with the agreements by the party
          of the Suppliers and proceed with any reimbursement, granted exclusively in the
          form of credit on the User's Account.
        </div>
        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          INTELLECTUAL PROPERTY
        </span>
        <div className="mt-4 mb-8 border-b text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          1. The Suppliers own all copyrights and other rights of intellectual property
          relating to the Contents.
          <br />
          2. Users are prohibited from reproducing, publishing, modifying, rendering
          available or duplicate any Content of the Site or the Services.
          <br />
          3. The rights of economic exploitation, as well as publication, modification,
          reproduction of the Contents are the subject of a specific agreement between the
          Site and i Providers. Any use by you in violation of these Terms and Conditions
          will be prosecuted according to the law.
        </div>
      </div>
    </div>
  );
};
