// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_TAG } from 'config/Enviroment';
import { IRestClientGetResponse, ITag } from 'interfaces';

import APIClient from '../ApiClient';

const getAllTag = async () => {
  const { data } = await APIClient.get<IRestClientGetResponse<ITag>>(ENV_TAG.GET_ALL_TAG);

  return data;
};

export function useGetAllTag() {
  const getAllTagQuery = useQuery<IRestClientGetResponse<ITag>, AxiosError<string, any>>(
    [ENV_TAG.GET_ALL_TAG],
    () => getAllTag(),
    {
      placeholderData: {
        items: [],
        hasMore: false,
      },
    }
  );

  return { getAllTagQuery };
}
