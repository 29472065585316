import { useGetUser } from 'api/admin/user/useGetUser';
import { UserBodyProfile, UserHeaderProfile } from 'components/admin/user';
import { AlertError } from 'components/common/alert';
import Loader from 'components/common/Loader';
import { useParams } from 'react-router-dom';

export const UserGetOne = () => {
  let { userId } = useParams<{ userId: string }>() as { userId: string };

  const { getUserQuery } = useGetUser(userId);
  const { isLoading, isError, data: user } = getUserQuery;

  if (isLoading) return <Loader />;

  if (isError)
    return <AlertError message="Errore: impossibile caricare l'utente. Riprova." />;

  if (!user) return <AlertError message="Utente non trovato" />;

  return (
    <>
      <div className="mb-5">
        <UserHeaderProfile user={user} />
      </div>
      <UserBodyProfile user={user} />
    </>
  );
};
