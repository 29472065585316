import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';

// app
import { DEFAULT_LIMIT } from 'config/Enviroment';

import HttpStatusCode from 'utils/HttpStatusCode';

import { IAlbum, ILoginForm, IMedia2 } from 'interfaces';
import { useAuth, useModal, useToast } from 'hooks';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';
import { BasicEmptyState } from 'components/common/empty';

import { MediaDeleteModal } from 'components/creator/album';

import { useGetAllMedia } from 'api/media/useGetAllMedia';

import { MediaCard } from './MediaCard';
import { MediaModal } from './MediaModal';
import { MediaPreview } from './MediaPreview';
import { formatMoney } from 'utils';
import LoginModal from 'views/web/auth/LoginModal';
import { ProductBuyModal } from '../payment';
import { useUpdatePreview } from 'api/album';
import { IMediaDeleteModalData } from 'components/creator/album/MediaDeleteModal';
import { useGetAllMediaWithoutPagination } from 'api/media/useGetAllMediaWithoutPagination';

interface IMediaGridProps {
  // albumId: string;
  album: IAlbum;
  hasDelete?: boolean;
  hasSetPreview?: boolean;
  hasBadgeApprovation?: boolean;
}

export const MediaGrid = (props: IMediaGridProps) => {
  const { album, hasDelete, hasSetPreview, hasBadgeApprovation } = props;

  const { id, price } = album;

  const { user } = useAuth();

  // Query Data
  const { getAllMediaQuery } = useGetAllMedia(id, {
    limit: DEFAULT_LIMIT,
    order: 'DESC',
    preview: false,
  });

  const {
    isError,
    error,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    data: getAllMediaResponse,
    refetch: getAllMediaRefetch,
  } = getAllMediaQuery;

  const getAllMediaData = useMemo(
    () => getAllMediaResponse?.pages,
    [getAllMediaResponse]
  );

  // Servizio che ritorna tutti gli url dei media. array mono e togliere thumbnail e pallini,
  // lasciare solo schermo intero e il play
  const { getAllMediaQueryWithoutPagination } = useGetAllMediaWithoutPagination(id, {
    limit: 100,
    order: 'DESC',
  });

  const { data: dataImageGallery, refetch: dateImageGalleryRefetch } =
    getAllMediaQueryWithoutPagination;

  const { updatePreviewMutation } = useUpdatePreview(id);
  const { mutateAsync: updatePreview } = updatePreviewMutation;

  const {
    openModal: mediaDeleteModalOpen,
    closeModal: mediaDeleteModalClose,
    modalData: mediaDeleteModal,
    setData: mediaDeleteModalSetData,
  } = useModal<IMediaDeleteModalData>();

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2>();

  const {
    openModal: loginModalOpen,
    closeModal: loginModalClose,
    modalData: loginModal,
  } = useModal<ILoginForm>();

  const {
    openModal: purchaseModalOpen,
    closeModal: purchaseModalClose,
    modalData: purchaseModal,
    setData: purchaseModalSetData,
  } = useModal<IAlbum>();

  const { toastError, toastSuccess } = useToast();

  const handleBuyClick = useCallback(async () => {
    // album a pagamento e non sono il proprietario e non sono admin e non sono Epoch
    if (user) {
      await purchaseModalSetData(album);
      purchaseModalOpen();

      return;
    }

    //non sono loggato
    loginModalOpen();
  }, [user, album, purchaseModalSetData, purchaseModalOpen, loginModalOpen]);

  /**
   * Set and open media delete modal
   */
  const handleOnDeleteMedia = useCallback(
    async (media: IMedia2) => {
      await mediaDeleteModalSetData({ media, album });
      mediaDeleteModalOpen();
    },
    [album, mediaDeleteModalSetData, mediaDeleteModalOpen]
  );

  /**
   * On Sucess delete media callback
   */
  const onSuccessDeleteMedia = useCallback(async () => {
    await mediaDeleteModalClose();
    await viewMediaModalClose();
  }, [mediaDeleteModalClose, viewMediaModalClose]);

  /**
   * On media card click callback
   */
  const handleOnClickMedia = useCallback(
    async (media: IMedia2) => {
      await viewMediaModalSetData(media);
      viewMediaModalOpen();
    },
    [viewMediaModalSetData, viewMediaModalOpen]
  );

  const onSuccessLogin = useCallback(() => {
    loginModalClose();
    dateImageGalleryRefetch();
    getAllMediaRefetch();
  }, [loginModalClose, getAllMediaRefetch, dateImageGalleryRefetch]);

  /**
   * On set media as preview callback
   */
  const handleOnSetPreview = useCallback(
    async (media: IMedia2) => {
      try {
        await updatePreview(media);

        toastSuccess({
          title: 'Operazione completata',
          message: 'Anteprima modificata',
        });
      } catch (error) {
        console.error(error);
      }
    },
    [updatePreview, toastSuccess]
  );

  const onErrorPayment = (error: AxiosError<string, any>) => {
    toastError({
      title: "Impossibile completare l'acquisto",
      message: error.response?.data,
    });
  };

  /**
   * Il bottone di acquisto viene mostrato solo nel caso di errore con HTTP Code Forbidden
   */
  const albumBuyButton = useMemo(() => {
    if (!isError) return;

    if (error.response?.status === HttpStatusCode.FORBIDDEN) {
      return (
        <input
          type="button"
          className="cursor-pointer w-full sm:w-auto inline-flex items-center justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          onClick={handleBuyClick}
          value={`Sblocca tutti i contenuti ${formatMoney(price)}`}
        />
      );
    }

    return <AlertError error={error} />;
  }, [isError, error, handleBuyClick, price]);

  return (
    <>
      <LoginModal {...loginModal} onClose={loginModalClose} onSuccess={onSuccessLogin} />
      <ProductBuyModal
        {...purchaseModal}
        setOpen={purchaseModalOpen}
        onClose={purchaseModalClose}
        onSuccess={purchaseModalClose}
        onError={onErrorPayment}
      />
      <MediaDeleteModal
        {...mediaDeleteModal}
        onClose={mediaDeleteModalClose}
        onSuccess={onSuccessDeleteMedia}
      />
      <MediaModal
        {...viewMediaModal}
        mediaArray={dataImageGallery}
        onClose={viewMediaModalClose}
        onDelete={hasDelete ? handleOnDeleteMedia : undefined}
        onSetPreview={hasSetPreview && price ? handleOnSetPreview : undefined}
      />
      <MediaPreview album={album} />
      <div className="mt-5 flex justify-center items-center">{albumBuyButton}</div>
      {isError || isLoading ? null : getAllMediaData &&
        getAllMediaData[0].data.data.length ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 lg:grid-cols-4 xl:gap-x-4">
          {getAllMediaData.map((group) =>
            group.data.data.map((media) => (
              <MediaCard
                key={media.id}
                data={media}
                hasBadgeApprovation={hasBadgeApprovation}
                onClick={handleOnClickMedia}
                //onDelete={hasDelete ? handleOnDeleteMedia : undefined}
              />
            ))
          )}
        </div>
      ) : (
        <BasicEmptyState title="Nessun contenuto" descr="Non presenti contenuti" />
      )}
      <div className="flex-1 flex justify-center mt-10">
        {isLoading || isFetchingNextPage ? (
          <Loader />
        ) : hasNextPage ? (
          <button
            onClick={() => fetchNextPage()}
            className="text-white px-4 py-2 border bg-pink-600 text-lg font-medium rounded-md hover:bg-pink-700"
          >
            Mostra altri
          </button>
        ) : null}
      </div>
    </>
  );
};
