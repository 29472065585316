// std
import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from 'react-query';

// app
import { IChatMessage, IResponseWithCursor } from 'interfaces';
import { ENV_ADMIN } from 'config';

import APIClient from '../../ApiClient';

const getAllThreadMessage = async ({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKey, any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const threadId = queryKey[1] as string;

  const url = ENV_ADMIN.GET_ALL_THREAD_MESSAGE(threadId);

  const { data } = await APIClient.get<IResponseWithCursor<IChatMessage>>(url, {
    params: {
      ...(pageParam ? pageParam : {}),
    },
  });

  return data;
};

export function useAdminGetAllMessage(threadId?: string) {
  const queryKey = useMemo(() => ['useAdminGetAllMessage', threadId], [threadId]);

  const adminGetAllThreadMessageQuery = useInfiniteQuery<
    IResponseWithCursor<IChatMessage>,
    AxiosError<string, any>
  >({
    queryKey: queryKey,
    queryFn: getAllThreadMessage,
    enabled: !!threadId,
    getPreviousPageParam: (lastPage) => {
      const { cursor } = lastPage;

      if (!cursor) {
        return null;
      }

      const { beforeCursor } = cursor;

      if (!beforeCursor) {
        return null;
      }

      return { beforeCursor };
    },
    refetchOnWindowFocus: true,
  });

  return { queryKey, adminGetAllThreadMessageQuery };
}
