import { useMemo } from 'react';

import { Chart as ChartJS, Legend, Tooltip, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// app
import { formatMoney } from 'utils';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';

import { useGetAdminAnalyticsGateway } from 'api/admin/analytics';

ChartJS.register(ArcElement, Tooltip, Legend);

// const AnalyticsGatewayCard = (props: any) => {

//   const {labels, data} = props;

//   const datasetSourcePie = useMemo(
//     () => ({
//       labels: data?.source.map((row) => row.source_code),
//       datasets: [
//         {
//           label: 'Gateway',
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.2)',
//             'rgba(54, 162, 235, 0.2)',
//             'rgba(255, 206, 86, 0.2)',
//             'rgba(75, 192, 192, 0.2)',
//             'rgba(153, 102, 255, 0.2)',
//             'rgba(255, 159, 64, 0.2)',
//           ],
//           borderColor: [
//             'rgba(255, 99, 132, 1)',
//             'rgba(54, 162, 235, 1)',
//             'rgba(255, 206, 86, 1)',
//             'rgba(75, 192, 192, 1)',
//             'rgba(153, 102, 255, 1)',
//             'rgba(255, 159, 64, 1)',
//           ],
//           borderWidth: 2,
//           data: data?.source.map((row) => row.num),
//         },
//       ],
//     }),
//     [data]
//   );

// }

interface IAnalyticsGatewayProp {
  startAt: string;
  endAt: string;
}

export const AnalyticsGateway = (props: IAnalyticsGatewayProp) => {
  const { startAt, endAt } = props;

  const { getAdminAnalyticsGatewayQuery } = useGetAdminAnalyticsGateway(startAt, endAt);

  const { data, isError, isLoading, error } = getAdminAnalyticsGatewayQuery;

  const datasetSourceOpts = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: `Analisi gateway di pagamento`,
      },
    },
  };

  const datasetSourcePie = useMemo(
    () => ({
      labels: data?.source.map((row) => row.source_code),
      datasets: [
        {
          label: 'Gateway',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 2,
          data: data?.source.map((row) => row.num),
        },
      ],
    }),
    [data]
  );

  const datasetMethodOpts = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: `Analisi metodi di pagamento`,
      },
    },
  };

  const datasetMethodPie = useMemo(
    () => ({
      labels: data?.method.map((row) => row.method_code),
      datasets: [
        {
          label: 'Gateway',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 2,
          data: data?.method.map((row) => row.num),
        },
      ],
    }),
    [data]
  );

  const datasetSourceCards = useMemo(() => {
    if (!data) return [];

    return data.source.map((row) => ({
      name: row.source_code,
      stat: formatMoney(row.amount),
    }));
  }, [data]);

  const datasetMethodCards = useMemo(() => {
    if (!data) return [];

    return data.method.map((row) => ({
      name: row.method_code,
      stat: formatMoney(row.amount),
    }));
  }, [data]);

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => {
    return (
      <div className="mx-auto my-6 flex flex-wrap">
        <div className="px-3 sm:px-6 lg:px-8 rounded-lg bg-white shadow">
          <Pie data={datasetSourcePie} options={datasetSourceOpts} />
        </div>
        <dl className="sm:mx-3 grid grid-cols-1 gap-3">
          {datasetSourceCards.map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow sm:p-3"
            >
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-xl font-medium tracking-tight text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
        <div className="px-3 sm:px-6 lg:px-8 rounded-lg bg-white shadow">
          <Pie data={datasetMethodPie} options={datasetMethodOpts} />
        </div>
        <dl className="sm:mx-3 grid grid-cols-1 sm:grid-cols-2 gap-3">
          {datasetMethodCards.map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow sm:p-3"
            >
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-xl font-medium tracking-tight text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
