import { useEffect, useState } from 'react';

// 3p
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// app
import { LoginForm } from 'components/common/auth';
import Loader from 'components/common/Loader';

import { useAuth } from 'hooks/useAuth';

import { ILoginForm } from 'interfaces';
import HttpStatusCode from 'utils/HttpStatusCode';
import { ROLES } from 'config/Roles';

const CreatorLoginView = () => {
  const [error, setError] = useState<string>();

  const { signin, isLoading, user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.role.code === ROLES.ADMIN) {
        navigate('/admin', { replace: true });
      } else {
        navigate('/app', { replace: true });
      }
    }
  }, [user, navigate]);

  const onSubmit: SubmitHandler<ILoginForm> = async ({ email, password }) => {
    try {
      await signin(email, password);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === HttpStatusCode.UNAUTHORIZED.valueOf()) {
          setError(error.response.data);
        }
      } else {
        setError(error.message || 'Errore generico');
      }
    }
  };

  const renderLoading = () => <Loader />;

  if (isLoading) return renderLoading();

  return <LoginForm error={error} onSubmit={onSubmit} />;
};

export default CreatorLoginView;
