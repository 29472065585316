import { PlusIcon } from '@heroicons/react/outline';

interface ProductBarProps {
  onCreateNew: Function;
}

export default function ProductBar(props: ProductBarProps) {
  const { onCreateNew } = props;

  const onClickCreateNew = () => {
    if (onCreateNew) onCreateNew();
  };

  return (
    <div className="bg-white overflow-hidden rounded sm:shadow-md sm:rounded-md">
      <div className="px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h1 className="text-gray-700 font-bold text-2xl">Listino</h1>
          </div>
          <div className="ml-4 mt-2 shrink-0">
            <button
              onClick={onClickCreateNew}
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
              <span> Crea nuovo </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
