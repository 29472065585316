// app
import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { SelectCategory, SelectTag } from 'components/common/select';
import { useGetAllCategory, useGetAllGender, useGetAllTag } from 'api/common';

import { useQueryParams, useAuth } from 'hooks';
import { ICategory, ITag } from 'interfaces';

import CreatorSignUpSection from 'components/web/CreatorSignUpSection';
import HeroSection from 'components/web/HeroSection';
import { FeatureGridSection } from 'components/web/FeatureGridSection';
import { SupportButton } from 'components/web/SupportButton';
import CreatorGrid from 'components/web/CreatorGridSection';

const Home = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { getAllGenderQuery } = useGetAllGender();
  const { data: getAllGenderResponse } = getAllGenderQuery;

  const { getAllCategoryQuery } = useGetAllCategory();
  const { data: getAllCategoryResponse } = getAllCategoryQuery;

  const { getAllTagQuery } = useGetAllTag();
  const { data: getAllTagResponse } = getAllTagQuery;

  const setSearchParams = useSearchParams()[1];
  const { search } = useLocation();
  const params = useQueryParams();

  const defaultCategories = useMemo(
    () =>
      //restituire un oggetto con tutte le categorie con filter e includes
      getAllCategoryResponse?.items.filter((category) =>
        params.categories?.includes(category.id.toString())
      ),
    [params, getAllCategoryResponse]
  );

  const defaultTags = useMemo(
    () =>
      getAllTagResponse?.items.filter((tag) => params.tags?.includes(tag.id.toString())),
    [params, getAllTagResponse]
  );

  const defaultGender = useMemo(() => params.gender, [params]);

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.value) {
      const queryString = new URLSearchParams(search);
      queryString.delete('gender');
      queryString.append('gender', e.target.value);

      setSearchParams(queryString);
    }
  };

  const handleChangeCategory = (e: any) => {
    if (e && e.length >= 0) {
      const queryString = new URLSearchParams(search);
      queryString.delete('categories[]');
      e.forEach(({ id }: any) => {
        queryString.append('categories[]', id.toString());
      });

      setSearchParams(queryString);
    }
  };

  const handleChangeTag = (e: any) => {
    if (e && e.length >= 0) {
      const queryString = new URLSearchParams(search);
      queryString.delete('tags[]');
      e.forEach(({ id }: any) => {
        queryString.append('tags[]', id.toString());
      });

      setSearchParams(queryString);
    }
  };

  return (
    <main className="mt-6">
      <HeroSection />

      <div className="mt-6 p-4 bg-white rounded sm:mt-8 sm:shadow-md sm:rounded-md">
        <h3 className="text-xl text-center sm:text-4xl font-extrabold leading-6 text-pink-400 tracking-tight">
          Scopri come funziona!
        </h3>
        <p className="mt-2 mb-2 max-w-lg mx-auto text-center text-xl text-pink-400 sm:max-w-3xl">
          Guarda il video 👇
        </p>
        <video className="h-full w-full" poster="/assets/home_video_cover.jpg" controls>
          <source
            src="https://vieniora-static.b-cdn.net/assets/home_video.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="mt-6 p-4 bg-white rounded sm:mt-8 sm:shadow-md sm:rounded-md">
        <form action="#" method="POST">
          <div className="sm:overflow-hidden">
            <div className=" space-y-6">
              <h3 className="text-lg text-center sm:text-4xl font-extrabold leading-6 text-pink-400 tracking-tight">
                Ricerca creators
              </h3>

              <div className="text-center grid grid-cols-1 md:grid-cols-6 gap-6">
                <div className="col-span-2 md:col-span-2">
                  <select
                    onChange={handleChange}
                    id="gender"
                    // value={
                    //   query && query.get('gender') && query.get('gender') !== null
                    //     ? query.get('gender')!
                    //     : 0
                    // }
                    value={defaultGender}
                    className="w-full text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md"
                  >
                    <option value={0}>Tutti i generi</option>
                    {getAllGenderResponse?.items.map(({ id, description }) => (
                      <option key={id} value={id}>
                        {description}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-2 md:col-span-2">
                  <SelectCategory
                    selectProps={{
                      value: defaultCategories,
                      onChange: handleChangeCategory,
                      getOptionLabel: (item: ICategory) => item.label,
                    }}
                  />
                </div>
                <div className="col-span-2 md:col-span-2">
                  <SelectTag
                    selectProps={{
                      value: defaultTags,
                      onChange: handleChangeTag,
                      getOptionLabel: (item: ITag) => item.label,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-white text-center">
              <CreatorGrid searchQuery={params} />
            </div>
          </div>
        </form>
      </div>
      <FeatureGridSection />
      {/* <LogoCloud /> */}
      {!user && <CreatorSignUpSection />}
      <SupportButton onClick={() => navigate('/support')} />
    </main>
  );
};

export default Home;
