// app
import { ITransfer } from 'interfaces';
import { useAuth, useFormatTransfer } from 'hooks';
import { formatDate, isCashAvailable } from 'utils';

import { TransferStatus } from './TransferStatus';
import { TransferType } from './TransferType';

import { DEFAULT_LIMIT_TRANSFER } from 'config/Enviroment';
import { ROLES } from 'config/Roles';

interface ITransferTableRowProps {
  transfer: ITransfer;
}

const TransferTableRow = (props: ITransferTableRowProps) => {
  const { transfer } = props;

  const { user: loggedUser } = useAuth();

  const { status, type, startAt } = transfer;

  const { user, image, description, amountTotal, amountFee, amountNet } =
    useFormatTransfer(transfer);

  return (
    <tr className="bg-white">
      <td className="px-6 py-4 text-right whitespace-nowrap text-medium text-gray-900">
        <time dateTime={formatDate(startAt)}>{formatDate(startAt)}</time>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          {image}

          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{user?.username}</div>
            {/* <div className="text-sm text-gray-500">{transfer.email} </div> */}
          </div>
        </div>
      </td>

      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
        <span className="group inline-flex space-x-2">
          <TransferType type={type} />
        </span>
      </td>

      <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex">
          <div className="group inline-flex space-x-2 truncate text-sm">
            <p className="text-gray-500 truncate italic">{description}</p>
          </div>
        </div>
      </td>

      {loggedUser && loggedUser.role.code !== ROLES.CUSTOMER && (
        <>
          <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-500 font-normal">{amountTotal}</span>
          </td>
          <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
            <span className="text-gray-500 font-normal">{amountFee}</span>
          </td>
        </>
      )}
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-600 font-medium">{amountNet}</span>
      </td>
      {loggedUser && loggedUser.role.code !== ROLES.CUSTOMER && (
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {isCashAvailable(startAt) ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 block m-auto"
              viewBox="0 0 20 20"
              fill="#86efac"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 block m-auto"
              viewBox="0 0 20 20"
              fill="#fca5a5"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </td>
      )}
      <td className="hidden px-6 py-7 whitespace-nowrap text-sm text-gray-500 md:block">
        <TransferStatus status={status} />
      </td>
    </tr>
  );
};

interface TransferTableProps {
  data: ITransfer[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
function TransferTable(props: TransferTableProps): JSX.Element {
  const { data, page, setPage } = props;

  const { user: loggedUser } = useAuth();

  const prevPage = () => {
    if (page >= DEFAULT_LIMIT_TRANSFER) setPage(page - DEFAULT_LIMIT_TRANSFER);
  };

  const nextPage = () => {
    setPage(page + DEFAULT_LIMIT_TRANSFER);
  };

  return (
    <div className="flex flex-col mt-4">
      <div className="bg-white align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
        {/* header */}
        <div className="bg-white border-b border-gray-200 px-4 py-5 sm:px-6 -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-700">Transazioni</h3>
          </div>
          {/* <div className="ml-4 mt-2 shrink-0">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              Richiedi versamento
            </button>
          </div> */}
        </div>

        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Data
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Utente
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tipo
              </th>
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Descrizione
              </th>
              {loggedUser && loggedUser.role.code !== ROLES.CUSTOMER && (
                <>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Lordo
                  </th>

                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Piattaforma
                  </th>
                </>
              )}
              <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Netto
              </th>
              {loggedUser && loggedUser.role.code !== ROLES.CUSTOMER && (
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Disponibilità
                </th>
              )}
              <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                Stato
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((transfer) => (
              <TransferTableRow key={transfer.id} transfer={transfer} />
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <nav
          className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
          aria-label="Pagination"
        >
          {/* <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Mostro <span className="font-medium">1</span> a{' '}
              <span className="font-medium">10</span> di{' '}
              <span className="font-medium">20</span> risultati
            </p>
          </div> */}
          <div className="flex-1 flex justify-between sm:justify-end">
            {page !== 0 && (
              <button
                onClick={prevPage}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Precedenti
              </button>
            )}
            <button
              onClick={nextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Successivi
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}

TransferTable.defaultProps = {
  data: [],
};

export default TransferTable;
