// std

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQueryClient } from 'react-query';

// app
import {
  IChatMessage,
  IChatThread,
  IChatThreadCache,
  IResponseWithCursor,
} from 'interfaces';
import { ENV_COMMUNICATION, ROLES } from 'config';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks';
import { useCallback, useMemo } from 'react';
import { findThreadInPages } from 'utils';

const getAllThread = async ({ pageParam, queryKey }: any) => {
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IChatThread>>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
      ...(pageParam ? pageParam : {}),
    },
  });

  return data;
};

export function useGetAllThread(searchQuery?: any, pagingQuery?: any) {
  const queryKey = useMemo(
    () => [ENV_COMMUNICATION.GET_ALL_THREAD, searchQuery, pagingQuery],
    [searchQuery, pagingQuery]
  );

  const { user } = useAuth();

  const getAllThreadQuery = useInfiniteQuery<
    IResponseWithCursor<IChatThread>,
    AxiosError<string, any>
  >({
    queryKey: queryKey,
    queryFn: getAllThread,
    enabled: !!user && user.role.code !== ROLES.ADMIN,
    refetchOnWindowFocus: true,
    getPreviousPageParam: (lastPage) => {
      const { cursor } = lastPage;

      if (!cursor) {
        return null;
      }

      const { beforeCursor } = cursor;

      if (!beforeCursor) {
        return null;
      }

      return { beforeCursor };
    },
  });

  const queryClient = useQueryClient();

  const updateLastMessage = useCallback(
    async (thread: IChatThread, message: IChatMessage) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);
      // Snapshot the previous value

      const cache = queryClient.getQueryData<IChatThreadCache>(queryKey);

      if (!cache) {
        queryClient.setQueryData(queryKey, { pages: [{ ...thread, message }] });
        return;
      }

      // Cerco il thread se già esisteva
      const { uuid: tuuid } = thread;

      const cacheClone = structuredClone(cache);
      const indexes = findThreadInPages(tuuid, cacheClone);

      if (!indexes.length) {
        cacheClone.pages[0].data.unshift({ ...thread, message });
      } else {
        cacheClone.pages[indexes[0]].data[indexes[1]].message = message;
      }

      queryClient.setQueryData(queryKey, cacheClone);
    },
    [queryClient, queryKey]
  );

  const updateReadStatus = useCallback(
    async (tuuid: string, hasNewMessage: boolean) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const cache = queryClient.getQueryData<IChatThreadCache>(queryKey);

      if (!cache) return;

      const cacheClone = structuredClone(cache);
      const indexes = findThreadInPages(tuuid, cacheClone);

      // Element found
      if (indexes.length) {
        const item = cacheClone.pages[indexes[0]].data[indexes[1]];
        cacheClone.pages[indexes[0]].data[indexes[1]] = { ...item, hasNewMessage };
      }

      queryClient.setQueryData(queryKey, cacheClone);

      // Se desideri invalidare le query, decommenta la riga seguente
      // await queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey]
  );

  return {
    queryKey,
    getAllThreadQuery,
    updateLastMessage,
    updateReadStatus,
  };
}
