import { CDN_ENDPOINT } from 'config/Config';

interface IMediaPreviewProps {
  imageUrl: string | null;
  alt: string;
  className?: string;
  params?: {
    class?: 'small' | 'medium';
    width?: string;
    height?: string;
    aspect_ratio?: '1:1' | '16:9';
  };
}

const MediaPreviewPlaceholder = (props: Partial<IMediaPreviewProps>) => {
  const { className } = props;

  return (
    <svg className={className + ' text-gray-300'} fill="currentColor" viewBox="0 0 24 24">
      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
  );
};

MediaPreviewPlaceholder.defaultProps = {
  className: 'h-full w-full',
};

export const MediaPreview = (props: IMediaPreviewProps) => {
  const { imageUrl, alt, className, params } = props;

  if (!imageUrl) return <MediaPreviewPlaceholder className={className} />;

  const queryString = params ? '?' + new URLSearchParams(params).toString() : '';
  const url = `${CDN_ENDPOINT.STATIC}${imageUrl}${queryString}`;

  return <img className={className} src={url} alt={alt} />;
};

MediaPreview.defaultProps = {
  params: {
    class: 'small',
  },
};
