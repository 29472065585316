import { useMemo } from 'react';

// 3p
import axios, { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

// app
import styles from 'styles';
import { IResetPasswordForm } from 'interfaces';
import { useAuth } from 'hooks';
import APIClient from 'api/ApiClient';

import Loader from 'components/common/Loader';
import { ENV_AUTH } from 'config/Enviroment';

import { AlertError, AlertSuccess } from '../alert';

interface IResetPasswordFormProps {
  token: string;
}

export const ResetPasswordForm = (props: IResetPasswordFormProps) => {
  const { token } = props;

  const { isLoading } = useAuth();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful, errors },
  } = useForm<IResetPasswordForm>({
    defaultValues: {
      token: useMemo(() => token, [token]),
    },
  });

  const onSubmit: SubmitHandler<IResetPasswordForm> = async (data) => {
    const { password, password2 } = data;

    if (password !== password2) {
      setError('password2', {
        type: 'manual',
        message: 'Le due password non coincidono',
      });

      return;
    }

    try {
      await APIClient.post(ENV_AUTH.REQUEST_PASSWORD_RESET, data);
    } catch (ex) {
      const error = ex as Error | AxiosError<string>;

      if (axios.isAxiosError(error)) {
        const errorMsg = error.response?.data as string;
        setError('token', { type: 'server', message: errorMsg });
      }

      console.error(error);
    }
  };

  if (isLoading) return <Loader />;

  if (isSubmitSuccessful)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertSuccess message="Password modificata correttamente." />
        <Link className={styles.button.full + ' mt-5'} to="/login">
          Vai alla pagina di accesso
        </Link>
      </div>
    );

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {errors.password2 && <AlertError message={errors.password2.message} />}
        {errors.token && <AlertError message={errors.token.message} />}
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="password" className={styles.input.label}>
              Nuova password
            </label>
            <div className="mt-1">
              <input
                required
                id="password"
                type="password"
                minLength={7}
                autoComplete="off"
                {...register('password', { required: 'Inserire nuova password' })}
                className={styles.input.base}
              />
            </div>
          </div>

          <div>
            <label htmlFor="password2" className={styles.input.label}>
              Ripeti password
            </label>
            <div className="mt-1">
              <input
                required
                id="password2"
                type="password"
                minLength={7}
                autoComplete="off"
                {...register('password2', {
                  required: 'Inserire nuova password di conferma',
                })}
                className={styles.input.base}
              />
            </div>
          </div>

          <div>
            <button type="submit" className={styles.button.full}>
              Continua
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
