import { endOfMonth, format, startOfMonth } from 'date-fns';

// app

import { useSearchParams } from 'react-router-dom';
import {
  AnalyticsFee,
  AnalyticsGateway,
  AnalyticsTransfer,
} from 'components/admin/analytics';
import { useMemo } from 'react';

const defaultSearchParams = new URLSearchParams();
defaultSearchParams.set('startAt', startOfMonth(new Date()).toISOString());
defaultSearchParams.set('endAt', endOfMonth(new Date()).toISOString());

function convertToHTMLDatetime(d: Date) {
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().slice(0, 16);
}

export const AnalyticsGetAll = () => {
  const [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);

  const startAt = searchParams.get('startAt');
  const endAt = searchParams.get('endAt');

  const renderData = useMemo(() => {
    const isTemporalSet = startAt != null && endAt != null;

    if (!isTemporalSet) return null;

    const startAtVal = convertToHTMLDatetime(new Date(startAt));
    const endAtVal = convertToHTMLDatetime(new Date(endAt));

    const startAtStr = format(new Date(startAt), 'dd/MM/yyyy');
    const endAtStr = format(new Date(endAt), 'dd/MM/yyyy');

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
              Statistiche mese dal {startAtStr} al {endAtStr}
            </h2>
          </div>
          <div className="mt-4 flex flex-wrap md:mt-0 md:ml-4">
            <div className=" px-4 py-2 text-sm font-medium">
              <label htmlFor="startAt" className="mr-3">
                Dal
              </label>
              <input
                type="datetime-local"
                id="startAt"
                name="startAt"
                onChange={(e) => {
                  searchParams.set('startAt', e.target.value);
                  setSearchParams(searchParams);
                }}
                value={startAtVal}
              />
            </div>
            <div className="ml-3  px-4 py-2 text-sm font-medium">
              <label htmlFor="endAt" className="mr-3">
                al
              </label>
              <input
                type="datetime-local"
                id="endAt"
                name="endAt"
                onChange={(e) => {
                  searchParams.set('endAt', e.target.value);
                  setSearchParams(searchParams);
                }}
                value={endAtVal}
              />
            </div>
          </div>
        </div>

        <AnalyticsTransfer startAt={startAt} endAt={endAt} />

        <AnalyticsGateway startAt={startAt} endAt={endAt} />

        <AnalyticsFee startAt={startAt} endAt={endAt} />
      </div>
    );
  }, [startAt, endAt, searchParams, setSearchParams]);

  return renderData;
};
