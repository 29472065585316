import { useCallback } from 'react';

// 3p
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';
import { PencilAltIcon } from '@heroicons/react/outline';

// app
import { TransferTypeEnum, TrasnferStatusEnum } from 'config';
import { formatDate, formatMoney, isCashAvailable } from 'utils';
import { IResponseWithCursor, ITransferTableRow } from 'interfaces';

import {
  AlertError,
  TransferType,
  Loader,
  BasicEmptyState,
  TransferStatus,
  UserImage,
} from 'components/common';

import { useModal, useToast } from 'hooks';

import { RefundModalStatus } from './RefundStatusModal';

interface ITransferTableRowProps {
  transfer: ITransferTableRow;
  onRowEdit?: (transfer: ITransferTableRow) => void;
}

const TransferTableRow = (props: ITransferTableRowProps) => {
  const { transfer, onRowEdit } = props;
  const {
    transaction,
    sourceUser,
    targetUser,
    paymentIn,
    type,
    status,
    code,
    completeAt,
    products,
  } = transfer;

  const handleRowEdit = useCallback(() => {
    if (onRowEdit) {
      onRowEdit(transfer);
    }
  }, [transfer, onRowEdit]);

  const transactionAmount = formatMoney(transaction.amount, {
    currency: transaction.currency,
  });

  const transactionFee = formatMoney(transaction.fee, {
    currency: transaction.currency,
  });

  const transactionNet = formatMoney(transaction.net, {
    currency: transaction.currency,
  });

  return (
    <tr key={transfer.id}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {completeAt && formatDate(completeAt)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {code && code.substring(code.length - 5)}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-12 w-12 shrink-0">
            <UserImage
              image={sourceUser?.image}
              alt={sourceUser ? sourceUser.username : 'Non registrato'}
              className="h-12 w-12 rounded-full"
            />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">
              {sourceUser ? sourceUser.username : 'Non registrato'}
            </div>
            {paymentIn && <div className="text-gray-500">{paymentIn.email}</div>}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
        <div className="flex items-center">
          <div className="h-12 w-12 shrink-0">
            <UserImage
              image={targetUser?.image}
              alt={targetUser ? targetUser.username : 'Non registrato'}
              className="h-12 w-12 rounded-full"
            />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">
              {targetUser ? targetUser.username : 'Non registrato'}
            </div>
            {/* <div className="text-gray-500">{transfer.email}</div> */}
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
        <span className="group inline-flex space-x-2">
          <TransferType type={transfer.type} />
        </span>
      </td>
      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
        {products.map(({ title }) => title)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {transfer.paymentIn ? transfer.paymentIn.method.description : 'Platform'}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-500 font-normal">{transactionAmount}</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-500 font-normal">{transactionFee}</span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-600 font-semibold">{transactionNet}</span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <TransferStatus status={transfer.status} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {isCashAvailable(transfer.completeAt) ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 block m-auto"
            viewBox="0 0 20 20"
            fill="#86efac"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 block m-auto"
            viewBox="0 0 20 20"
            fill="#fca5a5"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {TransferTypeEnum.REFUND === type.code &&
          TrasnferStatusEnum.PENDING === status.code && (
            <button className="text-pink-600 hover:text-pink-900" onClick={handleRowEdit}>
              <PencilAltIcon className="mr-2 h-5 w-5" />
            </button>
          )}
      </td>
    </tr>
  );
};

interface ITransferTableProps {
  query: UseQueryResult<IResponseWithCursor<ITransferTableRow>, AxiosError<string, any>>;
  setCursor?: React.Dispatch<any>;
}

export const TransferTable = (props: ITransferTableProps) => {
  const { query, setCursor } = props;

  const { isError, error, isLoading, data, isFetching } = query;

  const { toastSuccess } = useToast();

  const {
    openModal: refundStatusModalOpen,
    closeModal: refundStatusModalClose,
    modalData: refundStatusModal,
    setData: refundStatusModalSetData,
  } = useModal<ITransferTableRow>();

  const handleRowEdit = useCallback(
    (transfer: ITransferTableRow) => {
      refundStatusModalSetData(transfer);
      refundStatusModalOpen();
    },
    [refundStatusModalSetData, refundStatusModalOpen]
  );

  const onRowEditSuccess = useCallback(() => {
    refundStatusModalClose();
    toastSuccess({ title: 'Operazione eseguita' });
  }, [refundStatusModalClose, toastSuccess]);

  const fetchNextPage = () => {
    if (data?.cursor.afterCursor && setCursor)
      setCursor({ afterCursor: data.cursor.afterCursor });
  };

  const fetchPreviousPage = () => {
    if (data?.cursor.beforeCursor && setCursor)
      setCursor({ beforeCursor: data.cursor.beforeCursor });
  };

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderEmpty = () => <BasicEmptyState title={'Non sono presenti trasferimenti'} />;

  return isLoading ? (
    renderLoading()
  ) : isError ? (
    renderError()
  ) : !data?.data.length ? (
    renderEmpty()
  ) : (
    <>
      <RefundModalStatus
        {...refundStatusModal}
        onClose={refundStatusModalClose}
        onSuccess={onRowEditSuccess}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg leading-6 font-medium text-gray-900">Trasferimenti</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
              La lista di tutte le richieste di versamento effettuate dai creators.
            </p> */}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {/* <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 sm:w-auto"
          >
            Add user
          </button> */}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Data
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Codice
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Da
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 tracking-wider"
                    >
                      A
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-semibold text-gray-900"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-semibold text-gray-900"
                    >
                      Prodotto
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-semibold text-gray-900"
                    >
                      Metodo
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 tracking-wider"
                    >
                      Lordo
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 tracking-wider"
                    >
                      Piattaforma
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 tracking-wider"
                    >
                      Netto
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 bg-gray-50 text-center text-sm font-semibold text-gray-900"
                    >
                      Stato
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 tracking-wider">
                      Disponibilità
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data &&
                    data.data.map((transfer) => (
                      <TransferTableRow
                        key={transfer.id}
                        transfer={transfer}
                        onRowEdit={handleRowEdit}
                      />
                    ))}
                </tbody>
              </table>

              {setCursor && (
                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="flex justify-between sm:justify-end">
                    {data?.cursor.beforeCursor && (
                      <button
                        disabled={isFetching}
                        onClick={fetchPreviousPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Precedenti
                      </button>
                    )}
                    {data?.cursor.afterCursor && (
                      <button
                        disabled={isFetching}
                        onClick={fetchNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Successivi
                      </button>
                    )}
                  </div>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
