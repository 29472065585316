interface MessagePhotoProps {
  onImageClick: (id: string, url: string) => void;
  photo: { id: string; url: string };
}

export function MessagePhoto(props: MessagePhotoProps) {
  const { photo, onImageClick } = props;
  const { id, url } = photo;

  return (
    <div
      className="flex w-64 h-40 mb-1 relative flex-shrink-0 max-w-xs lg:max-w-md cursor-pointer"
      onClick={() => onImageClick(id, url)}
    >
      <img
        className="absolute shadow-md w-full h-full rounded-l-lg object-cover"
        src={url}
        alt="Attachment"
      />
    </div>
  );
}
