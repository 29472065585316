import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { useGetAllTransfer } from 'api/transfer';

import { useAuth } from 'hooks';
import { DEFAULT_LIMIT, ROLES } from 'config/index';

import { TransferList, Loader, AlertError, BasicEmptyState } from 'components/common';

import { WalletSummary } from 'components/creator';

const CreatorDashboardView: FunctionComponent = () => {
  const [page, setPage] = useState(0);

  const { user } = useAuth();

  // Transaction Data
  const { getAllTransferQuery, setCurrSkip } = useGetAllTransfer(page, DEFAULT_LIMIT);
  const { isError, error, isLoading, data } = getAllTransferQuery;

  const items = useMemo(() => (data ? data.items : []), [data]);

  useEffect(() => {
    setCurrSkip(page);
  }, [page, setCurrSkip]);

  const renderData = () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      {user && user.role.code === ROLES.CUSTOMER && <WalletSummary />}

      {items.length ? (
        <>
          {/* Activity list (smallest breakpoint only) */}
          <TransferList data={items} page={page} setPage={setPage} />
          {/* Activity table (small breakpoint and up) */}
          {/* <div className="hidden sm:block">
            <TransferTable data={items} page={page} setPage={setPage} />
          </div> */}
        </>
      ) : (
        <BasicEmptyState title="Non sono presenti movimenti" />
      )}
    </div>
  );

  return isLoading ? <Loader /> : isError ? <AlertError error={error} /> : renderData();
};

export default CreatorDashboardView;
