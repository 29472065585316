// std

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

// app
import { ENV_USER } from 'config/Enviroment';
import { IResponseWithCursor, IAlbum } from 'interfaces';

import APIClient from '../ApiClient';

const getAllUserPurchase = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IAlbum>>(key, {
    params: {
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllUserPurchase(pagingQuery: any) {
  const getAllUserPurchaseQuery = useInfiniteQuery<
    IResponseWithCursor<IAlbum>,
    AxiosError<string, any>
  >([ENV_USER.GET_ALL_PURCHASE, pagingQuery], getAllUserPurchase, {
    getNextPageParam: (lastPage) => lastPage.cursor.afterCursor,
  });

  return { getAllUserPurchaseQuery };
}
