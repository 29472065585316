import { useMemo, useState } from 'react';
// 3p

// app
import { IAlbum, IModal } from 'interfaces';
import { formatMoney } from 'utils';

import { AlbumDeleteModal } from 'components/creator/album';

interface IAlbumCardProps {
  data: IAlbum;
  onClick: (media: IAlbum, title: string) => void;
  hasDelete?: boolean;
}

export function AlbumCard(props: IAlbumCardProps): JSX.Element {
  const { data, onClick, hasDelete } = props;

  const { cover, title, price, hasPurchase = false, isEnable = true } = data;

  // const { user: currentUser } = useAuth();

  const handleOnClick = () => {
    if (onClick) onClick(data, title);
  };

  const [albumDeleteModal, setAlbumDeleteModal] = useState<IModal<IAlbum>>({
    open: false,
  });

  const albumDeleteOpen = () => {
    setAlbumDeleteModal({ open: true, data });
  };

  const albumDeleteClose = () => {
    setAlbumDeleteModal({ open: false });
  };

  const coverUrl = useMemo(() => {
    const url = cover ? cover.fileStorage.url : '/assets/placeholder-image.png';

    const params = new URLSearchParams({
      width: '300',
    }).toString();

    return `${url}?${params}`;
  }, [cover]);

  const cardStyle = `bg-white relative rounded shadow transition duration-500 dark:bg-grey-800 overflow-hidden ${
    !isEnable ? 'opacity-50' : ''
  }`;

  const priceStr = price ? (hasPurchase ? 'Acquistato' : formatMoney(price)) : 'Gratuito';

  return (
    <>
      <AlbumDeleteModal {...albumDeleteModal} onClose={albumDeleteClose} />

      <div className={cardStyle}>
        <img
          className="rounded-t-lg w-full cursor-pointer h-50 sm:h-40 object-cover"
          onClick={handleOnClick}
          src={coverUrl}
          alt={title}
        />
        <div className="py-2 px-3 rounded-lg bg-white">
          {!isEnable && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800 mb-1">
              Non visibile
            </span>
          )}
          <h1 className="text-gray-700 font-semibold text-lg hover:text-gray-900 cursor-pointer tracking-wide line-clamp-2 break-words">
            {title}
          </h1>
          {/* <p className="text-gray-700 tracking-wide line-clamp-2 break-words">
                {description}
              </p> */}
        </div>
        <div className="absolute top-2 left-2 py-2 px-4 bg-white rounded-lg">
          <span className="text-md font-semibold">{priceStr}</span>
        </div>
        {hasDelete && (
          <span className="absolute top-2 right-2 py-2 px-6 h-4 w-4">
            <button
              type="button"
              className="delete ml-auto focus:outline-none hover:bg-gray-400 bg-gray-300 p-1 rounded-md"
              onClick={(e) => {
                e.preventDefault();
                albumDeleteOpen();
              }}
            >
              <svg
                className="pointer-events-none fill-current w-4 h-4 ml-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  className="pointer-events-none"
                  d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                />
              </svg>
            </button>
          </span>
        )}
      </div>
    </>
  );
}
