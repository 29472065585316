// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { compress, EImageType } from 'image-conversion';

// app
import { ENV_USER } from 'config';
import { getFileType } from 'utils';
import { useAuth } from 'hooks';

import APIClient from '../ApiClient';

const uploadProfilePhotos = async (data: any) => {
  const formData = new FormData();
  const { mediaList } = data;

  for (const media of mediaList) {
    const type = media.file ? getFileType(media.file) : media.fileType;

    if (type === 'image') {
      const fileBlobCompressed = await compress(media.file, {
        quality: 0.8,
        type: EImageType.JPEG,
      });

      formData.append('images', fileBlobCompressed);
    }
  }

  await APIClient.post<void>(ENV_USER.UPLOAD_PROFILE_PHOTOS, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};

export function useSaveProfilePhoto() {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const saveProfilePhotosMutation = useMutation<void, AxiosError, any>(
    (mediaList) => {
      return uploadProfilePhotos(mediaList);
    },
    {
      onSuccess: () => {
        if (user) {
          const { username } = user;
          const url = ENV_USER.GET_PROFILE(username);
          queryClient.invalidateQueries(url);
        }
      },
    }
  );

  return {
    saveProfilePhotosMutation,
  };
}
