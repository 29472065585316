// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { IResponseWithCursor, ITransactionPayout } from 'interfaces';

import { ENV_ADMIN } from 'config/Enviroment';

import APIClient from '../../ApiClient';

const getAllPayout = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<ITransactionPayout>>(key, {
    params: { ...pagingQuery, ...(pageParam ? { afterCursor: pageParam } : {}) },
  });

  return data;
};

export function useGetAllPayout(pagingQuery: any) {
  const getAllPayoutQuery = useQuery<
    IResponseWithCursor<ITransactionPayout>,
    AxiosError<string, any>
  >([ENV_ADMIN.GET_ALL_PAYOUT, pagingQuery], getAllPayout, {
    keepPreviousData: true,
  });

  return { getAllPayoutQuery };
}
