import { Fragment, FunctionComponent } from 'react';

// 3p
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';

// app
import { useAuth } from 'hooks';

import { Loader, UserImage } from 'components/common';

import { ROLES, MENU_ITEMS } from 'config';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const HeaderDropdown: FunctionComponent = () => {
  const { user, signout } = useAuth();

  const renderMenuItem = () => {
    const filterByRole = MENU_ITEMS.filter((item) => {
      return !item.roles || (user && item.roles && item.roles.includes(user.role.code));
    });

    return filterByRole.map(({ title, path, icon: Icon }) => (
      <Menu.Item key={path}>
        {({ active }) =>
          title === 'Registrazioni' ? (
            <a
              key={path}
              href={path}
              target="_blank"
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-md text-gray-700'
              )}
              rel="noreferrer"
            >
              {title}
            </a>
          ) : (
            <Link
              to={path}
              className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-md text-gray-700'
              )}
            >
              {title}
            </Link>
          )
        }
      </Menu.Item>
    ));
  };

  if (!user) return <Loader />;

  return (
    <Menu as="div" className="ml-3 relative z-[5]">
      <div>
        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          <span className="sr-only">Apri menu utente</span>
          <UserImage
            image={user.image}
            alt={user.username}
            className="h-11 w-11 rounded-full"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="rounded-md origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {user.role.code === ROLES.CREATOR && (
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={'/' + user.username}
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block px-4 py-2 text-md text-gray-700'
                  )}
                >
                  Il mio profilo
                </Link>
              )}
            </Menu.Item>
          )}
          {renderMenuItem()}
          <Menu.Item>
            {({ active }) => (
              <div
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-md text-gray-700 cursor-pointer	'
                )}
                onClick={signout}
              >
                Esci
              </div>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
