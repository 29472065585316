// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_ADMIN } from 'config/Enviroment';

import APIClient from '../../ApiClient';

const payoutConfirm = async (transferId: string) => {
  await APIClient.put<void>(ENV_ADMIN.SET_PAYOUT_CONFIRM(transferId));
};

export function usePayoutConfirm() {
  const queryClient = useQueryClient();

  const usePayoutConfirmMutation = useMutation<void, AxiosError, string>(
    (transferId: string) => payoutConfirm(transferId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_PAYOUT);
        queryClient.invalidateQueries(ENV_ADMIN.GET_PAYOUT_SUMMARY);
      },
    }
  );

  return { usePayoutConfirmMutation };
}
