// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import APIClient from 'api/ApiClient';

const setUserBlockAt = async (userId: string | number) => {
  const { data } = await APIClient.put<void>(ENV_ADMIN.SET_BLOCKAT(userId));
  return data;
};

export function useSetUserBlockAt(userId: string | number) {
  const queryClient = useQueryClient();
  const setUserBlockAtMutation = useMutation<void, AxiosError, string | number>(
    (userId) => setUserBlockAt(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ONE(userId));
      },
    }
  );

  return {
    setUserBlockAtMutation,
  };
}
