import React, { useCallback, useState, createContext, useContext } from 'react';

// app
import { IToast, IToastStatus } from 'interfaces';
import Toast from 'components/common/Toast';

interface IToastContext {
  toastSuccess: (toast: IToast) => void;
  toastError: (toast: IToast) => void;
}

interface IToastContextProvider {
  children: JSX.Element | JSX.Element[];
}

const ToastContext = createContext<IToastContext>({} as IToastContext);

export function ToastContextProvider({ children }: IToastContextProvider): JSX.Element {
  const [toasts, setToasts] = useState<IToast[]>([]);

  // useEffect(() => {
  //   if (toasts.length > 0) {
  //     const timerHide = setTimeout(() => {
  //       const toast = toasts[toasts.length - 1];
  //       if (toast) {
  //         removeToast(toast);
  //       }
  //     }, 5000);
  //     return () => {
  //       clearTimeout(timerHide);
  //     };
  //   }
  // }, [toasts]);

  // const removeToast = (toast: IToast) =>
  //   setToasts((toasts) => {
  //     const index = toasts.findIndex((t) => t.time === toast.time);
  //     toasts[index].visible = false;

  //     return [...toasts];
  //   });

  const addToast = useCallback(
    function (toast: any, type: IToastStatus) {
      const time = Date.now();

      const removeFn = () => {
        setToasts((prevToasts) => prevToasts.filter((t) => t.time !== time));
      };

      setToasts((prevToasts) => {
        return [...prevToasts, { time, removeFn, type, ...toast }];
      });
    },
    [setToasts]
  );

  const toastSuccess = useCallback(
    (toast: any) => {
      addToast(toast, IToastStatus.SUCCESS);
    },
    [addToast]
  );

  const toastError = useCallback(
    (toast: any) => {
      addToast(toast, IToastStatus.ERROR);
    },
    [addToast]
  );

  return (
    <>
      <ToastContext.Provider value={{ toastSuccess, toastError }}>
        {children}
        <div
          aria-live="assertive"
          className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-end"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {toasts.map(({ time, ...toast }) => (
              <Toast key={time} {...toast} />
            ))}
          </div>
        </div>
      </ToastContext.Provider>
    </>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
