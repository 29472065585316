import { useCallback, useEffect, useMemo } from 'react';

// 3p
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { ITransfer, IRefundCreateForm, RefundTypeEnum } from 'interfaces';

import { ModalLayout } from 'components/common';

import { useCreateRefund } from 'api/transfer';
import { convertFromCent } from 'utils';

interface IRefundModalProps {
  data?: ITransfer;
  open: boolean;
  onSuccess?: () => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const RefundModal = (props: IRefundModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { createRefundMutation } = useCreateRefund();
  const { mutateAsync, isLoading: isLoadingCreateRefund } = createRefundMutation;

  const defaultValues = useMemo(
    () => ({
      method: RefundTypeEnum.COMPLETE,
      amount: undefined,
    }),
    []
  );

  const { register, handleSubmit, reset, watch, setValue } = useForm<IRefundCreateForm>({
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const refoundType = watch('method');
  const isRefundTypePartial = useMemo(
    () => refoundType === RefundTypeEnum.PARTIAL,
    [refoundType]
  );

  const transferId = useMemo(() => {
    if (!data) return;
    return data.id;
  }, [data]);

  const transferAmount = useMemo(() => {
    if (!data) return;
    return convertFromCent(data.transaction.amount);
  }, [data]);

  const onSubmit: SubmitHandler<IRefundCreateForm> = useCallback(
    async (data) => {
      if (!transferId) {
        return;
      }

      const body = { ...data };

      try {
        await mutateAsync([transferId, body]);
        if (onSuccess) onSuccess();
      } catch (error) {
        console.error(error);
        if (onError) onError(error);
      }
    },
    [onSuccess, onError, mutateAsync, transferId]
  );

  useEffect(() => {
    if (!isRefundTypePartial) {
      setValue('amount', undefined);
    }
  }, [isRefundTypePartial, setValue]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, open, reset]);

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingCreateRefund}>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            Richiesta rimborso
          </Dialog.Title>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
              <div className="sm:col-span-3">
                <fieldset>
                  <legend className="sr-only">Tipologia</legend>
                  <div className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          {...register('method', { required: true })}
                          defaultChecked={true}
                          id="complete"
                          aria-describedby={`complete-description`}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-600"
                          value={RefundTypeEnum.COMPLETE}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="complete" className="font-medium text-gray-900">
                          Totale <br />
                          <span id={`complete-description`} className="text-gray-500">
                            Rimborsa l'intero importo
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          {...register('method', { required: true })}
                          id="partial"
                          aria-describedby={`partial-description`}
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-600"
                          value={RefundTypeEnum.PARTIAL}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="partial" className="font-medium text-gray-900">
                          Parziale
                          <br />
                          <span id={`partial-description`} className="text-gray-500">
                            Rimborsa solo parte dell'importo
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {isRefundTypePartial && (
                <div className="sm:col-span-3">
                  <label
                    htmlFor="email"
                    className="block text-md font-medium text-gray-700"
                  >
                    Importo da rimborsare
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-lg">€</span>
                    </div>
                    <input
                      id="amount"
                      className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-7 pr-12 sm:text-lg border-gray-300 rounded-md"
                      type="number"
                      step="any"
                      {...register('amount', {
                        required: isRefundTypePartial,
                        valueAsNumber: true,
                        max: transferAmount,
                        min: 0,
                      })}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-lg" id="price-currency">
                        EUR
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="sm:col-span-3">
                <p className="mt-3 text-sm text-gray-500">
                  Importo minimo €50,00 e importo massimo €4.900,00.
                </p>
                <p className="mt-3 text-sm text-gray-500">
                  Per cifre minori di €250,00 viene applicata una commissione di €3,00.
                </p>
                <p className="mt-3 text-sm text-gray-500">
                  Per cifre maggiori o uguali a €250,00 non viene applicata nessuna
                  comissione.
                </p>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleSubmit(onSubmit)}
        >
          Invia richiesta
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Annulla
        </button>
      </div>
    </ModalLayout>
  );
};
