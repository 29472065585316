// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_PAYIN, ENV_TRANSFER } from 'config/Enviroment';
import { IPayInResponse, IPayInTopUpForm } from 'interfaces';

import APIClient from '../ApiClient';

const createPayInTopUp = async (body: IPayInTopUpForm) => {
  const { data } = await APIClient.post<IPayInResponse>(
    ENV_PAYIN.CREATE_PAYMENT_TOP_UP,
    body
  );

  return data;
};

export function useCreatePayInTopUp() {
  const queryClient = useQueryClient();

  const createPayInTopUpMutation = useMutation<
    IPayInResponse,
    AxiosError,
    IPayInTopUpForm
  >((data) => createPayInTopUp(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(ENV_TRANSFER.GET_SUMMARY);
    },
  });

  return {
    createPayInTopUpMutation,
  };
}
