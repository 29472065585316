// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ALBUM, ENV_MEDIA } from 'config/Enviroment';

import { IMedia2 } from 'interfaces';

import APIClient from '../ApiClient';

const updatePreview = async (albumId: number, media: IMedia2) => {
  await APIClient.put<void>(ENV_ALBUM.UPDATE_PREVIEW(albumId), media);
};

export function useUpdatePreview(albumId: number) {
  const queryClient = useQueryClient();

  const updatePreviewMutation = useMutation<void, AxiosError, IMedia2>(
    (data) => {
      return updatePreview(albumId, data);
    },
    {
      onSuccess: () => {
        if (albumId) queryClient.invalidateQueries(ENV_MEDIA.GET_ALL_MEDIA(albumId));
      },
    }
  );

  return { updatePreviewMutation };
}
