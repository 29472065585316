export const Dmca = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mb-8 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              DMCA
            </span>
          </h1>
          <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
            REPORTING CLAIMS OF COPYRIGHT INFRINGEMENT
          </span>
          <p className="mt-4 mb-8 text-xl text-gray-500 text-justify leading-8">
            We take claims of copyright infringement seriously. We will respond to notices
            of alleged copyright infringement that comply with the Digital Millennium
            Copyright Act (the “DMCA”) or any other applicable intellectual property
            legislation or laws. Responses may include removing, blocking or disabling
            access to material claimed to be the subject of infringing activity,
            terminating the user’s access to{' '}
            <a href="https://www.vieniora.com">vieniora.com</a> (Vieniora), or all of the
            foregoing. If you believe any material accessible on Vieniora infringes your
            copyright, you may submit a copyright infringement notification (see below,
            “Filing a DMCA Notice of Copyright Infringement” for instructions on filing
            such a notice). These requests should only be submitted by the copyright owner
            or an agent authorized to act on the owner’s behalf. If we remove or disable
            access to material in response to such a notice, we will take reasonable steps
            to notify the user that uploaded the affected content material that we have
            removed or disabled access to so that the user has the opportunity to submit a
            counter notification (see below, “Counter-Notification Procedures” for
            instructions on filing a counter notification). It is our policy to document
            all notices of alleged infringement on which we act. All copyright
            infringement notifications and counter-notifications must be written in
            English. Any attempted notifications written in foreign languages or using
            foreign characters may, at our discretion, be deemed non-compliant and
            disregarded.
          </p>
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          FILING A DMCA NOTICE OF COPYRIGHT INFRINGEMENT
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          If you choose to request removal of content by submitting an infringement
          notification, please remember that you are initiating a legal process. Do not
          make false claims. Misuse of this process may result in the suspension of your
          account or other legal consequences. You may notify Vieniora of alleged
          copyright infringement via our form found at{' '}
          <a href="https://www.vieniora.com/support">vieniora.com - Support</a>. This form
          is designed to help you identify your content accurately and help speed up the
          process of content take-down. We also accept free-form copyright infringement
          notifications. In that case, in accordance with the DMCA, the written notice
          (the “DMCA Notice”) must include substantially the following: Identification of
          the copyrighted work you believe to have been infringed or, if the claim
          involves multiple works, a representative list of such works. Identification of
          the material you believe to be infringing in a sufficiently precise manner to
          allow us to locate that material. If your complaint does not contain the
          specific URL of the video you believe infringes your rights, we may be unable to
          locate and remove it. General information about the video, such as a channel URL
          or username, is typically not adequate. Please include the URL(s) of the exact
          video(s). Adequate information by which we, and the uploader(s) of any video(s)
          you remove, can contact you (including your name, postal address, telephone
          number and, if available, e-mail address). A statement that you have a good
          faith belief that use of the copyrighted material is not authorized by the
          copyright owner, its agent or the law.. A statement that the information in the
          written notice is accurate, and under penalty of perjury, that you are the
          owner, or an agent authorized to act on behalf of the owner, of an exclusive
          right that is allegedly infringed. Complete complaints require the physical or
          electronic signature of the copyright owner or a representative authorized to
          act on their behalf. To satisfy this requirement, you may type your full legal
          name to act as your signature at the bottom of your complaint. Our designated
          Copyright Agent to receive DMCA Notices is: OSSES LTD 19 Leyden Street, London,
          England, E1 7LE Email: copyright@vieniora.com Please do not send other inquiries
          or requests to our designated copyright agent. Absent prior express permission,
          our designated copyright agent is not authorized to accept or waive service of
          formal legal process, and any agency relationship beyond that required to
          receive valid DMCA Notices or Counter-Notices (as defined below) is expressly
          disclaimed. If you fail to comply with all of the requirements of Section
          512(c)(3) of the DMCA, your DMCA Notice may not be effective. Please be aware
          that if you knowingly materially misrepresent that material or activity on
          Vieniora is infringing your copyright, you may be held liable for damages
          (including costs and attorneys’ fees) under Section 512(f) of the DMCA. The
          copyright owner’s name will be published on Vieniora in place of disabled
          content. This will become part of the public record of your DMCA Notice, along
          with your description of the work(s) allegedly infringed. All the information
          provided in a DMCA Notice, the actual DMCA Notice (including your personal
          information), or both may be forwarded to the uploader of the allegedly
          infringing content. By submitting a DMCA Notice, you consent to having your
          information revealed in this way.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          COUNTER-NOTIFICATION PROCEDURES
        </span>
        <div className="mt-4 mb-8 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          If you have received a DMCA Notice and believe that material you posted on
          Vieniora was removed or access to it was disabled by mistake or
          misidentification, you may file a counter-notification with us (a
          “Counter-Notice”). Counter notifications must be submitted by the video’s
          original uploader or an agent authorized to act on their behalf. Counter-notices
          must be sent to our designated agent: OSSES LTD 19 Leyden Street, London,
          England, E1 7LE Email: copyright@vieniora.com Please do not send other inquiries
          or requests to our designated copyright agent. Absent prior express permission,
          our designated copyright agent is not authorized to accept or waive service of
          formal legal process, and any agency relationship beyond that required to
          receive valid DMCA Notices or Counter-Notices (as defined below) is expressly
          disclaimed. Pursuant to the DMCA, the Counter-Notice must include substantially
          the following: Your name, address, phone number and physical or electronic
          signature; Identification of the allegedly infringing content and its location
          before removal or access to it was disabled; A statement under penalty of
          perjury that you believe in good faith that the content was removed by mistake
          or misidentification; and A statement that you consent to the jurisdiction of
          the U.S. Federal District Court for the judicial district in which you are
          located (or if you are outside the U.S., for any judicial district in which the
          operator of Vieniora may be found), and that you will accept service of process
          from the person who originally provided us with the DMCA Notice or an agent of
          such person. We will not respond to counter notifications that do not meet the
          requirements above. After we receive your Counter Notice, we will forward it to
          the party who submitted the original DMCA Notice and inform that party that the
          removed material may be restored after 10 business days but no later than 14
          business days from the date we received your Counter Notice, unless our
          Designated Agent first receives notice from the party who filed the original
          DMCA Notice informing us that such party has filed a court action to restrain
          you from engaging in infringing activity related to the material in question.
          Please note that when we forward your Counter Notice, it will include your
          personal information. By submitting a counter notification, you consent to
          having your information revealed in this way. We will not forward the counter
          notification to any party other than the original claimant or to law enforcement
          or parties that assist us with enforcing and protecting our rights. Please be
          aware that if you knowingly materially misrepresent that material or activity on
          Vieniora was removed or disabled by mistake or misidentification, you may be
          held liable for damages (including costs and attorneys’ fees) under Section
          512(f) of the DMCA.
        </div>

        <span className="block text-xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-xl">
          REPEAT INFRINGERS
        </span>
        <div className="mt-4 text-justify prose prose-indigo prose-lg text-gray-500 mx-auto">
          In accordance with the DMCA and other applicable law, we have adopted a policy
          of terminating or disabling, in appropriate circumstances and at our sole
          discretion, the accounts of users who are deemed to be repeat infringers. We may
          also at our sole discretion limit access to Vieniora, terminate or disable the
          accounts of any users who infringe any intellectual property rights of others,
          whether or not there is any repeat infringement.
        </div>
      </div>
    </div>
  );
};
