import axios from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';

// app
import APIClient from 'api/ApiClient';
import { ENV_USER } from 'config/Enviroment';

import { useToast } from 'hooks';
import { useUpdateUserEmail } from 'api/user/useUpdateUserEmail';
import { IUserEmail } from 'interfaces';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AccountDeleteForm } from './AccountDeleteForm';

interface IUpdatePasswordForm {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
}

export default function AccountTab() {
  const { toastError, toastSuccess } = useToast();

  const { updateUserEmailMutation } = useUpdateUserEmail();
  const { mutate: updateUserEmail } = updateUserEmailMutation;

  const { register, handleSubmit } = useForm<IUpdatePasswordForm>();

  const emailFormSchema = yup
    .object({
      email: yup.string().email().required(),
    })
    .required();

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    formState: { errors },
  } = useForm<IUserEmail>({ resolver: yupResolver(emailFormSchema) });

  const onSubmitEmail: SubmitHandler<IUserEmail> = (data: IUserEmail) => {
    updateUserEmail(data, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  const onSuccess = () => {
    resetEmail();
    toastSuccess({
      title: 'Operazione completata',
      message: 'E-mail modificata correttamente',
    });
  };

  const onError = () => {
    resetEmail();
    toastError({
      title: 'Errore generico',
      message: 'Impossibile modificare le informazioni. Riprovare',
    });
  };

  const onSubmitUpdatePassword = async (data: IUpdatePasswordForm) => {
    const { newPassword, newPassword2, oldPassword } = data;

    if (newPassword !== newPassword2) {
      toastError({
        title: 'Errore modifica password',
        message: 'Le nuove password non combaciano',
      });

      return;
    }

    try {
      await APIClient.put<{ oldPassword: string; newPassword: string }>(
        ENV_USER.UPDATE_PASSWORD,
        { newPassword, oldPassword }
      );

      toastSuccess({
        title: 'Operazione completata',
        message: 'Password modifica correttamente',
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          toastError({
            title: 'Errore modifica password',
            message: 'La password attuale è errata',
          });
        }
      }

      console.error(error);
    }
  };

  return (
    <>
      <form key={'emailForm'} onSubmit={handleSubmitEmail(onSubmitEmail)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Email</h3>
              <p className="mt-1 text-sm text-gray-500">Modifica la tua email</p>
            </div>

            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  autoComplete="email"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...registerEmail('email', { required: true })}
                />
                {errors.email?.type === 'required' ? (
                  <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                    Campo obbligatorio
                  </p>
                ) : (
                  errors.email && (
                    <p style={{ color: 'red', fontSize: 10, marginTop: 3 }}>
                      Inserire un' email valida
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
            >
              Salva
            </button>
          </div>
        </div>
      </form>

      <form key={'passwordForm'} onSubmit={handleSubmit(onSubmitUpdatePassword)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Password</h3>
              <p className="mt-1 text-sm text-gray-500">
                Inserisci una nuova password di minimo 6 caratteri e massimo 20
              </p>
            </div>

            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-2">
                <label
                  htmlFor="oldPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password attuale
                </label>
                <input
                  type="password"
                  id="oldPassword"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('oldPassword', { required: true })}
                />
              </div>

              <div className=" hidden sm:col-span-2 sm:block"></div>

              <div className="col-span-4 sm:col-span-2">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nuova password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('newPassword', {
                    required: true,
                    maxLength: 20,
                    minLength: 6,
                  })}
                />
              </div>
              <div className="col-span-4 sm:col-span-2">
                <label
                  htmlFor="newPassword2"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ripeti nuova password
                </label>
                <input
                  type="password"
                  id="newPassword2"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                  {...register('newPassword2', {
                    required: true,
                    maxLength: 20,
                    minLength: 6,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
            >
              Salva
            </button>
          </div>
        </div>
      </form>

      <AccountDeleteForm />
    </>
  );
}
