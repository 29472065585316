import CryptoJS from 'crypto-js';

export function fromByteToMb(sizeInBytes: number): number {
  return sizeInBytes / (1024 * 1024);
}

export function fromMbToGb(sizeInMb: number): number {
  return sizeInMb / 1024;
}

/**
 * Return the duration in seconds of the video
 * @param file
 * @returns
 */
export const getVideoDuration = (file: File) => {
  return new Promise<number>((resolve) => {
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;

    const videoUrl = URL.createObjectURL(file);
    video.src = videoUrl;

    video.onloadeddata = () => {
      video.pause();

      const durationSeconds = Math.floor(video.duration);

      URL.revokeObjectURL(videoUrl);

      return resolve(durationSeconds);
    };
  });
};

/**
 * Genera una anteprima JPEG del video mantenendo le proporzioni originali
 * @param file
 * @returns Promise<string>
 */
export const generateVideoThumbnail = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.playsInline = true;
    video.muted = true;

    const videoUrl = URL.createObjectURL(file);
    video.src = videoUrl;

    const handleError = (message: string) => {
      console.error(`Errore nella generazione del thumbnail: ${message}`);
      URL.revokeObjectURL(videoUrl);
      video.remove();
      reject(new Error(message));
    };

    const cleanupAndResolve = (base64: string) => {
      URL.revokeObjectURL(videoUrl);
      video.remove();
      resolve(base64);
    };

    video.onloadedmetadata = () => {
      if (isNaN(video.duration)) {
        handleError("Impossibile determinare la durata del video");
        return;
      }

      const seekTime = Math.min(3, video.duration / 2);
      video.currentTime = seekTime;
    };

    video.onseeked = () => {
      try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error("Impossibile ottenere il contesto del canvas");
        }

        // Calcola le dimensioni mantenendo l'aspect ratio
        const aspectRatio = video.videoWidth / video.videoHeight;
        const targetWidth = 260;
        const targetHeight = 160;
        let newWidth, newHeight;

        if (aspectRatio > targetWidth / targetHeight) {
          // Il video è più largo rispetto al target
          newWidth = targetWidth;
          newHeight = targetWidth / aspectRatio;
        } else {
          // Il video è più alto rispetto al target
          newHeight = targetHeight;
          newWidth = targetHeight * aspectRatio;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Centra l'immagine nel canvas
        const offsetX = (targetWidth - newWidth) / 2;
        const offsetY = (targetHeight - newHeight) / 2;

        ctx.drawImage(video, offsetX, offsetY, newWidth, newHeight);
        const base64 = canvas.toDataURL('image/jpeg', 0.8);

        cleanupAndResolve(base64);
      } catch (error: unknown) {
        if (error instanceof Error) {
          handleError(`Errore durante la generazione del thumbnail: ${error.message}`);
        } else {
          handleError("Errore sconosciuto durante la generazione del thumbnail");
        }
      }
    };

    video.onerror = () => {
      handleError(`Errore nel caricamento del video: ${video.error?.message || 'Errore sconosciuto'}`);
    };

    const timeoutId = setTimeout(() => {
      handleError("Timeout: Il video non è stato caricato in tempo");
    }, 30000); // 30 secondi di timeout

    video.onloadeddata = () => {
      clearTimeout(timeoutId);
    };

    // Gestione dell'errore ERR_FILE_NOT_FOUND
    const checkVideoValidity = setInterval(() => {
      if (video.readyState > 0) {
        clearInterval(checkVideoValidity);
      } else if (video.error) {
        clearInterval(checkVideoValidity);
        handleError(`Errore nel caricamento del video: ${video.error.message}`);
      }
    }, 1000);
  });
};

/**
 * Questa funzione va in errore su alcuni tipi di file.
 * Abbiamo deciso di deprecarla poichè la generazione dell'MD5 è una funzionalità non implementata
 * @param file
 * @returns
 */
export async function calculateMD5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result as ArrayBuffer;

      const md5 = CryptoJS.MD5(
        CryptoJS.enc.Latin1.parse(new TextDecoder().decode(arrayBuffer))
      ).toString();

      resolve(md5);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function getFileType(file: File) {
  return file.type.split('/')[0];
}

export function generatePreviewURL(url: string) {
  const urlObj = new URL(url);
  urlObj.searchParams.append('width', '280');
  urlObj.searchParams.append('quality', '50');
  return urlObj.toString();
}

export function generateOriginalURL(url: string) {
  const urlObj = new URL(url);
  urlObj.searchParams.append('width', '720')
  urlObj.searchParams.append('quality', '80');
  urlObj.searchParams.append('aspect_ratio', '1:1');
  return urlObj.toString();
}
