// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { IPayInCouponResponse, IPayInCouponForm } from 'interfaces';
import { ENV_PAYIN, ENV_TRANSFER } from 'config/Enviroment';

import APIClient from '../ApiClient';

const redeemCouponPOST = async (couponForm: IPayInCouponForm) => {
  const { data } = await APIClient.post<IPayInCouponResponse>(
    ENV_PAYIN.CREATE_PAYMENT_COUPON,
    couponForm
  );

  return data;
};

export function useRedeemCoupon() {
  const queryClient = useQueryClient();

  const createRedeemCouponMutation = useMutation<
    IPayInCouponResponse,
    AxiosError<string, string>,
    IPayInCouponForm
  >(redeemCouponPOST, {
    onSuccess: () => {
      queryClient.invalidateQueries(ENV_TRANSFER.GET_ALL);
      queryClient.invalidateQueries(ENV_TRANSFER.GET_SUMMARY);
    },
  });

  return {
    createRedeemCouponMutation,
  };
}
