// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_MEDIA } from 'config/Enviroment';
import { IAlbum, IMedia2 } from 'interfaces';

import APIClient from '../ApiClient';

const deleteMedia = async (media: IMedia2) => {
  await APIClient.delete(ENV_MEDIA.DELETE(media.id));
  return media;
};

export function useDeleteMedia(album?: IAlbum) {
  const queryClient = useQueryClient();

  const deleteMediaMutation = useMutation<IMedia2, AxiosError, IMedia2>(
    (data) => deleteMedia(data),
    {
      onSuccess: () => {
        if (album) {
          queryClient.invalidateQueries(ENV_MEDIA.GET_ALL_MEDIA(album.id));
        }
      },
    }
  );

  return {
    deleteMediaMutation,
  };
}
