// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IAnalyticsFee } from 'interfaces';

import APIClient from '../../ApiClient';

const getAdminAnalyticsFee = async (startAt: string | null, endAt: string | null) => {
  const { data } = await APIClient.get<IAnalyticsFee>(ENV_ADMIN.GET_ALL_ANLYTICS_FEE, {
    params: {
      startAt: startAt,
      endAt: endAt,
      groupBy: 'month',
    },
  });
  return data;
};

export function useGetAdminAnalyticsFee(startAt: string | null, endAt: string | null) {
  const getAdminAnalyticsFeeQuery = useQuery<IAnalyticsFee, AxiosError<string, any>>(
    [ENV_ADMIN.GET_ALL_ANLYTICS_FEE, startAt, endAt],
    () => getAdminAnalyticsFee(startAt, endAt),
    { enabled: startAt != null && endAt != null }
  );

  return {
    getAdminAnalyticsFeeQuery,
  };
}
