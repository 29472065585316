import { input, button } from './form';
import { modal } from './modal';

const styles = {
  input,
  button,
  modal,
};

export default styles;
