import { IFile } from 'interfaces';

interface IProfileChatButtonProps {
  onClick: () => void;
  image: IFile | null;
  isOnline: boolean;
}

export const ProfileChatButton = (props: IProfileChatButtonProps) => {
  const { onClick, image, isOnline } = props;

  const lastAccessColor = !isOnline ? 'bg-gray-300' : 'bg-green-300';

  return (
    <div
      onClick={onClick}
      className="flex justify-center items-center fixed z-10 shadow cursor-pointer bg-pink-600 hover:bg-pink-700 text-white font-bold hover:shadow-md shadow text-lg px-4 py-2 rounded-2xl outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
      style={{ right: 20, bottom: 20 }}
    >
      <span>💬 Contattami</span>
      {image ? (
        <span className="ml-5 relative inline-block">
          <img
            alt="Scrivi un messaggio"
            className="h-12 w-12 rounded-full object-cover"
            src={image.url}
          />
          <span
            className={`absolute bottom-0 right-0 block h-3 w-3 rounded-full ring-2 ring-white ${lastAccessColor}`}
          />
        </span>
      ) : null}
    </div>
  );
};
