import { useCallback, useReducer } from 'react';

import { IModal } from 'interfaces';

enum ACTION_TYPE_MODAL {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  SET = 'SET',
}

const initialState = {
  open: false,
};

type IReducerAction<T> = {
  type: ACTION_TYPE_MODAL;
  data?: T;
};

type IReducerState<T> = IModal<T>;

const createModalReducer =
  <T,>() =>
  (state: IReducerState<T>, action: IReducerAction<T>): IReducerState<T> => {
    const { type, data } = action;

    switch (type) {
      case ACTION_TYPE_MODAL.OPEN:
        return { ...state, open: true };

      case ACTION_TYPE_MODAL.CLOSE:
        return { open: false };

      case ACTION_TYPE_MODAL.SET:
        return { ...state, data };

      default:
        return state;
    }
  };

export const useModal = <T,>() => {
  const modalReducer = createModalReducer<T>();

  const [modalData, dispatch] = useReducer(modalReducer, initialState);

  const openModal = useCallback(() => {
    return new Promise<void>((resolve) => {
      dispatch({ type: ACTION_TYPE_MODAL.OPEN });
      resolve();
    });
  }, []);

  const closeModal = useCallback(() => {
    return new Promise<void>((resolve) => {
      dispatch({ type: ACTION_TYPE_MODAL.CLOSE });
      resolve();
    });
  }, []);

  const setData = useCallback((data: T) => {
    return new Promise<void>((resolve) => {
      dispatch({ type: ACTION_TYPE_MODAL.SET, data });
      resolve();
    });
  }, []);

  return { openModal, closeModal, setData, modalData };
};
