import { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

interface MessageAudioProps {
  id: string;
  playingId: string | null;
  setPlayingId: (id: string) => void;
  audio: { url: string };
}

export function MessageAudio(props: MessageAudioProps) {
  const { audio, id, playingId, setPlayingId } = props;
  const { url } = audio;
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (!playerRef.current) return;

    if (playingId !== id) {
      playerRef.current.seekTo(0);

      const player = playerRef.current.getInternalPlayer();
      if (player) player.pause();
    }
  }, [playingId, id]);

  const handlePlay = useCallback(() => {
    setPlayingId(id);
  }, [id, setPlayingId]);

  return (
    <div className="flex w-64 h-16 flex-shrink-0 max-w-xs lg:max-w-md">
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls
        config={{
          file: {
            forceAudio: true,
            attributes: { controlsList: 'nodownload' },
          },
        }}
        width="100%"
        height="100%"
        playing={playingId === id}
        onPlay={handlePlay}
      />
    </div>
  );
}
