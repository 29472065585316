// std
import { FunctionComponent } from 'react';

// 3p
import { Outlet } from 'react-router-dom';

// app
import Header from 'layouts/Header';

interface IChatLayoutProps {
  hideFooter?: boolean;
}

const ChatLayout: FunctionComponent<IChatLayoutProps> = () => {
  return (
    <div className="flex flex-col h-full">
      <Header />
      <Outlet />
    </div>
  );
};

export { ChatLayout };
