// std
import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import APIClient from 'api/ApiClient';
import { IUserTableRow } from 'interfaces';

const getUser = async (userId: string | number) => {
  const { data } = await APIClient.get<IUserTableRow>(ENV_ADMIN.GET_ONE(userId));
  return data;
};

export function useGetUser(userId: string | number) {
  const url = useMemo(() => ENV_ADMIN.GET_ONE(userId), [userId]);

  const getUserQuery = useQuery<IUserTableRow, AxiosError<string, IUserTableRow>>(
    [url],
    () => getUser(userId)
  );

  return {
    getUserQuery,
  };
}
