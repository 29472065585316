import { FOOTER, CURRENT_SITE_EMAIL_SUPPORT } from 'config';

export default function AppFooterLayout() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {FOOTER.MAIN.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div
            key="18 U.S.C. 2257 Record-Keeping Requirements Exemption Statement"
            className="px-5 py-2 text-center"
          >
            <a
              href="/legal/statment2257"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              18 U.S.C. 2257 Record-Keeping Requirements Exemption Statement
            </a>
          </div>
          <div key="DMCA" className="px-5 py-2">
            <a href="/legal/dmca" className="text-base text-gray-500 hover:text-gray-900">
              DMCA
            </a>
          </div>
        </nav>

        <div className="mt-8 flex justify-center space-x-6">
          {FOOTER.SOCIAL.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-4 text-center text-sm text-gray-600 max-w-lg mx-auto">
          È severamente vietato salvare, registrare, pubblicare, editare,
          commercializzare, diffondere, trasmettere in alcun modo e sotto qualsiasi forma,
          noleggiare e prestare, eseguire pubblicamente, utilizzare in qualsiasi modo
          foto, video, videochiamate ottenute tramite il presente sito.
        </p>
        <p className="mt-2 text-center text-sm text-gray-400">
          Support email{' '}
          <a
            className="font-medium text-blue-600 hover:text-blue-500"
            href={`mailto:${CURRENT_SITE_EMAIL_SUPPORT}`}
            target="_blank"
            rel="noreferrer"
          >
            {CURRENT_SITE_EMAIL_SUPPORT}
          </a>
        </p>
        <p className="mt-2 text-center text-sm text-gray-400">
          Visita{' '}
          <a
            href="https://epoch.com/"
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Epoch.com
          </a>
          , il nostro agente di vendita autorizzato.
        </p>
        <p className="mt-2 text-center text-sm text-gray-400">
          OSSES LTD 19 Leyden Street, London, England, E1 7LE
        </p>
        <p className="mt-2 text-center text-sm text-gray-400">Company number 12083699</p>
        <p className="mt-1 text-center text-sm text-gray-400">
          &copy; {new Date().getFullYear()} OSSES LTD - All rights reserved.
        </p>
      </div>
    </footer>
  );
}
