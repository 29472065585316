// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { compress, EImageType } from 'image-conversion';

// app
import { ENV_MEDIA } from 'config/Enviroment';

import APIClient from '../ApiClient';
import { getFileType } from 'utils';

const createMedia = async (data: any, albumId: string) => {
  const formData = new FormData();
  const { mediaList } = data;

  const videos = [];

  for (const media of mediaList) {
    const type = media.file ? getFileType(media.file) : media.fileType;

    if (type === 'image') {
      const fileBlobCompressed = await compress(media.file, {
        quality: 0.8,
        type: EImageType.JPEG,
      });

      formData.append('images', fileBlobCompressed);
    } else if (type === 'video') {
      videos.push({
        fileName: media.fileName,
        fileType: media.fileType,
        mimeType: media.mimeType,
      });
    }
  }

  formData.append('videos', JSON.stringify(videos));

  await APIClient.post<void>(ENV_MEDIA.CREATE(albumId), formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};

// const createBunnyMedia = async (data: any, albumId: string) => {
//   await APIClient.post<void>(ENV_MEDIA.CREATE(albumId), data);
// };

export function useSaveMedia(albumId: string) {
  const queryClient = useQueryClient();

  const saveMediaMutation = useMutation<void, AxiosError, any>(
    (mediaList) => {
      return createMedia(mediaList, albumId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_MEDIA.GET_ALL_MEDIA(albumId));
      },
    }
  );

  return {
    saveMediaMutation,
  };
}
