import { io } from 'socket.io-client';

import { ENV_SOCKET_PATH, ENV_SOCKET_URL } from 'config';

export function crateSocketConnection(accessToken: string) {
  return io(ENV_SOCKET_URL, {
    autoConnect: false,
    extraHeaders: {
      'Authorization': `Bearer ${accessToken}`
    },
    path: ENV_SOCKET_PATH
  })
}