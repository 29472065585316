// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { IChatThread } from 'interfaces';
import { ENV_ADMIN } from 'config';

import APIClient from '../../ApiClient';

const getThread = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, threadId] = queryKey;

  const { data } = await APIClient.get<IChatThread>(ENV_ADMIN.GET_THREAD(threadId));

  return data;
};

export function useAdminGetThread(threadId?: string, pagingQuery?: any) {
  const queryKey = ['useAdminGetThread', threadId, pagingQuery];

  const adminGetThreadQuery = useQuery<IChatThread, AxiosError<string, any>>({
    queryKey: queryKey,
    queryFn: getThread,
    enabled: !!threadId,
  });

  return { adminGetThreadQuery, queryKey };
}
