// 3p
import { useParams } from 'react-router-dom';

// app
import { ResetPasswordForm } from 'components/common/auth';

export const ResetPasswordView = () => {
  const { token } = useParams<{ token: string }>() as { token: string };
  return <ResetPasswordForm token={token} />;
};
