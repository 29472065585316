// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_COMMON } from 'config/Enviroment';
import { IRestClientGetResponse, ICountry } from 'interfaces';

import APIClient from '../ApiClient';

const getAllCountry = async () => {
  const { data } = await APIClient.get<IRestClientGetResponse<ICountry>>(
    ENV_COMMON.GET_ALL_COUNTRY
  );

  return data;
};

export function useGetAllCountry() {
  const getAllCountryQuery = useQuery<
    IRestClientGetResponse<ICountry>,
    AxiosError<string, any>
  >([ENV_COMMON.GET_ALL_COUNTRY], () => getAllCountry(), {
    placeholderData: {
      items: [],
      hasMore: false,
    },
  });

  return {
    getAllCountryQuery,
  };
}
