import { useCallback } from 'react';

// app
import { IMediaTableRow } from 'interfaces';
import { ModalLayout } from 'components/common';

interface MediaModalProps {
  open: boolean;
  data: IMediaTableRow;
  onClose: () => void;
}

export function MediaModalAdmin(props: MediaModalProps): JSX.Element {
  const { open, onClose, data } = props;
  const { bunnyId, fileUrl, type, libraryId } = data;

  const renderVideoPlayer = useCallback(() => {
    return (
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          title="123"
          src={`https://iframe.mediadelivery.net/embed/${libraryId}/${bunnyId}?autoplay=true`}
          loading="lazy"
          style={{
            height: '100%',
            width: '100%',
          }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  }, [bunnyId, libraryId]);

  const renderPhotoViewer = useCallback(() => {
    return (
      <img
        className="rounded-t-lg w-full h-full object-cover"
        src={fileUrl ? fileUrl : 'http://via.placeholder.com/200x200'}
        alt="no preview"
      />
    );
  }, [fileUrl]);

  return (
    <ModalLayout open={open} onClose={onClose}>
      {(type === 'PHOTO' || type === 'COVER') && renderPhotoViewer()}
      {type === 'VIDEO' && renderVideoPlayer()}
    </ModalLayout>
  );
}
