// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_PAYOUT } from 'config/Enviroment';
import { IBankAccount } from 'interfaces';

import APIClient from '../ApiClient';

const createBankAccount = async (bankAccount: IBankAccount) => {
  const formData = new FormData();

  for (const [key, val] of Object.entries(bankAccount)) {
    formData.append(key, val);
  }

  const { data } = await APIClient.post<IBankAccount>(
    ENV_PAYOUT.CREATE_BANK_ACCOUNT,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );

  return data;
};

export function useCreateBankAccount() {
  const queryClient = useQueryClient();

  const createBankAccountMutation = useMutation<IBankAccount, AxiosError, IBankAccount>(
    (data) => createBankAccount(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_PAYOUT.GET_ALL_BANK_ACCOUNT);
      },
    }
  );

  return {
    createBankAccountMutation,
  };
}
