// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { IPayInProductReq, IPayInResponse } from 'interfaces';

import { useAuth } from 'hooks';

import { ENV_PAYIN, ENV_TRANSFER } from 'config/Enviroment';
import { ROLES } from 'config/Roles';

import APIClient from '../ApiClient';

const createPayInProduct = async (body: IPayInProductReq) => {
  const { data } = await APIClient.post<IPayInResponse>(
    ENV_PAYIN.CREATE_PAYMENT_PRODUCT,
    body
  );
  return data;
};

export function useCreatePayInProduct() {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const createPayInMutation = useMutation<
    IPayInResponse,
    AxiosError<string, any>,
    IPayInProductReq
  >((data) => createPayInProduct(data), {
    onSuccess: () => {
      // se sono un customer devo refreshare il mio bilancio
      if (user && user.role.code === ROLES.CUSTOMER) {
        queryClient.invalidateQueries(ENV_TRANSFER.GET_SUMMARY);
      }
    },
  });

  return {
    createPayInMutation,
  };
}
