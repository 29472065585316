import { Route, Routes } from 'react-router-dom';

import { CreatorAlbumGetAll } from './AlbumGetAll';
import { CreatorAlbumGetOne } from './AlbumGetOne';

export const AlbumRouter = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <Routes>
        <Route index element={<CreatorAlbumGetAll />} />
        <Route path={`:albumId`} element={<CreatorAlbumGetOne />} />
      </Routes>
    </div>
  );
};
