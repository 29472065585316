export enum FileStatusEnum {
  AVAILABLE = 'AVAILABLE',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  UPLOADED = 'UPLOADED',
}

export enum PaymentGatewayEnum {
  EPOCH = 'EPOCH',
}

export enum PaymentInTypeEnum {
  DIRECT = 'DIRECT',
  LINK = 'LINK',
}

export enum TransferTypeEnum {
  PURCHASE = 'PURCHASE',
  PAYIN = 'PAYIN',
  PAYOUT = 'PAYOUT',
  TIP = 'TIP',
  TOPUP = 'TOPUP',
  REDEEM = 'REDEEM',
  REFUND = 'REFUND',
}

export enum TrasnferStatusEnum {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum BadgeTypeEnum {
  PAYING_CUSTOMER = 'PAYING_CUSTOMER',
}

export const EPOCH_PAYMENT_INFO = `Attenzione, siccome epoch processa i pagamenti in dollari ci saranno delle
commissioni per la conversione da euro a dollaro. Prima di effettuare il
pagamento verificare bene l’importo totale`;
