import { useState, useEffect } from 'react';

export function useTimer() {
  // Stato per tenere traccia del tempo trascorso in secondi
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    // Timer che incrementa il tempo trascorso ogni secondo
    const timer = setInterval(() => {
      setTimeElapsed((prevTime) => prevTime + 1);
    }, 1000);

    // Funzione di pulizia per interrompere il timer
    return () => clearInterval(timer);
  }, []);

  // Funzione per formattare il tempo trascorso in ore, minuti e secondi
  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const h = hours > 0 ? `${hours}h ` : '';
    const m = minutes > 0 ? `${minutes}m ` : '';

    return `${h}${m}${seconds}s`;
  };

  return formatTime(timeElapsed);
}
