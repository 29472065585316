import { Route, Routes, Navigate } from 'react-router-dom';

import { PayoutGetAll } from './PayoutGetAll';

import { AdminUserRouter } from './user';
import { TransferGetAll } from './TransferGetAll';
import { MediaGetAll } from './MediaGetAll';
import { CouponGetAll } from './CouponGetAll';
import { AnalyticsGetAll } from './AnalyticsGetAll';

import { AdminConversation, AdminChatList } from './chat';

import PageNotFound from 'views/web/PageNotFound';
import { ChatLayout, MainLayout } from 'layouts/web';

export const AdminRouter = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={`users/*`} element={<AdminUserRouter />} />

        <Route path={`analytics`} element={<AnalyticsGetAll />} />

        <Route path={`media`} element={<MediaGetAll />} />

        <Route path={`payouts`} element={<PayoutGetAll />} />

        <Route path={`transfers`} element={<TransferGetAll />} />

        <Route path={`coupons`} element={<CouponGetAll />} />

        <Route index element={<Navigate to={`users/customers`} replace />} />

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path={`chat`} element={<ChatLayout />}>
        <Route path="" element={<AdminChatList />}>
          <Route path=":chatId" element={<AdminConversation />} />
        </Route>
      </Route>
    </Routes>
  );
};
