import { XCircleIcon } from '@heroicons/react/outline';
import { AxiosError } from 'axios';

interface AlertErrorProps {
  message?: string;
  error?: AxiosError<string, any> | null;
}

/**
 * @param message string
 * @param error AxiosError | null
 */
export const AlertError = (props: AlertErrorProps) => {
  const { error, message } = props;

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-normal text-red-800">
            {message ? message : error?.response ? error.response.data : error?.message}
          </p>
        </div>
      </div>
    </div>
  );
};
