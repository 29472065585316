// 3p
import { Link } from 'react-router-dom';

// app
import { ICreator } from 'interfaces';
import { useGetAllCreator } from 'api/creator';

import {
  Loader,
  AlertError,
  UserImage,
  BasicEmptyState,
  BadgeOnline,
  BadgeVerification,
} from 'components/common';

interface ISearchQuery {
  gender?: number | undefined;
  categories?: number[] | undefined;
  tags?: number[] | undefined;
}

interface ICreatorsGridProps {
  searchQuery: ISearchQuery;
}

const DEFAULT_LIMIT = 20;

function CreatorsGridSection(props: ICreatorsGridProps): JSX.Element {
  const { searchQuery } = props;
  const { gender, categories, tags } = searchQuery;

  // Creators Data
  const { getAllCreatorQuery } = useGetAllCreator(
    { online: true, gender, categories, tags },
    { limit: DEFAULT_LIMIT, order: 'DESC' }
  );

  const {
    isError,
    error,
    isLoading,
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = getAllCreatorQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderCreatorItem = (creator: ICreator, isOnline: boolean) => {
    return (
      <li key={creator.username}>
        <div className="space-y-3">
          <Link to={`/${creator.username}`}>
            <div className="transition cursor-pointer rounded-md duration-500 ease-in-out hover:bg-pink-600 hover:-translate-y-1 hover:scale-110">
              <UserImage
                className="object-cover shadow-md rounded-md aspect-1"
                params={{ width: '350', quality: '90', aspect_ratio: '1:1' }}
                image={creator.image}
                alt={creator.username}
              />
            </div>
          </Link>

          <div className="flex justify-center items-center space-x-1 -space-y-1">
            <BadgeOnline isOnline={isOnline} />
            <Link
              className="truncate text-md sm:text-xl font-semibold leading-normal text-grey-700"
              to={`/${creator.username}`}
            >
              {creator.username}
            </Link>
            <BadgeVerification />
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className="py-12 lg:py-2 space-y-6 sm:px-6">
      {data?.pages[0].data ? (
        <ul
          id="creators"
          className="grid grid-cols-2 gap-x-3 gap-y-6 space-y-0 lg:grid-cols-3 lg:gap-x-8"
        >
          {data.pages.map((group, i) =>
            group.data.map(({ user, isOnline }) => renderCreatorItem(user, isOnline))
          )}
        </ul>
      ) : isLoading ? (
        renderLoading()
      ) : isError ? (
        renderError()
      ) : (
        <BasicEmptyState title="Non sono stati trovati creators" />
      )}
      <div className="flex-1 flex justify-between sm:justify-center">
        {isLoading || isFetchingNextPage ? (
          renderLoading()
        ) : hasNextPage ? (
          <button
            onClick={() => fetchNextPage()}
            type="button"
            className="ml-3 text-white relative inline-flex items-center px-4 py-2 border bg-pink-600 text-sm font-medium rounded-md hover:bg-pink-700"
          >
            Mostra altri
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default CreatorsGridSection;
