// std
import { Fragment, FunctionComponent, useMemo } from 'react';

// 3p
import { Outlet, useLocation } from 'react-router-dom';

// app
import Header from 'layouts/Header';
import { AppFooterLayout } from 'layouts/app';

interface IMainLayoutProps {
  hideFooter?: boolean;
}

const MainLayout: FunctionComponent<IMainLayoutProps> = (props) => {
  const { hideFooter } = props;

  const { pathname } = useLocation();

  const isChatView = useMemo(() => {
    if (hideFooter) return true;

    if (pathname.startsWith('/app/chat')) return true;

    if (pathname.startsWith('/admin/chat')) return true;

    return false;
  }, [hideFooter, pathname]);

  const mainLayoutStyle = `${
    isChatView ? '' : 'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6'
  }`;

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <main className="flex-grow">
        <div className={mainLayoutStyle}>
          <Outlet />
        </div>
      </main>
      {!isChatView ? <AppFooterLayout /> : <Fragment />}
    </div>
  );
};

MainLayout.defaultProps = {
  hideFooter: false,
};

export { MainLayout };
