// std
import { useState } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_TRANSFER } from 'config/Enviroment';
import { IRestClientGetResponse, ITransfer } from 'interfaces';

import APIClient from '../ApiClient';

const getAllTransfer = async (skip: number, take: number) => {
  const { data } = await APIClient.get<IRestClientGetResponse<ITransfer>>(
    ENV_TRANSFER.GET_ALL,
    {
      params: { skip, take },
    }
  );

  return data;
};

export function useGetAllTransfer(skip: number, take: number) {
  const [currSkip, setCurrSkip] = useState<number>(skip);
  const [currTake, setCurrTake] = useState<number>(take);

  const getAllTransferQuery = useQuery<
    IRestClientGetResponse<ITransfer>,
    AxiosError<string, any>
  >([ENV_TRANSFER.GET_ALL, currSkip, currTake], () => getAllTransfer(currSkip, currTake));

  return {
    setCurrSkip,
    setCurrTake,
    getAllTransferQuery,
  };
}
