import { useState } from 'react';

// 3p
import { useParams } from 'react-router-dom';

// app
import { IMedia, IModal } from 'interfaces';
import { useToast } from 'hooks';

import { MediaBar } from 'components/common/media';
import { MediaGrid } from 'components/common/media/MediaGrid';
import { AlertError, AlertInfo } from 'components/common/alert';
import { MediaUploadModal } from 'components/creator/album';
import Loader from 'components/common/Loader';

import { useGetAlbum } from 'api/album';

export function CreatorAlbumGetOne() {
  const [mediaFormModal, setMediaFormModal] = useState<IModal<IMedia>>({
    open: false,
  });

  const { albumId } = useParams<{ albumId: string }>() as { albumId: string };

  const { toastError, toastSuccess } = useToast();

  const { getAlbumQuery } = useGetAlbum(albumId);
  const { isLoading, isError, data: album } = getAlbumQuery;

  /** ON SUCCESS */
  const onSuccessCreate = () => {
    setMediaFormModal({ open: false });
    toastSuccess({
      title: 'Operazione completata',
      message: 'Media caricati correttamente',
    });
  };

  /** MEDIA MODAL DELETE */
  const mediaFormOpen = (media?: IMedia, isCover?: boolean) => {
    setMediaFormModal({ open: true, isCover });
  };

  const mediaFormClose = () => {
    setMediaFormModal({ open: false });
  };

  const onErrorUploadModal = () => {
    toastError({
      title: 'Errore generico',
      message: 'Contattare il supporto',
    });
  };

  if (isLoading) return <Loader />;

  if (isError) return <AlertError message="Errore impossibile caricare l'album" />;

  if (!album) return <AlertError message="Album non trovato" />;

  return (
    <>
      <MediaUploadModal
        {...mediaFormModal}
        albumId={albumId}
        onClose={mediaFormClose}
        onSuccess={onSuccessCreate}
        onError={onErrorUploadModal}
      />
      <MediaBar
        onUploadMedia={mediaFormOpen}
        onUploadCover={() => mediaFormOpen(undefined, true)}
        album={album}
      />
      <br />
      <AlertInfo message="La copertina è la FOTO che verrà visualizzata nel tuo profilo nel “Box” Album." />
      <br />
      <AlertInfo
        message="L’Anteprima può essere una foto ma anche un video, è il Media che i clienti
        possono visualizzare prima di acquistare un contenuto. Se all’interno dell’album
        hai ad esempio un video potresti caricare come anteprima un video trailer che fa
        capire al cliente cosa c’è all’interno dell album."
      />
      <div className="px-1 py-5 sm:p-6">
        <MediaGrid
          album={album}
          hasDelete={true}
          hasSetPreview={true}
          hasBadgeApprovation={true}
        />
      </div>
    </>
  );
}
