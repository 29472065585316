// std
import { useState } from 'react';

// 3p
import { AxiosError } from 'axios';

// app
import { ProductList } from 'components/common/product';
import { ProductBuyModal } from 'components/common/payment';

import { IModal, IProduct } from 'interfaces';
import { useToast } from 'hooks';

interface IProfileTabGetAllProductProps {
  username: string;
}

export const ProfileTabGetAllProduct = (props: IProfileTabGetAllProductProps) => {
  const { username } = props;

  const [productBuyModal, setProductBuyModal] = useState<IModal<IProduct>>({
    open: false,
  });

  const { toastError } = useToast();

  const onSuccessCreatePayment = () => {
    setProductBuyModal({ open: false });
  };

  const productBuyOpen = (product?: IProduct) => {
    setProductBuyModal({ open: true, data: product });
  };

  const productBuyClose = () => {
    setProductBuyModal({ open: false });
  };

  const onErrorPayment = (error: AxiosError<string, any>) => {
    toastError({
      title: "Impossibile completare l'acquisto",
      message: error.response?.data,
    });
  };

  return (
    <>
      <ProductBuyModal
        {...productBuyModal}
        setOpen={setProductBuyModal}
        onClose={productBuyClose}
        onSuccess={onSuccessCreatePayment}
        onError={onErrorPayment}
      />
      <ProductList username={username} onBuy={productBuyOpen} />
    </>
  );
};
