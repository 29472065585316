import { useMemo } from 'react';

// app
import { formatMoney } from 'utils';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';

import { useGetAdminAnalyticsFee } from 'api/admin/analytics';

interface IAnalyticsFeeProp {
  startAt: string;
  endAt: string;
}

export const AnalyticsFee = (props: IAnalyticsFeeProp) => {
  const { startAt, endAt } = props;

  const { getAdminAnalyticsFeeQuery } = useGetAdminAnalyticsFee(startAt, endAt);

  const { data, isError, isLoading, error } = getAdminAnalyticsFeeQuery;

  const transferCard = useMemo(() => {
    if (!data) return null;

    const { payout, transfer } = data;

    const countTransfer = transfer.count_transfer;
    const totalTransferVal = formatMoney(transfer.total_amount);
    const totalTransferCommission = formatMoney(transfer.total_commission);

    const countPayout = payout.count_payout;
    const totalPayoutVal = formatMoney(payout.total_amount);
    const totalPayoutCommission = formatMoney(payout.total_commission);

    const totalTransferService = formatMoney(transfer.total_service);
    const totalPayoutService = formatMoney(payout.total_service);

    let totalService = 0;

    if (payout.total_service) {
      totalService += Number(payout.total_service);
    }

    if (transfer.total_service) {
      totalService += Number(transfer.total_service);
    }

    const totalServiceVal = formatMoney(totalService);

    return [
      { name: 'Numero trasferimenti', stat: countTransfer },
      { name: 'Totale trasferimenti', stat: totalTransferVal },
      {
        name: 'Commissioni trasferimenti',
        stat: totalTransferCommission,
      },
      { name: 'Numero payout', stat: countPayout },
      { name: 'Totale payout', stat: totalPayoutVal },
      { name: 'Commissioni payout', stat: totalPayoutCommission },
      {
        name: 'Servizio trasferimenti (2.5%)',
        stat: totalTransferService,
      },
      { name: 'Servizio payout (2.5%)', stat: totalPayoutService },
      {
        name: 'Totale servizi',
        stat: totalServiceVal,
      },
    ];
  }, [data]);

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => {
    return (
      <>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {transferCard?.map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
      </>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
