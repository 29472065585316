import { useState } from 'react';

// 3p
import axios, { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

// app
import styles from 'styles';
import { IAccountRecoveryForm } from 'interfaces';
import { useAuth } from 'hooks';
import HttpStatusCode from 'utils/HttpStatusCode';

import { AlertError, AlertSuccess } from '../alert';
import Loader from '../Loader';

export const AccountRecoveryForm = () => {
  const [error, setError] = useState<string>();

  const { recovery, isLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm<IAccountRecoveryForm>();

  const onSubmit: SubmitHandler<IAccountRecoveryForm> = async ({ email }) => {
    try {
      await recovery(email);
    } catch (ex) {
      const error = ex as Error | AxiosError<string>;

      if (axios.isAxiosError(error)) {
        if (error.response?.status === HttpStatusCode.NOT_FOUND) {
          setError('Email non valida');
        } else {
          const errorMsg = error.response?.data as string;
          setError(errorMsg);
        }
      }

      throw error;
    }
  };

  if (isLoading) return <Loader />;

  if (isSubmitSuccessful)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertSuccess message="Abbiamo inviato un link per impostare una nuova password al tuo indizzo di posta elettornica. Controlla nella cartella posta in arrivo e spam. " />
      </div>
    );

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {error && <AlertError message={error} />}
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="email" className={styles.input.label}>
              Indirizzo email
            </label>
            <div className="mt-1">
              <input
                id="email"
                type="email"
                autoComplete="email"
                {...register('email', { required: true })}
                className={styles.input.base}
              />
            </div>
          </div>

          <div>
            <button type="submit" className={styles.button.full}>
              Continua
            </button>
          </div>
        </form>
        <div className="flex items-center justify-between mt-6">
          <div className="text-sm m-auto">
            <Link to="/login" className="font-medium text-pink-600 hover:text-pink-500">
              Torna alla pagina di accesso
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
