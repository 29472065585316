// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IResponseWithCursor, IUser } from 'interfaces';

import APIClient from '../../ApiClient';

interface ICouponCustomer {
  couponId: number;
  userId: number;
  user: Partial<IUser>;
}

const getAllCouponCustomer = async (couponId: string | number) => {
  const { data } = await APIClient.get<IResponseWithCursor<ICouponCustomer>>(
    ENV_ADMIN.GET_ALL_COUPON_CUSTOMER(couponId),
    {
      params: {
        limit: 100,
      },
    }
  );
  return data;
};

export function useGetAllCouponCustomer(couponId: string | number) {
  const getAllCouponCustomerQuery = useQuery<
    IResponseWithCursor<ICouponCustomer>,
    AxiosError<string, any>
  >([ENV_ADMIN.GET_ALL_COUPON_CUSTOMER, couponId], () => getAllCouponCustomer(couponId), {
    enabled: couponId !== undefined,
  });

  return {
    getAllCouponCustomerQuery,
  };
}
