import { ExclamationCircleIcon, PlayIcon } from '@heroicons/react/solid';
import Loader from 'components/common/Loader';
import { CDN_ENDPOINT, FileStatusEnum } from 'config';
import { memo } from 'react';

interface MessageVideoProps {
  onVideoClick: (id: string, url: string) => void;
  video:
    | {
        status: string;
        id: string;
        url: null;
        thumbnail: string;
        bunnyId: null;
        oldUrl: string;
      }
    | {
        status: string;
        id: string;
        url: string;
        thumbnail: string;
        bunnyId: string;
        oldUrl: null;
      };
}

function MessageVideoComponent(props: MessageVideoProps) {
  const { video, onVideoClick } = props;
  const { status, id, url, oldUrl, thumbnail, bunnyId } = video;

  const baseClasses =
    'w-64 h-40 mb-1 rounded-lg flex-shrink-0 max-w-xs lg:max-w-md bg-cover bg-center';

  if (status === FileStatusEnum.AVAILABLE || status === FileStatusEnum.UPLOADED) {
    const videoUrl =
      bunnyId === null
        ? oldUrl
        : status === FileStatusEnum.UPLOADED
        ? `${CDN_ENDPOINT.CHAT}/${bunnyId}/original`
        : url;

    // Old video doesn't have the thumbnail
    return (
      <div
        className={`${baseClasses} cursor-pointer`}
        onClick={() => onVideoClick(id, videoUrl)}
        style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : {}}
      >
        {thumbnail ? (
          <div className="flex justify-center items-center w-full h-full text-white text-sm bg-opacity-75">
            <PlayIcon className="h-16 opacity-80" />
          </div>
        ) : (
          <div className={`${baseClasses} flex justify-center items-center bg-slate-200`}>
            <PlayIcon className="h-16 opacity-80" />
          </div>
        )}
      </div>
    );
  }

  if (status === FileStatusEnum.PROCESSING) {
    return (
      <div
        className={`${baseClasses} flex justify-center items-center bg-slate-200/50 backdrop-blur-md`}
        style={{ backgroundImage: `url(${thumbnail})` }}
      >
        <Loader />
      </div>
    );
  }

  if (status === FileStatusEnum.ERROR) {
    return (
      <div className={`${baseClasses} flex justify-center items-center bg-slate-200`}>
        <ExclamationCircleIcon className="h-16 text-red-500" />
      </div>
    );
  }

  return null;
}

export const MessageVideo = memo(MessageVideoComponent);
