// std
import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

// app
import { ENV_USER } from 'config/Enviroment';
import { IResponseWithCursor, IAlbum } from 'interfaces';

import APIClient from '../ApiClient';

const getAllUserAlbum = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IAlbum>>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllUserAlbum(username: string, searchQuery: any, pagingQuery: any) {
  const url = useMemo(() => ENV_USER.GET_ALL_ALBUM(username), [username]);

  const getAllUserAlbumQuery = useInfiniteQuery<
    IResponseWithCursor<IAlbum>,
    AxiosError<string, any>
  >([url, searchQuery, pagingQuery], getAllUserAlbum, {
    getNextPageParam: (lastPage) => lastPage.cursor.afterCursor,
  });

  return {
    getAllUserAlbumQuery,
  };
}
