export const IMAGE_EXT = ['image/jpeg', 'image/jpg'];

export const CHAT_IMAGE_EXT = ['image/jpeg', 'image/jpg', 'image/png'];


export const VIDEO_EXT = [
  'video/mp4',
  'video/x-msvideo',
  'video/quicktime',
  'video/mpeg',
  'video/webm',
];

export const CHAT_ACCEPTED_EXT = [...CHAT_IMAGE_EXT, ...VIDEO_EXT];

export const ACCEPTED_EXT = [...IMAGE_EXT, ...VIDEO_EXT];

export const UPLOAD_MAX_SIZE_MB = 5000; // 5Gb
