import React, { useCallback, useRef } from 'react';

// app
import { CHAT_ACCEPTED_EXT, UPLOAD_MAX_SIZE_MB } from 'config';
import { fromByteToMb, fromMbToGb } from 'utils';
import { v4 as uuidv4 } from 'uuid';

interface FileUploadProps {
  onSuccess: (fileName: string, file: File) => Promise<void>;
}

const UPLOAD_ERROR_MSG = {
  INVALID_EXT: (name: string, valid: string) =>
    `L'estensione del file ${name} non è supportata, le estensioni supportate sono ${valid}`,
  MAX_SIZE: (fileSize: string, maxFileSize: string) =>
    `La dimensione massima consentita per l'upload è di ${maxFileSize}GB, file selezionato è di ${fileSize}GB.`,
};

export const ChatAttachmentMedia: React.FC<FileUploadProps> = ({ onSuccess }) => {
  const mediaPhotoRef = useRef<HTMLInputElement>(null);
  const mediaVideoRef = useRef<HTMLInputElement>(null);
  const mediaGalleryRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const file = event.target.files[0];

      if (!file) return;

      // Controllo se è un estensione accettata
      if (!CHAT_ACCEPTED_EXT.includes(file.type)) {
        const valid = CHAT_ACCEPTED_EXT.join(',');
        alert(UPLOAD_ERROR_MSG.INVALID_EXT(file.name, valid));
        return;
      }

      // Controllo se la dimensione è valida
      const fileSizeMb = fromByteToMb(file.size);

      if (fileSizeMb > UPLOAD_MAX_SIZE_MB) {
        const fileSizeGBMax = fromMbToGb(UPLOAD_MAX_SIZE_MB).toFixed(2);
        const fileSizeGB = fromMbToGb(fileSizeMb).toFixed(2);
        alert(UPLOAD_ERROR_MSG.MAX_SIZE(fileSizeGB, fileSizeGBMax));
        return;
      }

      const fileName = uuidv4();

      if (onSuccess) {
        await onSuccess(fileName, file);
      }

      // Resetta il valore dell'input dopo l'invio del file
      if (mediaPhotoRef.current) mediaPhotoRef.current.value = '';
      if (mediaVideoRef.current) mediaVideoRef.current.value = '';
      if (mediaGalleryRef.current) mediaGalleryRef.current.value = '';
    },
    [onSuccess]
  );

  return (
    <>
      <label
        htmlFor="media-photo"
        className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
          />
        </svg>

        <input
          ref={mediaPhotoRef}
          id="media-photo"
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          capture
          onChange={handleFileChange}
        />
      </label>

      <label
        htmlFor="media-video"
        className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
        <input
          ref={mediaVideoRef}
          id="media-video"
          style={{ display: 'none' }}
          type="file"
          accept="video/*"
          capture
          onChange={handleFileChange}
        />
      </label>

      <label
        htmlFor="media-gallery"
        className="flex flex-shrink-0 focus:outline-none mx-2 block text-pink-600 hover:text-pink-700 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>

        <input
          ref={mediaGalleryRef}
          id="media-gallery"
          style={{ display: 'none' }}
          type="file"
          accept="image/*,video/*"
          onChange={handleFileChange}
        />
      </label>
    </>
  );
};
