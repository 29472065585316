// std
import { Fragment, useEffect, useState } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';

import * as CONFIG from 'config/Config';

export default function AdultConsentModal() {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);

    // set localstorage
    localStorage.setItem(
      CONFIG.LOCAL_STORAGE_KEY.ADULT_CONSENT_MODAL,
      new Date().toISOString()
    );
  }

  useEffect(() => {
    // Check if the use has already accepted
    const hasAccepted = localStorage.getItem(
      CONFIG.LOCAL_STORAGE_KEY.ADULT_CONSENT_MODAL
    );

    if (!hasAccepted) {
      setIsOpen(true);
    }
  }, []);

  return (
    <>
      <Transition.Root appear show={isOpen} as={Fragment}>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 bg-gray-900 bg-opacity-90 backdrop-blur-md transition-opacity"
            aria-hidden="true"
          />

          {/* Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-2xl">
              <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                Vieniora.com è una piattaforma social di intrattenimento per maggiorenni.
              </Dialog.Title>

              <div className="mt-2">
                <p className="mt-4 text-sm text-gray-500 leading-8">
                  QUESTO SITO CONTIENE MATERIALE SESSUALMENTE ESPLICITO.
                </p>
                <p className="mt-2 prose prose-pink prose-sm text-gray-500 mx-auto">
                  Devi avere almeno diciotto (18) anni di età per far uso di questo sito,
                  a meno che nella tua giurisdizione nazionale la maggiore età non sia
                  oltre i diciotto (18) anni di età, nel qual caso devi avere almeno la
                  maggiore età richiesta dalla tua giurisdizione nazionale. L'uso di
                  questo sito non è permesso ove sia proibito dalla legge.
                </p>
                <p className="mt-4 prose prose-pink prose-sm text-gray-500 mx-auto">
                  Questo sito richiede anche l'uso di cookies. Puoi trovare ulteriori
                  informazioni sui nostri cookies nella nostra Informativa sulla Privacy.
                </p>
                <p className="mt-4 prose prose-pink prose-sm text-gray-500 mx-auto">
                  Tutte le modelle avevano 18 anni o più al momento delle creazione di
                  queste raffigurazioni.
                </p>
              </div>

              <div className="mt-4 text-center">
                <button
                  type="button"
                  className="inline-block margin-auto items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  onClick={closeModal}
                >
                  Ho più di 18 anni
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
