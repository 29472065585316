// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { PaymentInTypeEnum, ENV_PAYIN } from 'config';

import { IPaymentSource } from 'interfaces';

import APIClient from '../ApiClient';

interface IPayInSourceParams {
  type?: PaymentInTypeEnum;
  amount?: number;
}

const getPayInSource = async (params?: IPayInSourceParams) => {
  const { data } = await APIClient.get<IPaymentSource[]>(ENV_PAYIN.GET_ALL_SOURCE, {
    params,
  });

  return data;
};

export function useGetAllPayInSource(params?: IPayInSourceParams) {
  const getAllPayInSourceQuery = useQuery<IPaymentSource[], AxiosError<string, any>>(
    [ENV_PAYIN.GET_ALL_SOURCE, params],
    () => getPayInSource(params)
  );

  return { getAllPayInSourceQuery };
}
