// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_PAYOUT, ENV_TRANSFER } from 'config/Enviroment';
import { IRefundCreateForm } from 'interfaces';

import APIClient from '../ApiClient';

const createRefund = async (transferId: number, body: IRefundCreateForm) => {
  const { data } = await APIClient.post<IRefundCreateForm>(
    ENV_TRANSFER.CREATE_REFUND(transferId),
    body
  );
  return data;
};

export function useCreateRefund() {
  const queryClient = useQueryClient();

  const createRefundMutation = useMutation<IRefundCreateForm, AxiosError, any>(
    ([transferId, data]) => createRefund(transferId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_PAYOUT.GET_ALL_PAYOUT);
      },
    }
  );

  return {
    createRefundMutation,
  };
}
