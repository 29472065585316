// 3p
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

// app
import { ENV_USER } from 'config/Enviroment';
import { IUserEmail } from 'interfaces';

import APIClient from '../ApiClient';

const updateUserEmail = async (newEmail: IUserEmail) => {
  const { data } = await APIClient.put<IUserEmail>(ENV_USER.UPDATE_EMAIL, newEmail);
  return data;
};

export function useUpdateUserEmail() {
  const updateUserEmailMutation = useMutation<IUserEmail, AxiosError, IUserEmail>(
    (data) => updateUserEmail(data)
    //TODO: onSuccess with send verification email
  );

  return {
    updateUserEmailMutation,
  };
}
