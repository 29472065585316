import {
  add,
  differenceInYears,
  format,
  isAfter,
  isBefore,
  parse,
  parseISO,
  intervalToDuration,
  formatDuration,
  formatDistanceToNowStrict,
} from 'date-fns';

import { it } from 'date-fns/locale';

// app
import { IChatThread, IChatThreadCache, IMenuItem } from 'interfaces';
import { DEFAULT_DATA_FORMAT, ROLES } from 'config';

export { PrivateRoute } from './PrivateRoute';

export * from './Media';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const customFormatDuration = (end: Date) => {
  const durations = intervalToDuration({ start: new Date(), end });
  return formatDuration(durations);
};

export function formatDateDistance(date: string): { distance: string; seconds: number } {
  const dateObj = new Date(date);
  const now = new Date();
  const seconds = (now.getTime() - dateObj.getTime()) / 1000;

  const distance = formatDistanceToNowStrict(dateObj, { locale: it });
  //return `${distance} fa`;

  return { seconds, distance };
}

export function formatDate(currentDateStr: string | null, locale = 'it-IT') {
  if (!currentDateStr) return;

  return format(parseISO(currentDateStr), DEFAULT_DATA_FORMAT);

  // const formatter = new Intl.DateTimeFormat(locale);
  // return formatter.format(currentDate);

  // const currentDayOfMonth = currentDate.getDate();
  // const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
  // const currentYear = currentDate.getFullYear();
  // return currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear;
}

export function isCashAvailable(date: string) {
  return isAfter(new Date(), add(new Date(date), { days: 15 }));
}

export function isRefundExpired(date: string) {
  return isBefore(new Date(), add(new Date(date), { days: 10 }));
}

interface IFormatterMoneyOpts {
  currency?: string;
  locale?: string;
  addSign?: boolean;
}

const defaultFormatMoneyOpts: IFormatterMoneyOpts = {
  currency: 'EUR',
  locale: 'it-IT',
  addSign: false,
};

export function formatMoney(amountInCent: number, options = defaultFormatMoneyOpts) {
  if (!amountInCent) {
    amountInCent = 0;
  }
  // if (amountInCent === 0) return 'N/A';

  const { currency, locale, addSign } = options;

  var formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const amountFormatted = formatter.format(amountInCent / 100);

  return amountInCent > 0 && addSign ? '+' + amountFormatted : amountFormatted;
}

export function convertFromCent(amount: number) {
  return amount / 100;
}

export function convertToCent(amount: number) {
  return amount * 100;
}

export function filterMenuItemByRole(items: IMenuItem[], roleCode: string | undefined) {
  return items.filter((item) => {
    // L'item non ha ruoli di visibilità, quindi visibile a tutti
    // Io non sono loggato e l'item è visibile ai guest
    // Io sono loggato e il mio ruolo è tra quelli dell'item
    return !item.roles || (!roleCode && item.roles.includes(ROLES.GUEST)) || (roleCode && item.roles.includes(roleCode));
  });
}

export function calculateAge(birthday: string) {
  const date = parse(birthday, 'yyyy-MM-dd', new Date());
  const age = differenceInYears(new Date(), date);
  return age;
}

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}


export function sortBySentAt(items: IChatThread[]) {
  return items.sort((a, b) => {

    // Se hasNewMessage è lo stesso, ordina per sendAt decrescente
    return (
      new Date(b.message.sendAt).getTime() - new Date(a.message.sendAt).getTime()
    );

    /* Ordina prima per hasNewMessage
    if (a.hasNewMessage === b.hasNewMessage) {
      // Se hasNewMessage è lo stesso, ordina per sendAt decrescente
      return (
        new Date(b.message.sendAt).getTime() - new Date(a.message.sendAt).getTime()
      );
    }
    // Metti i messaggi con hasNewMessage true prima di quelli false
    return a.hasNewMessage ? -1 : 1; */
  })
}

export function findThreadInPages(uuid: string, cache: IChatThreadCache) {
  const indexes: number[] = [];

  cache.pages.forEach((cachePage, pageIndex) => {
    cachePage.data.forEach((cacheData, dataIndex) => {
      if (cacheData.uuid === uuid) {
        indexes.push(pageIndex, dataIndex);
        return;
      }
    });
    if (indexes.length) return;
  });

  return indexes;
}