// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { IPaymentOutSummary } from 'interfaces';

import { ENV_ADMIN } from 'config/Enviroment';

import APIClient from '../../ApiClient';

const getPayoutSummary = async () => {
  const { data } = await APIClient.get<IPaymentOutSummary>(ENV_ADMIN.GET_PAYOUT_SUMMARY);

  return data;
};

export function useGetPayoutSummary() {
  const getPayoutSummaryQuery = useQuery<IPaymentOutSummary, AxiosError<string, any>>(
    [ENV_ADMIN.GET_PAYOUT_SUMMARY],
    getPayoutSummary
  );

  return { getPayoutSummaryQuery };
}
