export const Privacy = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              PRIVACY POLICY
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto border-b text-justify">
          Premessa. La presente informativa sulla privacy è redatta allo scopo di dare
          evidenza all’Utente circa la natura dei dati che vengono raccolti e conservati,
          nelle modalità e alle condizioni indicate nei paragrafi seguenti, nel sito
          www.vieniora.com (nel seguito anche il “Sito”), di proprietà della Osses Ltd.,
          con sede legale in 19 Leyden Street, Londra, Regno Unito, E1 7LE, (UK Company
          Registration no. 12083699; nel seguito: la «Società». La raccolta, elaborazione,
          conservazione (nel seguito “Gestione”) dei dati avviene nel rispetto della
          normativa europea sulla tutela dei dati (Regolamento UE n. 679/2016, nel seguito
          “GDPR”). La presente informativa illustra in modo completo ed esaustivo
          l’utilizzo dei dati dell’Utente. Il Sito non condivide, rivela, vende o scambia
          con parti terze i dati gestiti. Definizioni ai sensi dell’art. 4 del GDPR. «dato
          personale»: qualsiasi informazione riguardante una persona fisica identificata o
          identificabile («interessato»): la persona fisica che può essere identificata,
          direttamente o indirettamente, con particolare riferimento a un elemento
          identificativo come il nome, un numero di identificazione, dati relativi
          all’ubicazione, un identificativo online o a uno o più elementi caratteristici
          della sua identità fisica, fisiologica, genetica, psichica, economica, culturale
          o sociale; «trattamento»: qualsiasi operazione o insieme di operazioni, compiute
          con o senza l’ausilio di processi automatizzati e applicate a dati personali o
          insiemi di dati personali, come la raccolta, la registrazione, l’organizzazione,
          la strutturazione, la conservazione, l’adattamento o la modifica, l’estrazione,
          la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o
          qualsiasi altra forma di messa a disposizione, il raffronto o
          l’interconnessione, la limitazione, la cancellazione o la distruzione;
          «limitazione di trattamento»: il contrassegno dei dati personali conservati con
          l’obiettivo di limitarne il trattamento in futuro; «profilazione»: qualsiasi
          forma di trattamento automatizzato di dati personali consistente nell’utilizzo
          di tali dati personali per valutare determinati aspetti personali relativi a una
          persona fisica, in particolare per analizzare o prevedere aspetti riguardanti il
          rendimento professionale, la situazione economica, la salute, le preferenze
          personali, gli interessi, l’affidabilità, il comportamento, l’ubicazione o gli
          spostamenti di detta persona fisica; «pseudonimizzazione»: il trattamento dei
          dati personali in modo tale che i dati personali non possano più essere
          attribuiti a un interessato specifico senza l’utilizzo di informazioni
          aggiuntive, a condizione che tali informazioni aggiuntive siano conservate
          separatamente e soggette a misure tecniche e organizzative intese a garantire
          che tali dati personali non siano attribuiti a una persona fisica identificata o
          identificabile; «archivio»: qualsiasi insieme strutturato di dati personali
          accessibili secondo criteri determinati, indipendentemente dal fatto che tale
          insieme sia centralizzato, decentralizzato o ripartito in modo funzionale o
          geografico; «titolare del trattamento»: la persona fisica o giuridica,
          l’autorità pubblica, il servizio o altro organismo che, singolarmente o insieme
          ad altri, determina le finalità e i mezzi del trattamento di dati personali;
          quando le finalità e i mezzi di tale trattamento sono determinati dal diritto
          dell’Unione o degli Stati membri, il titolare del trattamento o i criteri
          specifici applicabili alla sua designazione possono essere stabiliti dal diritto
          dell’Unione o degli Stati membri; «responsabile del trattamento»: la persona
          fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che
          tratta dati personali per conto del titolare del trattamento; «destinatario»: la
          persona fisica o giuridica, l’autorità pubblica, il servizio o un altro
          organismo che riceve comunicazione di dati personali, che si tratti o meno di
          terzi. Tuttavia, le autorità pubbliche che possono ricevere comunicazione di
          dati personali nell’ambito di una specifica indagine conformemente al diritto
          dell’Unione o degli Stati membri non sono considerate destinatari; il
          trattamento di tali dati da parte di dette autorità pubbliche è conforme alle
          norme applicabili in materia di protezione dei dati secondo le finalità del
          trattamento; «terzo»: la persona fisica o giuridica, l’autorità pubblica, il
          servizio o altro organismo che non sia l’interessato, il titolare del
          trattamento, il responsabile del trattamento e le persone autorizzate al
          trattamento dei dati personali sotto l’autorità diretta del titolare o del
          responsabile; «consenso dell’interessato»: qualsiasi manifestazione di volontà
          libera, specifica, informata e inequivocabile dell’interessato, con la quale lo
          stesso manifesta il proprio assenso, mediante dichiarazione o azione positiva
          inequivocabile, che i dati personali che lo riguardano siano oggetto di
          trattamento; «violazione dei dati personali»: la violazione di sicurezza che
          comporta accidentalmente o in modo illecito la distruzione, la perdita, la
          modifica, la divulgazione non autorizzata o l’accesso ai dati personali
          trasmessi, conservati o comunque trattati. Base giuridica del trattamento dei
          dati. Ai sensi dell’art. 6 del GDPR. Il Sito è tenuto a indicare all’Utente la
          base giuridica del trattamento dei dati gestiti. Il Sito gestisce i dati
          dell’Utente sulla base (I) dell’adempimento a obblighi contrattuali nei
          confronti dell’Utente, (II) del consenso dell’Utente e (III) in ottemperanza a
          norme di legge. In particolare: Con riferimento all’adempimento di obblighi
          contrattuali, il Sito gestisce i dati forniti: all’atto dell’Iscrizione;
          nell’elaborare un pagamento; nel momento in cui l’Utente usufruisce dei Servizi
          offerti dal Sito, accettandone Termini e Condizioni e fornendo il proprio
          Consenso espresso flaggando “Ho preso visione della Privacy Policy e fornisco il
          Consenso al trattamento dei Dati Personali” e successivamente cliccando sul
          bottone “Invia”. Tale Gestione dei dati è strettamente finalizzata
          all’adempimento degli obblighi contrattuali in capo al Sito, che sorgono con
          l’Iscrizione, il pagamento ovvero l’utilizzo del Sito. I dati vengono gestiti
          per tutta la durata del contratto fra il Sito e l’Utente. Tali dati, inoltre,
          possono venir utilizzati in modo anonimo allo scopo di fornire informazioni
          sull’utenza di un particolare Servizio offerto dal Sito. In quest’ultimo caso,
          nessun riferimento ai dati in modo individuale verrà rivelato, ma i dati
          verranno utilizzati in modo collettivo per valutare la performance dei Servizi
          offerti. In ottemperanza agli obblighi di legge, il Sito conserva i dati
          relativi alle comunicazioni e videochiamate fra gli Utenti e i Fornitori per un
          periodo di 6 (sei) mesi dalla data in cui tali comunicazioni e/o videochiamate
          sono avvenute. Il Sito adotta, nella conservazione di tali dati, le misure di
          sicurezza adeguate alla protezione e riservatezza degli stessi, anche mediante
          l’uso di appositi programmi di elaborazione e archiviazione dati. Il Sito ha
          facoltà di accedere alle comunicazioni e/o videochiamate conservate, durante il
          periodo suddetto, al solo scopo di adempiere agli obblighi di vigilanza e
          garanzia delle prescrizioni di legge e delle regole a tutela degli Utenti e dei
          Fornitori indicate nei Termini e Condizioni. In particolare, il Sito si riserva
          di verificare le comunicazioni e i contenuti allo scopo di interrompere e
          perseguire qualsiasi atto offensivo, discriminatorio, lesivo dell’immagine,
          dell’onore e/o della reputazione di Fornitori, altri Utenti o soggetti terzi,
          inclusi, a titolo non esaustivo, bestialità, incesto, sadomasochismo estremo o
          sottomissione presentati in un contesto sessuale, o presentazioni di minori
          nell’atto di contatto fisico intimo o situazioni sessuali recitate o raffigurate
          o prostituzione. Il consenso dell’Utente è prestato con l’accettazione dei
          Termini e Condizioni, necessaria per l’utilizzo dei Servizi offerti dal Sito.
          Per attività che non prevedono l’accettazione dei Termini e Condizioni, come la
          mera visualizzazione del Sito, la ricerca di contenuti e/o la richiesta di
          informazioni, il consenso viene rilevato specificamente attraverso la richiesta
          di autorizzazione all’utilizzo dei Cookies. La gestione di tali dati prosegue
          fino alla revoca del consenso, ovvero alla ragionevole presunzione di cessazione
          del consenso. Il consenso può essere revocato scrivendo a support@vieniora.com.
          La revoca del consenso comporta l’impossibilità di usufruire del Sito. Il Sito
          gestisce dati la cui raccolta, elaborazione e conservazione è obbligatoria ai
          sensi di legge, come, a titolo esemplificativo e non esaustivo, le normative che
          prescrivono la maggiore età per la fruizione di contenuti sessualmente
          espliciti. Natura del conferimento: facoltativa. Informazioni relative ai
          pagamenti. Le informazioni relative ai pagamenti non vengono gestite
          direttamente dal Sito, tramite il sistema di pagamento “VEROTEL”. Soltanto
          l’Utente ha diretto accesso alle informazioni di pagamento. Le pagine dei
          pagamenti e le informazioni relative sono gestite da fornitori terzi, abilitati
          e autorizzati a raccogliere ed elaborare i dati necessari al pagamento.
          Categorie di dati personali La informiamo che il Titolare, può venire a
          conoscenza dei suoi dati comuni come nome, cognome, paese di residenza, e-mail,
          dati di pagamento (quali dati bancari/carte di credito), nonché di “dati
          personali particolari” intendendosi con tali dati i dati personali idonei a
          rivelare la vita sessuale e le abitudini ad essa connesse. Destinatari dei dati
          personali. I Suoi dati personali sono trattati da personale dipendente
          adeguatamente formato e nominato quale autorizzato e/o designato e/o incaricato
          al trattamento di dati personali. Per dare esecuzione alle finalità indicata, i
          Suoi Dati potrebbero essere trattati da soggetti nominati responsabile del
          trattamento ex. Art. 28 GDPR. Trasferimento dei dati personali all’estero. Il
          Titolare informa che i dati personali da Lei forniti non subiscono trasferimenti
          in paesi terzi, ma che qualora dovesse accadere di trasferire delle attività di
          trattamento in un paese terzo questo sarà fatto nelle modalità richieste ed
          indicate nel Capo V del Regolamento Europeo n. 679/2016. Periodo di
          conservazione. I Suoi dati personali sono conservati fino al momento della
          realizzazione dello scopo per cui sono stati raccolti e successivamente trattati
          per tutto il periodo prescritto dalle leggi vigenti, sia civilistiche sia
          fiscali. Metodi di trattamento e sicurezza. Il trattamento viene effettuato
          tramite strumenti automatizzati (ad esempio utilizzando procedure e supporti
          elettronici) e/o manualmente (ad esempio su supporto cartaceo) per il tempo
          strettamente necessario a conseguire gli scopi per i quali i dati sono stati
          raccolti e, comunque, in conformità con le leggi in materia. Questi supporti
          elettronici e cartacei sono archiviati negli uffici della Società o negli uffici
          di altre persone incaricate del trattamento dei dati con password o altri mezzi
          che garantiscono l’adeguata sicurezza di tali dati. Dati opzionali. Lei può
          scegliere di fornire o meno i Suoi dati personali. Se non forniti, ciò comporta
          solo l’impossibilità di ottenere i servizi richiesti per i quali è necessaria la
          fornitura e il trattamento dei dati personali. A titolo esemplificativo, nel
          caso di utilizzo dei Servizi di chat e videochat offerto dal Sito, il Sito
          notificherà la ricezione di nuovi messaggi all’Utente via e-mail. Accettando i
          presenti Termini e Condizioni, l’Utente accetta di ricevere delle e-mail di
          notifica dei nuovi messaggi. Inoltre, in ottemperanza agli obblighi di legge, il
          Sito conserva i dati relativi alle comunicazioni e videochiamate fra gli Utenti
          e i Fornitori per un periodo di 6 (sei) mesi dalla data in cui tali
          comunicazioni e/o videochiamate sono avvenute. Il Sito adotta, nella
          conservazione di tali dati, le misure di sicurezza adeguate alla protezione e
          riservatezza degli stessi, anche mediante l’uso di appositi programmi di
          elaborazione e archiviazione dati. Divulgazioni Utilizziamo appaltatori terzi
          che eseguono servizi per, o con, la Società ed elaborano le informazioni
          personali secondo le nostre istruzioni in relazione al Sito, ad esempio allo
          scopo di condurre ricerche, elaborare informazioni o mantenere il Sito,
          contenuti o programmi. A tali appaltatori terzi è limitato l’utilizzo dei dati
          per qualsiasi altro scopo diverso da quello di fornire servizi per o con la
          Società. La Società è responsabile di qualsiasi trattamento da parte di tali
          terze parti e farà in modo che tali appaltatori abbiano stipulato accordi
          scritti che consentono alla Società di rispettare i nostri obblighi ai sensi
          della presente Informativa sulla privacy e delle leggi applicabili. Le Sue
          informazioni personali non saranno divulgate ad altre terze parti a meno che Lei
          non abbia esplicitamente dato il Suo consenso a tale divulgazione o se è
          richiesto dalla legge. Sicurezza delle informazioni personali Adottiamo tutte le
          misure ragionevoli per proteggere le Sue informazioni personali da perdita, uso
          improprio, accesso non autorizzato, divulgazione, alterazione o distruzione
          utilizzando precauzioni di sicurezza che forniscono una protezione standard del
          settore. Tuttavia, nessuna trasmissione di dati su Internet può essere garantita
          completamente sicura. Di conseguenza, nella misura consentita dalla legge
          applicabile, non forniamo alcuna garanzia, esplicita o implicita, relativa alla
          sicurezza o all’integrità di qualsiasi informazione personale. Informazioni
          relative ai Suoi diritti Avrà in qualsiasi momento il diritto di accedere alle
          Sue informazioni personali, di farle rettificare, cancellare o trasferire ad
          altre organizzazioni su Sua richiesta. Ha anche il diritto di opporsi o limitare
          alcuni trattamenti e, laddove abbiamo chiesto il Suo consenso al trattamento
          delle Sue informazioni personali, di revocare il Suo consenso in qualsiasi
          momento. Se ritira il Suo consenso, interromperemo qualsiasi uso futuro delle
          Sue informazioni personali. Ai sensi dell’articolo 12 e seguenti del Regolamento
          UE 2016/679, le persone interessate possono esercitare alcuni diritti in
          relazione al trattamento dei propri dati personali. In particolare, Lei può
          contattarci in qualsiasi momento per avere informazioni in merito al tipo di
          dati personali che La riguardano che trattiamo, per correggere informazioni
          inaccurate o imprecise, per accedere all’elenco dei responsabili del trattamento
          e relative sedi di trattamento dei dati attualmente utilizzate dalla Società,
          per presentare ulteriori richieste relative alle modalità di trattamento dei
          dati personali, per opporsi a specifici trattamenti di dati o richiederne
          l’interruzione o per richiedere la limitazione del trattamento dei Suoi dati, la
          loro portabilità nei confronti di terzi, o revocare il Suo consenso esplicito.
          La revoca del consenso prestato potrà essere effettuata con le medesime modalità
          con cui lo stesso è stato inizialmente concesso e senza aggravi o limitazioni di
          alcun genere nei suoi confronti. Lei ha inoltre diritto alla cancellazione dei
          dati che la riguardano senza ingiustificato ritardo nei seguenti casi: a) i dati
          personali non sono più necessari rispetto alle finalità per le quali sono stati
          raccolti o altrimenti trattati; b) Lei ha revocato il consenso al trattamento;
          c) Lei si è opposto al trattamento dei dati personali e non sussiste alcun
          motivo legittimo prevalente per procedere al trattamento; d) i dati personali
          sono stati trattati illecitamente; e) i dati personali devono essere cancellati
          per adempiere un obbligo legale. Faremo fronte alla Sua richiesta in conformità
          alle leggi applicabili e con modalità in linea con l’impegno della Società per
          la privacy. Può contattarci attraverso i seguenti riferimenti: Osses Ltd., con
          sede legale in 19 Leyden Street, Londra, Regno Unito, E1 7LE Email:
          support@vieniora.com Per qualunque Sua richiesta inerente al trattamento dei
          suoi dati personali il Titolare del Trattamento fornirà una risposta il più
          velocemente possibile e, comunque, al più tardi entro un mese dal ricevimento
          della richiesta stessa. In caso di commenti o reclami sul modo in cui vengono
          trattati i Suoi dati personali, ha il diritto di contattare L’Autorità di
          protezione dei dati personali Irlandese: Data Protection Commission (“DPC”): –
          Tramite posta: 21 Fitzwilliam Square South Dublin 2 D02 RD28 Ireland – Tramite
          webform o accesso al Sito internet della DPC:
          https://www.dataprotection.ie/en/contact/how-contact-us Collegamenti ad altri
          siti Questo Sito contiene link a una serie di siti che riteniamo possano offrire
          informazioni utili per i visitatori del nostro Sito. La presente Informativa
          sulla privacy e gli obblighi ad essa relativi non si applicano a tali siti Web
          (a meno che l’altro sito web non sia di proprietà della Società e non si
          colleghi direttamente a questa Informativa). Suggeriamo di contattare
          direttamente tali siti web per informazioni sulle loro politiche in materia di
          privacy, sicurezza, dati e raccolta. La Società non può essere ritenuta
          responsabile per eventuali azioni o contenuti forniti da tali siti web di terze
          parti. Contatti Accogliamo con favore qualsiasi domanda, richiesta, commento e
          reclamo che potresti avere in merito alla presente Informativa sulla privacy.
          Non esiti a contattarci scrivendo una e-mail a support@vieniora.com. Modifiche
          alla presente Informativa sulla privacy Potremmo aggiornare questa Informativa
          sulla privacy del Sito di volta in volta. In caso di modifiche sostanziali
          all’Informativa sulla privacy (ovvero eventuali modifiche che influiscono
          materialmente sui tuoi diritti), verrà pubblicato un avviso temporaneo sul Sito.
          Informazioni relative all’uso dei Cookie Il Sito ricorre all’utilizzo di Cookie
          di prime parti allo scopo di migliorare la velocità e la personalizzazione dei
          Servizi. I Cookie sono utilizzati nel rispetto della privacy dell’Utente.
          L’utilizzo dei Cookie è assoggettato al consenso dell’Utente, espressamente
          richiesto nel momento dell’accesso al Sito.
        </div>
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              PRIVACY POLICY
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-justify">
          Foreword. This privacy policy is drafted in order to give evidence to the User
          about the nature of the data that are collected and stored, in the manner and
          under the conditions indicated in the following paragraphs, in the site
          www.vieniora.com (hereinafter also the “Site”), owned by Osses Ltd., with
          registered office in 19 Leyden Street, London, United Kingdom, E1 7LE, (UK
          Company Registration no. 12083699). The collection, processing, storage
          (hereinafter referred to as “Management”) of the data takes place in compliance
          with European data protection legislation (EU Regulation No. 679/2016,
          hereinafter referred to as “GDPR”). This policy explains in a complete and
          exhaustive way the use of the User’s data. www.vieniora.com does not share,
          disclose, sell or exchange with third parties the managed data. 2. Definitions
          under Article 4 of the GDPR “personal data”: any information relating to an
          identified or identifiable natural person (“data subject”); an identifiable
          person is one who can be identified, directly or indirectly, with particular
          reference to an identifier such as a name, an identification number, location
          data, an online identifier or to one or more features of his/her physical,
          physiological, genetic, mental, economic, cultural or social identity;
          “processing” means any operation or set of operations, whether or not by
          automated means, applied to personal data or sets of personal data, such as
          collection, recording, organization, structuring, storage, adaptation or
          alteration, retrieval, consultation, use, disclosure by transmission,
          dissemination or otherwise making available, comparison or interconnection,
          restriction, erasure or destruction; “restriction of processing” means the
          marking of personal data stored with the aim of limiting its processing in the
          future; “profiling” means any form of automated processing of personal data
          consisting of the use of such personal data to evaluate certain personal aspects
          relating to a natural person, in particular to analyse or predict aspects of
          that natural person’s professional performance, economic situation, health,
          personal preferences, interests, reliability, behaviour, location or movements;
          “pseudonymization” means the processing of personal data in such a way that the
          personal data can no longer be attributed to a specific data subject without the
          use of additional information, provided that such additional information is
          stored separately and subject to technical and organizational measures designed
          to ensure that such personal data is not attributed to an identified or
          identifiable natural person; “repository” means any structured set of personal
          data that is accessible according to specified criteria, regardless of whether
          such set is centralized, decentralized, or functionally or geographically
          distributed; “controller” means the natural or legal person, public authority,
          agency or other body which alone or jointly with others determines the purposes
          and means of the processing of personal data; where the purposes and means of
          such processing are determined by Union or Member State law, the controller or
          the specific criteria applicable to its designation may be established by Union
          or Member State law “controller” means the natural or legal person, public
          authority, department or other body that processes personal data on behalf of
          the controller; “recipient” means the natural or legal person, public authority,
          service or other body that receives communication of personal data, whether or
          not it is a third party. However, public authorities that may receive
          communication of personal data in the context of a specific investigation in
          accordance with Union or Member State law shall not be considered recipients;
          the processing of such data by such public authorities shall be in accordance
          with the applicable data protection rules according to the purposes of the
          processing; “third party” means the natural or legal person, public authority,
          service or other body other than the data subject, the data controller, the data
          processor and persons authorized to process personal data under the direct
          authority of the data controller or processor; “consent of the data subject”
          means any free, specific, informed and unambiguous manifestation of will by
          which the data subject indicates his or her assent, by way of an unambiguous
          statement or affirmative action, that personal data concerning him or her be
          processed; “personal data breach”: a breach of security leading accidentally or
          unlawfully to the destruction, loss, modification, unauthorized disclosure of or
          access to personal data transmitted, stored or otherwise processed. 3. Legal
          basis of data processing Pursuant to Article 6 of the GDPR. The Site is required
          to indicate to the User the legal basis of the data processing managed. The Site
          handles the User’s data on the basis of (I) the fulfillment of contractual
          obligations to the User, (II) the User’s consent, and (III) in compliance with
          legal requirements. In particular: With respect to the fulfillment of
          contractual obligations, the Site handles the data provided: at the time of
          Registration; when processing a payment; when the user uses the services offered
          by the Site, accepting the Terms and Conditions and providing their consent by
          ticking “I have read the Privacy Policy and I consent to the processing of
          personal data” and then clicking on the “Submit” button. This data management is
          strictly aimed at fulfilling the contractual obligations of the Site, which
          arise with the registration, payment or use of the Site. The data is managed for
          the duration of the contract between the Site and the User. Such data may also
          be used anonymously in order to provide information about the users of a
          particular service offered by the Site. In the latter case, no reference to the
          data in an individual way will be revealed, but such data will be used in a
          collective way to evaluate the performance of the Services offered. In
          compliance with legal requirements, the Site stores the data related to the
          communications via chat and videochat with the User for a period of 6 (six)
          months from the date in which such communications occur. The Site adopts, in
          storing such data, all the necessary measures to protect the data, also by using
          specific programs for the management and storing of data. The Site has the
          authority to access the stored communications and/or videocalls during the
          6-month period, with the sole purpose of fulfilling legal provisions and rules
          aimed at protecting the User and the Suppliers, as indicated in the Terms and
          Conditions. In particular, the Site has the right to monitor the communications
          and the Contents with the purpose of interrupting and prosecute offensive acts,
          discriminatory acts, acts damaging the image, the honour or the reputation of
          Suppliers, Users or third parties, including but limited to bestiality, incest,
          extreme sadomasochism or submission presented in a sexual framework, or display
          of minors in physical contact or sexual behaviours, performed or depicted, or
          prostitution. The User’s consent is given with the acceptance of the Terms and
          Conditions, which is necessary for the use of the Services offered by the Site.
          For activities that do not require acceptance of the Terms and Conditions, such
          as merely viewing the Site, searching for content and/or requesting information,
          consent is specifically detected through the request for authorization to use
          Cookies. The management of such data continues until consent is revoked, or the
          reasonable presumption that consent has ceased. Consent may be revoked by
          writing to indicate e-mail address. Withdrawal of consent will make it
          impossible to use the Site. The Site manages data whose collection, processing
          and storage is required by law, such as, but not limited to, the regulations
          that require the age of majority for the use of sexually explicit content.
          Nature of the conferment: optional. 4. Payment Information. Information relating
          to payments is not managed directly by the Site through the VEROTEL payment
          system. Only the User has direct access to payment information. The payment
          pages and information relating to them are managed by third party providers who
          are authorised to collect and process the data necessary for payment. 5.
          Categories of personal data. We inform you that the Owner, may become aware of
          your common data such as name, surname, country of residence, email, payment
          data (such as bank data / credit cards), as well as “special personal data”
          meaning by this data the personal data revealing sexual life and habits related
          thereto. 6. Recipients of personal data. Your personal data are processed by
          adequately trained and appointed employees as authorized and / or designated and
          / or responsible for the processing of personal data. Your data, in order to
          carry out the purposes indicated may be processed by persons appointed as data
          processors ex. Art. 28 GDPR. 7. Transfer of personal data abroad. The Owner
          informs you that the personal data you provide will not be transferred to third
          countries, but that if it should happen to transfer processing activities in a
          third country this will be done in the manner required and indicated in Chapter
          V of the European Regulation no. 679/2016. 8. Period of conservation. Your
          personal data are stored until the moment of the realization of the purpose for
          which they were collected and subsequently processed for the entire period
          prescribed by the laws in force, both civil and fiscal. 9. Processing methods
          and security. The treatment is carried out through automated tools (e.g. using
          electronic procedures and media) and/or manually (e.g. on paper) for the time
          strictly necessary to achieve the purposes for which the data were collected
          and, in any case, in accordance with the relevant laws. These electronic and
          paper supports are filed in the offices of the Company or in the offices of
          other persons in charge of data processing with passwords or other means that
          guarantee the security of such data. 10. Optional data. You may choose whether
          or not to provide your personal data. If not provided, this will only result in
          the impossibility of obtaining the requested services for which the provision
          and processing of personal data is necessary. By way of example, in case of use
          of the Communication services (via chat and videochat) offered by the Site, the
          User will be notified of new incoming chat messages via e-mail. By accepting the
          Terms and Conditions, the User agrees on receiving notifications via e-mail of
          new incoming chat messages. Moreover, the data related to the communications
          will be stored for a period of 6 (six) months from the date in which such
          communications occur. The Site adopts, in storing such data, all the necessary
          measures to protect the data, also by using specific programs for the management
          and storing of data. 11. Disclosures. We use third party contractors who perform
          services for, or with, the Company and process personal information in
          accordance with our instructions in connection with the Site, for example, for
          the purpose of conducting research, processing information or maintaining the
          Site, content or programs. Such third party contractors are restricted from
          using the data for any purpose other than to provide services for or with the
          Company. The Company is responsible for any processing by such third parties and
          will ensure that such contractors have entered into written agreements that
          allow the Company to comply with our obligations under this Privacy Policy and
          applicable laws. Your personal information will not be disclosed to other third
          parties unless you have explicitly consented to such disclosure or if it is
          required by law. 12. Security of Personal Information. We take all reasonable
          steps to protect Your personal information from loss, misuse, unauthorized
          access, disclosure, alteration or destruction using security precautions that
          provide industry standard protection. However, no data transmission over the
          Internet can be guaranteed to be completely secure. Accordingly, to the extent
          permitted by applicable law, we make no warranty, express or implied, regarding
          the security or integrity of any personal information. 13. Information relating
          to your rights. You have the right at any time to access your personal
          information, to have it corrected, deleted or transferred to other organizations
          at your request. You also have the right to object to or restrict certain
          processing and, where we have asked for your consent to process your personal
          information, to withdraw your consent at any time. If you withdraw your consent,
          we will stop any future use of your personal information. Pursuant to Article 12
          et seq. of EU Regulation 2016/679, data subjects may exercise certain rights in
          relation to the processing of their personal information. In particular, You may
          contact us at any time to obtain information regarding the type of personal data
          concerning You that we process, to correct inaccurate or imprecise information,
          to access the list of data controllers and related data processing locations
          currently used by the Company, to submit further requests regarding the manner
          in which we process personal data, to object to specific data processing or
          request its interruption, or to request the restriction of the processing of
          Your data, its portability to third parties, or revoke Your explicit consent.
          The revocation of the consent given may be carried out in the same manner in
          which the same was initially granted and without any burden or limitation of any
          kind to you. You also have the right to the deletion of data concerning you
          without undue delay in the following cases: a) personal data are no longer
          necessary in relation to the purposes for which they were collected or otherwise
          processed; b) you have revoked your consent to the processing; c) you have
          objected to the processing of personal data and there is no overriding
          legitimate reason to proceed with the processing; d) personal data have been
          processed unlawfully; e) personal data must be deleted in order to comply with a
          legal obligation. We will deal with your request in accordance with applicable
          laws and in a manner consistent with the Company’s commitment to privacy. You
          may contact us through the following references: Osses Ltd. with registered
          office at 19 Leyden Street, London, United Kingdom, E1 7LE Email:
          support@vieniora.com 8. Period of conservation Your personal data are stored
          until the moment of the realization of the purpose for which they were collected
          and subsequently processed for the entire period prescribed by the laws in
          force, both civil and fiscal. 9. Processing methods and security The treatment
          is carried out through automated tools (e.g. using electronic procedures and
          media) and/or manually (e.g. on paper) for the time strictly necessary to
          achieve the purposes for which the data were collected and, in any case, in
          accordance with the relevant laws. These electronic and paper supports are filed
          in the offices of the Company or in the offices of other persons in charge of
          data processing with passwords or other means that guarantee the security of
          such data. 10. Optional data You may choose whether or not to provide your
          personal data. If not provided, this will only result in the impossibility of
          obtaining the requested services for which the provision and processing of
          personal data is necessary. 11. Disclosures We use third party contractors who
          perform services for, or with, the Company and process personal information in
          accordance with our instructions in connection with the Site, for example, for
          the purpose of conducting research, processing information or maintaining the
          Site, content or programs. Such third party contractors are restricted from
          using the data for any purpose other than to provide services for or with the
          Company. The Company is responsible for any processing by such third parties and
          will ensure that such contractors have entered into written agreements that
          allow the Company to comply with our obligations under this Privacy Policy and
          applicable laws. Your personal information will not be disclosed to other third
          parties unless you have explicitly consented to such disclosure or if it is
          required by law. 12. Security of Personal Information We take all reasonable
          steps to protect Your personal information from loss, misuse, unauthorized
          access, disclosure, alteration or destruction using security precautions that
          provide industry standard protection. However, no data transmission over the
          Internet can be guaranteed to be completely secure. Accordingly, to the extent
          permitted by applicable law, we make no warranty, express or implied, regarding
          the security or integrity of any personal information. 13. Information relating
          to your rights You have the right at any time to access your personal
          information, to have it corrected, deleted or transferred to other organizations
          at your request. You also have the right to object to or restrict certain
          processing and, where we have asked for your consent to process your personal
          information, to withdraw your consent at any time. If you withdraw your consent,
          we will stop any future use of your personal information. Pursuant to Article 12
          et seq. of EU Regulation 2016/679, data subjects may exercise certain rights in
          relation to the processing of their personal information. In particular, You may
          contact us at any time to obtain information regarding the type of personal data
          concerning You that we process, to correct inaccurate or imprecise information,
          to access the list of data controllers and related data processing locations
          currently used by the Company, to submit further requests regarding the manner
          in which we process personal data, to object to specific data processing or
          request its interruption, or to request the restriction of the processing of
          Your data, its portability to third parties, or revoke Your explicit consent.
          The revocation of the consent given may be carried out in the same manner in
          which the same was initially granted and without any burden or limitation of any
          kind to you. You also have the right to the deletion of data concerning you
          without undue delay in the following cases: a) personal data are no longer
          necessary in relation to the purposes for which they were collected or otherwise
          processed; b) you have revoked your consent to the processing; c) you have
          objected to the processing of personal data and there is no overriding
          legitimate reason to proceed with the processing; d) personal data have been
          processed unlawfully; e) personal data must be deleted in order to comply with a
          legal obligation. We will deal with your request in accordance with applicable
          laws and in a manner consistent with the Company’s commitment to privacy. You
          may contact us through the following references: Osses Ltd. with registered
          office at 19 Leyden Street, London, United Kingdom, E1 7LE Email:
          support@vieniora.com
        </div>
      </div>
    </div>
  );
};
