// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import APIClient from 'api/ApiClient';

// interface IApproveRequest {
//   isApprove: boolean;
//   rejectionMotivation: string;
// }

const setUserApproveAt = async (userId: string | number, data: any) => {
  await APIClient.put<void>(ENV_ADMIN.SET_APPROVE_USER(userId), data);
};

export function useSetUserApproveAt(userId: string | number) {
  const queryClient = useQueryClient();
  const setUserApproveAtMutation = useMutation<void, AxiosError, any>(
    (data) => setUserApproveAt(userId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ONE(userId));
      },
    }
  );

  return {
    setUserApproveAtMutation,
  };
}
