// 3p
import { OverviewCard } from 'components/common/transfer';

import { CheckCircleIcon, RefreshIcon, ScaleIcon } from '@heroicons/react/outline';

import { AlertError, Loader } from 'components/common';

import { useGetSummary } from 'api/transfer';

export const SummaryCard = () => {
  const { getSummaryQuery } = useGetSummary();
  const { isError, error, isLoading, data } = getSummaryQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  return isLoading ? (
    renderLoading()
  ) : isError ? (
    renderError()
  ) : !data ? (
    <p>Data not found</p>
  ) : (
    <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      {/* Card */}
      <OverviewCard
        name="Disponibili"
        icon={ScaleIcon}
        value={data.availableNow}
        currency={data.currency}
      />
      <OverviewCard
        name="In arrivo"
        icon={RefreshIcon}
        value={data.availableSoon}
        currency={data.currency}
      />
      <OverviewCard
        name="In trasferimento"
        icon={CheckCircleIcon}
        value={data.inTransit}
        currency={data.currency}
      />
    </div>
  );
};
