import React, { useState, useRef, useEffect, useCallback } from 'react';

// 3p
import { StopIcon, TrashIcon } from '@heroicons/react/outline';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import { LiveAudioVisualizer } from 'react-audio-visualize';

type Props = {
  onSuccess?: (fileName: string, file: File) => void;
  onCancel: () => void;
};

const MEDIA_RECORDER_MIME_TYPE = 'audio/mp4';

function useTimer() {
  const timerInterval = useRef<NodeJS.Timer | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);

  const startTimer = useCallback(() => {
    timerInterval.current = setInterval(() => {
      setRecordingTime((time: number) => time + 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
    setRecordingTime(0);
  }, []);

  return Object.freeze({ recordingTime, startTimer, stopTimer });
}

export const ChatAttachmentAudio: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);

  const mediaChunks = React.useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const getMediaStream = useCallback(async () => {
    try {
      let stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });

      stream.getAudioTracks().forEach((audioTrack) => stream.addTrack(audioTrack));

      mediaStream.current = stream;

      return stream;
    } catch (err) {
      console.error(err);
    }
  }, []);

  const clearMediaStream = useCallback(() => {
    if (mediaStream.current != null) {
      for (const track of mediaStream.current.getTracks()) {
        track.stop();
        mediaStream.current.removeTrack(track);
      }
      mediaStream.current = null;
    }
  }, []);

  const { startTimer, stopTimer, recordingTime } = useTimer();

  const handleDataAvailable = useCallback((e: BlobEvent) => {
    // if (e.data.size) {
    mediaChunks.current.push(e.data);
    // }
  }, []);

  const handleStop = useCallback(() => {
    if (mediaChunks.current.length) {
      let [sampleChunk] = mediaChunks.current;
      let blobPropertyBag = Object.assign({ type: sampleChunk.type });
      setRecordingBlob(new Blob(mediaChunks.current, blobPropertyBag));
    }
  }, []);

  const handleError = useCallback((e: any) => {
    console.error('Recording Error', e);
  }, []);

  // *********************

  const handleDeleteRecording = useCallback(() => {
    setRecordingBlob(null);
    onCancel();
  }, [onCancel]);

  const handleStartRecording = useCallback(async () => {
    if (!mediaStream.current) {
      await getMediaStream();
    }

    mediaChunks.current = [];

    if (!mediaStream.current) {
      return;
    }

    if (!MediaRecorder.isTypeSupported(MEDIA_RECORDER_MIME_TYPE)) {
      throw new Error('Il tuo broswer non supporta la registrazione audio');
    }

    mediaRecorder.current = new MediaRecorder(mediaStream.current, {
      mimeType: MEDIA_RECORDER_MIME_TYPE,
    });

    mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
    mediaRecorder.current.addEventListener('stop', handleStop);
    mediaRecorder.current.addEventListener('error', handleError);

    try {
      mediaRecorder.current.start();
      startTimer();
    } catch (error) {
      handleError({ error });
    }
  }, [getMediaStream, handleDataAvailable, handleError, handleStop, startTimer]);

  const handleStopRecording = useCallback(() => {
    stopTimer();

    if (!mediaRecorder.current) return;

    mediaRecorder.current.stop();
  }, [stopTimer]);

  useEffect(() => {
    if (!recordingBlob) return;
    if (!mediaRecorder.current) return;

    mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable);
    mediaRecorder.current.removeEventListener('stop', handleStop);
    mediaRecorder.current.removeEventListener('error', handleError);

    mediaRecorder.current = null;

    clearMediaStream();
  }, [clearMediaStream, handleDataAvailable, handleError, handleStop, recordingBlob]);

  const handleSendAudio = useCallback(async () => {
    if (!recordingBlob) return;

    const fileName = uuidv4();
    const audioFile = new File([recordingBlob], fileName + '.mp4', {
      type: MEDIA_RECORDER_MIME_TYPE,
    });

    if (onSuccess) {
      onSuccess(fileName, audioFile);
    }
  }, [recordingBlob, onSuccess]);

  useEffect(() => {
    try {
      handleStartRecording();
    } catch (e) {
      console.error(e);
      alert(e);

      handleDeleteRecording();
    }
  }, [handleStartRecording, handleDeleteRecording]);

  return (
    <div
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '25px 20px',
      }}
      className="bg-gray-200 flex"
    >
      {recordingBlob ? (
        <>
          <button type="button" onClick={handleDeleteRecording}>
            <TrashIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          </button>
          <div className="w-full h-12 px-8">
            <ReactPlayer
              url={URL.createObjectURL(recordingBlob)}
              controls
              config={{
                file: {
                  forceAudio: true,
                  attributes: { controlsList: 'nodownload' },
                },
              }}
              width="100%"
              height="100%"
            />
          </div>
          <button
            type="button"
            className="focus:outline-none text-pink-600 hover:text-pink-700"
            onClick={handleSendAudio}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
              />
            </svg>
          </button>
        </>
      ) : (
        <>
          <div className="text-red-500 font-bold">
            {recordingTime && (
              <>
                {Math.floor(recordingTime / 60)}:
                {String(recordingTime % 60).padStart(2, '0')}
              </>
            )}
          </div>
          {mediaRecorder.current && (
            <LiveAudioVisualizer
              mediaRecorder={mediaRecorder.current}
              barWidth={2}
              gap={2}
              width={140}
              height={30}
              fftSize={512}
              maxDecibels={-10}
              minDecibels={-80}
              smoothingTimeConstant={0.4}
              barColor="#ef4444"
            />
          )}
          <StopIcon
            className="cursor-pointer h-10 w-10 text-red-500"
            onClick={handleStopRecording}
            aria-hidden="true"
          />
        </>
      )}
    </div>
  );
};
