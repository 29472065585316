import { useCallback, useMemo } from 'react';

// 3p
import { PlayIcon } from '@heroicons/react/solid';

// app
import { DEFAULT_LIMIT } from 'config/Enviroment';
import { IAlbum, IImageGalleryItem, IMedia2 } from 'interfaces';

import { useGetAllMedia } from 'api/media';
import { MediaModal } from './MediaModal';
import { useModal } from 'hooks';

interface IMediaPreviewProps {
  album: IAlbum;
}

export const MediaPreview = (props: IMediaPreviewProps) => {
  const { album } = props;

  const albumId = useMemo(() => album.id, [album]);

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2>();

  // Retrive album preview
  const { getAllMediaQuery } = useGetAllMedia(albumId, {
    limit: DEFAULT_LIMIT,
    order: 'DESC',
    preview: true,
  });

  const { data: getAllMediaResponse } = getAllMediaQuery;

  const getAllMediaData = useMemo(
    () => getAllMediaResponse?.pages,
    [getAllMediaResponse]
  );

  const handleOnClick = useCallback(
    async (media: IMedia2) => {
      await viewMediaModalSetData(media);
      viewMediaModalOpen();
    },
    [viewMediaModalSetData, viewMediaModalOpen]
  );

  if (!getAllMediaData || !getAllMediaData[0].data.data.length) {
    return null;
  }

  const media = getAllMediaData[0].data.data[0];

  const mediaArr: IImageGalleryItem[] = [
    {
      id: media.id,
      thumbnail: media.video?.thumbnailURL,
      original: media.url,
      isVideo: media.photo ? false : true,
      bunnyId: media.video ? media.video.bunnyId : undefined,
      libraryId: media.video ? media.video.libraryId : undefined,
    },
  ];

  const { photo, cover, video, url } = media;

  const imagePreviewUrl =
    photo || cover ? url : video ? video.thumbnailURL : '/assets/placeholder-image.png';

  return (
    <>
      <MediaModal
        {...viewMediaModal}
        mediaArray={mediaArr}
        onClose={viewMediaModalClose}
      />
      <div
        className={`w-full sm:w-3/4 mx-auto bg-white relative shadow rounded sm:shadow-md sm:rounded-md transition duration-500 dark:bg-grey-800 overflow-hidden`}
        onClick={() => handleOnClick(media)}
      >
        <div
          className="rounded-t-lg cursor-pointer h-80 bg-cover bg-center"
          style={{
            backgroundImage: `url(${imagePreviewUrl})`,
          }}
        >
          {video && (
            <div className="flex justify-center items-center h-full text-white text-sm ">
              <PlayIcon className="h-16 opacity-80" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
