// std

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

// app
import { ENV_CREATOR } from 'config/Enviroment';
import { IResponseWithCursor, ICreator } from 'interfaces';

import APIClient from '../ApiClient';

interface IUserLastAccess {
  lastActivityAt: string;
  isOnline: boolean;
  userId: number;
  user: ICreator;
}

const getAllCreator = async ({ pageParam, queryKey }: any) => {
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IUserLastAccess>>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllCreator(searchQuery: any, pagingQuery: any) {
  const getAllCreatorQuery = useInfiniteQuery<
    IResponseWithCursor<IUserLastAccess>,
    AxiosError<string, any>
  >([ENV_CREATOR.GET_ALL, searchQuery, pagingQuery], getAllCreator, {
    getNextPageParam: (lastPage) => lastPage.cursor.afterCursor,
  });

  return {
    getAllCreatorQuery,
  };
}
