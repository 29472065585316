import { Link } from 'react-router-dom';

// app
import { DEFAULT_LIMIT } from 'config/Enviroment';
import { useGetAllUserAlbum } from 'api/user';

interface IProfileAlbumGalleryPreviewProps {
  username: string;
}

export function ProfileAlbumGalleryPreview(props: IProfileAlbumGalleryPreviewProps) {
  const { username } = props;

  const { getAllUserAlbumQuery } = useGetAllUserAlbum(
    username,
    {},
    { limit: DEFAULT_LIMIT, order: 'DESC' }
  );

  const { data } = getAllUserAlbumQuery;
  const albums = data?.pages[0].data;

  return (
    <section className="overflow-hidden text-gray-700">
      <div className="container p-5 mx-auto lg:px-32">
        <div className="flex flex-wrap -m-1 md:-m-2">
          <div className="flex flex-row w-full sm:flex-wrap sm:w-1/2">
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-1/2 sm:p-1 md:p-2">
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src={
                    albums && albums[0] && albums[0].cover
                      ? albums[0].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
            </div>
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-1/2 sm:p-1 md:p-2">
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src={
                    albums && albums[1] && albums[1].cover
                      ? albums[1].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
            </div>
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-full sm:p-1 md:p-2">
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src={
                    albums && albums[2] && albums[2].cover
                      ? albums[2].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
            </div>
          </div>

          <div className="flex flex-row w-full mt-2 sm:mt-0 sm:flex-wrap sm:w-1/2">
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-full sm:p-1 md:p-2">
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src={
                    albums && albums[3] && albums[3].cover
                      ? albums[3].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
            </div>
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-1/2 sm:p-1 md:p-2">
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  src={
                    albums && albums[4] && albums[4].cover
                      ? albums[4].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
            </div>
            <div className="w-1/3 mr-2 sm:mr-0 sm:w-1/2 sm:p-1 md:p-2 relative">
              {/* <img
                alt="Copertina assente"
                className="block object-cover cursor-pointer object-center w-full h-full rounded-lg hover:bg-gray-600"
                src="/assets/placeholder-image.png"
                onClick={() => history.push(`${url}/albums`)}
              /> */}
              <Link to="albums">
                <img
                  alt="Copertina assente"
                  className="block object-cover object-center w-full h-full rounded-lg"
                  //className="block object-cover object-center w-full h-full rounded-lg hover:bg-pink-700"
                  src={
                    albums && albums[5] && albums[5].cover
                      ? albums[5].cover.fileStorage.url
                      : '/assets/placeholder-image.png'
                  }
                />
              </Link>
              {/* Decommentare per tornare a versione con card con scritta "vai a tutti gli album" 
              <div className="block object-cover bg-white object-center w-full h-full rounded-lg cursor-pointer" /> */}
              {/* Decommentare per transizione con scritta
              <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-6xl text-pink-600 font-semibold"> */}
              {/* Decommentare per tornare a versione con card con scritta "vai a tutti gli album" 
              <Link
                to="albums"
                className="opacity-100 absolute inset-0 z-10 flex justify-center text-center rounded-lg items-center text-lg sm:text-2xl text-pink-600 hover:text-pink-900 bg-gradient-to-r from-pink-500 font-semibold hover:underline"
              >
                Vai a tutti gli album
              </Link> */}
              {/* <div className="text-sm m-auto">
                <Link
                  to="albums"
                  className="font-medium text-pink-600 hover:text-pink-500"
                >
                  Vai a tutti gli album
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
