import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// app
import { DEFAULT_LIMIT } from 'config';
import { TransferTable } from 'components/admin/transfer/TransferTable';
import { useGetAllTransfer } from 'api/admin/transfer/useGetAllTransfer';
import { SummaryPayout } from 'components/admin/payout';

export const TransferGetAll = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');

  const searchQuery = useMemo(() => {
    if (!code) return {};

    return { code };
  }, [code]);

  const [cursor, setCursor] = useState<any>({});

  const { getAllTransferQuery } = useGetAllTransfer(
    {
      limit: DEFAULT_LIMIT,
      order: 'DESC',
      ...cursor,
    },
    searchQuery
  );

  return (
    <>
      <div className="px-4 sm:px-6 mb-8 lg:px-8">
        <SummaryPayout />
      </div>
      {/* TODO: lista trasferimenti con visualizzazione mobile ved. src/views/app/Dashboard.tsx */}
      {/* <TransferList data={data?.items} page={page} setPage={setPage} /> */}
      <div className="px-4 sm:px-6 lg:px-8">
        <TransferTable setCursor={setCursor} query={getAllTransferQuery} />
      </div>
    </>
  );
};
