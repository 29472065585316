import { ChevronLeftIcon, PlusSmIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

interface IAlbumGetAllBarProps {
  onChangeFilter?: Function;
  onCreateNew?: () => void;
  isWebsite?: boolean;
}

export function AlbumGetAllBar(props: IAlbumGetAllBarProps) {
  const { onCreateNew, isWebsite } = props;
  const navigate = useNavigate();

  return (
    <div className="bg-white overflow-hidden rounded sm:shadow-md sm:rounded-md">
      <div className="px-4 py-4 sm:px-6">
        {isWebsite ? (
          <div className="flex sm:space-x-5 sm:mb-0 truncate">
            <div className="shrink-0">
              <button
                type="button"
                className="p-2 bg-white hover:bg-gray-200 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                onClick={() => navigate(-1)}
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>

            <div className="text-center sm:text-left pt-1 truncate">
              <h1 className="text-xl sm:text-2xl font-bold leading-7 text-gray-900 truncate">
                Album
              </h1>
            </div>
          </div>
        ) : (
          <div className="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4">
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900 truncate">
                Album
              </h1>
            </div>

            <div className="ml-4 shrink-0">
              <button
                onClick={onCreateNew ? () => onCreateNew() : undefined}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <PlusSmIcon
                  className="-ml-1 mr-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />{' '}
                <span>Crea nuovo</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
