import { useState } from 'react';

// app
import { IMedia2, IModal } from 'interfaces';

import { Loader } from 'components/common';

import { ProfilePhotoDeleteModal } from './ProfilePhotoDeleteModal';

interface IProfilePhotoCardProps {
  data: IMedia2;
  onClick?: (media: IMedia2) => void;
  onDelete?: (media: IMedia2) => void;
  hasBadgeApprovation?: boolean;
  isLoading?: boolean;
}

export function ProfilePhotoCard(props: IProfilePhotoCardProps): JSX.Element {
  const { data, onClick, isLoading } = props;
  const { url } = data;

  const [profilePhotoDeleteModal, setprofilePhotoDeleteModal] = useState<IModal<IMedia2>>(
    {
      open: false,
    }
  );

  const handleOnClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  const mediaDeleteOpen = () => {
    setprofilePhotoDeleteModal({ open: true });
  };

  const mediaDeleteClose = () => {
    setprofilePhotoDeleteModal({ open: false });
  };

  return (
    <div
      className={`bg-white relative shadow rounded sm:shadow-md sm:rounded-md transition duration-500 dark:bg-grey-800`}
    >
      <ProfilePhotoDeleteModal
        {...profilePhotoDeleteModal}
        onClose={mediaDeleteClose}
        idProfilePhoto={data.id}
      />

      {!isLoading ? (
        <div
          className="rounded-t-lg rounded-b-lg w-full cursor-pointer h-40 bg-cover bg-center"
          onClick={handleOnClick}
          style={{
            backgroundImage: `url(${url ? url : '/assets/placeholder-image.png'})`,
          }}
        />
      ) : (
        <div className="h-40 w-full">
          <Loader />
        </div>
      )}

      <span className="absolute top-1 right-3 block h-4 w-4">
        <button
          type="button"
          className="delete ml-auto focus:outline-none hover:bg-gray-400 bg-gray-300 p-1 rounded-md"
          onClick={(e) => {
            e.preventDefault();
            mediaDeleteOpen();
          }}
        >
          <svg
            className="pointer-events-none fill-current w-4 h-4 ml-auto"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              className="pointer-events-none"
              d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
            />
          </svg>
        </button>
      </span>

      {/* 
      TODO: Aggiungere per approvazione foto profilo 
      {hasBadgeApprovation && (data.photo?.isPreview || data.video?.isPreview) ? (
        <div className="absolute top-2 left-2 py-0.5 px-2 bg-yellow-100 rounded-lg">
          <span className="text-sm font-semibold text-yellow-800">Anteprima</span>
        </div>
      ) : null}

      {hasBadgeApprovation &&
      (data.photo?.isApprove ||
        data.video?.isApprove ||
        data.cover?.isApprove) ? null : hasBadgeApprovation && // </div> //   <span className="text-sm font-semibold text-green-800">Approvato</span> // <div className="absolute top-2 left-2 py-0.5 px-2 bg-green-100 rounded-lg">
        (data.photo?.isApprove === false ||
          data.video?.isApprove === false ||
          data.cover?.isApprove === false) ? (
        <div className="absolute top-2 left-2 py-0.5 px-2 bg-red-100 rounded-lg">
          <span className="text-sm font-semibold text-red-800">Non approvato</span>
        </div>
      ) : (
        hasBadgeApprovation && (
          <div className="absolute top-2 left-2 py-0.5 px-2 bg-yellow-100 rounded-lg">
            <span className="text-sm font-semibold text-yellow-800">Da approvare</span>
          </div>
        )
      )} */}
    </div>
  );
}
