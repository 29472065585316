// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_USER } from 'config';
import { useAuth } from 'hooks';

import APIClient from '../ApiClient';

const deleteProfilePhoto = async (idProfilePhoto: number) => {
  await APIClient.delete(ENV_USER.DELETE_PROFILE_PHOTO(idProfilePhoto));
  return idProfilePhoto;
};

export function useDeleteProfilePhoto() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const deleteProfilePhotoMutation = useMutation<number, AxiosError, number>(
    (idProfilePhoto) => deleteProfilePhoto(idProfilePhoto),
    {
      onSuccess: () => {
        if (user) {
          const { username } = user;
          const url = ENV_USER.GET_PROFILE(username);
          queryClient.invalidateQueries(url);
        }
      },
    }
  );

  return {
    deleteProfilePhotoMutation,
  };
}
