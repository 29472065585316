// 3p
import { Switch } from '@headlessui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// app
import { classNames } from 'utils';
import { useToast } from 'hooks';

import { ICreator, IProfile } from 'interfaces';
import { useSaveUserProfile } from 'api/user';

interface ICreatorVisibilityFormProps {
  data: ICreator;
}

const ProfileVisibilityForm = (props: ICreatorVisibilityFormProps) => {
  const { data } = props;
  const { profile } = data;

  const { toastError, toastSuccess } = useToast();

  const { saveUserPorfileMutation } = useSaveUserProfile();
  const { mutate } = saveUserPorfileMutation;

  const { handleSubmit, control } = useForm<IProfile>({
    defaultValues: {
      isVisible: profile?.isVisible ?? true,
    },
  });

  const onSubmit: SubmitHandler<IProfile> = (data) => {
    mutate(data, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  const onSuccess = () => {
    toastSuccess({
      title: 'Operazione completata',
      message: 'Profilo aggiornato',
    });
  };

  const onError = () => {
    toastError({
      title: 'Errore operazione annullata',
      message: 'Errore generico',
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Privacy</h3>
            <p className="mt-1 text-sm text-gray-500">Modifica impostazioni privacy</p>
          </div>

          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <Switch.Group as="div" className="flex items-center justify-between">
                <span className="flex-grow flex flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium text-gray-900"
                    passive
                  >
                    Profilo visibile
                  </Switch.Label>
                  <Switch.Description as="span" className="text-sm text-gray-500">
                    Scegli se rendere visibile il tuo profilo sulla piattaforma
                  </Switch.Description>
                </span>
                <Controller
                  name="isVisible"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value === true}
                      onChange={onChange}
                      className={classNames(
                        value ? 'bg-pink-600' : 'bg-gray-200',
                        'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          value ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                  )}
                />
              </Switch.Group>
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
          >
            Salva
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileVisibilityForm;
