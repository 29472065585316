import { formatMoney } from 'utils';

interface OverviewCardProps {
  name: string;
  value: number;
  currency: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export default function OverviewCard(props: OverviewCardProps): JSX.Element {
  const { name, value, currency, icon: I } = props;

  return (
    <div key={name} className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="shrink-0">
            <I className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
              <dd>
                <div className="text-lg font-medium text-gray-900">
                  {formatMoney(value, { currency })}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {/* <div className="bg-gray-50 px-5 py-3">
        <div className="text-sm">
          <a
            href={href}
            className="font-medium text-cyan-700 hover:text-cyan-900"
          >
            Mostra tutti
          </a>
        </div>
      </div> */}
    </div>
  );
}
