import { TransferTypeEnum, TrasnferStatusEnum } from 'config';
import { IProduct, IUser } from 'interfaces';

export interface ITransaction {
  id: number;
  amount: number;
  fee: number;
  net: number;
  currency: string;
  startAt: string;
  completeAt: string | null;
}

export interface ITransferType {
  code: TransferTypeEnum;
  id: number;
}

export interface ITransferStatus {
  code: TrasnferStatusEnum;
  id: number;
}

interface IPaymentIn {
  amount: number;
  currency: string;
  email: string;
  method: {
    code: string;
    description: string;
  };
  source: {
    code: string;
    description: string;
  };
}

interface ITransferBase {
  id: number;

  code: string;
  message: string;

  transaction: ITransaction;

  sourceUser: IUser;
  targetUser: IUser;

  startAt: string;
  completeAt: string;

  type: ITransferType;
  status: ITransferStatus;

  products?: IProduct[];

  paymentIn?: IPaymentIn;

  refundTransferIn?: ITransfer;
  refundTransferOut?: ITransfer;
}

export interface ITransferTableRow {
  id: string;
  code: string;
  startAt: string;
  completeAt: string;
  sourceUser: IUser;
  targetUser: IUser;
  status: ITransferStatus;
  type: ITransferType;
  transaction: ITransaction;
  paymentIn?: IPaymentIn;
  refundTransferIn?: ITransfer;
  refundTransferOut?: ITransfer;
  products: { id: number, title: string }[]
}

export enum RefundTypeEnum {
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
}

export interface IRefundCreateForm {
  method: RefundTypeEnum;
  amount?: number;
}

export interface IRefundUpdateForm {
  hasApprove: boolean;
}

// EXPLOITING

// type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

interface ITransferWithTargetUser extends Omit<ITransferBase, 'sourceUser'> {
  sourceUser: null;
}

interface ITransferWithSourceUser extends Omit<ITransferBase, 'targetUser'> {
  targetUser: null;
}

export type ITransfer = ITransferBase | ITransferWithTargetUser | ITransferWithSourceUser;
