export const Refund = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            {/* <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
              Introducing
            </span> */}
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Politica di rimborsi
            </span>
          </h1>
        </div>
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          Il Sito è responsabile delle transazioni effettuale mediante la propria
          piattaforma e di eventuali rimborsi dovuti al mancato esito del pagamento. Ciò
          non include il caso in cui il pagamento non vada a buon fine per mancato
          riscontro da parte dell’emittente la carta di credito o debito o altro
          malfunzionamento che non dipenda dalla piattaforma offerta da vieniora.com. In
          caso di fallimento del pagamento da parte dell’Utente, il Sito si riserva di
          agire nei confronti dell’Utente, che sarà tenuto al pagamento del capitale
          dovuto, oltre che le spese legali e i costi legati al recupero di tali somme. La
          cancellazione o termine dell’Iscrizione non estingue le eventuali obbligazioni
          ancora pendenti in capo all’Utente. In caso di reiterato fallimento delle
          transazioni, il Sito si riserva la possibilità di terminare l’iscrizione
          dell’Utente e di agire per il recupero di eventuali crediti nei confronti dello
          stesso. Il Sito ha facoltà di cambiare i metodi di pagamento e fatturazione in
          ogni momento. Il Sito ha inoltre facoltà di cambiare le tariffe legate ai
          Servizi, nei limiti della propria competenza. Ciò non include il prezzo dei
          Servizi stesso, stabilito in modo autonomo e indipendente dai Fornitori.
          L’Utente ha facoltà di terminare l’Iscrizione in ogni momento a seguito della
          modifica dei costi e delle tariffe da parte del Sito. L’Utilizzo del Sito a
          seguito di tali modifiche costituisce tacita accettazione delle modifiche e non
          conferisce alcun diritto al rimborso dei prezzi, né delle eventuali differenze
          di eventuali maggiorazioni rispetto ai prezzi precedenti. L’Utente è
          personalmente ed esclusivamente responsabile di tutti i costi associati
          all’utilizzo del Sito, che accetta in modo specifico e tacito al momento
          dell’Iscrizione e dell’utilizzo dei singoli Servizi. Per qualsiasi ulteriore
          domanda o informazione relativa alle transazioni, è possibile contattare
          ragazzetelegram.com mediante il Supporto Tecnico. Il Sito non è responsabile
          delle condizioni dei Servizi e del relativo pagamento. Tuttavia, qualora il
          Servizio non sia stato svolto secondo gli accordi, è fatta facoltà all’Utente di
          contattare il Sito per poter richiedere un rimborso, facendo specifico
          riferimento alle circostanze di fatto. Il Sito si riserva la possibilità di
          verificare il mancato rispetto degli accordi da parte dei Fornitori e procedere
          ad eventuale rimborso, concesso esclusivamente nella forma di credito
          sull’Account dell’Utente.
        </div>
      </div>
    </div>
  );
};
