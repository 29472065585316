// ScrollToTop.jsx
// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// type IScrollToTopProps = {
//   children: React.ReactNode;
// };

// const ScrollToTop = (props: IScrollToTopProps) => {
//   const location = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);

//   return <>{props.children}</>;
// };

// export default ScrollToTop;
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}
