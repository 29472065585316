import { useState } from 'react';

// app
import Loader from '../Loader';
import { AlertError } from '../alert';
import { BasicEmptyState } from '../empty';

import { IProduct } from 'interfaces';

import { useGetAllUserProduct } from 'api/user';

import ProductCard from './ProductCard';

import { DEFAULT_LIMIT_PRODUCT } from 'config/Enviroment';

interface IProductListProps {
  username: string;
  onEdit?: Function;
  onRemove?: Function;
  onBuy?: Function;
  className?: string;
}

export const ProductList = (props: IProductListProps) => {
  const { username, onEdit, onBuy, onRemove, className } = props;

  const [page, setPage] = useState<number>(0);

  // Query Data
  const { getAllUserProductQuery } = useGetAllUserProduct(
    username,
    page,
    DEFAULT_LIMIT_PRODUCT
  );
  const { isError, error, isLoading, data, isPreviousData } = getAllUserProductQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderEmpty = () => (
    <BasicEmptyState title="Catalogo servizi" descr="Non sono presenti servizi!" />
  );

  const renderData = () => {
    if (!data?.items) return renderError();
    if (!data.items.length) return renderEmpty();

    const { items, hasMore } = data;

    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
          {items.map((product: IProduct) => (
            <div key={product.id} className="col-span-1 sm:col-span-2">
              <ProductCard
                data={product}
                onEdit={onEdit}
                onBuy={onBuy}
                onRemove={onRemove}
              />
            </div>
          ))}
        </div>
        {hasMore && (
          <div className="mt-6">
            <button
              type="button"
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              onClick={onClickLoadMore}
              disabled={isPreviousData || hasMore}
            >
              Mostra altri
            </button>
          </div>
        )}
      </>
    );
  };

  const onClickLoadMore = () => {
    if (!isPreviousData && data?.hasMore) {
      setPage((old) => old + 1);
    }
  };

  return (
    <div className={className}>
      {isLoading ? renderLoading() : isError ? renderError() : renderData()}
    </div>
  );
};
