import styles from './Tooltip.module.css';

export const Tooltip = (props: any) => {
  const { children, text } = props;

  return (
    <div className={styles.tooltip}>
      {children}
      <span className={styles.tooltiptext}>{text}</span>
    </div>
  );
};
