// std

// 3p
import { Switch } from '@headlessui/react';
import { Controller, useForm } from 'react-hook-form';

// app
import { classNames } from 'utils';
import { Link, Location, useLocation } from 'react-router-dom';
import { useToast } from 'hooks';
import APIClient from 'api/ApiClient';
import { ENV_COMMON } from 'config/Enviroment';
import { useMemo } from 'react';

interface IReportForm {
  fullname: string;
  subject: string;
  email: string;
  message: string;
  hasAcceptedPolicies: boolean;
}

interface ILocationState extends Location {
  state: { mediaId?: string };
}

export default function Report() {
  const { toastSuccess, toastError } = useToast();

  const { state } = useLocation() as ILocationState;
  const mediaId = useMemo(() => (state && state.mediaId ? state.mediaId : ''), [state]);

  const { control, reset, register, handleSubmit } = useForm<IReportForm>({
    defaultValues: {
      subject: useMemo(() => 'Segnelazione media ' + mediaId, [mediaId]),
      hasAcceptedPolicies: false,
    },
  });

  const onSubmit = async (data: IReportForm) => {
    if (!data.hasAcceptedPolicies) {
      toastError({
        title: 'Attenzione!',
        message: 'Accetta le policy',
      });
      return;
    }

    try {
      await APIClient.post(ENV_COMMON.SEND_SUPPORT_EMAIL, data);

      toastSuccess({
        title: 'Richiesta inviata',
        message: 'La tua richiesta di segnalazione è stata inviata correttamente',
      });

      reset();
    } catch (error) {
      console.error(error);

      toastError({
        title: 'Errore',
        message: 'Impossibile inviare il messaggio',
      });
    }
  };

  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-pink-600 sm:text-4xl">
            Invia una segnalazione
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Specifica in modo dettagliato il motivo della tua segnalazione
          </p>
        </div>
        <div className="mt-12">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="sm:col-span-2">
              <label
                htmlFor="fullname"
                className="block text-sm font-medium text-gray-700"
              >
                Nome e Cognome
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="fullname"
                  autoComplete="given-name"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md"
                  {...register('fullname', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md"
                  {...register('email', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="block text-sm font-medium text-gray-700"
              >
                Oggetto
              </label>
              <div className="mt-1">
                <input
                  disabled
                  id="subject"
                  type="text"
                  autoComplete="subject"
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border-gray-300 rounded-md opacity-50"
                  {...register('subject', { required: true })}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Messaggio
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  rows={4}
                  className="py-3 px-4 block w-full shadow-sm focus:ring-pink-600 focus:border-pink-600 border border-gray-300 rounded-md"
                  {...register('message')}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="shrink-0">
                  <Controller
                    name="hasAcceptedPolicies"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, value, ...field } }) => (
                      <Switch
                        {...field}
                        checked={value}
                        className={classNames(
                          value ? 'bg-pink-600' : 'bg-gray-200',
                          'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600'
                        )}
                      >
                        <span className="sr-only">Accetta le hasAcceptedPolicies</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            value ? 'translate-x-5' : 'translate-x-0',
                            'inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                    )}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    Selezionando, accetterai la{' '}
                    <Link
                      to="/legal/privacy"
                      className="font-medium text-gray-700 underline"
                    >
                      Privacy Policy
                    </Link>{' '}
                    e la{' '}
                    <Link
                      to="/legal/cookie"
                      className="font-medium text-gray-700 underline"
                    >
                      Cookie Policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
              >
                Invia
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
