// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_COMMON } from 'config/Enviroment';
import { IRestClientGetResponse, IGender } from 'interfaces';

import APIClient from '../ApiClient';

const getAllGender = async () => {
  const { data } = await APIClient.get<IRestClientGetResponse<IGender>>(
    ENV_COMMON.GET_ALL_GENDER
  );

  return data;
};

export function useGetAllGender() {
  const getAllGenderQuery = useQuery<
    IRestClientGetResponse<IGender>,
    AxiosError<string, any>
  >([ENV_COMMON.GET_ALL_GENDER], () => getAllGender(), {
    placeholderData: {
      items: [],
      hasMore: false,
    },
  });

  return {
    getAllGenderQuery,
  };
}
