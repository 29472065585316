import { CURRENT_LOGO_URI, CURRENT_SITE_NAME } from 'config';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">{CURRENT_SITE_NAME}</span>
              <img
                className="block h-8 w-auto"
                src={CURRENT_LOGO_URI}
                alt={CURRENT_SITE_NAME}
              />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-pink-600 uppercase tracking-wide">
                Errore 404
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Pagina non trovata.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sembra che la pagina che stavi cercando non esiste.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-pink-600 hover:text-pink-500"
                >
                  Torna alla home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
