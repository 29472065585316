import { Fragment } from 'react';

// 3p
import { Link } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';

// app
import { IMedia2 } from 'interfaces';

interface MediaModalMenuProps {
  media: IMedia2;
  onDelete?: (media: IMedia2) => void;
  onSetPreview?: (media: IMedia2) => void;
}

export const MediaModalMenu = (props: MediaModalMenuProps) => {
  const { media, onDelete, onSetPreview } = props;

  const { id, video, photo } = media;

  const isPreview = video?.isPreview || photo?.isPreview;

  return (
    <Menu as="div" className="relative mr-4 inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-1 py-0.5 bg-white text-sm font-medium text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
          <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {onSetPreview ? (
              <Menu.Item>
                <button
                  className="text-gray-700 block px-4 py-2 text-sm"
                  onClick={() => onSetPreview(media)}
                >
                  {isPreview ? 'Rimuovi come anteprima' : 'Imposta come anteprima'}
                </button>
              </Menu.Item>
            ) : null}
            {onDelete ? (
              <Menu.Item>
                <button
                  className="text-gray-700 block px-4 py-2 text-sm"
                  onClick={() => onDelete(media)}
                >
                  Elimina
                </button>
              </Menu.Item>
            ) : null}
            <Menu.Item>
              <Link
                to="/report"
                state={{ mediaId: id }}
                className="text-gray-700 block px-4 py-2 text-sm"
              >
                Report this content
              </Link>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
