import { useState } from 'react';

// 3p
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

// app
import { IAlbum, ILoginForm, IModal } from 'interfaces';
import { useToast } from 'hooks';

import { DEFAULT_LIMIT } from 'config/Enviroment';

import { useGetAllUserAlbum } from 'api/user';

import { AlbumGetAllBar, AlbumGrid } from 'components/common/album';
import { ProductBuyModal } from 'components/common/payment';

import LoginModal from '../auth/LoginModal';

// interface IProfileAlbumGetAllProps {
//   username: string;
//   query: UseInfiniteQueryResult<IResponseWithCursor<IAlbum>, AxiosError<string, any>>;
// }

export const ProfileAlbumGetAll = () => {
  const { username } = useParams<{ username: string }>() as { username: string };

  const navigate = useNavigate();

  const { getAllUserAlbumQuery } = useGetAllUserAlbum(
    username,
    {},
    { limit: DEFAULT_LIMIT, order: 'DESC' }
  );

  const [productBuyModal, setProductBuyModal] = useState<IModal<IAlbum>>({
    open: false,
  });

  const { toastError } = useToast();

  const [loginModal, setloginModal] = useState<IModal<ILoginForm>>({
    open: false,
  });

  const onSuccessCreatePayment = () => {
    setProductBuyModal({ open: false });
  };

  // const productBuyOpen = (album?: IAlbum) => {
  //   setProductBuyModal({ open: true, data: album });
  // };

  const productBuyClose = () => {
    setProductBuyModal({ open: false });
  };

  const onErrorPayment = (error: AxiosError<string, any>) => {
    toastError({
      title: "Impossibile completare l'acquisto",
      message: error.response?.data,
    });
  };

  // const loginModalOpen = (album?: IAlbum) => {
  //   setloginModal({ open: true });
  // };

  const loginModalClose = () => {
    setloginModal({ open: false });
  };

  const handleAlbumClick = (album: IAlbum, albumTitle?: string) => {
    navigate(`${album.id}`, { state: albumTitle });

    // if (
    //   !album.price ||
    //   album.hasPurchase ||
    //   currentUser?.id === album.user.id ||
    //   currentUser?.role.code === ROLES.ADMIN ||
    //   currentUser?.id === ID_ACCOUNT_EPOCH
    // ) {
    //   navigate(`${album.id}`, { state: albumTitle });
    //   //{ pathname: `${url}/${album.id}`, state: { albumTitle } }
    // } else {
    //   // album a pagamento e non sono il proprietario e non sono admin e non sono Epoch
    //   if (currentUser) productBuyOpen(album);
    //   //non sono loggato
    //   else loginModalOpen();
    // }
  };

  return (
    <>
      <ProductBuyModal
        {...productBuyModal}
        setOpen={setProductBuyModal}
        onClose={productBuyClose}
        onSuccess={onSuccessCreatePayment}
        onError={onErrorPayment}
      />
      <LoginModal {...loginModal} onClose={loginModalClose} />
      <AlbumGetAllBar isWebsite={true} />
      <div className="px-1 py-5 sm:p-6">
        <AlbumGrid
          query={getAllUserAlbumQuery}
          onAlbumClick={handleAlbumClick}
          emptyMessage="Questo utente non ha album"
        />
      </div>
    </>
  );
};
