// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_CATEGORY } from 'config/Enviroment';
import { IRestClientGetResponse, ICategory } from 'interfaces';

import APIClient from '../ApiClient';

const getAllCategory = async () => {
  const { data } = await APIClient.get<IRestClientGetResponse<ICategory>>(
    ENV_CATEGORY.GET_ALL_CATEGORY
  );

  return data;
};

export function useGetAllCategory() {
  const getAllCategoryQuery = useQuery<
    IRestClientGetResponse<ICategory>,
    AxiosError<string, any>
  >([ENV_CATEGORY.GET_ALL_CATEGORY], () => getAllCategory(), {
    placeholderData: {
      items: [],
      hasMore: false,
    },
  });

  return { getAllCategoryQuery };
}
