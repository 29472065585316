// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_PRODUCT, ENV_USER } from 'config/Enviroment';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks/useAuth';

const deleteProduct = async (productId: number) => {
  await APIClient.delete(ENV_PRODUCT.UPDATE + '/' + productId);
};

export function useDeleteProduct() {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const deleteProductMutation = useMutation<void, AxiosError, number>(
    (productId) => deleteProduct(productId),
    {
      onSuccess: () => {
        if (user) queryClient.invalidateQueries(ENV_USER.GET_ALL_PRODUCT(user.username));
      },
    }
  );

  return {
    deleteProductMutation,
  };
}
