// std
import { useEffect } from 'react';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// app
import { useSaveUserProfile } from 'api/user';
import { useGetAllCountry, useGetAllGender } from 'api/common';

import { SelectCategory, SelectTag } from 'components/common/select';

import { useToast } from 'hooks';
import { ICreator, IProfile } from 'interfaces';

interface ProfileDataFormProps {
  data: ICreator;
}

const ProfileDataForm = (props: ProfileDataFormProps) => {
  const { data } = props;
  const { username, profile } = data;

  const { toastError, toastSuccess } = useToast();

  const { saveUserPorfileMutation } = useSaveUserProfile();
  const { mutate } = saveUserPorfileMutation;

  const { register, handleSubmit, setValue, control } = useForm<IProfile>({
    defaultValues: profile,
  });

  const onSubmit: SubmitHandler<IProfile> = (data) => {
    mutate(data, {
      onSuccess: onSuccess,
      onError: onError,
    });
  };

  const onSuccess = () => {
    toastSuccess({
      title: 'Operazione completata',
      message: 'Informazioni salvate correttamente',
    });
  };

  const onError = () => {
    toastError({
      title: 'Errore generico',
      message: 'Impossibile salvare le informazioni',
    });
  };

  const { getAllCountryQuery } = useGetAllCountry();
  const { data: getAllCountryResponse } = getAllCountryQuery;

  const { getAllGenderQuery } = useGetAllGender();
  const { data: getAllGenderResponse } = getAllGenderQuery;

  // const { upload } = useUploadUserImage();
  // const { user, setUser } = useAuth();

  // const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!e.target.files || !e.target.files.length || !user) return;

  //   const fileBlob = e.target.files[0];
  //   const fileSizeMb = fileBlob.size / 1024 / 1024; // in MiB

  //   if (fileSizeMb > 5) {
  //     alert('Il tuo file è ' + fileSizeMb + 'Mb il limite massimo è 5Mb');
  //     return;
  //   }

  //   // Compress image
  //   const fileBlobCompressed = await compress(fileBlob, {
  //     quality: 0.8,
  //     type: EImageType.JPEG,
  //   });

  //   var formData = new FormData();
  //   formData.append('profile', fileBlobCompressed);

  //   try {
  //     const { image } = await upload(formData);
  //     setUser({ ...user, image });

  //     onSuccess();
  //   } catch (error) {
  //     console.error(error);
  //     onError();
  //   }
  // };

  useEffect(() => {
    if (!getAllCountryResponse?.items.length || !profile) return;
    if (profile.country) setValue('country', profile.country);
  }, [getAllCountryResponse, profile, setValue]);

  useEffect(() => {
    if (!getAllGenderResponse?.items.length || !profile) return;
    if (profile.gender) setValue('gender', profile.gender);
  }, [getAllGenderResponse, profile, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Profilo</h3>
            <p className="mt-1 text-sm text-gray-500">
              Queste informazioni verranno visualizzate pubblicamente, quindi fai
              attenzione a ciò che condividi.
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="col-span-1 sm:col-span-3">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  vieniora.com/
                </span>
                <input
                  type="text"
                  id="username"
                  autoComplete="username"
                  className="flex-1 focus:ring-pink-500 focus:border-pink-500 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 disabled:opacity-50"
                  disabled
                  value={username}
                />
              </div>
            </div>
            <div className="col-span-1 sm:col-span-3" />

            {/* <div className="col-span-1 sm:col-span-3">
              <label
                htmlFor="contacts.web"
                className="block text-sm font-medium text-gray-700"
              >
                Website
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="contacts.web"
                  placeholder="https://www.example.com"
                  className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  {...register('contacts.web', { required: true })}
                />
              </div>
            </div> */}

            <div className="col-span-1 sm:col-span-3">
              <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                Genere
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <select
                  id="gender"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md"
                  {...register('gender.id', { required: true })}
                >
                  <option disabled value={0}>
                    Seleziona
                  </option>
                  {getAllGenderResponse?.items.map(({ id, description }) => (
                    <option key={id} value={id}>
                      {description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Nazionalità
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <select
                  id="country"
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm rounded-md"
                  {...register('country.id', { required: true })}
                >
                  <option disabled value={0}>
                    Seleziona
                  </option>
                  {getAllCountryResponse?.items.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-6">
              <label
                htmlFor="biography"
                className="block text-sm font-medium text-gray-700"
              >
                Bio
              </label>
              <div className="mt-1">
                <textarea
                  id="biography"
                  rows={3}
                  className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  {...register('biography', { required: true })}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Scrivi alcune frasi su di te.</p>
            </div>

            <div className="col-span-1 sm:col-span-3">
              <label
                htmlFor="categories"
                className="block text-sm font-medium text-gray-700"
              >
                Categorie
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <Controller
                  name="categories"
                  control={control}
                  render={({ field }) => <SelectCategory selectProps={field} />}
                />
              </div>
            </div>

            <div className="col-span-1 sm:col-span-3">
              <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                Servizi
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => <SelectTag selectProps={field} />}
                />
              </div>
            </div>

            {/* {socials.map(({ key, title, placeholder }) => (
                <div key={key} className="sm:col-span-3">
                  <label
                    htmlFor={key}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {title}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name={key}
                      id={key}
                      placeholder={placeholder}
                      className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              ))} */}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="bg-pink-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            Salva
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProfileDataForm;
