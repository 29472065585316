interface IBadgeOnlineProps {
  isOnline?: boolean;
}

export const BadgeOnline = (props: IBadgeOnlineProps) => {
  const { isOnline } = props;

  const lastAccessColor = !isOnline ? 'bg-gray-300' : 'bg-green-300';

  return (
    <span
      className={'inline-block h-3 w-3 sm:h-4 sm:w-4 rounded-full ' + lastAccessColor}
    />
  );
};
