import Select from 'react-select';

import { useGetAllCategory } from 'api/common';
import { ICategory } from 'interfaces';

interface ISelectCategory {
  selectProps?: any;
}

// TODO: Interface props
export const SelectCategory = (props: ISelectCategory) => {
  const { selectProps } = props;

  const { getAllCategoryQuery } = useGetAllCategory();
  const { data: getAllCategoryResponse } = getAllCategoryQuery;

  return (
    <Select
      isMulti
      getOptionValue={(item: ICategory) => item.id.toString()}
      options={getAllCategoryResponse?.items}
      placeholder="Seleziona categorie"
      className="block w-full text-base border-gray-300 focus:outline-none focus:ring-pink-600 focus:border-pink-600 sm:text-sm rounded-md"
      {...selectProps}
      styles={{
        input: (base) => ({
          ...base,
          'input:focus': {
            boxShadow: 'none',
          },
        }),
        multiValue: (base) => ({
          ...base,
          background: 'rgba(219, 39, 119, 0.75)',
          borderRadius: '4px',
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: 'rgb(255,255,255)',
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: 'rgb(255,255,255)',
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(219, 39, 119, 0.25)',
          primary75: 'rgba(219, 39, 119, 0.75)',
          primary50: 'rgba(219, 39, 119, 0.50)',
          primary: 'rgba(219, 39, 119, 1)',
        },
      })}
    />
  );
};
