import ReactPlayer from 'react-player';

import { IAttachmentChatVideo, IImageGalleryItem, IMediaVideo } from 'interfaces';

interface MediaModalVideoProps {
  video: IMediaVideo | IImageGalleryItem | IAttachmentChatVideo;
}

export const MediaModalVideo = (props: MediaModalVideoProps) => {
  const { video } = props;
  const { libraryId, bunnyId, url } = video as any;

  return libraryId && bunnyId ? (
    <div className="aspect-w-16 aspect-h-9">
      <iframe
        title="title"
        src={`https://iframe.mediadelivery.net/embed/${libraryId}/${bunnyId}`}
        loading="lazy"
        style={{
          height: '100%',
          width: '100%',
        }}
        allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      />
    </div>
  ) : (
    <div className="flex bg-black justify-center">
      <ReactPlayer playing url={url} controls />
    </div>
  );
};
