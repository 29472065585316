import { useEffect, useState } from 'react';

// 3p
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// app
import { SignupForm } from 'components/common/auth';
import Loader from 'components/common/Loader';

import { useAuth } from 'hooks/useAuth';

import { ISignupForm } from 'interfaces';
import { AlertError, AlertSuccess } from 'components/common/alert';
import HttpStatusCode from 'utils/HttpStatusCode';

import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils';
import { useToast } from 'hooks';

export const SignupView = () => {
  let { role } = useParams<{ role: string }>() as { role: string };
  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [defaultValues, setDefaultValues] = useState<Partial<ISignupForm>>();

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);

  const { signup, isLoading, user } = useAuth();

  const navigate = useNavigate();

  const { toastError } = useToast();

  useEffect(() => {
    if (user) navigate('/app/dashboard', { replace: true });
  }, [user, navigate]);

  const onSubmit: SubmitHandler<ISignupForm> = async ({
    username,
    email,
    password,
    hasAdultConsent,
    termsConditionsPrivacyConsent,
    marketingConsent,
  }) => {
    setDefaultValues({
      username,
      email,
      hasAdultConsent,
      termsConditionsPrivacyConsent,
      marketingConsent,
    });

    try {
      const isCreatorBoolean: boolean = role === 'creator' ? true : false;
      const userNameCapitalized = capitalizeFirstLetter(username);

      await signup(
        userNameCapitalized,
        email,
        password,
        isCreatorBoolean,
        hasAdultConsent,
        termsConditionsPrivacyConsent,
        marketingConsent
      );

      setDefaultValues(undefined);
      setEmail(email);
      setIsSubmitSuccessful(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === HttpStatusCode.BAD_REQUEST.valueOf()) {
          toastError({ title: error.response.data });
          return;
        }
        if (error.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR.valueOf()) {
          setError(error.response.data);
          return;
        }
      } else {
        setError(error.message || "Errore generico. Contattare l'assistenza.");
      }
    }
  };

  const renderLoading = () => <Loader />;

  if (isLoading) return renderLoading();

  if (error)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertError message={error} />
      </div>
    );

  if (isSubmitSuccessful)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertSuccess
          message={`Ti abbiamo inviato un link all' indizzo ${email} per confermare la registrazione. Controlla nella casella posta in arrivo e spam.`}
        />
      </div>
    );

  return (
    <SignupForm
      defaultValues={defaultValues}
      error={error}
      onSubmit={onSubmit}
      role={role}
    />
  );
};
