// std
import { Fragment } from 'react';

// 3p
import { AxiosError } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';

// app
import { IAlbum, IResponseWithCursor } from 'interfaces';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';
import { AlbumCard } from 'components/common/album';
import { BasicEmptyState } from 'components/common/empty';

interface IAlbumGridProps {
  query: UseInfiniteQueryResult<IResponseWithCursor<IAlbum>, AxiosError<string, any>>;
  onAlbumClick: (album: IAlbum, title: string) => void;
  emptyMessage?: string;
  hasDelete?: boolean;
}

export const AlbumGrid = (props: IAlbumGridProps) => {
  const { query, onAlbumClick, emptyMessage = 'Non ci sono album', hasDelete } = props;

  const {
    isError,
    error,
    isLoading,
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = query;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderEmpty = () => <BasicEmptyState title={emptyMessage} />;

  const renderItem = (album: IAlbum) => (
    <AlbumCard
      key={album.id}
      data={album}
      onClick={() => onAlbumClick(album, album.title)}
      hasDelete={hasDelete}
    />
  );

  const renderData = () => {
    if (!data || !data.pages[0].data.length) {
      return renderEmpty();
    }

    return (
      <>
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-4">
          {data.pages.map((group) => group.data.map(renderItem))}
        </div>
        <div className="flex-1 flex justify-center mt-10">
          {isLoading || isFetchingNextPage ? (
            renderLoading()
          ) : hasNextPage ? (
            <button
              onClick={() => fetchNextPage()}
              className="text-white px-4 py-2 border bg-pink-600 text-lg font-medium rounded-md hover:bg-pink-700"
            >
              Mostra altri
            </button>
          ) : null}
        </div>
      </>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
