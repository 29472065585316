// std
import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';

// 3p
import { crateSocketConnection } from 'utils/Socket';

// app
import { useAuth } from 'hooks/useAuth';
import { Socket } from 'socket.io-client';
import APIClient from 'api/ApiClient';
import { ROLES } from 'config';

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const socketContext = createContext<{
  isConnected: boolean;
  socket: Socket | undefined;
  emit: (ev: string, args?: Record<string, any>) => void;
  emitWithAck: (ev: string, args?: Record<string, any>) => Promise<any>;
  on: (ev: string, listener: any) => void;
  off: (ev: string, listener: any) => void;
}>({} as any);

// Provider component that wraps your app and makes socket object ...
// ... available to any child component that calls useSocket().
export function SocketContextProvider({ children }: { children: JSX.Element }) {
  const socket = useProvideSocket();
  return <socketContext.Provider value={socket}>{children}</socketContext.Provider>;
}

// Hook for child components to get the socket object ...
// ... and re-render when it changes.
export const useSocket = () => {
  return useContext(socketContext);
};

// Provider hook that creates socket object and handles state
function useProvideSocket() {
  const [accessToken, setAccessToken] = useState<string>();
  const [refreshToken, setRefreshToken] = useState<string>();

  const [socket, setSocket] = useState<Socket>();

  const { user } = useAuth();

  const requestAccessToken = useCallback(async () => {
    try {
      const { data } = await APIClient.get<{ accessToken: string; refreshToken: string }>(
        '/communications/token'
      );

      const { accessToken, refreshToken } = data;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    } catch (e) {
      console.log('Error fetching socket token');
    }
  }, []);

  const requestRefreshToken = useCallback(async () => {
    try {
      const { data } = await APIClient.post<{ accessToken: string }>(
        '/communications/token/refresh',
        { token: refreshToken }
      );

      const { accessToken } = data;

      setAccessToken(accessToken);
    } catch (e) {
      console.log('Error fetching socket token');
    }
  }, [refreshToken]);

  // Sono loggato, richiedo i token per la socket
  useEffect(() => {
    if (!user || user.role.code === ROLES.ADMIN) return;

    requestAccessToken();

    // Request Access Token
    return () => {
      setAccessToken(undefined);
      setRefreshToken(undefined);
    };
  }, [user, requestAccessToken]);

  useEffect(() => {
    if (!accessToken) return;

    const socket = crateSocketConnection(accessToken);

    function onConnect() {
      console.log('Connect to socket');
      setSocket(socket);
    }

    function onDisconnect() {
      console.log('Disconnect to socket');
      setSocket(undefined);
    }

    function onError(err: any) {
      console.error('Error on socket', err);
      // requestRefreshToken();
      // if (err.message === 'Authentication error') {
      //   // Tentativo di rinnovare il token di accesso
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   const response = await axios.post(`${SERVER_URL}/refresh_token`, {
      //     refreshToken,
      //   });
      //   if (response.status === 200) {
      //     localStorage.setItem('accessToken', response.data.accessToken);
      //     connectSocket(); // Riconnetti il socket con il nuovo token
      //   } else {
      //     console.log('Session expired. Please log in again.');
      //     // Reindirizza all'area di login o mostra un messaggio
      //   }
      // }
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onError);

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);

      socket.disconnect();
    };
  }, [accessToken, requestRefreshToken]);

  const isConnected = useMemo(() => {
    if (!socket) return false;
    return socket.connected;
  }, [socket]);

  const emit = useCallback(
    (ev: string, args?: Record<string, any>) => {
      if (!socket || !isConnected) return;
      socket.emit(ev, args);
    },
    [socket, isConnected]
  );

  const emitWithAck = useCallback(
    async (ev: string, args?: Record<string, any>) => {
      if (!socket || !isConnected) return;
      return socket.emitWithAck(ev, args);
    },
    [socket, isConnected]
  );

  const on = useCallback(
    (ev: string, listener: any) => {
      if (!socket || !isConnected) return;
      console.log('socket:on', ev);
      socket.on(ev, listener);
    },
    [socket, isConnected]
  );

  const off = useCallback(
    (ev: string, listener: any) => {
      if (!socket || !isConnected) return;
      socket.off(ev, listener);
    },
    [socket, isConnected]
  );

  // Return the user object and socket methods
  return Object.freeze({
    isConnected,
    socket,
    emit,
    emitWithAck,
    on,
    off,
  });
}
