import { RadioGroup } from '@headlessui/react';
import { classNames } from 'utils';
import { IPaymentSource } from 'interfaces';

interface IRadioCardsImageProps {
  options?: IPaymentSource[];
  onChange: (e: any) => void;
  value: string;
}

export default function RadioCardsImage(props: IRadioCardsImageProps) {
  const { options, onChange, value } = props;

  return (
    <RadioGroup
      value={value}
      onChange={(e: any) => {
        onChange(e);
      }}
    >
      <div className="space-y-4 mt-1">
        {options &&
          options.length &&
          options.map((option) => (
            <RadioGroup.Option
              key={option.code}
              value={option.code}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-pink-500 ring-2 ring-pink-500' : '',
                  'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex justify-center'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className="flex justify-center">
                    <span className="flex flex-col text-sm">
                      <RadioGroup.Description as="span" className="text-gray-500">
                        <img
                          className="rounded-t-lg w-full h-24"
                          src={
                            option.logoUrl
                              ? option.logoUrl
                              : 'http://via.placeholder.com/200x200'
                          }
                          alt={option.code}
                        ></img>
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-pink-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
}
