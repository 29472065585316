import Select from 'react-select';

import { useGetAllTag } from 'api/common';
import { ITag } from 'interfaces';

interface ISelectTag {
  selectProps?: any;
}

export const SelectTag = (props: ISelectTag) => {
  const { selectProps } = props;

  const { getAllTagQuery } = useGetAllTag();
  const { data: getAllTagResponse } = getAllTagQuery;

  return (
    <Select
      isMulti
      getOptionValue={(item: ITag) => item.id.toString()}
      options={getAllTagResponse?.items}
      placeholder="Seleziona servizi"
      className="w-full text-base border-gray-300 focus:outline-none focus:ring-pink-600 focus:border-pink-600 sm:text-sm rounded-md"
      {...selectProps}
      styles={{
        input: (base) => ({
          ...base,
          'input:focus': {
            boxShadow: 'none',
          },
        }),
        multiValue: (base) => ({
          ...base,
          background: 'rgba(219, 39, 119, 0.75)',
          borderRadius: '4px',
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: 'rgb(255,255,255)',
        }),
        multiValueRemove: (base) => ({
          ...base,
          color: 'rgb(255,255,255)',
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(219, 39, 119, 0.25)',
          primary75: 'rgba(219, 39, 119, 0.75)',
          primary50: 'rgba(219, 39, 119, 0.50)',
          primary: 'rgba(219, 39, 119, 1)',
        },
      })}
    />
  );
};
