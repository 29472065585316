// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_TRANSFER } from 'config/Enviroment';
import { ITransfertSummary } from 'interfaces';

import APIClient from '../ApiClient';

const getSummary = async () => {
  const { data } = await APIClient.get<ITransfertSummary>(ENV_TRANSFER.GET_SUMMARY);

  return data;
};

export function useGetSummary() {
  const getSummaryQuery = useQuery<ITransfertSummary, AxiosError<string, any>>(
    [ENV_TRANSFER.GET_SUMMARY],
    () => getSummary()
  );

  return { getSummaryQuery };
}
