import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// app
import CreatorLoginView from 'views/web/auth/Login';

import Faq from './Faq';
import Home from './Home';
import Support from './Support';
import Report from './Report';

import { Success } from './Success';
import PageNotFound from './PageNotFound';

// legal
import { Terms, Privacy, Statment2257, Refund, Dmca } from './legal';

// reset password
import { AccountRecoveryView, ResetPasswordView, SignupView } from './auth';

import { SignupCustomerConfirm, SignupCreatorConfirm } from 'components/common/auth';

import ProfileWebsiteView from './profile';
import FaqCreator from './FaqCreator';

function OldRouteRedirect() {
  const location = useLocation();
  const newPathame = location.pathname.replace('/p', '');
  return <Navigate to={newPathame} replace />;
}

export const WebRouter = () => {
  return (
    <Routes>
      <Route path="/faq" element={<Faq />} />

      <Route path="/faqCreator" element={<FaqCreator />} />

      <Route path="/report" element={<Report />} />

      <Route path="/support" element={<Support />} />

      <Route path="/success" element={<Success />} />

      <Route path="/legal/terms" element={<Terms />} />

      <Route path="/legal/privacy" element={<Privacy />} />

      <Route path="/legal/statment2257" element={<Statment2257 />} />

      <Route path="/legal/dmca" element={<Dmca />} />

      <Route path="/legal/refund" element={<Refund />} />

      <Route path="/login" element={<CreatorLoginView />} />

      <Route path="/recovery" element={<AccountRecoveryView />} />

      <Route path="/reset/:token" element={<ResetPasswordView />} />

      <Route path="/p/:username/*" element={<OldRouteRedirect />} />

      <Route path="/:username/*" element={<ProfileWebsiteView />} />

      <Route path="/signup/:role" element={<SignupView />} />

      {/** TODO cambiare con una sola Route signup/confirm/:token e un
       * solo componente <SignupConfirmView /> dove all' interno in base
       * al query param isCreator=true o false viene renderizzato il
       * componente <SignupCustomerConfirm /> o <SignupCreatorConfirm />
       * ved. <SignupView />
       */}
      <Route path="/signup/customer/confirm/:token" element={<SignupCustomerConfirm />} />

      <Route path="/signup/creator/confirm/:token" element={<SignupCreatorConfirm />} />

      <Route index element={<Home />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
