// std
// import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_USER } from 'config/Enviroment';
import { ICreator } from 'interfaces';

import APIClient from '../ApiClient';

const getUserProfile = async (username: string) => {
  if (!username) {
    throw new Error('User undefined');
  }

  const url = ENV_USER.GET_PROFILE(username);

  const { data } = await APIClient.get<ICreator>(url);

  return data;
};

export function useGetUserProfile(username: string) {
  const url = ENV_USER.GET_PROFILE(username);

  const getUserProfileQuery = useQuery<ICreator, AxiosError<string, any>>([url], () =>
    getUserProfile(username)
  );

  return {
    getUserProfileQuery,
  };
}
