// std
import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ALBUM } from 'config/Enviroment';
import { IAlbum } from 'interfaces';

import APIClient from '../ApiClient';

const getAlbum = async (albumId: string | number) => {
  const { data } = await APIClient.get<IAlbum>(ENV_ALBUM.GET_ALBUM(albumId));

  return data;
};

export function useGetAlbum(albumId: string | number) {
  const url = useMemo(() => ENV_ALBUM.GET_ALBUM(albumId), [albumId]);

  const getAlbumQuery = useQuery<IAlbum, AxiosError<string, any>>([url], () =>
    getAlbum(albumId)
  );

  return {
    getAlbumQuery,
  };
}
