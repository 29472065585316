/* This example requires Tailwind CSS v2.0+ */
import { useRef } from 'react';

// 3p
import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

// app
import { IAlbum } from 'interfaces';
import { useDeleteAlbum } from 'api/album';
import { ModalLayout } from 'components/common';

interface IAlbumDeleteModalProps {
  open: boolean;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onClose: () => void;
  data?: IAlbum;
}

export function AlbumDeleteModal(props: IAlbumDeleteModalProps) {
  const { open, onClose, data, onSuccess, onError } = props;

  const cancelButtonRef = useRef(null);

  const { deleteAlbumMutation } = useDeleteAlbum();
  const { mutateAsync: mutateDeleteAlbum } = deleteAlbumMutation;

  const handleRemoveAlbum = async () => {
    if (!data) return;

    try {
      await mutateDeleteAlbum(data);
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      console.error(error);
    }
  };

  return (
    <ModalLayout open={open} onClose={onClose}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Eliminazione album
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Rimuovere definitivamente questo album?
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleRemoveAlbum}
        >
          Elimina
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
          ref={cancelButtonRef}
        >
          Annulla
        </button>
      </div>
    </ModalLayout>
  );
}
