// std
import { useEffect, useState } from 'react';

// 3p
import { useToast } from 'hooks';
import { AxiosError } from 'axios';

//app
import { IModal, IRestClientError } from 'interfaces';

import { formatDate, formatMoney } from 'utils';

import { useGetAllPayout } from 'api/transfer';
import { DEFAULT_LIMIT_PAYOUT } from 'config/Enviroment';

import { Loader, TransferStatus, AlertError, BasicEmptyState } from 'components/common';
import { PayoutModal } from 'components/creator';

export const PayoutTable = () => {
  const [page, setPage] = useState(0);

  const { getAllPayoutQuery, setCurrSkip } = useGetAllPayout(page, DEFAULT_LIMIT_PAYOUT);
  const { isError, error, isLoading, data } = getAllPayoutQuery;

  useEffect(() => {
    setCurrSkip(page);
  }, [page, setCurrSkip]);

  const prevPage = () => {
    if (page >= DEFAULT_LIMIT_PAYOUT) setPage(page - DEFAULT_LIMIT_PAYOUT);
  };

  const nextPage = () => {
    setPage(page + DEFAULT_LIMIT_PAYOUT);
  };

  const items = data?.items ?? [];

  const { toastError, toastSuccess } = useToast();

  const [payoutFormModal, setPayoutFormModal] = useState<IModal<any>>({
    open: false,
    data: { avaibleNow: 100 },
  });

  const renderEmptyState = () => {
    return (
      <BasicEmptyState
        title="Nessun dato"
        descr="Non è stato effettuato nessun versamento"
      />
    );
  };

  const onPayoutFormModalClose = () => {
    setPayoutFormModal({ open: false });
  };

  const onPayoutFormModalSuccess = () => {
    setPayoutFormModal({ open: false });

    toastSuccess({
      title: 'Operazione eseguita',
      message: 'Richiesta di prelivero inviata correttamente',
    });
  };

  const onPayoutFormModalError = (error: AxiosError<IRestClientError>) => {
    const data = error.response?.data;

    if (data) {
      toastError({
        title: 'Impossibile continuare',
        message: data.message,
      });
    }
  };

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  // if (!items.length) {
  //   return renderEmptyState();
  // }

  const renderData = () => (
    <>
      <PayoutModal
        {...payoutFormModal}
        onClose={onPayoutFormModalClose}
        onSuccess={onPayoutFormModalSuccess}
        onError={onPayoutFormModalError}
      />
      <div className="bg-white overflow-hidden shadow sm:rounded-lg mt-4 border-b border-gray-200">
        {/* header */}
        <div className="border-b border-gray-200 px-4 py-5 sm:px-6 -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-700">
              Cronologia prelievi
            </h3>
          </div>
          <div className="ml-4 mt-2 shrink-0">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              onClick={() => setPayoutFormModal({ open: true })}
            >
              Richiedi prelievo
            </button>
          </div>
        </div>
        {items.length ? (
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Data richiesta
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Codice
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Lordo
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Piattaforma
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Importo
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      {/* <th scope="col" className="relative px-6 py-3">
                         <span className="sr-only">Edit</span>
                       </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {items.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <time dateTime={formatDate(transaction.startAt)}>
                            {formatDate(transaction.startAt)}
                          </time>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {transaction.code.substring(transaction.code.length - 5)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                          {formatMoney(transaction.transaction.amount, {
                            currency: transaction.transaction.currency,
                          })}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                          {formatMoney(transaction.transaction.fee, {
                            currency: transaction.transaction.currency,
                          })}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                          {formatMoney(transaction.transaction.net, {
                            currency: transaction.transaction.currency,
                          })}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <TransferStatus status={transaction.status} />
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                           <a
                             href="#"
                             className="text-pink-600 hover:text-pink-900"
                           >
                             Edit
                           </a>
                         </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination */}
                <nav
                  className="rounded-b-md bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                  aria-label="Pagination"
                >
                  {/* <div className="hidden sm:block">
            <p className="text-sm text-gray-700">
              Mostro <span className="font-medium">1</span> a{' '}
              <span className="font-medium">10</span> di{' '}
              <span className="font-medium">20</span> risultati
            </p>
          </div> */}
                  <div className="flex-1 flex justify-between sm:justify-end">
                    {page !== 0 ? (
                      <button
                        onClick={prevPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Precedenti
                      </button>
                    ) : (
                      <div className="relative inline-flex items-center px-4 py-2" />
                    )}
                    <button
                      onClick={nextPage}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Successivi
                    </button>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* <div className="flex flex-col mt-2">

      </div> */}
    </>
  );

  return isLoading
    ? renderLoading()
    : isError
    ? renderError()
    : data
    ? renderData()
    : renderEmptyState();
};
