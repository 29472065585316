// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import APIClient from 'api/ApiClient';

const setUserUnlock = async (userId: string | number) => {
  const { data } = await APIClient.put<void>(ENV_ADMIN.SET_BLOCKAT_TO_NULL(userId));
  return data;
};

export function useSetUserUnlock(userId: string | number) {
  const queryClient = useQueryClient();
  const setUserUnlockMutation = useMutation<void, AxiosError, string | number>(
    (userId) => setUserUnlock(userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ONE(userId));
      },
    }
  );

  return {
    setUserUnlockMutation,
  };
}
