// app
import { IPayInCouponResponse } from 'interfaces';
import { formatMoney } from 'utils';

import { AlertError } from 'components/common/alert';
import Loader from 'components/common/Loader';

import { WalletTopUpModal, WalletCouponModal } from 'components/customer/payment';

import { useGetSummary } from 'api/transfer';
import { useModal, useToast } from 'hooks';

export function WalletSummary() {
  const {
    openModal: walletTopUpModalOpen,
    closeModal: walletTopUpModalClose,
    modalData: topupModalData,
  } = useModal();

  const {
    openModal: couponRedeemModalOpen,
    closeModal: couponRedeemModalClose,
    modalData: couponRedeemModalData,
  } = useModal();

  const { getSummaryQuery } = useGetSummary();
  const { data, error, isLoading, isError } = getSummaryQuery;

  const { toastSuccess } = useToast();

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const onRedeemSuccess = (coupon: IPayInCouponResponse) => {
    const { amount } = coupon;

    toastSuccess({
      title: 'Operazione completata',
      message: `Abbiamo accreditato sul tuo wallet ${formatMoney(amount)}`,
    });

    couponRedeemModalClose();
  };

  if (isLoading) return renderLoading();

  if (isError) return renderError();

  if (!data) return null;

  return (
    <>
      <WalletTopUpModal {...topupModalData} onClose={walletTopUpModalClose} />
      <WalletCouponModal
        {...couponRedeemModalData}
        onClose={couponRedeemModalClose}
        onSuccess={onRedeemSuccess}
      />
      <div className="bg-white overflow-hidden sm:shadow rounded-md mb-4">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-items-end">
            <div className="min-w-0 grow flex flex-col">
              <h3 className="text-md font-bold text-gray-500 sm:text-md">Saldo totale</h3>
              <h2 className="text-2xl font-bold leading-7 text-gray-600 sm:text-3xl">
                {formatMoney(data.availableDeposit)}
              </h2>
            </div>
            <div className="mt-4 flex mt-0 ml-4 flex sm:flex-row flex-col">
              <button
                onClick={couponRedeemModalOpen}
                type="button"
                className="sm:ml-3 sm:w-auto inline-flex w-full justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                Riscatta coupon
              </button>
              <button
                onClick={walletTopUpModalOpen}
                type="button"
                className="mt-3 sm:mt-0 sm:ml-3 sm:w-auto inline-flex w-full justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                Ricarica
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
