// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_PAYOUT, ENV_TRANSFER } from 'config/Enviroment';
import { IPayoutRequest } from 'interfaces';

import APIClient from '../ApiClient';

const createPayOut = async (body: IPayoutRequest) => {
  const { data } = await APIClient.post<IPayoutRequest>(ENV_PAYOUT.CREATE_PAYOUT, body);
  return data;
};

export function useCreatePayOut() {
  const queryClient = useQueryClient();

  const createPayOutMutation = useMutation<IPayoutRequest, AxiosError, IPayoutRequest>(
    (data) => createPayOut(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_PAYOUT.GET_ALL_PAYOUT);
        queryClient.invalidateQueries(ENV_TRANSFER.GET_SUMMARY);
      },
    }
  );

  return {
    createPayOutMutation,
  };
}
