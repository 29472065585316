// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_ADMIN } from 'config/Enviroment';

import APIClient from '../../ApiClient';

const mediaConfirm = async (objRequest: any) => {
  const { mediaId, mediaType, hasApprove } = objRequest;
  await APIClient.put<void>(ENV_ADMIN.SET_APPROVE_MEDIA(mediaId), {
    mediaType,
    hasApprove,
  });
};

export function useApproveMedia() {
  const queryClient = useQueryClient();

  const useApproveMediaMutation = useMutation<void, AxiosError, any>(
    (objRequest) => mediaConfirm(objRequest),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_MEDIA);
      },
    }
  );

  return { useApproveMediaMutation };
}
