// app
import { AlertError } from 'components/common/alert';
import Loader from 'components/common/Loader';

import { useAuth } from 'hooks/useAuth';
import { useGetUserProfile } from 'api/user';

import ProfileDataForm from './ProfileDataForm';
import ProfileVisibilityForm from './ProfileVisibilityForm';
import ProfilePhotosForm from './ProfilePhotosForm';

export default function ProfileTab() {
  const { user } = useAuth();

  // Profile Data
  const { getUserProfileQuery } = useGetUserProfile(user!.username);
  const { isError, error, isLoading, data } = getUserProfileQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  if (isError) return renderError();

  if (isLoading) return renderLoading();

  // Non ho ricevuto dati
  if (!data) return renderError();

  return (
    <>
      {/* Hide profile card */}
      <ProfilePhotosForm data={data} />

      <ProfileDataForm data={data} />

      <ProfileVisibilityForm data={data} />
    </>
  );
}
