// std
// import { useMemo } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_PAYOUT } from 'config/Enviroment';
import { IBankAccount } from 'interfaces';

import APIClient from '../ApiClient';

const getUserProfile = async () => {
  const { data } = await APIClient.get<IBankAccount[]>(ENV_PAYOUT.GET_ALL_BANK_ACCOUNT);

  return data;
};

export function useGetAllBankAccount() {
  const getAllBankAccountQuery = useQuery<IBankAccount[], AxiosError<string, any>>(
    [ENV_PAYOUT.GET_ALL_BANK_ACCOUNT],
    () => getUserProfile()
  );

  return { getAllBankAccountQuery };
}
