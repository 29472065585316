// 3p
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';

// app
import { ROLES, TrasnferStatusEnum } from 'config';
import { IResponseWithCursor, ITransactionPayout } from 'interfaces';

import {
  Loader,
  AlertError,
  BasicEmptyState,
  TransferStatus,
  UserImage,
} from 'components/common';

import { formatMoney } from 'utils';
import { Link } from 'react-router-dom';

interface IPayoutTableProps {
  query: UseQueryResult<IResponseWithCursor<ITransactionPayout>, AxiosError<string, any>>;
  onChangeStatus?: (item: ITransactionPayout) => void;
  setCursor?: React.Dispatch<any>;
}

export const PayoutTable = (props: IPayoutTableProps) => {
  const { query, onChangeStatus, setCursor } = props;

  const { isError, error, isLoading, data, isFetching } = query;

  const fetchNextPage = () => {
    if (data?.cursor.afterCursor && setCursor)
      setCursor({ afterCursor: data.cursor.afterCursor });
  };

  const fetchPreviousPage = () => {
    if (data?.cursor.beforeCursor && setCursor)
      setCursor({ beforeCursor: data.cursor.beforeCursor });
  };

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderEmpty = () => (
    <BasicEmptyState title={'Non ci sono richieste di versamenti'} />
  );

  const renderRow = (transfer: ITransactionPayout) => {
    const isPending = transfer.status.code === TrasnferStatusEnum.PENDING;
    const transferCode = transfer.code.substring(transfer.code.length - 5);

    const transactionAmount = formatMoney(transfer.transaction.amount, {
      currency: transfer.transaction.currency,
    });

    const transactionFee = formatMoney(transfer.transaction.fee, {
      currency: transfer.transaction.currency,
    });

    const transactionNet = formatMoney(transfer.transaction.net, {
      currency: transfer.transaction.currency,
    });

    const sourceUser = transfer.sourceUser;
    const bankAccount = transfer.paymentOut.bankAccount;

    return (
      <tr key={transfer.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
          <div className="flex items-center">
            <div className="h-12 w-12 shrink-0">
              <UserImage
                image={sourceUser.image}
                alt={sourceUser.username}
                className="h-12 w-12 rounded-full"
              />
            </div>
            <div className="ml-4">
              <Link
                to={
                  sourceUser.role && sourceUser.role.code === ROLES.CREATOR
                    ? `/${sourceUser.username}`
                    : `/admin/users/customers/${sourceUser.id}`
                }
              >
                <div className="font-medium text-gray-900">{sourceUser.username}</div>
                {/* <div className="text-gray-500">{transfer.email}</div> */}
              </Link>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {transferCode}
        </td>
        <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500">
          <div className="text-gray-900">{bankAccount.beneficiaryName}</div>
          <div className="text-gray-500">{bankAccount.IBAN}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-500 font-normal">{transactionAmount}</span>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-500 font-normal">{transactionFee}</span>
        </td>

        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          <span className="text-gray-500 font-semibold">{transactionNet}</span>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <TransferStatus status={transfer.status} />
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            onClick={() => {
              if (onChangeStatus) onChangeStatus(transfer);
            }}
            className="text-pink-600 hover:text-pink-900"
          >
            {isPending ? 'Conferma' : 'Visualizza'}
            <span className="sr-only">, richiesta {transfer.code}</span>
          </button>
        </td>
      </tr>
    );
  };

  const renderData = () => (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg leading-6 font-medium text-gray-900">Richieste</h1>
          {/* <p className="mt-2 text-sm text-gray-700">
              La lista di tutte le richieste di versamento effettuate dai creators.
            </p> */}
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          {/* <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-pink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 sm:w-auto"
          >
            Add user
          </button> */}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-900 tracking-wider"
                    >
                      Codice
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Estremi
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-900 tracking-wider"
                    >
                      Lordo
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-900 tracking-wider"
                    >
                      Piattaforma
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-900 tracking-wider"
                    >
                      Netto
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Stato
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data && data.data.map(renderRow)}
                </tbody>
              </table>

              {setCursor && (
                <nav
                  className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  <div className="flex justify-between sm:justify-end">
                    {data?.cursor.beforeCursor && (
                      <button
                        disabled={isFetching}
                        onClick={fetchPreviousPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Precedenti
                      </button>
                    )}
                    {data?.cursor.afterCursor && (
                      <button
                        disabled={isFetching}
                        onClick={fetchNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Successivi
                      </button>
                    )}
                  </div>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return isLoading
    ? renderLoading()
    : isError
    ? renderError()
    : !data?.data.length
    ? renderEmpty()
    : renderData();
};
