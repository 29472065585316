// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_USER } from 'config/Enviroment';
import { IProfile } from 'interfaces';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks/useAuth';

const updateUserProfile = async (product: IProfile) => {
  const { data } = await APIClient.put<IProfile>(ENV_USER.UPDATE_PROFILE, product);
  return data;
};

export function useSaveUserProfile() {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const saveUserPorfileMutation = useMutation<IProfile, AxiosError, IProfile>(
    (data) => updateUserProfile(data),
    {
      onSuccess: () => {
        if (user) {
          const { username } = user;
          const url = ENV_USER.GET_PROFILE(username);
          queryClient.invalidateQueries(url);
        }
      },
    }
  );

  return {
    saveUserPorfileMutation,
  };
}
