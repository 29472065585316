// 3p

// App
import { IAlbum } from 'interfaces';

import { DEFAULT_LIMIT } from 'config/Enviroment';

import { AlbumGrid } from 'components/common/album';

import { useGetAllUserPurchase } from 'api/user';
import { useNavigate } from 'react-router-dom';

function PurchaseView() {
  const navigate = useNavigate();

  const { getAllUserPurchaseQuery } = useGetAllUserPurchase({
    limit: DEFAULT_LIMIT,
    order: 'DESC',
  });

  const handleAlbumClick = (album: IAlbum, title: string) => {
    navigate(`/${album.user.username}/albums/${album.id}`, {
      state: { albumTitle: title },
    });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <div className="px-1 py-5 sm:p-6">
        <AlbumGrid
          query={getAllUserPurchaseQuery}
          onAlbumClick={handleAlbumClick}
          emptyMessage="Non sono presenti acquisti"
        />
      </div>
    </div>
  );
}

export default PurchaseView;
