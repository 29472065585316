// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';
import { IResponseWithCursor, IUserTableRow } from 'interfaces';

import APIClient from '../../ApiClient';

const getAllUser = async (params: any) => {
  const { queryKey } = params;
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IUserTableRow>>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
    },
  });
  return data;
};

export function useGetAllUser(searchQuery: any, pagingQuery: any) {
  const getAllUserQuery = useQuery<
    IResponseWithCursor<IUserTableRow>,
    AxiosError<string, any>
  >([ENV_ADMIN.GET_ALL, searchQuery, pagingQuery], getAllUser, {
    keepPreviousData: true,
  });

  return {
    getAllUserQuery,
  };
}
