import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';

const customersFaqs = [
  {
    question: 'Perchè usare vieniora.com per pagare i Creators?',
    answer:
      'Ti garantiamo l’anonimato nei loro confronti (vedranno solo email o nome utente se sei registrato). Ti garantiamo dalle truffe, in quanto in caso del mancato servizio, verrai rimborsato. Puoi pagare con tutte le carte di credito (compresa la Postepay), Paypal, PaysafeCard e Crypto. I nostri processori di pagamento sono tra i più affidabili online!',
  },
  {
    question:
      'Ho avuto un problema con un Creator per un servizio, posso avere un rimborso?',
    answer:
      'Se hai pagato per un servizio che non hai ricevuto cerca prima, se possibile, di risolvere il problema con il creator in quanto lei ha la facoltà di effettuare il rimborso. In caso non ci riesci, contattaci alla pagina del supporto (cliccabile) tramite form,whatsapp, telegram o email entro 60 giorni dal pagamento (oltre questo termine non possiamo garantirti il rimborso).',
  },
  {
    question: 'Come posso acquistare tranquillamente?',
    answer:
      'Tutte i creators sono certificati, cioè garantiamo che sono davvero le persone raffigurate nei contenuti da loro caricati, e utilizzando i nostri servizi di pagamento sarai garantito al 100%, in quanto in caso di mancato servizio potrai essere rimborsato direttamente da noi con un servizio da parte di un’altra ragazza o rimborso sul conto che hai utilizzato per effettuare il pagamento.',
  },
  {
    question: 'Sull’estratto conto della carta si capirà che servizio ho acquistato?',
    answer:
      'Assolutamente no, in quanto apparirà una transazione con una descrizione che non vuol dire nulla e non è riconducibile a nessun servizio per adulti.',
  },
  {
    question: 'Conviene registrarmi su VieniOra.com?',
    answer:
      'Creando un account sulla nostra piattaforma avrai accesso ad i servizi della chat per poter contattare i creators che più ti interessano e avrai accesso ad un’area personale denominata "media acquistati" dove troverai tutti i media da te comprati in precedenza, avrai la possibilità di ricaricare il tuo wallet cliente e spenderlo con molteplici creator. Potrai ricevere email che ti avvisano di offerte e promozioni, potremo inoltre mandarti codici sconto o cupon da inserire nella tua area personale per ricevere un credito bonus GRATUITAMENTE.',
  },
  {
    question: 'Cose ASSOLUTAMENTE VIETATE!',
    answer: `È assolutamente vietato, come descritto già nei nostri termini e condizioni di:
    <ul>
    <li>• Chiedere incontri ai creators;</li>
    <li>• Chiedere o lasciare contatti esterni (tipo whatsapp, Telegram, Skype ecc…) alla piattaforma per garantire un legale svolgimento dei servizi e tutelare tutti gli utenti;</li>
    <li>• Registrare, duplicare o divulgare i contenuti e/o servizi acquistati sulla piattaforma;</li>
    </ul>
    <br />
    Ci teniamo a precisare che ogni comportamento scorretto o non educato verso un utente o creator della piattaforma verrà preso seriamente in esame per garantire a tutti gli user una esperienza piacevole sulla nostra piattaforma. 
    <br /><br />
    Ogni comportamento che viola i termini e condizioni verrà punito con un Ban immediato dalla piattaforma.`,
  },
  {
    question: 'Funzionalità CHAT E VIDEOCHAT',
    answer: `Sulla nostra piattaforma c’è la possibilità di contattare direttamente il creator al quale siete interessati tramite il bottone apposito nella sua pagina con scritto “Scrivimi”. Potete usare la funzionalità chat per mettervi d’accordo con il creator per un acquisto personalizzato o accordarvi per una videochiamata. I Clienti non hanno la possibilità di videochiamare un creator per evitare usi impropri della funzionalità ma una volta accordati il creator potrà videochiamavi senza nessun problema.`,
  },
];

const creatorsFaqs = [
  {
    question: 'Come posso guadagnare con VieniOra.com?',
    answer:
      'Il nostro sito è aperto a tutti i creatori di contenuti per adulti, potrai creare un listino dei servizi da te offerti e una sezione album foto e video dove potrai inserire anche dei contenuti da sbloccare a pagamento. Una volta concordati con il cliente puoi mandarlo alla tua pagina personale sul nostro sito per ricevere il compenso per il servizio richiesto. All’interno della tua area personale una volta effettuato l’accesso avrai la possibilità di inserire le tue foto e personalizzare tutta la tua pagina personale come meglio preferisci. ',
  },
  {
    question: 'Perché VieniOra.com è differente dagli altri siti?  ',
    answer:
      'Grazie alla nostra piattaforma chiunque sarà in grado di guadagnare GRAZIE AI CLIENTI DEL SITO. Una volta registrata/o apparirai nella home principale e i clienti potranno scriverti tramite la nostra chat per contattarti. A differenza di molti siti noi ti offriamo la visibilità e sopratutto abbiamo una chat che permette di effettuare anche le videochiamate e invio di foto/video/audio quindi potrai tranquillamente effettuare tutti i servizi sulla nostra piattaforma e garantiamo il completo anonimato.',
  },
  {
    question: 'È garantito il mio anonimato?',
    answer:
      'Assolutamente sì, il cliente pagherà direttamente VieniOra.com senza aver accesso ai tuoi dati personali. È richiesto un fronte e retro del documento di identità da te in possesso ed un selfie con il documento stesso ben leggibile, tutto questo per garantire la legalità per quanto riguarda la verifica della maggiore età del creator. ',
  },
  {
    question:
      "Sull'estratto conto della carta del cliente si capirà che servizio ha acquistato?",
    answer:
      'Assolutamente no. Ovviamente la dicitura della descrizione dipenderà soprattutto dal metodo di pagamento che il cliente sceglierà ma ci assicuriamo che ogni transazione non contenga in nessun modo una descrizione riconducibile ad un servizio per adulti.',
  },
  {
    question: 'Come e quando verrò pagata?',
    answer: `
      Per quanto riguarda la modalità di pagamento, all’inizio della registrazione ti verrà chiesto il tuo IBAN sul quale ricevere il bonifico. (Il conto deve essere OBBLIGATORIAMENTE intestato al nome del creator).<br /> 
      Ogni volta che si riceve un pagamento potrai visualizzare l’importo ricevuto nel tuo portafoglio virtuale nella sezione “Bilancio”. Appena ricevuto un pagamento verrà visualizzato nella sezione “Presto Disponibili”, dopo 15 giorni dal pagamento ricevuto verranno spostati nella sezione “Ora Disponibili” questo sta a significare che possono essere prelevati (Minimo prelievo 50€). <br /> 
      Una volta richiesto il prelievo dal nostro sito il bonifico verrà effettuato entro 3 giorni lavorativi (di solito riusciamo a farlo molto prima).`,
  },
  {
    question: 'Quanto costa usufruire della vostra piattaforma?',
    answer: `
      La nostra piattaforma non richiede nessun pagamento anticipato, tratteniamo una percentuale del 30%.<br />
      Incassando su conto corrente bancario in Europa non avrai nessun costo aggiuntivo se l'importo prelevato è di almeno 250€, altrimenti ti verranno addebitati 3€ per le spese di bonifico.<br />
      Incassando su conto corrente bancario in area NON EUROPEO non avrai nessun costo aggiuntivo se l'importo prelevato è di almeno 750€, altrimenti ti verranno addebitati 20€ per le spese.`,
  },
  {
    question: 'Come Funziona la chat?',
    answer: `
      All’interno della chat i creators hanno la possibilità di chattare con i clienti per accordare un servizio. <br />
      I Creator hanno anche la possibilità di videochiamare i clienti nel caso il servizio richiesto sia una videochiamata.`,
  },
  {
    question: "Come avere la sicurezza dell'avvenuto pagamento?",
    answer:
      'Riceverai una email oppure potrai controllare all’interno della tua Dashboard nell’area personale dove troverai tutte le transazioni ricevute.',
  },
  {
    question: 'È possibile effettuare rimborsi ai clienti?',
    answer:
      'Sì, nella lista delle transazioni avrai la possibilità tramite il bottone "Rimborsa". Una volta avviata la procedura di rimborso questo verrà effettuato entro 24h lavorative (solitamente riusciamo molto prima).',
  },
  {
    question: 'Cose ASSOLUTAMENTE VIETATE per non essere bannati!',
    answer: `
    È assolutamente vietato, come descritto già nei nostri termini e condizioni di: 
    <ul>
    <li>• Proporre incontri;</li>
    <li>• Chiedere o lasciare contatti esterni (tipo whatsapp, Telegram, Skype ecc…) alla piattaforma per garantire un legale svolgimento dei servizi e tutelare tutti gli utenti;</li>
    <li>• Accettare o Proporre pagamenti esterni, tutti i pagamenti dovranno essere ricevuti dalla nostra piattaforma per garantire la sicurezza e la legalità degli utenti e dei servizi;</li> 
    <li>• Inserimento di foto o video rubati o senza il consenso di tutte le persone presenti in essi;</li>
    </ul>
    <br />
    Ci teniamo a precisare che ogni comportamento scorretto o non educato verso un utente o creator della piattaforma verrà preso seriamente in esame per garantire a tutti gli user una esperienza piacevole sulla nostra piattaforma. 
    <br /><br />
    Ogni comportamento che viola i termini e condizioni verrà punito con un Ban immediato dalla piattaforma.`,
  },
];

function CustomersFaqs() {
  return (
    <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
      <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
        FAQ Clienti
      </h2>
      <dl className="mt-6 space-y-6 divide-y divide-gray-200">
        {customersFaqs.map((faq) => (
          <Disclosure as="div" key={faq.question} className="pt-6">
            {({ open }) => (
              <>
                <dt className="text-lg">
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-medium text-gray-900">{faq.question}</span>
                    <span className="ml-6 h-7 flex items-center">
                      <ChevronDownIcon
                        className={classNames(
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform'
                        )}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel className="mt-2 pr-12">
                  <div
                    className="text-base text-gray-500"
                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </dl>
    </div>
  );
}

export default function Faq() {
  return (
    <>
      <CustomersFaqs />
    </>
  );
}
