// std
import { useMemo } from 'react';

// 3p
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useInfiniteQuery } from 'react-query';

// app
import HttpStatusCode from 'utils/HttpStatusCode';
import { ENV_MEDIA } from 'config/Enviroment';

import { IResponseWithCursor, IMedia2 } from 'interfaces';

import APIClient from '../ApiClient';

const getAllMedia = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, searchQuery] = queryKey;

  const response = await APIClient.get<IResponseWithCursor<IMedia2>>(key, {
    params: {
      ...searchQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return response;
};

export function useGetAllMedia(albumId: number, searchQuery: any) {
  const url = useMemo(() => ENV_MEDIA.GET_ALL_MEDIA(albumId), [albumId]);

  const getAllMediaQuery = useInfiniteQuery<
    AxiosResponse<IResponseWithCursor<IMedia2>, any>,
    AxiosError<string, any>
  >([url, searchQuery], getAllMedia, {
    getNextPageParam: (response) => response.data.cursor.afterCursor,
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status;

        if (statusCode === HttpStatusCode.FORBIDDEN) {
          return false;
        }
      }

      return true;
    },
  });

  return {
    getAllMediaQuery,
  };
}
