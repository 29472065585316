// std

// 3p
import { AxiosError } from 'axios';
import { QueryFunctionContext, useInfiniteQuery, useQueryClient } from 'react-query';

// app
import { IChatThread, IChatThreadCache, IResponseWithCursor } from 'interfaces';
import { ENV_ADMIN } from 'config';

import APIClient from '../../ApiClient';
import { useCallback, useMemo } from 'react';
import { findThreadInPages } from 'utils';

async function getAllThread({ pageParam }: QueryFunctionContext) {
  const { data } = await APIClient.get<IResponseWithCursor<IChatThread>>(
    ENV_ADMIN.GET_ALL_THREAD,
    {
      params: {
        ...(pageParam ? pageParam : {}),
      },
    }
  );

  return data;
}

export function useAdminGetAllThread() {
  const queryKey = useMemo(() => [ENV_ADMIN.GET_ALL_THREAD], []);

  const adminGetAllThreadQuery = useInfiniteQuery<
    IResponseWithCursor<IChatThread>,
    AxiosError<string, any>
  >({
    queryKey: queryKey,
    queryFn: getAllThread,
    getPreviousPageParam: (lastPage) => {
      const { cursor } = lastPage;

      if (!cursor) {
        return null;
      }

      const { beforeCursor } = cursor;

      if (!beforeCursor) {
        return null;
      }

      return { beforeCursor };
    },
  });

  const queryClient = useQueryClient();

  const adminUpdateReadStatus = useCallback(
    async (tuuid: string, hasNewMessage: boolean) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const cache = queryClient.getQueryData<IChatThreadCache>(queryKey);

      if (!cache) return;

      const cacheClone = structuredClone(cache);
      const indexes = findThreadInPages(tuuid, cacheClone);

      // Element found
      if (indexes.length) {
        const item = cacheClone.pages[indexes[0]].data[indexes[1]];
        cacheClone.pages[indexes[0]].data[indexes[1]] = { ...item, hasNewMessage };
      }

      queryClient.setQueryData(queryKey, cacheClone);

      // Se desideri invalidare le query, decommenta la riga seguente
      // await queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey]
  );

  return {
    queryKey,
    adminGetAllThreadQuery,
    adminUpdateReadStatus,
  };
}
