// std
import { useMemo } from 'react';

// 3p
import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app
import HttpStatusCode from 'utils/HttpStatusCode';
import { ENV_MEDIA } from 'config/Enviroment';

import { IImageGalleryItem } from 'interfaces';

import APIClient from '../ApiClient';

const getAllMediaWithoutPagination = async (params: any) => {
  const { queryKey } = params;
  const [key, searchQuery] = queryKey;

  const { data } = await APIClient.get<IImageGalleryItem[]>(key, {
    params: {
      ...searchQuery,
    },
  });

  return data;
};

export function useGetAllMediaWithoutPagination(albumId: number, searchQuery: any) {
  const url = useMemo(
    () => ENV_MEDIA.GET_ALL_MEDIA_WITHOUT_PAGINATION(albumId),
    [albumId]
  );

  const getAllMediaQueryWithoutPagination = useQuery<
    IImageGalleryItem[],
    AxiosError<string, any>
  >([url, searchQuery], getAllMediaWithoutPagination, {
    retry: (failureCount, error) => {
      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status;

        if (statusCode === HttpStatusCode.FORBIDDEN) {
          return false;
        }
      }

      return true;
    },
  });

  return {
    getAllMediaQueryWithoutPagination,
  };
}
