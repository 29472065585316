/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';

// 3p
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';

// app
import { IToast, IToastStatus } from 'interfaces';

import { DEFAULT_DURATION_MS } from 'config/Config';

const defaultProps = {
  duration: DEFAULT_DURATION_MS,
  type: IToastStatus.SUCCESS,
};

const Toast = (props: IToast) => {
  const { title, message, removeFn, duration, type, icon } = props;

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    // invoke show animation
    const timerShow = setTimeout(() => {
      setVisible(true);
    }, 0);

    // invoke hide animation
    const timerHide = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(timerShow);
      clearTimeout(timerHide);
    };
  }, [duration]);

  // if not visible, remove permanently (after animation duration 300)
  useEffect(() => {
    if (isVisible) return;

    const timerRemove = setTimeout(removeFn, 350);

    return () => {
      clearTimeout(timerRemove);
    };
  }, [isVisible, removeFn]);

  const [customIcon, setCustomIcon] = useState<any>({});

  useEffect(() => {
    let iconColor = '';
    let CustomIcon = icon;

    if (!icon) {
      if (type === IToastStatus.ERROR) {
        CustomIcon = ExclamationCircleIcon;
        iconColor = 'text-red-400';
      } else if (type === IToastStatus.SUCCESS) {
        CustomIcon = CheckCircleIcon;
        iconColor = 'text-green-400';
      }
    }

    if (CustomIcon) {
      setCustomIcon(<CustomIcon className={`h-6 w-6 ${iconColor}`} aria-hidden="true" />);
    }
  }, [icon, type]);

  return (
    <>
      <Transition
        show={isVisible}
        as={Fragment}
        enter="ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              {customIcon && <div className="mr-3 shrink-0">{customIcon}</div>}
              <div className="w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900">{title}</p>
                {message ? <p className="mt-1 text-sm text-gray-500">{message}</p> : null}
                {type === IToastStatus.ERROR ? (
                  <p className="mt-1 text-sm text-blue-500 underline">
                    <a href="/support" target="_blank">
                      Contatta l'assistenza
                    </a>
                  </p>
                ) : null}
              </div>
              <div className="ml-4 shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  onClick={() => setVisible(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

Toast.defaultProps = defaultProps;

export default Toast;
