import { useMemo } from 'react';

import { getDate } from 'date-fns';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
} from 'chart.js';

// app
import { convertFromCent, formatMoney } from 'utils';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';

import { useGetAdminAnalyticsTransfer } from 'api/admin/analytics/useGetAdminAnalyticsTransfer';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Title,
  LineController,
  BarController
);

interface IAnalyticsTransferProp {
  startAt: string;
  endAt: string;
}

export const AnalyticsTransfer = (props: IAnalyticsTransferProp) => {
  const { startAt, endAt } = props;

  const { getAdminAnalyticsTransferQuery } = useGetAdminAnalyticsTransfer(startAt, endAt);

  const { data, isError, isLoading, error } = getAdminAnalyticsTransferQuery;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: `Andamento giornaliero`,
      },
    },
  };

  const dataset = useMemo(
    () => ({
      labels: data?.map((row) => getDate(new Date(row.day))),
      datasets: [
        {
          type: 'line' as const,
          label: 'Numero vendite',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: data?.map((row) => row.num),
        },
        {
          type: 'bar' as const,
          label: 'Valore vendite',
          backgroundColor: 'rgb(75, 192, 192)',
          data: data?.map((row) => convertFromCent(row.amount)),
          borderColor: 'white',
          borderWidth: 2,
        },
      ],
    }),
    [data]
  );

  const transferCard = useMemo(() => {
    if (!data) return null;

    const totNum = data.reduce((pv, cv) => pv + Number(cv.num), 0);
    const totValue = data.reduce((pv, cv) => pv + Number(cv.amount), 0);
    const avgValue = totValue / totNum;

    return [
      { name: 'Numero di vendite', stat: totNum },
      { name: 'Valore vendite', stat: formatMoney(totValue) },
      { name: 'Valore medio', stat: formatMoney(avgValue) },
    ];
  }, [data]);

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => {
    return (
      <>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {transferCard?.map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>

        <div className="mx-auto my-6 px-3 sm:px-6 lg:px-8 rounded-lg bg-white shadow">
          <Chart type="bar" data={dataset} options={options} />
        </div>
      </>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
