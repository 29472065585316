// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app
import { ENV_ADMIN } from 'config/Enviroment';

import { ICoupon } from 'interfaces';

import APIClient from '../../ApiClient';

const couponSave = async (formData: ICoupon) => {
  const { data } = await APIClient.post<ICoupon>(ENV_ADMIN.CREATE_COUPON, formData);

  return data;
};

const updateCoupon = async (couponId: number, coupon: ICoupon) => {
  const { data } = await APIClient.put<ICoupon>(
    ENV_ADMIN.UPDATE_COUPON(couponId),
    coupon
  );
  return data;
};

export function useCouponSave(couponId?: number) {
  const queryClient = useQueryClient();

  const useCouponSaveMutation = useMutation<ICoupon, AxiosError, any>(
    (data) => {
      if (couponId) {
        return updateCoupon(couponId, data);
      }

      return couponSave(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_COUPON);
      },
    }
  );

  return { useCouponSaveMutation };
}
