import { useCallback, useMemo, useState } from 'react';

// 3p
import { AxiosError } from 'axios';
import { UseQueryResult } from 'react-query';
import { PencilAltIcon } from '@heroicons/react/outline';

// app
import {
  ICursorObj,
  IResponseWithCursor,
  IMediaTableRow,
  IResponseGetAllAdminMedia,
  IMediaApproveModalForm,
  IModal,
  IMedia2,
} from 'interfaces';

import { MediaPreview } from 'components/common/image';
import { formatDate } from 'utils';

import { MediaModalAdmin } from './MediaModalAdmin';
import { IGetAllMediaResponse } from 'api/admin/media/useGetAllMedia';
import { BasicEmptyState } from 'components/common';

interface IMediaTableRowProps {
  media: IMediaTableRow;
  onClickApprove?: (obj: IMediaApproveModalForm) => void;
}

const MediaTableRow = (props: IMediaTableRowProps) => {
  const { media, onClickApprove } = props;

  const [mediaModal, setMediaModal] = useState<IModal<IMedia2>>({
    open: false,
  });

  const mediaModalOpen = () => {
    setMediaModal({ open: true });
  };

  const mediaModalClose = () => {
    setMediaModal({ open: false });
  };

  const checkStatus = (status: boolean | null) =>
    status ? (
      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
        Approvato
      </span>
    ) : status === false ? (
      <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
        Non approvato
      </span>
    ) : (
      <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
        Da approvare
      </span>
    );

  const image = media?.fileUrl ? (
    <div className="shrink-0 h-12 w-12 cursor-pointer">
      <MediaPreview
        className="h-12 w-12"
        alt={''}
        imageUrl={
          media.type === 'VIDEO' && media.thumbnailURL
            ? media.thumbnailURL
            : media.fileUrl
        }
        params={{ class: 'small' }}
      />
    </div>
  ) : (
    <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
      <span className="text-md font-medium leading-none text-white">
        {media ? media.creator.slice(0, 2).toUpperCase() : 'UK'}
      </span>
    </span>
  );

  return (
    <>
      <MediaModalAdmin data={media} onClose={mediaModalClose} open={mediaModal.open} />
      <tr key={media.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
          <div className="flex items-center">
            <div className="h-10 w-10 shrink-0" onClick={mediaModalOpen}>
              {image}
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{media.creator}</div>
              <div className="text-gray-500">{media.albumTitle}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {checkStatus(media.isApprove)}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {formatDate(media.uploadedAt)}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            onClick={() => {
              if (onClickApprove && media.id)
                onClickApprove({
                  mediaId: media.id,
                  hasApprove: media.isApprove,
                  mediaType: media.type,
                });
            }}
            className="text-pink-600 hover:text-pink-900"
          >
            <PencilAltIcon className="mr-2 h-5 w-5" />
          </button>
        </td>
      </tr>
    </>
  );
};

interface IMediaTableProps {
  query: UseQueryResult<IGetAllMediaResponse, AxiosError<string, any>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  tableTitle?: string;
  onClickApprove?: (obj: IMediaApproveModalForm) => void;
}

function MediaTable(props: IMediaTableProps): JSX.Element {
  const { query, page, setPage, tableTitle, onClickApprove } = props;
  const { data } = query;

  const result = useMemo(() => {
    if (!data) return [];

    return data.items.map((el) => ({
      media: {
        id: el.photo
          ? el.photo.id
          : el.cover
          ? el.cover.id
          : el.video
          ? el.video.id
          : undefined,
        bunnyId: el.photo
          ? undefined
          : el.cover
          ? undefined
          : el.video
          ? el.video.bunnyId
          : undefined,
        albumTitle: el.photo
          ? el.photo.product?.title
          : el.cover
          ? el.cover.product?.title
          : el.video
          ? el.video.product?.title
          : '',
        creator: el.photo
          ? el.photo.product?.user.username
          : el.cover
          ? el.cover.product?.user.username
          : el.video
          ? el.video.product?.user.username
          : '',
        isApprove: el.photo
          ? el.photo.isApprove
          : el.cover
          ? el.cover.isApprove
          : el.video
          ? el.video.isApprove
          : false,
        uploadedAt: el.uploadAt,
        fileUrl: el.url,
        libraryId: el.video?.libraryId,
        type: el.photo ? 'PHOTO' : el.cover ? 'COVER' : el.video ? 'VIDEO' : undefined,
        thumbnailURL: el.photo
          ? undefined
          : el.cover
          ? undefined
          : el.video
          ? el.video.thumbnailURL
          : undefined,
      },
    }));
  }, [data]);

  const prevPage = () => {
    setPage((old) => Math.max(old - 1, 1));
  };

  const nextPage = () => {
    if (!data) return;
    setPage((old) => (!data.totalPages || old < data.totalPages ? old + 1 : old));
  };

  const renderEmpty = useCallback(
    () => <BasicEmptyState title="Registro media" descr="Non sono presenti media" />,
    []
  );

  if (!data || !data.totalItems) {
    return renderEmpty();
  }

  return (
    <>
      {/* <MediaModal data={data} onClose={mediaModalClose} open={mediaModal.open} /> */}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{tableTitle}</h1>
            <p className="mt-2 text-sm text-gray-700">
              Registro dei {tableTitle?.toLowerCase()} presenti all'interno del sistema
            </p>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Aggiungi utente
          </button>
        </div> */}
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Anteprime
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Stato
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Data caricamento
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Modifica</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {result &&
                      result.length &&
                      result.map((media) => (
                        // media.media.type controllo se è una foto profilo. Se lo è allora non la mostro poichè non è da approvare.
                        <MediaTableRow
                          key={media.media.id}
                          media={media.media}
                          onClickApprove={onClickApprove}
                        />
                      ))}
                  </tbody>
                </table>
                {/* Pagination */}
                <nav
                  className="bg-white px-4 py-3 flex items-center border-t border-gray-200 sm:px-6"
                  aria-label="Pagination"
                >
                  {/*TODO: mettere pagina 1 di x*/}
                  {/* <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Mostro <span className="font-medium">1</span> a{' '}
            <span className="font-medium">10</span> di{' '}
            <span className="font-medium">{data && data.data.length}</span> risultati
          </p>
        </div> */}
                  <div className="flex-1 flex sm:justify-end">
                    {!(page === 1) && (
                      <button
                        onClick={prevPage}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Precedenti
                      </button>
                    )}
                    {data && !(page === data.totalPages) && (
                      <button
                        onClick={nextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Successivi
                      </button>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaTable;
