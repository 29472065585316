// 3p
import { AlertError } from 'components/common/alert';
import Loader from 'components/common/Loader';

import { useGetPayoutSummary } from 'api/admin/transfer';
import { formatMoney } from 'utils';

export const SummaryPayout = () => {
  const { getPayoutSummaryQuery } = useGetPayoutSummary();
  const { isError, error, isLoading, data } = getPayoutSummaryQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  return isLoading ? (
    renderLoading()
  ) : isError ? (
    renderError()
  ) : !data ? null : (
    <div>
      <h3 className="text-xl leading-6 font-semibold text-gray-900">Riepilogo</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Disponibili</dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {formatMoney(Number(data.availableNow))}
          </dd>
        </div>

        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">
            Presto disponibili
          </dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {formatMoney(Number(data.availableSoon))}
          </dd>
        </div>

        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Richiesti</dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">
            {formatMoney(Number(data.inTransit))}
          </dd>
        </div>
      </dl>
    </div>
  );
};
