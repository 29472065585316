// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { compress, EImageType } from 'image-conversion';

// app
import { ENV_MEDIA } from 'config/Enviroment';

import APIClient from '../ApiClient';
import { getFileType } from 'utils';

const createCover = async (data: any, albumId: string) => {
  const formData = new FormData();
  const { mediaList } = data;

  for (const media of mediaList) {
    const type = getFileType(media);
    if (type === 'image') {
      const fileBlobCompressed = await compress(media, {
        quality: 0.8,
        type: EImageType.JPEG,
      });
      formData.append('images', fileBlobCompressed);
    }
  }

  await APIClient.post<void>(ENV_MEDIA.CREATE_COVER(albumId), formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};

export function useSaveCover(albumId: string) {
  const queryClient = useQueryClient();

  const saveCoverMutation = useMutation<void, AxiosError, any>(
    (mediaList) => {
      return createCover(mediaList, albumId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_MEDIA.GET_ALL_MEDIA(albumId));
      },
    }
  );

  return {
    saveCoverMutation,
  };
}
