// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_ADMIN, ENV_PAYOUT } from 'config/Enviroment';
import { IRefundUpdateForm } from 'interfaces';

import APIClient from '../../ApiClient';

const updateRefund = async (transferId: number, body: IRefundUpdateForm) => {
  const { data } = await APIClient.put<IRefundUpdateForm>(
    ENV_ADMIN.PUT_REFUND(transferId),
    body
  );
  return data;
};

export function useUpdateRefund() {
  const queryClient = useQueryClient();

  const updateRefundMutation = useMutation<IRefundUpdateForm, AxiosError, any>(
    ([transferId, data]) => updateRefund(transferId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ENV_PAYOUT.GET_ALL_PAYOUT);
        queryClient.invalidateQueries(ENV_ADMIN.GET_ALL_TRANSFER);
      },
    }
  );

  return {
    updateRefundMutation,
  };
}
