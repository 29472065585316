import { useState } from 'react';

// app
import styles from 'styles';
import { useAuth } from 'hooks';
import APIClient from 'api/ApiClient';

import Loader from 'components/common/Loader';
import { ENV_AUTH } from 'config/Enviroment';

import { AlertError, AlertSuccess } from '../alert';
import { Link, useParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

export const SignupCustomerConfirm = () => {
  let { token } = useParams<{ token: string }>() as { token: string };

  const { isLoading } = useAuth();
  const [tokenError, setTokenError] = useState<boolean>(false);
  const [isSignupConfirmed, setIsSignupConfirmed] = useState<boolean>(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string | undefined>(
    undefined
  );

  const signupConfirm = async () => {
    const dataToSend = { token };

    try {
      await APIClient.post(ENV_AUTH.SIGNUP_CUSTOMER_CONFIRM, dataToSend);
      setIsSignupConfirmed(true);
    } catch (ex) {
      if (axios.isAxiosError(ex)) {
        const error = ex as AxiosError<string, any>;

        setTokenError(true);

        const { response } = error;
        if (response) {
          const { data } = response;
          setTokenErrorMessage(data);
        }
      }
      console.error(ex);
    }
  };

  if (isLoading) return <Loader />;

  if (isSignupConfirmed)
    return (
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
        <AlertSuccess message="Registrazione completata con successo." />
        <Link className={styles.button.full + ' mt-5'} to="/login">
          Vai alla pagina di accesso
        </Link>
      </div>
    );

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        {tokenError && <AlertError message={tokenErrorMessage} />}
        <div className="space-y-6">
          <button onClick={signupConfirm} className={styles.button.full}>
            Completa registrazione
          </button>
        </div>
      </div>
    </div>
  );
};
