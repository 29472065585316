import { useState } from 'react';

// app
import { DEFAULT_LIMIT_USER } from 'config/Enviroment';

import { ICursorObj } from 'interfaces';

import { useGetAllCoupon } from 'api/admin/coupon';

import Loader from 'components/common/Loader';
import { AlertError } from 'components/common/alert';
import { CouponTable } from 'components/admin/coupon';

export const CouponGetAll = () => {
  const [cursor, setCursor] = useState<ICursorObj>({});

  const { getAllCouponQuery } = useGetAllCoupon({
    limit: DEFAULT_LIMIT_USER,
    order: 'DESC',
    ...cursor,
  });

  const { isError, isLoading, error } = getAllCouponQuery;

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => {
    return (
      <CouponTable setCursor={setCursor} query={getAllCouponQuery} tableTitle="Coupons" />
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
