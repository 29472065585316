// A custom hook that builds on useLocation to parse

import { useMemo } from 'react';
import { useQueryString } from './useQueryString';

// the query string for you.
export function useQueryParams() {
  const query = useQueryString();

  return useMemo(() => {
    let params: any = {};
    new URLSearchParams(query).forEach((value, key) => {
      // Decodifico sottoforma di stringa le chiavi e i valori presenti in query string
      let decodedKey = decodeURIComponent(key);
      let decodedValue = decodeURIComponent(value);

      if (decodedKey.endsWith('[]')) {
        decodedKey = decodedKey.replace('[]', '');
        params[decodedKey] || (params[decodedKey] = []);
        params[decodedKey].push(decodedValue);
      } else {
        params[decodedKey] = decodedValue;
      }
    });

    return params;
  }, [query]);
}
