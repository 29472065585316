// std
import { useCallback } from 'react';

// app
import { ENV_USER } from 'config/Enviroment';

import APIClient from '../ApiClient';
import { IFile } from 'interfaces';

export const useUploadUserImage = () => {
  const upload = useCallback(async (formData: FormData) => {
    const { data } = await APIClient.post<{ image: IFile }>(
      ENV_USER.UPLOAD_IMAGE,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data;
  }, []);

  return Object.freeze({ upload });
};
