import { TransferTypeEnum } from 'config';
import { ITransfer } from 'interfaces';
import { formatMoney } from 'utils';

import { UserImage } from 'components/common';
import { useAuth } from './useAuth';

export function useFormatTransfer(transfer: ITransfer) {
  const { type, transaction, sourceUser, targetUser, paymentIn, products, message } =
    transfer;

  const { user: currentUser } = useAuth();

  // User and money
  let { amount: amountTotal, fee: amountFee, net: amountNet, currency } = transaction;
  amountFee *= -1; // fee are always negative

  let description = message
    ? message
    : products?.length
    ? products[0].title
    : 'Descrizione non disponibile';

  let user;

  const typeEnum = TransferTypeEnum[type.code];

  switch (typeEnum) {
    // In a purchase source and target are always definited
    case TransferTypeEnum.PURCHASE:
    case TransferTypeEnum.TIP:
      // user exists and has NOT deleted himself
      if (sourceUser && !sourceUser.deleteAt) {
        if (sourceUser.id === currentUser!.id) {
          // Current user is the actor
          user = targetUser;
          amountFee = 0;
          amountTotal *= -1;
          amountNet = amountTotal;
        } else {
          user = sourceUser;
        }
      }
      // user exists and has deleted himself
      else if (paymentIn && sourceUser && sourceUser.deleteAt) {
        user = { username: 'Utente Eliminato' };
      }
      // non-subscriber user to vieniora.com
      else if (!sourceUser && paymentIn) {
        user = { username: paymentIn.email };
      }
      break;
    // In a payout only the sourceUser is the actor
    case TransferTypeEnum.REFUND:
      user = sourceUser;
      if (sourceUser?.id === currentUser!.id) {
        // Current user is the actor
        amountFee = 0;
        amountTotal *= -1;
        amountNet *= -1;
      } else {
        amountNet = amountTotal;
      }
      break;
    case TransferTypeEnum.PAYOUT:
      user = sourceUser!;
      amountTotal *= -1;
      amountNet *= -1;
      break;
    // Payin target user is definited, but the actor is anoymous
    case TransferTypeEnum.PAYIN:
      user = { username: paymentIn!.email };
      break;
    case TransferTypeEnum.TOPUP:
    case TransferTypeEnum.REDEEM:
      user = targetUser;
      break;
  }

  const amountTotalStr = formatMoney(amountTotal, {
    currency: currency,
    addSign: true,
  });

  const amountFeeStr = formatMoney(amountFee, {
    currency: currency,
    addSign: true,
  });

  const amountNetStr = formatMoney(amountNet, {
    currency: currency,
    addSign: true,
  });

  const image = user?.image ? (
    <div className="shrink-0 h-12 w-12">
      <UserImage
        className="h-12 w-12 rounded-full"
        alt={user.username}
        image={user.image}
        params={{ class: 'small' }}
      />
    </div>
  ) : (
    <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
      <span className="text-md font-medium leading-none text-white">
        {user ? user.username.slice(0, 2).toUpperCase() : 'UK'}
      </span>
    </span>
  );

  return {
    user,
    image,
    description,
    amountTotal: amountTotalStr,
    amountFee: amountFeeStr,
    amountNet: amountNetStr,
  };
}
