// std

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_PAYOUT } from 'config/Enviroment';
import { IRestClientGetResponse, ITransactionPayout } from 'interfaces';

import APIClient from '../ApiClient';
import { useState } from 'react';

const getAllPayout = async (skip: number, take: number) => {
  const { data } = await APIClient.get<IRestClientGetResponse<ITransactionPayout>>(
    ENV_PAYOUT.GET_ALL_PAYOUT,
    {
      params: { skip, take },
    }
  );

  return data;
};

export function useGetAllPayout(skip: number, take: number) {
  const [currSkip, setCurrSkip] = useState<number>(skip);
  const [currTake, setCurrTake] = useState<number>(take);

  const getAllPayoutQuery = useQuery<
    IRestClientGetResponse<ITransactionPayout>,
    AxiosError<string, any>
  >([ENV_PAYOUT.GET_ALL_PAYOUT, currSkip, currTake], () =>
    getAllPayout(currSkip, currTake)
  );

  return { setCurrSkip, setCurrTake, getAllPayoutQuery };
}
