import { Fragment, ReactNode } from 'react';

// 3p
import { Dialog, Transition } from '@headlessui/react';

// app
import { Loader } from 'components/common';

interface IModalLayoutProps {
  isLoading?: boolean;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const ModalLayout = (props: IModalLayoutProps) => {
  const { isLoading, open, onClose, children } = props;

  return (
    <Transition show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 w-screen overflow-y-auto">
            {/* Container to center the panel */}
            <div className="flex min-h-full items-center justify-center p-4">
              <Dialog.Panel className="w-full max-w-md transform overflow rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                {isLoading ? <Loader /> : children}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
