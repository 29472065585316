import { useCallback, useState } from 'react';

// 3p

// App
import { ProductList } from 'components/common/product';

import {
  ProductBar,
  ProductDeleteModal,
  ProductFormModal,
} from 'components/creator/product';

import { useAuth, useToast } from 'hooks';
import { IModal, IProduct } from 'interfaces';

function CreatorProduct() {
  const [productFormModal, setProductFormModal] = useState<IModal<IProduct>>({
    open: false,
  });

  const [productDeleteModal, setProductDeleteModal] = useState<IModal<IProduct>>({
    open: false,
  });

  const { toastError, toastSuccess } = useToast();

  // Current user
  const { user } = useAuth();

  // Product Modal Callbacks
  const onSuccessCreate = useCallback(
    (product: IProduct) => {
      setProductFormModal({ open: false });

      toastSuccess({
        title: 'Operazione completata',
        message: 'Prodotto Salvato Correttamente',
      });
    },
    [toastSuccess]
  );

  // Product Modal Callbacks
  const onSuccessRemove = useCallback(() => {
    setProductDeleteModal({ open: false });

    toastError({
      title: 'Operazione completata',
      message: 'Prodotto Eliminato correttamente',
    });
  }, [toastError]);

  /** PRODUCT MODAL REMOVE */
  const productDeleteOpen = useCallback((product: IProduct) => {
    setProductDeleteModal({ open: true, data: product });
  }, []);

  const productRemoveClose = useCallback(() => {
    setProductDeleteModal({ open: false });
  }, []);

  /** PRODUCT MODAL FORM */
  const productFormOpen = useCallback((product?: IProduct) => {
    setProductFormModal({ open: true, data: product });
  }, []);

  const productFormClose = useCallback(() => {
    setProductFormModal({ open: false });
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6 mb-6">
      <ProductFormModal
        {...productFormModal}
        onClose={productFormClose}
        onSuccess={onSuccessCreate}
      />
      <ProductDeleteModal
        {...productDeleteModal}
        onClose={productRemoveClose}
        onSuccess={onSuccessRemove}
      />
      <ProductBar onCreateNew={productFormOpen} />
      <ProductList
        username={user!.username}
        onEdit={productFormOpen}
        onRemove={productDeleteOpen}
        className="px-4 py-5 sm:p-6"
      />
    </div>
  );
}

CreatorProduct.defaultProps = {
  createProduct: true,
};

export default CreatorProduct;
