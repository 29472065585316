import { useEffect } from 'react';

// 3p
import { SubmitHandler, useForm } from 'react-hook-form';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { useCreatePayOut, useGetSummary } from 'api/transfer';

import { formatMoney } from 'utils';
import { IPayoutRequest } from 'interfaces';

import { ModalLayout } from 'components/common';

interface IPayoutModalProps {
  data?: any;
  open: boolean;
  onSuccess?: (data?: any) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const PayoutModal = (props: IPayoutModalProps) => {
  const { open, onSuccess, onError, onClose } = props;

  const { getSummaryQuery } = useGetSummary();
  const { data: summaryData, isLoading: isLoadingSummary } = getSummaryQuery;

  const { createPayOutMutation } = useCreatePayOut();
  const { mutateAsync, isLoading: isLoadingCreatePayout } = createPayOutMutation;

  const { register, handleSubmit, reset } = useForm<IPayoutRequest>();

  const onSubmit: SubmitHandler<IPayoutRequest> = async (data) => {
    try {
      const payout = await mutateAsync(data);
      if (onSuccess) onSuccess(payout);
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };

  useEffect(() => {
    if (!open) reset();
  }, [open, reset]);

  const renderData = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className="text-2xl leading-6 font-medium text-gray-900">
            {summaryData && `Disponibili ${formatMoney(summaryData.availableNow)}`}
          </Dialog.Title>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-md font-medium text-gray-700">
                Importo da prelevare
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-lg">€</span>
                </div>
                <input
                  id="amount"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-lg border-gray-300 rounded-md"
                  placeholder="50"
                  type="number"
                  min="50"
                  max="4900"
                  step="any"
                  {...register('amount', {
                    required: true,
                    valueAsNumber: true,
                    max: 4900,
                    min: 50,
                  })}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-lg" id="price-currency">
                    EUR
                  </span>
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <p className="mt-3 text-sm text-gray-500">
                Importo minimo €50,00 e importo massimo €4.900,00.
              </p>
              <p className="mt-3 text-sm text-gray-500">
                Per cifre minori di €250,00 viene applicata una commissione di €3,00.
              </p>
              <p className="mt-3 text-sm text-gray-500">
                Per cifre maggiori o uguali a €250,00 non viene applicata nessuna
                comissione.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Invia richiesta
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          Annulla
        </button>
      </div>
    </form>
  );

  return (
    <ModalLayout
      open={open}
      onClose={onClose}
      isLoading={isLoadingCreatePayout || isLoadingSummary}
    >
      {renderData()}
    </ModalLayout>
  );
};
