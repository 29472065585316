// 3p
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

// app

import { ENV_ALBUM, ENV_USER } from 'config/Enviroment';
import { IAlbum, IAlbumForm } from 'interfaces';

import APIClient from '../ApiClient';
import { useAuth } from 'hooks/useAuth';

const createAlbum = async (album: IAlbumForm) => {
  const { data } = await APIClient.post<IAlbum>(ENV_ALBUM.CREATE, album);
  return data;
};

const updateAlbum = async (albumId: string | number, album: IAlbumForm) => {
  const { data } = await APIClient.put<IAlbum>(ENV_ALBUM.UPDATE(albumId), album);
  return data;
};

export function useSaveAlbum(albumId?: string | number) {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const saveAlbumMutation = useMutation<IAlbum, AxiosError, IAlbumForm>(
    (data) => {
      // if album id, update
      if (albumId) {
        return updateAlbum(albumId, data);
      }
      // else create
      return createAlbum(data);
    },
    {
      onSuccess: () => {
        if (user) queryClient.invalidateQueries(ENV_USER.GET_ALL_ALBUM(user.username));
        if (albumId) queryClient.invalidateQueries(ENV_ALBUM.GET_ALBUM(albumId));
        queryClient.invalidateQueries(ENV_ALBUM.GET_ALL);
      },
    }
  );

  return { saveAlbumMutation };
}
