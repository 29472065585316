import { MeetingProvider } from '@videosdk.live/react-sdk';
import { MeetingView } from './MeetingView';
import { useAuth } from 'hooks';
import { useMemo } from 'react';
import { ROLES } from 'config';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export function Meeting() {
  const { user } = useAuth();

  const { meetingId } = useParams();

  const { state } = useLocation() as any;

  const authToken = useMemo(() => {
    if (!state) return;
    const { authToken } = state;
    return authToken;
  }, [state]);

  const isCreator = useMemo(() => {
    if (!user) return false;

    const {
      role: { code },
    } = user;

    return code === ROLES.CREATOR;
  }, [user]);

  if (!user || !meetingId || !authToken) {
    return <Navigate to="/app/chat" replace={true} />;
  }

  const { id, username } = user;

  return (
    <MeetingProvider
      config={{
        debugMode: true,
        meetingId: meetingId,
        micEnabled: true,
        webcamEnabled: isCreator,
        name: username,
        participantId: id.toString(),
        defaultCamera: 'front',
        maxResolution: 'hd',
      }}
      token={authToken}
      joinWithoutUserInteraction
    >
      <MeetingView />
    </MeetingProvider>
  );
}
