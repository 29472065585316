import { useMemo } from 'react';

// app
import { DEFAULT_LIMIT_TRANSFER, ROLES, TransferTypeEnum } from 'config';
import { ITransfer } from 'interfaces';

import { useAuth, useFormatTransfer } from 'hooks';
import { formatDate, isCashAvailable, isRefundExpired } from 'utils';

import { TransferStatus } from './TransferStatus';
import { TransferType } from './TransferType';

interface ITransferListItemProps {
  transfer: ITransfer;
  onRequestRefund?: (transfer: ITransfer) => void;
}

const TransferListItem = (props: ITransferListItemProps) => {
  const { transfer, onRequestRefund } = props;

  const { user: loggedUser } = useAuth();

  const { code, status, type, startAt, refundTransferOut, refundTransferIn } = transfer;

  const { user, image, description, amountTotal, amountFee, amountNet } =
    useFormatTransfer(transfer);

  const isPaymentIn = useMemo(
    () =>
      type.code === TransferTypeEnum.PURCHASE.toString() ||
      type.code === TransferTypeEnum.TIP.toString(),
    [type]
  );

  const isMoneyAvailable = useMemo(
    () => isPaymentIn && loggedUser && loggedUser.role.code !== ROLES.CUSTOMER,
    [isPaymentIn, loggedUser]
  );

  const isRefundAvailable: boolean = useMemo(
    () => isPaymentIn && !refundTransferOut && isRefundExpired(startAt),
    [isPaymentIn, refundTransferOut, startAt]
  );

  const refundTransferInCode = useMemo(() => {
    if (!refundTransferIn) return;
    const { code } = refundTransferIn;
    return code;
  }, [refundTransferIn]);

  return (
    <li>
      <details className="block px-4 py-4 bg-white hover:bg-gray-50">
        <summary className="flex items-center space-x-4">
          <span className="flex-1 flex space-x-2 truncate">
            {image}
            <span className="flex flex-col text-gray-500 text-sm truncate">
              <span className="inline-flex items-baseline">
                <time
                  className="text-gray-900 font-medium"
                  dateTime={formatDate(startAt)}
                >
                  {formatDate(startAt)}
                </time>
                {isMoneyAvailable ? (
                  isCashAvailable(startAt) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mx-1 self-center"
                      viewBox="0 0 20 20"
                      fill="#86efac"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mx-1 self-center"
                      viewBox="0 0 20 20"
                      fill="#fca5a5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )
                ) : null}
              </span>
              <span className="text-gray-900 font-medium">{user?.username}</span>
              <div className="mt-1 flex items-center">
                <TransferType type={type} />
              </div>
            </span>
          </span>
          <div className="flex-col">
            <div className="text-gray-900 font-medium">{amountTotal}</div>
          </div>
        </summary>
        <div className="mt-5 grid grid-cols-3 gap-4">
          {isMoneyAvailable && (
            <>
              <div className="col-span-1 flex flex-col">
                <span className="font-medium">Lordo</span> {amountTotal}
              </div>
              <div className="col-span-1 flex flex-col">
                <span className="font-medium">Piattaforma</span> {amountFee}
              </div>
            </>
          )}
          <div className="col-span-1 flex flex-col">
            <span className="font-medium">Netto</span> {amountNet}
          </div>
          <div className="col-span-1 flex flex-col">
            <span className="font-medium">Tipo</span>
            <div className="mt-1 flex items-center">
              <TransferType type={type} />
            </div>
          </div>
          <div className="col-span-1 flex flex-col">
            <span className="font-medium">Stato</span>
            <div className="mt-1">
              <TransferStatus status={status} />
            </div>
          </div>
          <div className="col-span-1 flex flex-col">
            <span className="font-medium">Codice</span>
            <div className="mt-1">{code && code.substring(code.length - 5)}</div>
          </div>
          {refundTransferInCode && (
            <div className="col-span-1 flex flex-col">
              <span className="font-medium">Rimborso</span>
              <div className="mt-1">
                {refundTransferInCode.substring(refundTransferInCode.length - 5)}
              </div>
            </div>
          )}
          {isMoneyAvailable && (
            <div className="col-span-1 flex flex-col">
              <span className="font-medium">Disponibilità</span>
              <div className="mt-1">
                {isCashAvailable(startAt) ? (
                  <span className="bg-green-100 text-green-800 mx-1 px-3 py-1 rounded-full text-sm font-medium capitalize text-center">
                    Si
                  </span>
                ) : (
                  <span className="bg-red-100 text-red-800 mx-1 px-3 py-1 rounded-full text-sm font-medium capitalize text-center">
                    No
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="col-span-2 flex flex-col">
            <span className="font-medium">Messaggio</span>
            <div className="mt-1">
              <p className="break-all">{description}</p>
            </div>
          </div>
          {isRefundAvailable && onRequestRefund && (
            <div className="col-span-3 flex flex-col sm:col-end-4 sm:col-span-1">
              <button
                onClick={() => onRequestRefund(transfer)}
                className="items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Rimborsa
              </button>
            </div>
          )}
        </div>
      </details>
    </li>
  );
};

interface TransferListProps {
  data: ITransfer[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onRequestRefund?: (transfer: ITransfer) => void;
}

function TransferList(props: TransferListProps): JSX.Element {
  const { data, page, setPage, onRequestRefund } = props;

  const prevPage = () => {
    if (page >= DEFAULT_LIMIT_TRANSFER) setPage(page - DEFAULT_LIMIT_TRANSFER);
  };

  const nextPage = () => {
    setPage(page + DEFAULT_LIMIT_TRANSFER);
  };

  return (
    <div className="">
      <div className="bg-white overflow-hidden shadow sm:rounded-lg mt-4 border-b border-gray-200">
        <div className="border-b border-gray-200 px-4 py-5 sm:px-6 -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-700">Transazioni</h3>
          </div>
        </div>

        <ul className="rounded-t-md divide-y divide-gray-200 overflow-hidden">
          {data.map((transfer) => (
            <TransferListItem
              key={transfer.id}
              transfer={transfer}
              onRequestRefund={onRequestRefund}
            />
          ))}
        </ul>

        <nav
          className="rounded-b-md bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="flex-1 flex justify-between">
            {page !== 0 ? (
              <button
                onClick={prevPage}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Precedenti
              </button>
            ) : (
              <div className="relative inline-flex items-center px-4 py-2" />
            )}
            <button
              onClick={nextPage}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
            >
              Successive
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
}

TransferList.defaultProps = {
  data: [],
};

export default TransferList;
