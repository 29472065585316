// std
import { useCallback, useEffect, useMemo, useState } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';

import {
  HangUpButton,
  MicrophoneButton,
  ParticipantView,
  SwitchCameraButton,
  VideoCameraButton,
  useTimer,
} from 'components/common/chat/video';

import styles from './MeetingView.module.css';
import { createCameraVideoTrack, useMeeting } from '@videosdk.live/react-sdk';
import { Loader } from 'components/common';

interface IWebCam {
  deviceId: string;
  label: string;
  facingMode: 'environment' | 'front';
}

export function MeetingView() {
  // const { callId } = useParams();

  const navigate = useNavigate();

  const timeElapsed = useTimer();

  const [joined, setJoined] = useState('JOINING');

  const [state, setState] = useState<
    'CONNECTING' | 'CONNECTED' | 'FAILED' | 'DISCONNECTED' | 'CLOSING' | 'CLOSED'
  >();

  //Get the method which will be used to join the meeting.
  //We will also get the participants list to display all participants
  const {
    participants,
    end,
    localMicOn,
    muteMic,
    unmuteMic,
    localWebcamOn,
    enableWebcam,
    disableWebcam,
    getWebcams,
    toggleWebcam,
  } = useMeeting({
    onMeetingStateChanged({ state }) {
      console.log('STATE', state);
      setState(state);
    },
    //callback for when meeting is joined successfully
    onMeetingJoined: () => {
      setJoined('JOINED');
    },
    onParticipantJoined: (participant) => {
      participant.setQuality('high');
    },
  });

  //Creating two react states to store list of available mics and selected microphone
  const [webcams, setWebcams] = useState<IWebCam[]>([]);

  useEffect(() => {
    getWebcams().then((webcams) => {
      setWebcams(webcams);
    });
  }, [getWebcams]);

  const handleEnableWebcam = useCallback(() => {
    enableWebcam();
  }, [enableWebcam]);

  const handleDisableWebcam = useCallback(() => {
    disableWebcam();
  }, [disableWebcam]);

  const handleEnableMic = useCallback(() => {
    unmuteMic();
  }, [unmuteMic]);

  const handleDisableMic = useCallback(() => {
    muteMic();
  }, [muteMic]);

  const disableSwitchCamera = useMemo(() => {
    return webcams && webcams.length <= 1;
  }, [webcams]);

  const [facingMode, setFacingMode] = useState('user');

  const handleSwitchCamera = useCallback(async () => {
    disableWebcam();

    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';

    const customTrack = await createCameraVideoTrack({
      facingMode: newFacingMode,
    });

    toggleWebcam(customTrack);
    setFacingMode(newFacingMode);
  }, [disableWebcam, toggleWebcam, facingMode]);

  const hangUpCallButton = useCallback(() => {
    end();
    navigate(-1);
  }, [end, navigate]);

  const remotes = useMemo(() => {
    return Array.from(participants.values())
      .filter(({ local }) => !local)
      .map(({ id }) => id);
  }, [participants]);

  const local = useMemo(() => {
    return Array.from(participants.values())
      .filter(({ local }) => local)
      .map(({ id }) => id);
  }, [participants]);

  useEffect(() => {
    if (state === 'CLOSED') {
      navigate(-1);
    }
  }, [state, navigate]);

  if (joined === 'JOINING' || state === 'CONNECTING') {
    return (
      <div className={styles.positionCenter}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100vh',
        background: '#000',
        padding: 0,
        margin: 0,
      }}
    >
      <div className={styles.actionBarWrapper}>
        <div className={styles.actionBarContainer}>
          <span className={styles.timer}>{timeElapsed}</span>

          <SwitchCameraButton
            disable={disableSwitchCamera}
            switchCamera={handleSwitchCamera}
          />

          <VideoCameraButton
            isCameraEnable={localWebcamOn}
            startVideoButton={handleEnableWebcam}
            stopVideoButton={handleDisableWebcam}
          />

          <MicrophoneButton
            isMicrophoneEnable={localMicOn}
            startMicrophoneButton={handleEnableMic}
            stopMicrophoneButton={handleDisableMic}
          />

          {/* Chiusura Chiamata */}
          <HangUpButton hangUpCallButton={hangUpCallButton} />
        </div>
      </div>

      <div className={styles.selfVideo}>
        {local.map((participantId) => (
          <ParticipantView participantId={participantId} key={participantId} />
        ))}
      </div>

      {participants.size > 1 ? (
        <div className={styles.mainVideo}>
          {remotes.map((participantId) => (
            <ParticipantView participantId={participantId} key={participantId} />
          ))}
        </div>
      ) : (
        <div className={styles.positionCenter}>
          <h1 style={{ color: '#fff', fontWeight: 'bold' }}>In attesa di partecipanti</h1>
        </div>
      )}
    </div>
  );
}
