// std
import { useState } from 'react';

// 3p
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

// app

import { ENV_USER } from 'config/Enviroment';
import { IRestClientGetResponse, IProduct } from 'interfaces';

import APIClient from '../ApiClient';

const getAllUserProduct = async (username: string, skip: number, take: number) => {
  const url = ENV_USER.GET_ALL_PRODUCT(username);

  const { data } = await APIClient.get<IRestClientGetResponse<IProduct>>(url, {
    params: { skip, take },
  });

  return data;
};

export function useGetAllUserProduct(username: string, skip: number, take: number) {
  const [currSkip, setCurrSkip] = useState<number>(skip);
  const [currTake, setCurrTake] = useState<number>(take);

  const getAllUserProductQuery = useQuery<
    IRestClientGetResponse<IProduct>,
    AxiosError<string, any>
  >([ENV_USER.GET_ALL_PRODUCT(username), currSkip, currTake], () =>
    getAllUserProduct(username, currSkip, currTake)
  );

  return {
    setCurrSkip,
    setCurrTake,
    getAllUserProductQuery,
  };
}
