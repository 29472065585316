import { useGetAllMedia } from 'api/admin/media/useGetAllMedia';
import { MediaApproveModal } from 'components/admin/media/MediaApproveModal';
import MediaTable from 'components/admin/media/MediaTable';
import { AlertError } from 'components/common/alert';
import { BasicEmptyState } from 'components/common/empty';
import Loader from 'components/common/Loader';
import { DEFAULT_LIMIT } from 'config/Enviroment';
// import { useToast } from 'hooks';
import { ICursorObj, IMediaApproveModalForm, IModal } from 'interfaces';
import { useState } from 'react';

export const MediaGetAll = () => {
  const [page, setPage] = useState(1);

  const { getAllMediaQuery } = useGetAllMedia(
    {},
    { page, limit: DEFAULT_LIMIT, order: 'DESC' }
  );
  const { isError, isLoading, error } = getAllMediaQuery;

  // const { toastSuccess } = useToast();

  const [mediaApproveFormModal, setMediaApproveFormModal] = useState<
    IModal<IMediaApproveModalForm | undefined>
  >({
    open: false,
  });

  const onMediaApproveFormModalClose = () => {
    setMediaApproveFormModal({ open: false });
  };

  const onMediaApproveFormModalSuccess = () => {
    setMediaApproveFormModal({ open: false });
    // toastSuccess({ title: 'Operazione eseguita' });
  };

  const onClickApprove = (obj: IMediaApproveModalForm) => {
    setMediaApproveFormModal({ open: true, data: obj });
  };

  const renderLoading = () => <Loader />;

  const renderError = () => <AlertError error={error} />;

  const renderData = () => {
    return (
      <>
        <MediaApproveModal
          {...mediaApproveFormModal}
          onClose={onMediaApproveFormModalClose}
          onSuccess={onMediaApproveFormModalSuccess}
          // onError={onPayoutFormModalError}
        />
        <MediaTable
          page={page}
          setPage={setPage}
          query={getAllMediaQuery}
          tableTitle="Media"
          onClickApprove={onClickApprove}
        />
      </>
    );
  };

  return isLoading ? renderLoading() : isError ? renderError() : renderData();
};
