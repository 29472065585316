// std

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery } from 'react-query';

// app
import { IResponseWithCursor, IAlbum } from 'interfaces';
import { ENV_ALBUM } from 'config/Enviroment';

import APIClient from '../ApiClient';

const getAllAlbum = async (params: any) => {
  const { pageParam, queryKey } = params;
  const [key, searchQuery, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IAlbum>>(key, {
    params: {
      ...searchQuery,
      ...pagingQuery,
      ...(pageParam ? { afterCursor: pageParam } : {}),
    },
  });

  return data;
};

export function useGetAllAlbum(searchQuery: any, pagingQuery: any) {
  const getAllAlbumQuery = useInfiniteQuery<
    IResponseWithCursor<IAlbum>,
    AxiosError<string, any>
  >([ENV_ALBUM.GET_ALL, searchQuery, pagingQuery], getAllAlbum, {
    getNextPageParam: (lastPage) => lastPage.cursor.afterCursor,
  });

  return {
    getAllAlbumQuery,
  };
}
