import { Fragment, useEffect, useMemo } from 'react';

// 3p
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog, Switch } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

// app
import { TransferTypeEnum, TrasnferStatusEnum } from 'config';
import { IRefundUpdateForm, ITransferTableRow } from 'interfaces';
import { classNames, formatDate, formatMoney } from 'utils';

import { ModalLayout, TransferStatus } from 'components/common';

import { useUpdateRefund } from 'api/admin/transfer';

interface IRefundStatusModalProps {
  data?: ITransferTableRow;
  open: boolean;
  onSuccess?: (data?: any) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export const RefundModalStatus = (props: IRefundStatusModalProps) => {
  const { data, open, onSuccess, onError, onClose } = props;

  const { updateRefundMutation } = useUpdateRefund();
  const { mutateAsync, isLoading: isLoadingCreateRefund } = updateRefundMutation;

  const { control, handleSubmit, reset } = useForm<IRefundUpdateForm>({
    defaultValues: {
      hasApprove: false,
    },
  });

  const transferId = useMemo(() => data?.id, [data]);

  const onSubmit: SubmitHandler<IRefundUpdateForm> = async (data) => {
    if (!transferId) {
      return;
    }

    try {
      await mutateAsync([transferId, data]);
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      if (onError) onError(error);
    }
  };

  useEffect(() => {
    reset();
  }, [data, reset]);

  if (!data) {
    return <Fragment />;
  }

  const {
    code,
    startAt,
    sourceUser,
    targetUser,
    transaction,
    type,
    status,
    refundTransferIn,
  } = data;

  const refundTransferAmount = transaction?.amount;

  const refundTransferInCode = refundTransferIn?.code;
  const refundTransferInPaymentIn = refundTransferIn?.paymentIn;
  const refundTransferInCompleteAt = refundTransferIn?.completeAt;
  const refundTransferInStatus = refundTransferIn?.status;

  const refundPaymentInEmail = refundTransferInPaymentIn?.email;
  const refundPaymentInAmount = refundTransferInPaymentIn?.amount;
  const refundPaymentInMethod = refundTransferInPaymentIn?.method;
  const refundPaymentInSource = refundTransferInPaymentIn?.source;

  // Informazioni refound
  // Codice
  // Data richiesta
  // Utente richiesta
  // Beneficiario
  // Importo refound
  // TRANSAZIONE ORIGINALE
  // Importo transfer
  // Stato refound
  // Data completamento

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingCreateRefund}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
            <Dialog.Title
              as="h3"
              className="mb-5 text-2xl leading-6 font-medium text-gray-900"
            >
              Richiesta {code.substring(code.length - 5)}
            </Dialog.Title>

            <h3 className="font-bold mb-3">Richiesta rimborso</h3>
            <div className="border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Data</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {formatDate(startAt)}
                  </dd>
                </div>
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Richiedente</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {sourceUser.username}
                  </dd>
                </div>
                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Beneficiario</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {targetUser ? targetUser.username : 'Non registrato'}
                  </dd>
                </div>

                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Importo</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {formatMoney(refundTransferAmount)}
                  </dd>
                </div>

                <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Stato</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <TransferStatus status={status} />
                  </dd>
                </div>
              </dl>
            </div>

            <h3 className="font-bold mb-3">Dati Pagamento</h3>
            <div className="border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200">
                {refundTransferInCode && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Codice</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {refundTransferInCode.substring(refundTransferInCode.length - 5)}
                    </dd>
                  </div>
                )}

                {refundTransferInCompleteAt && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Data</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(refundTransferInCompleteAt)}
                    </dd>
                  </div>
                )}

                {refundPaymentInEmail && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {refundPaymentInEmail}
                    </dd>
                  </div>
                )}

                {refundPaymentInAmount && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Importo</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatMoney(refundPaymentInAmount)}
                    </dd>
                  </div>
                )}

                {refundPaymentInSource && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Fonte</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {refundPaymentInSource.description}
                    </dd>
                  </div>
                )}

                {refundPaymentInMethod && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Metodo</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {refundPaymentInMethod.description}
                    </dd>
                  </div>
                )}

                {refundTransferInStatus && (
                  <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Stato</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <TransferStatus status={refundTransferInStatus} />
                    </dd>
                  </div>
                )}
              </dl>
            </div>

            {type.code === TransferTypeEnum.REFUND &&
              status.code === TrasnferStatusEnum.PENDING && (
                <div className="mt-2 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
                  <div className="sm:col-span-3">
                    <Switch.Group as="div" className="flex items-start justify-between">
                      <span className="flex-grow flex flex-col">
                        <Switch.Label
                          as="span"
                          className="block text-md font-medium text-gray-700"
                          passive
                        >
                          <span className="block text-sm font-medium text-gray-700">
                            Approva rimborso
                          </span>
                        </Switch.Label>
                      </span>

                      <Controller
                        name="hasApprove"
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                          <Switch
                            checked={value}
                            onChange={onChange}
                            className={classNames(
                              value ? 'bg-pink-600' : 'bg-gray-200',
                              'relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500'
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                value ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200'
                              )}
                            />
                          </Switch>
                        )}
                      />
                    </Switch.Group>
                    {/* {errors.hasConfirm?.type === 'required' ? (
                  <p className="mt-2 text-red-500 text-sm">{errors.hasConfirm.message}</p>
                ) : null} */}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="mt-5 sm:flex sm:flex-row-reverse">
          {data?.status.code === TrasnferStatusEnum.PENDING && (
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Conferma
            </button>
          )}
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Chiudi
          </button>
        </div>
      </form>
    </ModalLayout>
  );
};
